@use "variables";

#inpostShipping {
    .modal-dialog {
        width: 395px;

        @media(max-width: 767px) {
            width: auto;
            margin: 15px;
        }
    }

    @media(max-width: 767px) {
        .modal-title {
            font-size: 16px;
        }
    }

    .inpost-group {
        position: relative;
    }

    .inpost-input {
        height: 53px;
        border: 1px solid #D1D5DB;
        border-radius: 5px;
        color: #6B7280;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 23px;
    }

    .search-for-inpost {
        position: absolute;
        right: 8px;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        width: 80px;
    }

    .section-title-bottom {
        text-align: left;
        color: #808599;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 15px;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    .inpost-results {
        margin: 0 -10px;

        .loader {
            margin: 10px;
            font-size: 14px;
        }
    }

    .inpost-result-box {
        text-align: left;
        display: flex;
        align-items: center;
        transition: all .2s ease-in-out;
        margin-bottom: 2px;
        padding: 8px 10px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .name-wrapper {
            flex-grow: 1;

            & > div {
                font-size: 13px;
                line-height: 13px;
                font-weight: 500;
                color: #808599;

                &.top {
                    color: #1E2026;
                    margin-bottom: 3px;
                    font-weight: 600;
                }
            }
        }

        .tick-box {
            height: 24px;
            width: 24px;
            border: 1px solid #BAC0CD;
            transition: all .2s ease-in-out;
            border-radius: 50%;
        }

        &:hover, &.active {
            background-color: rgba(48, 50, 59, 0.1);
            cursor: pointer;

            .tick-box {
                border-color: #1E2026;
            }
        }

        &.active {
            .tick-box {
                border: 1px solid #1E2026;
                background-color: rgba(48, 50, 59, 0.1);
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background-color: #1E2026;
                }
            }
        }
    }
}

.selmo-alert, .selmo-react-alert {
    position: fixed !important;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 22px 0 rgba(16, 24, 39, 0.08);
    padding: 11px;
    border-left: 5px solid #000;
    z-index: 9999;
    min-width: 400px;
    transition: all .2s ease-in-out;

    -webkit-animation: selmoAlert .5s;
    -moz-animation: selmoAlert .5s;
    -ms-animation: selmoAlert .5s;
    -o-animation: selmoAlert .5s;
    animation: selmoAlert .5s;

    @keyframes selmoAlert {
        from {
            opacity: .5;
            top: 5px;
        }
        to {
            opacity: 1;
            top: 15px;
        }
    }

    @media(max-width: 767px) {
        min-width: 0;
        width: 90%;
    }

    .top-part {
        display: flex;
        align-items: center;
    }

    .icon {
        margin-right: 8px;
        height: 16px;
        width: 16px;
        border-radius: 3px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 7px;
    }

    .title {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
        display: flex;
        margin-top: 1px;
        align-items: center;
        margin-right: 10px;
    }

    .btn-close {
        margin-left: auto;
        padding-right: 0;
        position: relative;
        top: 1px;
        outline: none;
        box-shadow: none;

        &:focus, &:active {
            outline: none;
            box-shadow: none;
        }

        &:before {
            font-size: 10px;
        }
    }

    .desc {
        text-align: left;
        color: #246C57;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        margin-left: 25px;
        margin-top: 3px;
        margin-bottom: 0;
    }

    &.alert-success {
        border: 1px solid #0EB981;
        background-color: #F1FAF7;

        .icon-tick {
            position: relative;
            left: 0.2px;
            top: -0.7px;

            &:before {
                font-weight: bold;
            }
        }

        .icon {
            background: #0EB981;
        }

        .title {
            color: #2A473E;
        }

        .btn-close {
            color: #45B796;
        }

        .desc {
            color: #246C57;
        }
    }

    &.alert-fail {
        border: 1px solid #F87171;
        background-color: #FDF1F1;

        .icon-cross {
            position: relative;
            left: .7px;
            top: -0.7px;
        }

        .icon {
            background: #F87171;
        }

        .title {
            color: #D34545;
        }

        .btn-close {
            color: #F87171;
        }

        .desc {
            color: #F87171;
        }
    }

    &.alert-info {
        border: 1px solid #3C61EA;
        background-color: #EFF6FF;

        .icon-cross {
            position: relative;
            left: .7px;
            top: -0.7px;
        }

        .icon {
            background: #3C61EA;
        }

        .title {
            color: #446aea;
        }

        .btn-close {
            color: #3C61EA;
        }

        .desc {
            color: #3C61EA;
        }
    }
}

.inpost-results-wrapper {
    margin-top: 15px;

    .inpost-section-title {
        color: #101827;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 23px;
        margin-bottom: 5px;
    }

    .inpost-row {
        border-color: #e5e7eb;
    }
}

.inpost-row {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    border: 1px solid #D1D5DB;
    border-radius: 5px;
    padding: 20px;

    .photo {
        margin-right: 10px;
    }

    .name-wrapper {
        flex-grow: 1;

        .top-name {
            color: #1E2026;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 13px;
            margin-bottom: 5px;
        }

        .extra-info {
            color: #808599;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 13px;
        }
    }
}

.status-bg {
    border-radius: 3px;
    padding: 6px;
    font-size: 0.625rem;
    line-height: 0.75rem;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    min-width: 92px;

    &.waiting, &.blue {
        color: variables.$status-blue;
        background: #EFF6FF;
    }

    &.to-send, &.orange {
        color: #DE8E09;
        background: #FCF6E5;
    }

    &.already-send, &.purple {
        color: #7C3AED;
        background: #EFEFFD;
    }

    &.finished, &.green {
        color: #069769;
        background: #EDF8F4;
    }

    &.grey {
        color: #6B7280;
        background: #F0F1F3;
    }

    &.not-paid, &.red {
        color: #E64747;
        background: #FDF0F0;
    }

    &.green {
        color: #0EB981;
        background-color: #E6F8F2;
    }

    &.grey {
        color: #6B7280;
        background: #F3F4F6;
    }

    &.edited, &.pink {
        color: #FF2BAD;
        background: #FFE8F8;
    }

    &.cancelled {
        background: #F0F1F3;
        color: #6B7280;
    }

    &.open-cart, &.light-blue, &.open-package {
        background: #EDFAFE;
        color: #00BCEF;
    }

    &.black {
        background: #000;
        color: #fff;
    }

    &.border {
        border: 1px solid #E5E7EB;
        color: #1F2937;
        min-width: 105px;
    }

    &.with-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        img, i {
            width: 10px;
            font-size: 4px;
            margin-right: 4px;
        }
    }

    &.variant {
        color: #4B5563;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 12px;
        min-width: auto;
        padding: 6px;
    }

    &.small-status {
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 12px;
        min-width: auto;
        padding: 6px;
    }

    &.added-to-live {
        font-size: 12px;
        padding: 10px;
        border-radius: 5px;
    }
}

.empty-state-container {
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.messenger-container {
        //background-image: url('/public/assets/images/placeholder/selmo-messenger-placeholder.jpeg');
        //TODO
        background-size: 100%;
        background-repeat: no-repeat;
    }

    &.storage-container {
        //background-image: url('/public/assets/images/placeholder/selmo-storage-placeholder.jpeg');
        //TODO
        background-size: 100%;
        background-repeat: no-repeat;
    }

    &.table-container {
        height: 100%;
    }

    .empty-state {
        max-width: 390px;
        text-align: center;
        width: 100%;

        .img-icon {
            width: 104px;
            margin: 0 auto 20px auto;
            text-align: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .title {
            color: #1F2937;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 30px;
            text-align: center;

            @media(max-width: 991px) {
                font-size: 20px;
                line-height: 26px;
            }
        }

        .desc {
            color: #9CA3AF;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            margin-top: 5px;
            margin-bottom: 32px;

            @media(max-width: 991px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &.in-add-order {
        height: 456px;

        .empty-state {
            .title {
                font-size: 20px;
            }

            .desc {
                margin-top: 2px;
            }
        }
    }

    &.full-height {
        flex-grow: 1;

        .empty-state {
            width: auto;
        }
    }
}

.table-responsive {
    .empty-state-container {
        background: transparent;
        box-shadow: none;
        margin: 50px 0;

        .empty-state {
            .title {
                font-size: 18px;
                color: #6b7280;
                font-weight: 500;

                strong {
                    color: #1f2937;
                }
            }
        }
    }
}

.form-info-box {
    border-radius: 7px;
    background-color: #F0FAFF;
    padding: 20px 16px;
    display: inline-flex;


    i {
        font-size: 18px;
        position: relative;
        top: 1px;
        margin-right: 10px;
        color: #5fa5fa;
    }

    .tag {
        display: inline-block;
        border-radius: 3px;
        background-color: #5FA5FA;
        padding: 4px 8px;
        color: #FFFFFF;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        margin-left: 5px;
    }

    .title {
        color: #0D3364;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 2px;

        .tag {
            text-transform: uppercase;
            font-size: 8px;
            font-weight: bold;
            letter-spacing: 0.6px;
            line-height: 14px;
            padding: 2px 5px;
        }

        > a {
            text-decoration: underline;
        }
    }

    .desc {
        color: #0E276D;
        font-size: 14px;
        opacity: 0.8;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: 2px;

        > a {
            margin-left: 3px;
            color: inherit;
            text-decoration: underline;
            word-break: break-all;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                color: #101827;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 16px;
                margin-bottom: 6px;
            }
        }

    }

    &.orange {
        background-color: #FEFCE8;

        i {
            color: #FBCC14;
        }

        .title {
            color: #854E0F;
        }

        .desc {
            color: #A16107;
        }
    }

    &.green {
        background-color: #EFFEF4;

        i {
            color: #3CD9A0;
        }

        .title {
            color: #156534;
        }

        .desc {
            color: #18803D;
        }
    }

    &.red {
        background-color: #FEF2F2;

        i {
            color: #F87171;
        }

        .title {
            color: #981B1B;
        }

        .desc {
            color: #B91F1D;
        }
    }

    &.country-error {
        .title, .desc {
            font-size: 12px;
        }

        .country-row {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            span {
                font-weight: bold;
                margin-right: 5px;

                &:after {
                    content: ",";
                }

                &:first-of-type {
                    margin-left: 5px;
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    &.with-button {
        @media(max-width: 480px) {
            flex-wrap: wrap;
            .button {
                width: 100%;
            }
        }

        .button.small-size {
            margin-left: auto;
            align-self: center;
            padding: 8px 13px;
        }

        .button.white-bg {
            background: #fff;
        }
    }

    &.transparent {
        background: transparent;
    }

    &.small-line-height {
        padding: 10px 12px;

        .title {
            font-size: 12px;
            line-height: 18px;
        }

        .desc {
            font-size: 12px;
            line-height: 18px;
        }
    }

    &.alert-style {
        padding: 8px 12px;
        align-items: center;

        .title {
            font-size: 12px;
            font-weight: 600;
            color: #F87171;
        }
    }
}

.xs-visible {
    display: none;
    @media(max-width: 576px) {
        display: inline-block;
    }
}

.form-group {
    .form-control {
        @media(max-width: 480px) {
            &::placeholder {
                font-size: 14px !important;
            }
        }
    }
}

.dotted-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

input.dotted-text {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    color: inherit;
    flex-grow: 1;
}

.product-sizes-wrapper {

    .product-sizes-row {
        display: flex;
        align-items: center;
        color: #101827;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        border-bottom: 1px solid #E5E7EB;
        padding: 6px 0;

        .name-col {
            width: 30%;
        }

        .code-col {

        }

        .squ {
            margin-left: auto;
            text-align: right;
        }

        &.label-row {
            color: #6B7280;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }
}
