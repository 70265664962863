.clients-page {
	background-color: #fff;
	padding: 28px 22px;
	border-radius: 5px;

	.d-flex {
		margin-bottom: 25px;

		.desc {
			color: #6B7280;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: -0.1px;
			line-height: 22px;
		}

		@media(max-width: 767px) {
			flex-wrap: wrap;
			.flex-grow-1 {
				margin-bottom: 10px;
			}
		}
	}

	h1 {
		font-size: 18px;
		font-weight: bold;
		line-height: 23px;
		color: #101827;
		display: flex;
		align-items: center;
		margin-bottom: 0;

		span {
			position: relative;
			top: 1px;
			font-size: 12px;
			width: 20px;
			height: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 5px;
			background: #f3f4f6;
			color: #4B5563;
			border-radius: 3px;
		}
	}

	.client-row {
		display: flex;
		align-items: center;
		padding: 12px 0;
		border-bottom: 1px solid #f6f7f8;
		font-size: .875rem;
		line-height: 1.125rem;

		.flex-grow-1 {
			min-width: 250px;
		}

		&:last-of-type {
			border: 0;
		}

		&__numbers {
			min-width: 160px;
			width: 160px;
			text-align: right;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			&.active {
				color: #000;
			}

			&.left {
				text-align: left;
				justify-content: flex-start;
				min-width: 80px;
				width: 80px;
			}

			&.asc {
				i {
					transform: rotate(180deg);
					display: block;
				}
			}
		}
	}

	.header-row {
		color: #6B7280;
		font-size: 0.75rem;
		line-height: 1rem;
		font-weight: bold;
		border: 0;

		a {
			color: #6B7280;
			text-decoration: none;
		}

		i {
			font-size: 10px;
			margin-left: 3px;
		}
	}

	.search-wrapper {
		position: relative;
		font-size: 0;
		color: transparent;
		margin-bottom: 0;

		&:before {
			content: '\e913';
			font-family: selmo;
			position: absolute;
			left: 8px;
			top: 6px;
			font-size: 14px;
			color: #9AA3B0;
		}

		input {
			border-radius: 5px;
			height: 33px;
			width: 160px;
			color: #101827;
			font-size: 0.75rem;
			line-height: 1rem;
			padding: 8px 8px 8px 30px;
			background-color: #F3F4F6;
			border: 0;

			&:focus-within {
				outline: none;
			}
		}
	}
}

.pagination-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px 0;

	a, strong {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #6B7280;
		width: 32px;
		height: 32px;
		border-radius: 5px;
		font-size: 0.875rem;
		font-weight: 500;
		margin-right: 10px;
		text-decoration: none;
	}

	strong {
		color: #101827;
		background-color: #fff;
		box-shadow: 0 12px 16px rgba(0, 0, 0, .04), 0 1px 3px rgba(0, 0, 0, .05);
	}

	.icon-arrows {
		font-size: 10px;
	}

	.prevlink {
		.icon-arrows {
			transform: rotate(90deg);
		}
	}

	.nextlink {
		.icon-arrows {
			transform: rotate(270deg);
		}
	}
}

.client-details-row {
	display: flex;
	margin: 0 -7px;
	@media(max-width: 991px) {
		flex-wrap: wrap;
	}

	> div {
		padding: 0 7px;
	}

	.orders-col {
		width: 65%;
		flex-grow: 1;
		@media(max-width: 991px) {
			width: 100%;
			order: 2;
		}

		.react-pagination-wrapper {
			.items-per-page-select {
				> span {
					display: none;
				}
			}
		}
	}

	.details-col {
		width: 40%;
		@media(max-width: 991px) {
			width: 100%;
			margin-bottom: 10px;
		}

		.default-shadow-box {
			@media(max-width: 480px) {
				padding-top: 25px;
				padding-bottom: 25px;
			}
		}

		.top-part {
			@media(max-width: 991px) {
				width: 100%;
			}

			.avatar-row {
				display: flex;
				align-items: center;


				figure {
					height: 50px;
					width: 50px;
					margin-right: 10px;
					margin-bottom: 0;
					@media(max-width: 480px) {
						width: 35px;
						height: 35px;
						margin-right: 6px;
					}

					img:not(.msg-logo) {
						object-fit: cover;
						width: 100%;
						height: 100%;
						border-radius: 100%;
					}

					.msg-logo {
						right: -2px;
						bottom: -2px;
						width: 20px;
						height: 20px;
					}
				}

				.name {
					color: #101827;
					font-size: 18px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 23px;
					@media(max-width: 480px) {
						font-size: 14px;
						line-height: 20px;
					}
				}

				.client-number {
					color: #6B7280;
					font-size: 14px;
					letter-spacing: -0.12px;
					line-height: 18px;
					@media(max-width: 480px) {
						font-size: 12px;
						line-height: 16px;
					}
				}
			}
		}

		.data-part {
			margin-top: 20px;
			width: 100%;

			.title {
				color: #101827;
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0;
				line-height: 18px;
				margin-bottom: 8px;
			}

			.data {
				color: #6B7280;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: -0.1px;
				line-height: 22px;
			}

			&.with-border {
				border-top: 1px solid #F3F4F6;
				padding-top: 20px;
			}

			.stats-boxes {
				margin-left: -7px;
				margin-right: -7px;
				display: flex;
				align-items: center;
				@media(max-width: 480px) {
					flex-wrap: wrap;
				}

				> div {
					padding: 0 7px;
					width: 50%;
					@media(max-width: 480px) {
						width: 100%;
						&:first-of-type {
							margin-bottom: 10px;
						}
					}

					.inner {
						padding: 12px 14px;
						border-radius: 5px;
						background-color: #F9FAFB;

						.label {
							color: #6B7280;
							font-size: 12px;
							font-weight: 500;
							letter-spacing: 0;
							line-height: 15px;
						}

						.value {
							color: #101827;
							font-size: 16px;
							font-weight: bold;
							letter-spacing: 0;
							line-height: 21px;
						}
					}
				}
			}

			.email-status-wrapper {
				h3 {
					color: #101827;
					font-size: 14px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 18px;
				}
			}
		}
	}
}
