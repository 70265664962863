@mixin custom-scrollbar {
	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background: #e7e7e7;
	}
}
