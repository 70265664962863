
#root {
  height: 100%;
}

html,
body {
  height: 100%;
  @media (min-width: 991px) {
    height: auto;
  }
  @media (max-width: 991px) {
    overflow-x: hidden;
  }
}

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #dce2e5;
  }
}

@mixin custom-thin-scrollbar {
  &::-webkit-scrollbar {
    height: 14px;
  }

  &::-webkit-scrollbar-track {
    background: #f9fafb;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d1d5db;
    border: 3px solid #f9fafb;
  }
}

.selmo-video-wrapper {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  &.no-border-sm {
    iframe {
      @media (max-width: 480px) {
        border-radius: 0;
      }
    }
  }
}

.tutorial-finished-modal {
  h2 {
    color: #1f2937;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.4px;
    line-height: 25px;
    margin-bottom: 3px;
  }

  .description {
    margin-bottom: 15px;
    font-size: 14px;
    color: #6b7280;
  }

  .help-text {
    margin-top: 10px;
    font-size: 14px;

    a {
      color: #6b7280;
      text-decoration: underline;
    }
  }

  .modal-header {
    padding: 0 !important;
  }

  .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 11px !important;
  }

  .modal-body {
    padding: 30px 25px 25px !important;
    @media (max-width: 991px) {
      padding: 20px !important;
    }
  }

  .modal-footer {
    justify-content: center;
  }

  .button {
    display: block;
    margin: 0 auto;
    border: 1px solid #dfe1e4;
    background-color: #ffffff !important;
    color: #4b5563 !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
  }
}

.pricing-wrapper {
  h2 {
    color: #101827;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -0.4px;
    line-height: 35px;
    text-align: center;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 22px;
      margin-top: 15px !important;
    }
  }

  .description {
    color: #6b7280;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.23px;
    line-height: 21px;
    text-align: center;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.selmo-trial-info {
  height: 40px;
  background-color: #3b61ea;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-decoration: none !important;

  ~ .main-container .full-height-page .table-responsive {
    max-height: calc(100vh - 330px) !important;
    @media (max-width: 991px) {
      max-height: none !important;
    }
  }

  + .message-container,
  + .storage-container {
    height: calc(100% - 40px) !important;
  }

  &:hover {
    color: #fff;
  }

  button {
    margin-left: 15px;
    height: 26px;
    border-radius: 3px;
    background-color: #fff;
    color: #3b61ea;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    border: 0;
    box-shadow: none;
    padding: 0 6px;
  }

  strong {
    color: #0eb981;
  }

  &__days {
    display: flex;
    align-items: center;
    margin-right: 3px;

    & > div {
      border-radius: 3px;
      background-color: rgba(#fff, 0.2);
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
      height: 22px;
      width: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 2px;
    }
  }

  @media (max-width: 767px) {
    padding: 0 15px;
    color: #ffffff;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    button {
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 12px;
      height: 24px;
    }

    .mobile-hidden {
      display: none;
    }

    &__days {
      & > div {
        height: 20px;
        width: 18px;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 12px;
        text-align: center;
      }
    }
  }

  + .main-container .main-aside > div {
    @media (min-width: 991px) {
      height: calc(100vh - 75px);
    }
  }

  &.red {
    background: #f75f5e;

    .selmo-trial-info__days {
      > div {
        background-color: rgba(#000, 0.2);
      }
    }

    button {
      color: #f75f5e;
    }
  }
}

.selmo-app-bar {
  ~ .main-container .full-height-page .table-responsive {
    max-height: calc(100vh - 330px) !important;
    @media (max-width: 991px) {
      max-height: none !important;
    }
  }

  + .message-container,
  + .storage-container {
    height: calc(100% - 40px) !important;
  }

  + .main-container .main-aside > div {
    @media (min-width: 991px) {
      height: calc(100vh - 75px);
    }
  }
}

.btn-close {
  position: relative;
  background: transparent;
  border: 0;
  font-size: 10px;

  &:after {
    font-family: 'selmo';
    content: '\e909';
  }
}

.chart-placeholder-box {
  background-color: #ffffff;
  padding: 15px;
  height: 300px;
  border-radius: 5px;
  position: relative;

  img {
    height: 56px;
    margin-bottom: 15px;
  }

  @media (max-width: 991px) {
    height: 200px;
    width: 100%;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    @media (max-width: 991px) {
      top: 0;
      left: 0;
      transform: translate(0);
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .main-text {
      font-size: 18px;
      white-space: nowrap;
      font-weight: 500;
      color: #6b7280;

      strong {
        color: #1f2937;
      }

      @media (max-width: 991px) {
        font-size: 14px;
        white-space: normal;
      }
    }
  }

  &__button-place {
    text-align: center;
    margin-top: 25px;

    .btn {
      margin: 0 auto;
      height: 48px;
      font-size: 16px;
      padding: 0 20px;

      i {
        font-size: 14px;
      }

      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
  }

  img {
    max-width: 100%;
  }
}

.main-container {
  position: relative;
  max-width: 1260px;
  margin: 0 auto;
  height: 100%;
  @media (max-width: 1280px) {
    max-width: none;
    padding: 0 20px;
    background: #eff2f6;
  }

  .wrapper-section {
    display: flex;
    padding: 15px 0;
    height: 100%;
    @media (max-width: 991px) {
      position: relative;
      display: block;
      padding: 0;
    }

    > .loader-container {
      left: -20px;
      right: -20px;
      width: auto;
    }

    .header-bar {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      @media (max-width: 991px) {
        margin: 0;
      }

      .switcher-header-wrapper {
        display: flex;
        align-items: center;

        .form-group {
          margin-bottom: 0;
          margin-right: 30px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .notification-wrapper {
        margin-right: 10px;
        margin-left: auto;

        > button {
          background: transparent;
          border: 0;
          padding: 0;
        }
      }

      &.breadcrumbs-in-react-app {
        position: relative;
        display: block;
        margin-bottom: 0;
        @media (max-width: 991px) {
          margin: 20px;
        }

        .breadcrumbs {
          position: absolute;
          top: -35px;
          @media (max-width: 991px) {
            position: static;
          }
        }
      }
    }

    .tutorial-page-box + .header-bar.breadcrumbs-in-react-app .breadcrumbs,
    .tutorial-page-box
      + .settings-page
      .header-bar.breadcrumbs-in-react-app
      .breadcrumbs {
      @media (min-width: 991px) {
        top: -127px;
      }
    }

    main {
      //overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-left: 50px;
      @media (max-width: 1280px) {
        margin-left: 15px;
      }
      @media (max-width: 991px) {
        padding: 0;
        margin-left: -20px;
        margin-right: -20px;
      }

      &.full-height-page {
        #root {
          height: calc(100% - 45px);
          @media (max-width: 991px) {
            height: 100%;
          }
        }
      }

      &.orders-page {
        overflow: hidden;

        &.full-height-page {
          .table-responsive {
            max-height: calc(100vh - 290px);
            @media (max-width: 991px) {
              max-height: none;
            }
          }
        }
      }

      &.table-page {
        overflow: hidden;
      }
    }

    &.admin-wrapper-section {
      .main-aside {
        > div {
          min-height: 57px;
        }
      }

      main {
        margin-top: 15px;
        @media (max-width: 991px) {
          margin-top: 0;
        }

        .header-bar .profile-wrapper {
          display: none;
        }
      }
    }
  }

  .breadcrumbs {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow-x: auto;

    li {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;

      a {
        color: #6b7280;
      }

      &:after {
        content: '\e903';
        font-family: 'selmo';
        display: inline-block;
        margin: 0 10px;
        color: #d1d5db;
        font-size: 7px;
        transform: rotate(270deg);
      }

      &.active {
        color: #101827;
        font-weight: bold;
      }

      &:last-of-type {
        &:after {
          content: '';
        }
      }

      > div {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.storage-container {
    .storage-page {
      @media (max-width: 991px) {
        margin-left: -20px;
        margin-right: -20px;
        height: 100%;

        .header-bar {
          margin: 15px;
        }
        .back-to-panels {
          margin: 0 15px;
        }
      }
    }
  }

  &.message-container {
    @media (max-width: 991px) {
      overflow: hidden;
      margin: 0;
      padding: 0 20px;
    }

    .message-page {
      @media (max-width: 991px) {
        margin-left: -20px;
        margin-right: -20px;
        height: calc(100% - 57px);
      }

      .messenger-wrapper {
        .right-panel {
          @media (max-width: 991px) {
            padding-bottom: 0;
            height: auto;
            bottom: 0;
            .user-header {
              .orders-number {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .send-input-wrapper {
              margin-left: -20px;
              margin-right: -20px;
              border-bottom: 0;
              box-shadow: none;
              border-radius: 0;
            }
          }
          @media (max-width: 991px) {
            left: calc(100% + 20px);
          }

          .messenger-content {
            height: 0;
          }
        }

        &.open {
          @media (max-width: 991px) {
            .right-panel {
              left: -20px;
              right: -20px;
              width: auto;
              top: 57px;
            }
          }
        }
      }

      .header-bar {
        @media (max-width: 991px) {
          margin: 0;

          .switcher-header-wrapper {
            display: none;
          }
        }
      }
    }
  }

  &.settings-main-container {
    .header-bar {
      @media (max-width: 991px) {
        margin: 20px 15px;
      }
    }

    @media (min-width: 1200px) {
      .wrapper-section {
        .main-aside {
          > div {
            height: calc(100vh - 30px);

            ul {
              overflow-y: auto;
            }
          }
        }
      }
    }
  }

  &.full-container {
    max-width: 100%;
    margin: 0;
  }
}

.empty-datatable-alert {
  padding: 15px;
  text-align: center;
  color: #6b7280;
}

.flex-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.hidden-file-input {
  position: absolute;
  opacity: 0;
  top: -9999999px;
}

#user-email-modal {
  .modal-dialog {
    max-width: none;
    width: 300px;
  }
}

.normal-sellfie-alert {
  border: 1.5px solid #fcd34c;
  border-radius: 5px;
  background-color: #fff8e7;
  color: #101827;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  display: inline-flex;
  padding: 14px 16px 14px 10px;
  margin: 20px 0;

  i {
    color: #fcbe24;
    margin-right: 10px;
    font-size: 16px;
    position: relative;
    top: -1px;
  }

  span {
    color: #101827;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    background: #fcd34c;
    padding: 1px 4px;
    border-radius: 3px;
  }
}

.danger-sellfie-alert {
  border: 1.5px solid #f87171;
  border-radius: 5px;
  background-color: rgba(248, 113, 113, 0.15);
  color: #ef4444;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  display: flex;
  padding: 14px 10px;
  margin: 20px 0;

  i {
    color: #ef4444;
    margin-right: 10px;
    font-size: 16px;
    position: relative;
    top: -1px;
  }

  span {
    color: #101827;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    background: #fcd34c;
    padding: 1px 4px;
    border-radius: 3px;
  }
}

@-webkit-keyframes alertMove {
  from {
    top: 25px;
  }
  to {
    top: 50px;
  }
}

@keyframes delay {
  from {
    top: 25px;
  }
  to {
    top: 50px;
  }
}

.form-label {
  display: block;
  color: #6b7280;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 7px;

  &.bigger {
    font-size: 14px;
    line-height: 18px;
  }

  span {
    font-size: 10px;
  }
}

.small-status {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  display: inline-flex;
  align-items: center;

  i {
    margin-right: 4px;

    &.icon-cross {
      font-size: 8px;
    }
  }

  &.green {
    color: #0eb981;
  }

  &.blue {
    color: #3b82f6;
    background-color: #eff6ff;
  }

  &.grey {
    color: #9ca3af;
    background-color: #f3f4f6;
  }

  &.red {
    color: #f87171;
  }
}

hr {
  border-color: #e5e7eb;
}

.tabs-switcher {
  border-radius: 5px;
  background-color: #f3f4f6;
  padding: 3px;
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;

  button {
    display: block;
    color: #6b7280;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    padding: 10px 16px;
    background: transparent;
    white-space: nowrap;
    border: 1px solid transparent;

    &.active {
      border: 1px solid #dfe1e4;
      border-radius: 5px;
      background-color: #ffffff !important;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
      color: #4b5563 !important;
    }

    &:focus {
      outline: none;
    }
  }
}

.selmo-fb {
  max-width: 150px;
  margin-bottom: 25px;
}

.facebook-benefits-wrapper {
  > div {
    display: flex;
    align-items: center;

    i {
      color: #0eb981;
      margin-right: 16px;
      font-size: 14px;
    }

    .benefits-text {
      color: #1f2937;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      padding: 15px 0;
      border-bottom: 1px solid #e5e7eb;
      flex-grow: 1;

      span {
        font-weight: bold;
      }
    }

    &:last-of-type {
      .benefits-text {
        border-bottom: 0;
      }
    }
  }

  &.bigger-size {
    > div {
      .benefits-text {
        font-size: 18px;
        line-height: 20px;
        padding: 25px 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .mobile-w-100 {
    width: 100% !important;
  }
}

.select2-results {
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  margin-top: 4px;

  ul {
    padding: 3px;

    .select2-results__option {
      font-size: 12px;
      padding: 7px 9px;
      margin-bottom: 5px;
      border-radius: 3px;
      transition: all 0.3s ease-in-out;
      color: #6b7280;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover:not(.select2-results__message) {
        background: #f9fafb;
        color: #101827;
      }

      &--selected {
        font-weight: bold;
        color: #101827;
      }
    }
  }
}

.select2-option-repository {
  &__title {
    color: #101827;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__description {
    display: flex;
    align-items: center;

    & > div {
      color: #6b7280;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
    }

    &__quantity {
      position: relative;
      margin-left: 5px;
      display: flex;
      align-items: center;

      &:before {
        display: block;
        content: '';
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background: #9ca3af;
        margin-right: 5px;
      }
    }
  }
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 9px;
}

.select-sizes-wrapper {
  .select2-container {
    width: 100% !important;
  }

  .select2-container--default .select2-selection--single {
    height: 45px;
  }

  .select2-results {
    width: 300px;
  }
}

.select2-container--open .select2-dropdown--below {
  background: transparent;
  border: 0;
}

.select2-container--default {
  .select2-results__option--highlighted {
    background-color: #f3f4f6 !important;
    color: #101827 !important;
  }

  .select2-results__option--selectable {
    background: transparent;
    color: inherit;
  }
}

.status-select {
  + .select2-container--default {
    width: 180px !important;

    .select2-selection--single {
      display: flex;
      align-items: center;
      border-radius: 5px;
      height: 31px;
      min-height: auto;

      .select2-selection__rendered {
        color: inherit;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
      }

      .select2-selection__arrow {
        height: 31px;
        top: 0;
        right: 4px;

        b {
          display: block;
          position: absolute;
          border: 0;
          margin: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: none;
          color: inherit;

          &:after {
            content: '\e903';
            font-family: selmo;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 7px;
          }
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &.open-cart {
    + .select2-container--default .select2-selection--single {
      background: #00bff41a;
      border: 1px solid #00bcef;
      color: #00bcef;

      .select2-selection__rendered:before {
        background: #00bcef;
      }
    }
  }

  &.waiting {
    + .select2-container--default .select2-selection--single {
      background: #eff6ff;
      border: 1px solid #3c61ea;
      color: #3c61ea;

      .select2-selection__rendered:before {
        background: #3c61ea;
      }
    }
  }

  &.in-realization {
    + .select2-container--default .select2-selection--single {
      background: #fff6de;
      border: 1px solid #fcbe24;
      color: #fcbe24;

      .select2-selection__rendered:before {
        background: #fcbe24;
      }
    }
  }

  &.not-paid {
    + .select2-container--default .select2-selection--single {
      background: #fdf0f0;
      border: 1px solid #e64747;
      color: #e64747;

      .select2-selection__rendered:before {
        background: #e64747;
      }
    }
  }

  &.to-send {
    + .select2-container--default .select2-selection--single {
      background: #fcf6e5;
      border: 1px solid #de8e09;
      color: #de8e09;

      .select2-selection__rendered:before {
        background: #de8e09;
      }
    }
  }

  &.edited {
    + .select2-container--default .select2-selection--single {
      background: #ffe8f8;
      border: 1px solid #ff2bad;
      color: #ff2bad;

      .select2-selection__rendered:before {
        background: #ff2bad;
      }
    }
  }

  &.already-send {
    + .select2-container--default .select2-selection--single {
      background: #efeffd;
      border: 1px solid #7c3aed;
      color: #7c3aed;

      .select2-selection__rendered:before {
        background: #7c3aed;
      }
    }
  }

  &.finished {
    + .select2-container--default .select2-selection--single {
      background-color: #edf8f4;
      border: 1px solid #069769;
      color: #069769;

      .select2-selection__rendered:before {
        background-color: #069769;
      }
    }
  }

  &.cancelled {
    + .select2-container--default .select2-selection--single {
      background-color: #f0f1f3;
      border: 1px solid #6b7280;
      color: #6b7280;

      .select2-selection__rendered:before {
        background-color: #f0f1f3;
      }
    }
  }

  &.orders-filters-select {
    + .select2-container--default .select2-selection--single {
      border-radius: 5px !important;

      .select2-selection__rendered {
        font-weight: normal;
        color: #101827;
        @media (max-width: 991px) {
          color: #6b7280;
        }
      }
    }
  }
}

.product-paid-select {
  + .select2-container--default {
    width: 120px !important;

    .select2-selection--single {
      display: flex;
      align-items: center;
      border-radius: 5px;
      height: 31px;
      min-height: auto;

      .select2-selection__rendered {
        color: inherit;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
      }

      .select2-selection__arrow {
        height: 31px;
        top: 0;
        right: 4px;

        b {
          display: block;
          position: absolute;
          border: 0;
          margin: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: none;
          color: inherit;

          &:after {
            content: '\e903';
            font-family: selmo;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 7px;
          }
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &.paid {
    + .select2-container--default .select2-selection--single {
      border: none;
      background-color: #f2faf7;
      color: #0eb981;

      .select2-selection__rendered:before {
        background-color: #f2faf7;
      }
    }
  }

  &.not-paid {
    + .select2-container--default .select2-selection--single {
      border: none;
      background-color: #fcf6e5;
      color: #e79d00;

      .select2-selection__rendered:before {
        background-color: #fcf6e5;
      }
    }
  }
}

.filter-box {
  position: relative;
  display: flex;
  align-items: center;
  order: 3;
  margin-left: 10px;

  .daterange-input {
    border-radius: 5px 0 0 5px;
    height: 33px;
    border: 1px solid #dfe1e4;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 8px 8px 8px 25px;
    color: #101827;
    border-right: 0;
    width: 125px;

    &:focus {
      outline: none;
    }
  }

  &:before {
    content: '\e905';
    font-family: selmo;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #dfe1e4;
    font-size: 14px;
  }

  &:after {
    content: '\e903';
    font-family: selmo;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    width: 35px;
    border: 1px solid #dfe1e4;
    height: 33px;
    border-radius: 0 5px 5px 0;
    color: #6b7280;
    background: #fff;
  }
}

.dashboard-wrapper {
  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 .date {
      font-size: 14px;
      color: #6b7280;
      font-weight: normal;
    }
  }

  .default-shadow-box {
    @media (max-width: 480px) {
      padding: 20px !important;
    }
  }

  section {
    margin-bottom: 20px;

    .top-boxes-row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -6px;
      margin-right: -6px;
      //margin-bottom: -15px;
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }

      > div {
        width: 50%;
        padding: 0 6px;
        margin-bottom: 15px;
        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 10px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        a,
        > div {
          height: 100%;

          .label {
            color: #6b7280;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            @media (max-width: 991px) {
              margin-bottom: 0;
              flex-grow: 1;
              font-size: 14px;
            }

            img {
              width: 16px;
              height: 16px;
              object-fit: contain;
              margin-right: 6px;
            }
          }

          .value {
            color: #101827;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: -0.5px;
            line-height: 30px;
            @media (max-width: 991px) {
              font-size: 22px;
            }
          }

          &.goal {
            color: #069769 !important;
            background: #edf8f4 !important;

            .label,
            .value {
              color: inherit;
            }
          }

          &.red {
            color: #7f1d1e;
            background-color: #fef2f2;

            .label,
            .value {
              color: inherit;
            }
          }

          &.blue {
            color: #3c61ea;
            background: #eff6ff;

            .label,
            .value {
              color: inherit;
            }
          }

          &:hover {
            text-decoration: none;
          }
        }

        &:first-child {
          .new-stats-box {
            background: linear-gradient(
              45deg,
              #fdfff0 0%,
              rgba(250, 245, 217, 1) 50%,
              rgba(249, 239, 240, 1) 100%
            );
          }
        }

        &:nth-child(2) {
          .new-stats-box {
            background: linear-gradient(
              45deg,
              rgba(247, 233, 245, 1) 0%,
              rgba(240, 233, 246, 1) 50%,
              rgba(229, 233, 250, 1) 100%
            );
          }
        }

        &:nth-child(3) {
          .new-stats-box {
            background: linear-gradient(
              45deg,
              rgba(223, 236, 254, 1) 0%,
              rgba(249, 240, 227, 1) 100%
            );
          }
        }
      }
    }

    .configuration-wrapper.items-wrapper {
      @media (max-width: 991px) {
        width: 100%;
      }

      > div {
        margin-bottom: 9px;

        a {
          display: flex;
          align-items: center;
          border: 1px solid #e5e7eb;
          border-radius: 5px;
          color: #101827;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 18px;
          padding: 10px;

          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
            margin-right: 12px;
          }

          .button {
            margin-left: auto;
          }
        }
      }
    }

    .last-orders-wrapper {
      @media (max-width: 991px) {
        width: 100%;
      }

      > div {
        @media (max-width: 991px) {
          margin-left: -15px;
          margin-right: -15px;
        }

        &:last-of-type {
          a {
            border-bottom: 0;
            @media (max-width: 991px) {
              padding-bottom: 0;
            }

            i {
              @media (max-width: 991px) {
                bottom: 0;
              }
            }
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        color: #101827;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        padding: 13px 20px;
        border-bottom: 1px solid #e5e7eb;
        margin-left: -20px;
        margin-right: -20px;
        @media (max-width: 991px) {
          position: relative;
          padding: 15px;
          margin-left: 0;
          margin-right: 0;
          width: 100%;
          flex-wrap: wrap;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .time {
        margin-left: auto;
        color: #9ca3af;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        margin-right: 13px;
        @media (max-width: 991px) {
          width: 100%;
        }
      }

      i {
        transform: rotate(180deg);
        display: inline-block;
        font-size: 10px;
        color: #d1d5db;
        @media (max-width: 991px) {
          position: absolute;
          bottom: 15px;
          right: 15px;
        }
      }
    }

    .blogs-wrapper {
      a {
        display: flex;
        align-items: center;
        border: 1px solid #e5e7eb;
        border-radius: 5px;

        img {
          width: 70px;
          height: 70px;
          object-fit: cover;
        }

        .content {
          flex-grow: 1;
          height: 70px;
          padding: 0 14px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 18px;
            margin-bottom: 3px;
          }

          .desc {
            color: #9ca3af;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
          }
        }

        > i {
          color: #d1d5db;
          font-size: 10px;
          display: inline-block;
          transform: rotate(180deg);
          margin-right: 14px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    .video-wrapper {
      display: flex;
      height: 180px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }

      &:hover {
        cursor: pointer;
        background: #e5e7eb;
      }

      .video {
        position: relative;
        height: 100%;
        width: 240px;
        @media (max-width: 767px) {
          width: 100%;
        }

        .play-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000;
          height: 38px;
          width: 50px;
          font-size: 12px;
          border-radius: 7px;
          border: 0;
          background-color: #ffffff;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);

          i {
            position: relative;
            right: -1px;
          }

          &:focus {
            outline: none;
          }
        }

        video,
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .right-content {
        border: 1px solid #e5e7eb;
        border-left: 0;
        padding: 25px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex-grow: 1;
        border-radius: 0 5px 5px 0;

        .small-label {
          color: #9ca3af;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
          margin-bottom: 4px;
        }

        h2 {
          color: #101827;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 21px;
          flex-grow: 1;
        }
      }
    }

    .items-wrapper {
      > div {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.order-summary {
  width: 360px;
  margin-left: 50px;
  position: sticky;
  top: 50px;

  @media (max-width: 991px) {
    width: 100%;
    position: initial;
    margin-left: 0;
  }

  &.mobile {
    display: none;
  }

  @media (max-width: 991px) {
    &.mobile {
      background: #f3f4f6;
      margin-bottom: 15px;
      padding: 15px;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: 9px;

      &.active {
        display: block;
      }
    }

    &.desktop {
      display: none;
    }
  }

  &__title {
    color: #101827;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__products {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;

      figure {
        position: relative;
        width: 50px;
        height: 50px;
        min-width: 50px;
        margin: 0;
        margin-right: 12px;

        img {
          width: 100%;
          border-radius: 8px;
          object-fit: cover;
        }

        .count {
          position: absolute;
          top: -6px;
          right: -6px;
          color: #fff;
          border-radius: 50%;
          height: 16px;
          width: 16px;
          background-color: #6b7280;
          font-size: 10px;
          font-weight: 800;
          letter-spacing: 0;
          line-height: 12px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            position: relative;
          }
        }
      }

      &:last-of-type {
        .order-summary__products__content {
          border-bottom: 0;
        }
      }
    }

    &__content {
      display: flex;
      flex-grow: 1;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 21px;
      padding: 20px 0;
      border-bottom: 1px solid #e5e7eb;

      &__name {
        color: #101827;

        i {
          font-size: 10px;
          color: #6b7281;
          margin-left: 4px;
          position: relative;
          top: -1px;
        }
      }

      &__size {
        font-weight: 500;
        color: #9ca3af;

        span {
          color: #101827;
        }
      }

      &__price {
        margin-left: auto;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        text-align: right;

        .old-price {
          text-decoration: line-through;
          font-size: 12px;

          + .new-price {
            color: #e93245;
          }
        }
      }

      &.in-clients-orders {
        min-height: 75px;
        display: flex;
        align-items: center;
      }

      .hover-icons {
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease-in-out;
        display: flex;
        align-items: center;
        background: #f3f4f6;
        padding: 2px 3px;
        border-radius: 9px;
        position: relative;
        top: 2px;

        .source-icon {
          margin-left: 2px;
        }
      }

      &:hover {
        .hover-icons {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &__prices {
    padding: 25px 0;
    border-bottom: 1px solid #e5e7eb;
    border-top: 1px solid #e5e7eb;
  }

  .price-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .name {
      color: #101827;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      flex-grow: 1;
    }

    .value {
      color: #101827;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  &__final-price {
    .price-row {
      padding: 25px 0;

      .name {
        color: #101827;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 25px;
        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 23px;
        }
      }

      .value {
        color: #101827;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        text-align: right;
        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 23px;
        }
      }
    }
  }

  &__description {
    position: relative;
    margin-top: 25px;

    @media (max-width: 767px) {
      margin-top: 0;
    }

    h3 {
      color: #101827;
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 23px;
    }

    .more-description {
      color: #6b7280;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 22px;
      position: relative;
      max-height: 200px;
      overflow: hidden;
      min-height: 58px;

      &:before {
        content: '';
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          0deg,
          rgba(243, 244, 246, 1) 0%,
          rgba(243, 244, 246, 0.8) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        z-index: 9;
      }
    }

    &.active {
      .more-description {
        overflow: visible;
        max-height: none;

        &:before {
          display: none;
        }
      }
    }

    .more-description-btn {
      background: linear-gradient(
        0deg,
        rgba(243, 244, 246, 1) 0%,
        rgba(243, 244, 246, 0.8) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      padding: 10px 0;
      border: 0;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 12;

      &:focus {
        outline: none;
      }
    }
  }
}

.w-sm-auto {
  @media (min-width: 480px) {
    width: auto !important;
  }
}

.w-sm-50 {
  width: auto;
  @media (min-width: 480px) {
    width: 50% !important;
  }
}

.w-sm-75 {
  width: auto;
  @media (min-width: 480px) {
    width: 75% !important;
  }
}

.w-lg-auto {
  @media (min-width: 991px) {
    width: auto !important;
  }
}

.default-shadow-box {
  display: block;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  padding: 26px 20px 16px;
  @media (max-width: 991px) {
    padding: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .title {
    color: #101827;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      width: 100%;
      font-size: 16px;
      line-height: 21px;
    }

    a {
      margin-left: auto;
      color: #3c61ea;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      text-align: right;
      text-decoration: underline;
    }

    &.smaller {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 21px;
    }
  }

  .sub-title {
    color: #6b7280;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .button-place {
    border-top: 1px solid #f6f7f8;
    padding-top: 15px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 991px) {
      padding-top: 0;
      border-top: 0;
      flex-wrap: wrap;
      width: 100%;
      .button {
        width: 100%;
      }
    }
  }

  &.bigger-y-padd {
    padding-top: 20px;
    padding-bottom: 20px !important;
  }

  &.bigger-x-padd {
    @media (min-width: 991px) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  &.big-padd {
    padding-top: 30px;
    padding-bottom: 30px !important;

    @media (max-width: 991px) {
      align-items: flex-start;
    }
    @media (max-width: 767px) {
      padding-top: 20px;
      padding-bottom: 20px !important;
    }

    .form-col {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
      border-bottom: 0 !important;
    }
  }

  &.new-stats-box {
    background: linear-gradient(
      45deg,
      rgba(255, 243, 245, 1) 0%,
      rgba(255, 247, 223, 1) 100%
    );
    box-shadow: none;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 15px;
    padding: 20px;

    @media (max-width: 480px) {
      align-items: flex-start;
      height: auto !important;
    }

    .circle-icon {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background: #fff;
      margin-bottom: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;

      img {
        width: 18px;
      }

      &.flag {
        border: 1px solid #dadada;
        overflow: hidden;
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.bigger {
          width: 30px;
          height: 30px;
        }
      }
    }

    .label {
      font-weight: normal !important;
      color: #828a99 !important;
      margin-bottom: 0 !important;
      @media (max-width: 480px) {
        font-size: 10px !important;
        line-height: 14px !important;
      }
    }

    .value {
      font-size: 36px !important;
      line-height: 44px !important;
      @media (max-width: 480px) {
        font-size: 30px !important;
        line-height: 38px !important;
      }
    }
  }
}

.custom-dropdown {
  box-shadow: 0 4px 16px 0 rgba(#000, 0.12), 0 1px 3px 0 rgba(#000, 0.08);
  background: #fff;
  position: absolute;
  z-index: 1;
  padding: 0;
  margin-top: 5px;
  border-radius: 5px;
  min-width: 100%;
  list-style: none;
  max-height: 300px;
  overflow-y: auto;

  > div {
    font-weight: 500;
    cursor: pointer;
    padding: 8px;
    transition: all 0.1s ease-in-out;

    .name {
      position: relative;
      top: 1px;
      display: flex;
      align-items: center;
      font-weight: bold;
      overflow: visible;
    }

    .client-number {
      color: #6b7280;
      font-size: 10px;
      font-weight: 300;
      line-height: 10px;
    }

    &:hover:not(.no-result-message):not(.loader-parent) {
      background: #f3f4f6;
    }
  }

  &.opened-custom-dropdown {
    padding: 5px;

    .select-custom-option {
      border-radius: 4px;
    }
  }

  .dropdown-search {
    margin-bottom: 0;
    padding: 10px;

    input {
      height: 30px;
      min-height: 35px;
      box-shadow: none;
      border: 1px solid #e5e7eb;
      background: transparent;
      width: 100%;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      padding: 0 10px;
      color: #101827;

      &:focus {
        border-color: #e5e7eb !important;
        box-shadow: 0 0 0 1px #e5e7eb;
        outline: none;
      }
    }
  }
}

.select-custom-dropdown {
  padding: 0;
}

.select-custom-option {
  color: #101827;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;

  .name {
    color: #101827;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .bottom-bar {
    color: #6b7280;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 10px;
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;

      &:after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 5px;
        background: #a9b3cb;
        margin: 0 5px;
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }

    &.no-separator {
      > div::after {
        display: none;
      }
    }
  }

  .placeholder {
    font-weight: normal;
  }

  button {
    background: transparent;
    border: 0;
    padding: 0;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus:not(.disabled-bg):not(.no-result-message) {
    background: #f3f4f6;
    outline: none;
    box-shadow: none;
  }
}

.inputs-wrapper {
  &.only-first-row-labels {
    .inputs-row {
      margin-top: 6px;

      .button-col {
        .react-dropdown {
          top: 7px;

          .dropdown-toggle {
            transition: all 0.2s ease-in-out;

            &:hover {
              color: #000;
            }

            &:before {
              display: none;
            }
          }
        }
      }

      &:not(:first-of-type) {
        .form-group:not(.floated-small-label) {
          .control-label {
            display: none;
          }
        }

        .floated-small-label {
          &:after {
            content: attr(data-text);
            display: block;
            position: absolute;
            left: 10px;
            top: 4px;
            font-size: 8px;
          }

          .control-label {
            display: none;
            position: absolute;
            left: 10px;
            top: 4px;
            font-size: 8px;

            + .form-control {
              padding-top: 14px;
            }
          }
        }

        .button-col {
          .remove-row-button {
            top: -3px;
          }

          .react-dropdown {
            top: -2px;
          }
        }
      }

      &:first-of-type {
        .tooltip-content {
          bottom: calc(100% - 8px);
        }
      }
    }
  }
}

.inputs-row {
  &.small-inputs {
    margin-left: -3px;
    margin-right: -3px;
    @media (max-width: 991px) {
      flex-wrap: nowrap;
    }

    > div {
      padding: 0 3px;

      .form-group {
        margin-bottom: 0;

        .control-label {
          display: block;
          color: #6b7280;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 12px;
          margin-bottom: 7px;
        }

        .form-control {
          border-radius: 5px;
          box-shadow: none;
          border: 1px solid #e5e7eb;
          transition: none;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          padding: 6px 10px;
          color: #101827;
          height: 35px;
          min-height: 35px;
          width: 100%;
          display: flex;
          align-items: center;

          &::placeholder {
            color: #9aa3b0;
          }
        }

        #custom-dropdown-default [class*='-control'] {
          box-shadow: none;

          > div:first-of-type {
            font-weight: 500;
            color: #101827;
          }
        }
      }

      .remove-row-button {
        color: #9ca3af;
        position: relative;
        font-size: 8px;
        top: 6px;
        background: transparent;
        border: 0;
        padding: 0;
      }
    }
  }

  &.single-client-row {
    .tooltip-content {
      bottom: calc(100% - 8px);
    }
  }

  &.cancelled {
    .form-group {
      .form-control,
      #custom-dropdown-default [class*='-control'] {
        text-decoration: line-through;
        background-color: #eceff2 !important;
      }

      .custom-value,
      .small-label {
        text-decoration: line-through;
      }
    }
  }

  &.blue {
    .form-group {
      .form-control,
      #custom-dropdown-default [class*='-control'] {
        border-color: #4090ea !important;
        background: rgba(64, 144, 234, 0.02) !important;
      }

      &.error-group {
        .form-control,
        #custom-dropdown-default [class*='-control'] {
          border: 1px solid #ff0000 !important;
          background: rgba(248, 113, 113, 0.12) !important;
        }
      }
    }
  }

  &.duplicated {
    .form-group {
      .form-control,
      #custom-dropdown-default [class*='-control'] {
        border-color: #de8e09 !important;
        background: #fcf6e5 !important;
      }

      &.error-group {
        .form-control,
        #custom-dropdown-default [class*='-control'] {
          border: 1px solid #ff0000 !important;
          background: rgba(248, 113, 113, 0.12) !important;
        }
      }
    }
  }

  &.not-available {
    .form-group {
      .form-control,
      #custom-dropdown-default [class*='-control'] {
        border: 1px solid #f87171 !important;
        background: rgba(248, 113, 113, 0.07) !important;
      }

      &.error-group {
        .form-control,
        #custom-dropdown-default [class*='-control'] {
          border: 1px solid #ff0000 !important;
          background: rgba(248, 113, 113, 0.12) !important;
        }
      }
    }
  }

  &.last-item {
    .form-group {
      .form-control,
      #custom-dropdown-default [class*='-control'] {
        border: 1px solid #3c61ea !important;
        background: rgba(60, 97, 234, 0.05) !important;
      }

      &.error-group {
        .form-control,
        #custom-dropdown-default [class*='-control'] {
          border: 1px solid #ff0000 !important;
          background: rgba(248, 113, 113, 0.12) !important;
        }
      }
    }
  }

  &.default-form-label > div .form-group .control-label {
    font-size: 12px;
    line-height: 15px;
  }

  .product-input-col {
    &.with-avatar {
      .form-group {
        position: relative;

        > .user-avatar {
          position: absolute;
          left: 8px;
          bottom: 7px;
        }

        > .client-number {
          position: absolute;
          left: 41px;
          bottom: 5px;
          color: #6b7280;
          font-size: 10px;
          font-weight: 300;
          line-height: 10px;
        }

        .form-control {
          padding-left: 40px;
        }

        &.with-client-number {
          .form-control {
            padding-bottom: 17px;
          }
        }
      }
    }

    .remove-product {
      position: absolute;
      right: 6px;
      background: transparent;
      border: 0;
      font-size: 8px;
      bottom: 7px;
      color: #9ca3af;
      transition: all 0.2s ease-in-out;
      z-index: 9;
      padding: 4px;

      &:hover {
        color: #101827;
      }
    }
  }
}

.user-avatar,
.product-avatar {
  width: 22px;
  height: 22px;
  min-width: 22px;
  margin: 0;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > img {
    height: 22px;
    width: 22px;
    margin: 0;
    object-fit: cover;
    border-radius: 50%;
  }

  .icon-profile {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #4b5563;
  }

  .fb-pic {
    img {
      border-radius: 50%;
      height: 22px;
      width: 22px;
      margin: 0;
      object-fit: cover;
    }
  }

  .msg-logo {
    position: absolute;
    width: 13px;
    height: 13px;
    right: -2px;
    bottom: -3px;

    &.row-icons {
      right: 1px;
    }
  }

  &.w-38 {
    width: 38px;
    height: 38px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &.big-size {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &.small-size {
    width: 14px;
    height: 14px;
    min-width: 14px;
    margin-right: 4px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.product-avatar {
  width: 28px;
  height: 28px;
  min-width: 28px;

  > img {
    width: 28px;
    height: 28px;
    border-radius: 5px;
  }

  &.big-size {
    width: 36px;
    height: 36px;
    min-width: 36px;

    > img {
      width: 100%;
      height: 100%;
    }
  }
}

.react-dropdown {
  .dropdown-toggle {
    position: relative;
    background: transparent;
    border: 0;
    padding: 0;
    color: #9ca3af;
    font-size: 12px;
    z-index: 1;
    transition: all 0.2s ease-in-out;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      background: #f3f4f6;
      opacity: 0;
      z-index: -1;
      border-radius: 50%;
      transition: all 0.1s ease-in-out;
    }

    i {
      display: block;
    }

    .icon-dots {
      font-size: 4px;
    }

    &:after {
      display: none;
    }

    &.dots-horizontal-button {
      height: 4px;
    }

    &.show,
    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:focus {
      outline: none;
      box-shadow: none !important;
    }

    &.only-icon {
      font-size: 16px;
    }
  }

  .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    top: calc(100%);
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12),
      0 1px 3px 0 rgba(0, 0, 0, 0.08);
    border: 0;
    padding: 5px;
    max-width: none;
    overflow-y: auto;
    list-style: none;
    margin-top: 15px;
    min-width: 100px;

    .custom-option {
      display: block;
      white-space: nowrap;
      color: #697281;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      background: transparent;
      border: 0;
      padding: 10px;
      border-radius: 3px;
      transition: all 0.1s ease-in-out;
      width: 100%;
      text-align: left;

      &:before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 100%;
        background: #101827;
        margin-right: 5px;
        display: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover:not(:disabled):not(.disabled) {
        text-decoration: none;
        background-color: #f3f4f6;
        color: #101827;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.active {
        color: #101827;
        display: flex;
        align-items: center;

        &:before {
          display: block;
        }
      }
    }

    hr {
      margin: 0 -5px;
    }
  }

  &.right {
    .dropdown-menu {
      left: 0 !important;
      right: auto !important;
    }
  }
}

.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 150px;
  background: white;
  top: 0;
  left: 0;
  border-radius: 3px;
  z-index: 9;

  .circle-loader {
    position: absolute;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    margin: 30px auto;
    width: 40px;
    height: 40px;

    .circle {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid #f6f7f8;
      border-top-color: #3c61ea;
      animation: spin 1s infinite linear;
    }
  }

  &.transparent-loader {
    .circle-loader {
      .circle {
        border: 3px solid #f6f7f8;
        border-top-color: transparent;
      }
    }
  }

  &.static-loader {
    position: static;
    min-height: 30px;

    .circle-loader {
      position: static;
      margin: auto;
      transform: none;
      width: 20px;
      height: 20px;

      .circle {
        width: 20px;
        height: 20px;
        border-width: 2px;
      }
    }
  }

  &.transform-none {
    transform: none !important;
  }

  &.small-loader {
    min-height: 20px;

    .circle-loader {
      width: 20px;
      height: 20px;

      .circle {
        width: 20px;
        height: 20px;
        border-width: 2px;
      }
    }
  }

  &.extra-small-loader {
    min-height: auto;

    .circle-loader {
      width: 12px;
      height: 12px;

      .circle {
        width: 12px;
        height: 12px;
        border: 2px solid #3c61ea;
        border-top-color: #f6f7f8;
      }
    }
  }

  &.higher-border-radius {
    border-radius: 12px;
  }

  &.higher-index {
    z-index: 10;
  }

  &.sticky-content {
    background: #fff;

    .circle-loader {
      position: sticky;
      top: 50%;
      margin: auto;
      transform: translateY(-50%);
    }
  }
}

.loader-parent {
  position: relative;
  min-height: 150px;

  &.max-height {
    max-height: 500px;
    overflow: hidden;
  }

  &.min-height-auto {
    min-height: auto;

    .loader-container {
      min-height: auto;
    }
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    width: 100%;
  }

  .img-icon {
    width: 83px;
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  .title {
    color: #1f2937;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    margin-bottom: 2px;
    @media (max-width: 991px) {
      justify-content: center;
    }

    span {
      font-weight: normal;
      color: #6b7280;
    }
  }

  .desc {
    color: #6b7280;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 22px;
    text-align: center;
  }

  &.small-size {
    .img-icon {
      width: 70px;
    }

    .title {
      font-size: 16px;
    }
  }

  &.with-padd {
    padding: 120px 0;
    @media (max-width: 767px) {
      padding: 40px 0;
    }
  }

  &.with-bg {
    background: #fff;
  }

  &.full-size {
    .img-icon {
      width: 100%;
    }
  }

  &.with-shadow {
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  }

  &.functionality-purchase {
    .img-icon {
      width: auto;
      max-width: 130px;
      height: 30px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &.no-permission {
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);

    .img-icon {
      width: 50px;
      margin-bottom: -5px;
    }
  }
}

.full-size-empty-state {
  position: relative;

  .empty-state {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loader-parent {
  &.full-height {
    height: calc(100vh - 75px);
    @media (max-width: 991px) {
      height: auto;
    }
  }

  &.active-loader-parent {
    height: 400px;

    .settings-form {
      display: none;
    }
  }
}

.filters-wrapper {
  position: relative;
  @media (max-width: 767px) {
    margin-left: 0 !important;
  }

  .filters-button {
    height: 33px;
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    color: #6b7280;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    padding: 0 12px;
    display: flex;
    align-items: center;

    .badge {
      height: 15px;
      width: 15px;
      border-radius: 3px;
      background-color: rgba(60, 97, 234, 0.1);
      color: #3c61ea;
      font-size: 9px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 12px;
      margin-left: 5px;
    }

    &:after {
      position: relative;
      top: 1px;
      content: '\e903';
      font-family: selmo;
      display: flex;
      align-items: center;
      font-size: 8px;
      color: #9ca3af;
      margin-left: 6px;
    }

    &:hover,
    &.active {
      color: #1f2937;
    }

    .column-visibility-button {
      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        color: #6b7280;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        height: 33px;
        padding: 0 12px;
        border: 1px solid #e5e7eb;
        border-radius: 5px;
        width: 100%;
        span {
          margin-right: 5px;
          font-weight: bold;
        }
      }
    }
  }

  .filters-header {
    margin-bottom: 15px;

    .title {
      font-size: 14px;
      margin-bottom: 0;
    }

    .sub-title {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .dropdown-menu {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
    min-width: 185px;
    padding: 15px 12px;
    border: 0;
    margin-top: 6px;

    .checkbox-filter-wrapper {
      max-height: 350px;
      overflow-y: auto;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;
      overflow-x: hidden;
      @include custom-scrollbar;

      .form-group {
        margin-bottom: 20px;
      }
    }

    &.loader-parent {
      position: absolute;
      min-height: 100px;

      .loader-container {
        top: 50%;
        left: 50%;
        min-height: auto;
        transform: translate(-50%, -50%);
        margin: 0;

        .circle-loader {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
        }
      }
    }

    &.big-min-width {
      min-width: 250px;
    }
  }

  .filters-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -12px -15px;
    padding: 7px 12px;
    border-top: 1px solid #f3f4f6;

    > button {
      padding: 7px 22px;
      font-size: 10px;

      &:first-of-type {
        margin-right: 10px;
      }

      &:last-of-type {
        margin-left: 10px;
      }

      &.reset-filter {
        color: #6b7280;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 12px;
        text-align: center;
        padding: 0;
        background: transparent;
        border: 0;
      }
    }
  }

  &.column-filters {
    .filters-button {
      border: 0;
      width: auto;
      height: auto;
      font-size: 16px;
      padding: 0;
      margin: 0 6px;
      color: #9ca3af;
      transition: all 0.2s ease-in-out;
      @media (max-width: 767px) {
        margin: 0;
      }

      &:hover,
      &.active {
        color: #000;
      }

      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      @media (min-width: 767px) {
        margin-top: 12px;
        min-width: 220px;
        right: 0;
        left: auto;
      }
    }
  }

  &.with-tags {
    .dropdown-menu {
      .checkbox-filter-wrapper .form-group {
        margin-bottom: 12px;

        .control-label .tag {
          position: static;
        }
      }
    }
  }
}

.table-list-wrapper {
  .default-shadow-box {
    box-shadow: none;
    @media (max-width: 991px) {
      display: block;
    }

    .table-header {
      margin-bottom: 20px;

      .top-part {
        display: flex;
        align-items: center;

        h1 {
          margin-bottom: 0;
          flex-wrap: wrap;

          .badge {
            position: relative;
            top: 1px;
            font-size: 12px;
            min-width: 20px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            background: #f3f4f6;
            color: #4b5563;
            border-radius: 3px;
            @media (max-width: 480px) {
              margin-right: 10px;
            }

            &.white {
              background-color: #fff;
            }
          }

          .desc {
            width: 100%;
            color: #6b7280;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.1px;
            line-height: 22px;
          }
        }

        .react-dropdown {
          .dropdown-toggle {
            background: transparent;
            margin-left: auto;
            padding: 0;
            height: 31px;
            min-width: 31px;
            width: 31px;
            border: 1px solid #dfe1e4;
            border-radius: 4.5px;
            box-shadow: 0 1px 1px 0 rgba(#000, 0.07);
            margin-right: 0.5rem;
            color: #6b7280;
            font-size: 12px;

            &:before {
              display: none;
            }
          }
        }

        .button {
          white-space: nowrap;
          margin-left: auto;
          @media (max-width: 767px) {
            margin-left: 0;
          }

          &.primary {
            padding: 9px 18px;
          }
        }
      }

      .filters-part {
        display: flex;
        align-items: center;
        margin-top: 20px;
        @media (max-width: 767px) {
          flex-wrap: wrap;
          > div {
            width: 100%;
            margin-bottom: 10px;

            > button,
            .form-control {
              width: 100%;
            }

            &.search-form {
              margin-right: 0;
            }
          }
        }

        .search-form {
          margin-left: 10px;
          margin-right: 10px;
          @media (max-width: 767px) {
            margin-left: 0;
          }
        }

        .order-export-wrapper {
          @media (max-width: 767px) {
            order: 1;
          }

          .react-dropdown {
            display: flex;
            align-items: center;

            .dropdown-toggle {
              top: 1px;
              font-size: 15px;
              @media (max-width: 767px) {
                display: flex;
                align-items: center;
                color: #6b7280;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;
                height: 33px;
                padding: 0 12px;
                border: 1px solid #e5e7eb;
                border-radius: 5px;
                width: 100%;
                span {
                  margin-right: 5px;
                  font-weight: bold;
                }
              }

              &:before {
                display: none;
              }

              &:hover {
                color: #6b7280;
              }
            }
          }
        }

        .filters-wrapper {
          @media (max-width: 767px) {
            order: 1;
          }
        }

        .button.border-button {
          height: 33px;
          box-shadow: none;

          &:hover {
            background: transparent;
            color: #1f2937;
          }
        }

        .filters-open-button {
          @media (max-width: 767px) {
            width: 100%;
            justify-content: flex-start;
            margin-bottom: 10px;
          }
        }
      }

      .selected-filters {
        margin-top: 6px;
        @media (max-width: 767px) {
          margin-bottom: 12px;
          margin-top: -2px;
        }

        .tag {
          margin-top: 2.5px;
          margin-bottom: 2.5px;
        }
      }
    }

    .table-wrapper {
      margin-left: -20px;
      margin-right: -20px;
      @media (max-width: 991px) {
        margin-left: -15px;
        margin-right: -15px;
      }

      .table-responsive {
        box-shadow: none;
      }

      &.loader-parent {
        min-height: auto;
      }
    }

    .admin-shop-box {
      display: flex;
      width: 100%;
      text-align: left;
      border: 1px solid #dfe1e4;
      border-radius: 7px;
      background-color: #ffffff;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
      padding: 15px;
      margin-bottom: 12px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      .basic-data-col {
        display: flex;
        width: 30%;
        min-width: 30%;

        .name-part {
          .date {
            display: flex;
            align-items: center;
            color: #4b5563;
            font-size: 10px;
            letter-spacing: -0.17px;
            line-height: 12px;

            span {
              display: inline-flex;
              align-items: center;

              strong {
                margin-left: 4px;
              }

              &:before {
                content: '';
                margin: 0 3px;
                display: inline-block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: #4b5563;
              }
            }
          }

          a {
            text-decoration: underline;
          }

          .name {
            display: block;
            color: #101827;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;
            margin-bottom: 12px;
          }

          .status-place {
            display: flex;
            align-items: center;

            .status-bg {
              white-space: nowrap;
              margin-right: 5px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .avatar {
          height: 32px;
          width: 32px;
          margin-right: 10px;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            margin: 0;
            object-fit: cover;
          }
        }
      }

      .contact-col {
        color: #101827;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 12px;
        width: 30%;
        min-width: 30%;
        display: flex;
        flex-direction: column;

        .seller {
          margin-top: auto;
        }
      }

      .note-col {
        color: #4b5563;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        flex-grow: 1;

        &:hover {
          cursor: pointer;
        }
      }

      .right-socials {
        width: 69px;
      }

      .facebook-logo {
        display: block;
        width: 69px;
        min-width: 69px;
        align-self: flex-start;

        img {
          width: 100%;
        }
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover:not(.no-hover) {
        border-color: #a2a2a2;
      }

      &.no-hover {
        cursor: default;
      }

      &.lift {
        padding: 20px;
        border-radius: 12px;
        box-shadow: none;
        border-color: #efefef;
        margin-bottom: 16px;

        .basic-data-col {
          width: auto;

          .avatar {
            height: 45px;
            width: 45px;
            margin-right: 10px;
            border-radius: 10px;
            border: 1px solid #f9f9f9;
          }

          .name-part {
            .name {
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 5px;
            }

            .date {
              font-size: 12px;
              letter-spacing: 0;
              font-weight: 400;
              margin-bottom: 5px;

              span strong {
                font-weight: 600;
              }
            }
          }
        }

        &.blue {
          border-color: #3c61ea;
          background: #eff6ff1c;
        }

        &.orange {
          border-color: #de8e09;
          background: #fcf6e526;
        }

        &.green {
          border-color: #069769;
          background: #edf8f42b;
        }

        &.red {
          border-color: #e64747;
          background: #fdf0f02b;
        }
      }
    }

    .date-separator {
      color: #101827;
      font-size: 14px;
      letter-spacing: 0;
      font-weight: normal;
      line-height: 18px;
      margin-bottom: 14px;
      text-transform: capitalize;
      margin-top: 25px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.help-box {
  & > div {
    width: 100%;
  }

  h2.title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 0;
  }

  &__description {
    color: #6b7280;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 21px;
    margin-bottom: 15px;
  }

  &__buttons {
    a {
      display: block;
      width: 100%;
      height: 42px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;

      .icon-messenger-2-f {
        font-size: 16px;
        margin-right: 3px;
        position: relative;
        top: -0.5px;
      }
    }
  }

  &__extra-links {
    width: 100%;

    a {
      height: 64px;
      width: 100%;
      border-radius: 5px;
      background-color: #f9fafb;
      display: flex;
      align-items: center;
      padding: 16px;
      text-decoration: none;
      margin-top: 8px;

      .flex-grow-1 {
        div {
          color: #101827;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 21px;
        }

        .label {
          color: #4b5563;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
        }
      }

      &:hover {
        .flex-grow-1 {
          div {
            text-decoration: underline;

            &.label {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.main-tutorial-box {
  display: flex;
  align-items: center;

  .left-side {
    margin-right: 35px;
    @media (max-width: 480px) {
      margin-right: 10px;
    }
  }

  h2 {
    color: #101827;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.17px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  &__description {
    color: #6b7280;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 19px;
  }

  .button {
    margin-top: 14px;
  }

  &__thumbnail {
    position: relative;
    height: 110px;
    width: 110px;
    border-radius: 5px;
    overflow: hidden;
    @media (max-width: 480px) {
      height: 90px;
      width: 90px;
    }

    &:hover {
      cursor: pointer;
    }

    &:before {
      content: '';
      height: 45px;
      width: 45px;
      display: block;
      //background: url('/public/assets/assets/images/pulpit/play.svg') no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-size: 100% 100%;
      @media (max-width: 480px) {
        height: 40px;
        width: 40px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.no-play {
      &:before {
        display: none;
      }
    }
  }

  &__download {
    border: 1px solid #9db0f4;
    border-radius: 5px;
    background-color: #eff5fe;
    height: 61px;
    display: flex;
    align-items: center;
    color: #101827;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.17px;
    line-height: 18px;
    padding: 15px;
    margin: 12px 0;

    .button {
      display: flex;
      align-items: center;
      width: 84px;
      padding: 10px !important;
      height: 31px;

      img {
        margin-left: 5px;
        height: 14px;
      }
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.selmo-dashboard-box {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  padding: 20px;

  @media (max-width: 991px) {
    padding: 15px;
  }

  &__top {
    margin: 0 -20px 15px;
    padding: 0 20px 10px;
    border-bottom: 1px solid #f3f4f6;

    h1 {
      color: #101827;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
    }

    @media (max-width: 991px) {
      margin: 0 0 15px;
      padding: 0 0 10px;
      width: 100%;
    }
  }
}

.selmo-dashboard {
  @media (max-width: 767px) {
    .default-shadow-box {
      border-radius: 0;
    }
  }

  h2 {
    color: #101827;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
  }

  .sticky-top {
    position: sticky;
    top: 15px;
    z-index: auto;

    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.selmo-news-box {
  &__photo {
    img {
      max-width: 100%;
      border-radius: 5px;
    }
  }

  &__text {
    h3 {
      color: #101827;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.2px;
      line-height: 24px;
      margin: 10px 0;
    }
  }

  &:hover {
    button {
      text-decoration: none;
    }
  }
}

#custom-dropdown-default {
  [class*='-control'] {
    color: #6b7280;
    border-radius: 5px;
    min-height: 35px;
    background: transparent;
    border: 1px solid #e5e7eb;
    cursor: pointer;

    > div:first-of-type {
      padding: 0 8px;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
    }

    [class*='-singleValue'] {
      border-color: inherit !important;
      background: inherit !important;
      color: inherit !important;
    }

    [class*='-multiValue'] {
      > div > div {
        padding-left: 0 !important;
      }
    }

    [class*='-indicatorSeparator'] {
      display: none;
    }

    [class*='-placeholder'] {
      color: #9aa3b0;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
    }

    [class*='-indicatorContainer'] {
      position: relative;
      margin-right: 10px;
      color: inherit;

      svg {
        display: none;
      }

      &:before {
        content: '\e903';
        font-family: selmo;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 8px;
        color: inherit;
      }
    }
  }

  [class*='-menu'] {
    padding: 5px;
    box-shadow: 0 4px 16px 0 rgba(#000, 0.12), 0 1px 3px 0 rgba(#000, 0.08);

    > div {
      padding: 0;

      > [class*='-option'] {
        cursor: pointer;
        font-size: 12px;
        padding: 7px 9px;
        color: #101827;
        background: transparent;
        transition: all 0.1s ease-in-out;

        &:hover {
          background: #f3f4f6;
        }

        &.selected {
          background: #f9fafb;
          font-weight: 700;
        }
      }

      > [aria-disabled='true'] {
        cursor: not-allowed !important;

        &:hover {
          background: #fff !important;
        }
      }
    }

    [class*='-NoOptionsMessage'] {
      font-size: 12px;
      color: #6b7280;
    }
  }

  &.dark {
    [class*='-control'] {
      background: #3a3a3a;
      border-color: #4e4e4e;
      color: #fff;

      > div:first-of-type {
        color: #fff !important;
      }

      .select-custom-option {
        color: #fff !important;

        .name {
          color: #fff !important;
        }

        .bottom-bar {
          color: #dadada;
        }
      }
    }
  }
}

.product-input-col {
  .form-group {
    &.storage-product {
      position: relative;

      .form-control {
        padding-left: 35px !important;
        padding-bottom: 16px !important;
        font-size: 10px;
      }

      > figure {
        position: absolute;
        bottom: 6px;
        left: 6px;
        height: 24px;
        width: 24px;
        border-radius: 3px;
        overflow: hidden;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .small-label {
        position: absolute;
        bottom: 5px;
        left: 36px;
        display: flex;
        align-items: center;
        color: #6b7280;
        font-size: 10px;
        letter-spacing: -0.1px;
        line-height: 12px;
        white-space: nowrap;
        right: 0;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
          &:nth-of-type(2) {
            display: inline;

            &:before {
              content: '';
              width: 3px;
              height: 3px;
              border-radius: 50%;
              display: inline-block;
              background: #6b7280;
              margin: 2px 3px;
            }
          }

          &:last-child {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .form-control {
      &:disabled:not(.order-created) {
        background: transparent;
      }
    }
  }
}

.custom-hint-dropdown {
  box-shadow: 0 4px 16px 0 rgba(#000, 0.12), 0 1px 3px 0 rgba(#000, 0.08);
  background: #fff;
  position: absolute;
  padding: 0;
  margin-top: 5px;
  border-radius: 5px;
  min-width: 100%;
  z-index: 99999;
  max-height: 145px;
  overflow-y: auto;

  .select-custom-option {
    color: #101827;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;

    .name {
      color: #101827;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .bottom-bar {
      color: #6b7280;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 10px;
      display: flex;
      align-items: center;

      > div {
        display: flex;
        align-items: center;

        &:after {
          content: '';
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 5px;
          background: #a9b3cb;
          margin: 0 5px;
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }
    }

    &.with-img {
      .name {
        line-height: 14px;

        .client-number {
          color: #6b7280;
          font-size: 10px;
          letter-spacing: -0.1px;
          line-height: 12px;
          white-space: nowrap;
        }
      }

      figure {
        width: 24px;
        height: 24px;

        img {
          border-radius: 3px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .select-custom-option {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding: 8px;
    transition: all 0.1s ease-in-out;

    .name {
      font-weight: 500;
    }

    &:hover:not(.disabled-bg):not(.no-result-message) {
      background: #f3f4f6;
    }
  }

  &.custom-storage-hint {
    overflow-y: auto;

    .select-custom-dropdown {
      position: static;
      max-width: 250px;
    }
  }

  &.max-height-dropdown {
    max-height: 252px !important;
  }
}

.inputs-row-section {
  .stage-separator {
    display: flex;
    align-items: center;
    color: #101827;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    flex-grow: 1;
    margin: 10px 0;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      height: 18px;
      width: 18px;
      border-radius: 3.6px;
      background-color: #f3f4f6;
      color: #1f2937;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 12px;
    }
  }

  .form-group {
    &.checkbox-form {
      display: inline-flex;
      margin-bottom: 1.5rem;
    }
  }
}

.tooltip-parent {
  position: relative;

  .tooltip-content {
    position: absolute;
    bottom: calc(100% + 6px);
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    background: black;
    display: inline-block;
    padding: 4px 10px;
    font-weight: normal;
    color: white;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    @media (max-width: 480px) {
      display: none !important;
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 4px solid #000;
    }

    &.light-version {
      background: #fff;
      color: #6b7280;
      padding: 8px 14px;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);

      &:after {
        border-top: 4px solid #fff;
      }
    }

    &.left-position {
      left: 0;
      transform: none;

      &:after {
        transform: none;
        left: 20px;
      }

      &.left-arrow {
        &:after {
          transform: none;
          left: 4px;
        }
      }
    }
  }

  &:hover,
  &.visible-hover {
    .tooltip-content {
      opacity: 1;
      visibility: visible;
    }
  }

  &.visible-hover {
    .tooltip-content {
      transition: none;
    }
  }

  &.no-out-transition {
    .tooltip-content {
      transition: none;
    }
  }
}

.row {
  &.small-padd-row {
    margin-left: -7px;
    margin-right: -7px;

    > div {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}

.seller-option {
  display: inline-block;
  font-weight: normal;
  padding: 3px 6px !important;
  border-radius: 3px;
  color: #fff !important;
  font-weight: 500;

  &.orange {
    color: #de8e09 !important;
    background: #fcf6e5 !important;
  }

  &.yellow {
    color: #000 !important;
    background: #fffb56 !important;
  }

  &.purple {
    color: #7c3aed !important;
    background: #efeffd !important;
  }

  &.green {
    color: #069769 !important;
    background: #edf8f4 !important;
  }

  &.pink {
    color: #9d174d !important;
    background-color: #fef5fa !important;
  }

  &.blue {
    color: #1d40af !important;
    background-color: #eff6ff !important;
  }

  &.lblue {
    color: #005bff !important;
    background: #adcfff !important;
  }

  &.red {
    color: #c30000 !important;
    background: #ffb0b0 !important;
  }

  &.null {
    color: #101827 !important;
  }
}

.gender-option {
  display: inline-block;
  font-weight: normal;
  padding: 3px 6px !important;
  border-radius: 3px;
  color: #fff !important;
  font-weight: 500;

  &.pink {
    color: #ff4d94 !important;
    background-color: #fff0f8 !important;
  }

  &.blue {
    color: #2563eb !important;
    background-color: #ebf5ff !important;
  }

  &.gray {
    color: #6b7280 !important;
    background-color: #f3f4f6 !important;
  }
}

.cell-option {
  display: inline-block;
  font-weight: normal;
  padding: 3px 6px !important;
  border-radius: 3px;
  color: #fff !important;

  &.gray {
    color: #6b7280 !important;
    background-color: #f3f4f6 !important;
  }
}

.title,
h1,
.control-label {
  .badge {
    position: relative;
    top: 1px;
    font-size: 12px;
    min-width: 20px;
    width: auto;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    background: #f3f4f6;
    color: #4b5563;
    border-radius: 3px;
    @media (max-width: 480px) {
      margin-right: 10px;
    }

    &.white {
      background-color: #fff;
    }
  }
}

.control-label .badge {
  font-size: 10px;
  display: inline-flex;
}

.tooltip {
  @media (max-width: 480px) {
    display: none !important;
  }

  &.small-size {
    font-size: 12px !important;
  }
}

.breadcrumbs-in-react-app {
  display: block;
}

.custom-badge {
  position: relative;
  top: 1px;
  font-size: 12px;
  min-width: 20px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  background: #f3f4f6;
  color: #4b5563;
  border-radius: 3px;
}

.form-group.with-avatar {
  position: relative;

  > .user-avatar {
    position: absolute;
    left: 8px;
    bottom: 11px;
  }

  > .client-number {
    position: absolute;
    left: 41px;
    bottom: 5px;
    color: #6b7280;
    font-size: 10px;
    font-weight: 300;
    line-height: 10px;
  }

  .form-control {
    padding-left: 40px;
  }

  &.with-client-number {
    .form-control {
      padding-bottom: 17px;
    }
  }

  &.shop-logo {
    .user-avatar img {
      border-radius: 3px;
    }
  }
}

.country-label {
  display: inline;
  font-size: 10px;
  opacity: 0.7;
  line-height: 10px;
}

.logo-upload-wrapper {
  display: flex;
  align-items: center;

  > .cursor-pointer {
    cursor: pointer;
  }

  .toggle-file-input {
    position: relative;

    input {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 0;
    }

    .loader-container {
      min-height: auto;
      background: rgba(#fff, 0.8);

      .circle-loader {
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }
  }

  &.big-photo {
    align-items: flex-start;
    @media (max-width: 480px) {
      flex-wrap: wrap;
    }

    figure {
      width: 220px;
      height: 52px;
      padding: 5px;
      border: 1px solid #e5e7eb;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      @media (max-width: 480px) {
        width: 100%;
        margin-bottom: 15px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &.default-img {
        img {
          height: 22px;
        }
      }
    }

    &.with-border {
      figure {
        border: 1px solid #e5e7eb;
        border-radius: 5px;
      }
    }
  }

  &.rounded-circle {
    figure {
      width: 50px;
      height: 50px;
      border-radius: 50%;

      img {
        border-radius: 50%;
      }
    }
  }

  &.video-upload {
    .circle-loader {
      position: static !important;
      transform: none !important;
    }
  }

  figure {
    position: relative;
    width: 70px;
    height: 70px;
    margin: 0;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    > i {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
    }
  }

  .action-buttons {
    @media (max-width: 991px) {
      position: relative;
      overflow: hidden;
    }

    .button {
      &.text-only {
        margin-left: 15px;
        color: #6b7280;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
      }

      &.toggle-file-input {
        position: relative;
        cursor: pointer;

        input {
          cursor: pointer;
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 0;
        }
      }
    }
  }

  .bottom-text {
    color: #9ca3af;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    margin-top: 7px;

    span {
      font-weight: bold;
    }
  }

  &.upload-gallery {
    position: relative;
    border: 1px dashed #e5e7eb;
    border-radius: 5px;
    padding: 20px 5px;
    text-align: center;
    flex-direction: column;
    justify-content: center;

    .button {
      &.text-only {
        margin-left: 15px;
        color: #6b7280;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
      }

      &.toggle-file-input {
        position: static;
        cursor: pointer;

        input {
          cursor: pointer;
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 0;
        }
      }
    }
  }

  &.big-image-wrapper {
    @media (max-width: 480px) {
      flex-grow: 1;
    }

    .photo-box-wrapper {
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
      @media (max-width: 480px) {
        flex-grow: 1;
      }

      .photo-wrapper {
        position: relative;
        width: 220px;
        height: 54px;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;

        @media (max-width: 767px) {
          width: 100%;
        }

        .loader-container {
          background: rgba(255, 255, 255, 0.5);

          .circle-loader {
            position: absolute;
            margin: auto;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
          }
        }

        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 220px;
          height: 54px;
          right: 0;
          bottom: 0;
          z-index: 1;
          cursor: pointer;
        }

        .label {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
          z-index: 1;
          opacity: 0;
          transition: all 0.2s ease-in-out;
          pointer-events: none;

          i {
            margin-right: 3px;
          }
        }

        figure {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.with-border {
          border: 1px solid #e5e7eb;

          .label {
            opacity: 1;
            color: #6b7280;

            i {
              color: #d1d5db;
            }
          }

          &.border-dashed {
            border-style: dashed;
            border-color: #e5e5e5;
          }

          &:hover {
            &:after {
              opacity: 0;
            }
          }
        }

        &:hover {
          .label {
            opacity: 1;
          }

          &:after {
            opacity: 1;
          }
        }

        &:after {
          content: '';
          background-color: rgba(0, 0, 0, 0.6);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          pointer-events: none;
          transition: all 0.2s ease-in-out;
        }
      }

      .sizes-label {
        color: #9ca3af;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        margin-left: 20px;
        text-align: left;
        font-weight: normal;
        @media (max-width: 767px) {
          margin-left: 0;
          margin-top: 15px;
        }

        .label {
          color: #1f2937;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 13px;
        }

        strong {
          color: #6b7280;
        }

        > div {
          margin-bottom: 2px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.block {
        display: block;

        .sizes-label {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }

  &.error-group {
    .photo-box-wrapper {
      .photo-wrapper {
        border-color: #ef4444 !important;
      }

      .label {
        color: #ef4444;
      }
    }
  }
}

.gallery-upload {
  display: flex;

  .upload-btn {
    border: 1px dashed #3c61ea;
    border-radius: 5px;
    color: #3c61ea;
    font-size: 12px;
    text-decoration: underline;
    font-weight: bold;
    line-height: 17px;
    width: 106px;
    min-width: 106px;
    height: 106px;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    background: transparent;
    padding: 12px;
    margin-top: 5px;

    input {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 0;
    }
  }

  .loader-container {
    background: rgba(#fff, 0.8);
    min-height: auto;

    .circle-loader {
      margin: 0;
    }
  }
}

.selected-gallery-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  > div {
    padding: 5px;

    figure {
      position: relative;
      width: 108px;
      height: 108px;
      margin: 0;

      .icon-button {
        position: absolute;
        top: -6px;
        right: -6px;
        background: red;
        color: #fff;
        border-radius: 50%;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
      }

      img {
        border-radius: 5px;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.selected-gallery-box {
  &.active-sortable {
    border: 1px solid #e5e7eb;
    padding: 10px;
    border-radius: 10px;

    img {
      cursor: grab;
    }

    .info {
      display: block;
      color: #6b7280;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      margin-bottom: 7px;
    }
  }
}

.gallery-upload-wrapper {
  .selected-wrapper {
    display: flex;
    align-items: center;

    .checkbox-box {
      display: flex;
      align-items: center;
      border: 1px solid #dfe1e4;
      border-radius: 5px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
      color: #6b7280;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
      padding: 7px;

      .form-group {
        margin-right: 2px;
      }
    }
  }
}

.react-photo-gallery--gallery {
  > div {
    margin: 0 -5px;

    .gallery-photo {
      .icon-button {
        opacity: 0;
      }

      &:hover {
        .icon-button {
          opacity: 1;
        }

        .form-group {
          opacity: 1;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.photo.gallery-photo {
  padding: 5px;
  position: relative;

  .icon-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 2.5px;
    background-color: #f3f4f6;
    color: #6b7280;
    padding: 4px;
    cursor: grab;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    opacity: 1;

    &.drag-icon {
      left: 10px;
      right: auto;
      z-index: 2;
    }

    &.remove-icon {
      cursor: pointer;
    }
  }

  img {
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    width: 108px !important;
    height: 108px !important;
    margin: 0 !important;
    object-fit: cover !important;
    box-shadow: 0 0 0 1px #e5e7eb;
  }

  .form-group {
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 0;
    opacity: 0;
    z-index: 1;

    transition: all 0.2s ease-in-out;

    .checkbox-checkmark {
      border-radius: 2.5px;
      background-color: #f3f4f6;
      color: #6b7280;
      padding: 4px;
      margin-right: 0;
      height: 20px;
      width: 20px;
      min-width: 20px;

      &:after {
        content: '\e904';
        font-family: selmo;
        opacity: 1;
        font-size: 12px;
        color: #6b7280;
      }
    }

    .checkbox-checkmark {
      background: #fff;
      border: 0;
    }

    &.download-photo {
      top: 35px;

      .checkbox-checkmark {
        &:after {
          content: '\e92f';
          font-family: selmo;
          opacity: 1;
          font-size: 12px;
          color: #6b7280;
        }
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.2s ease-in-out;
    opacity: 0;
    border-radius: 5px;
  }

  &.selected {
    .form-group {
      opacity: 1;
    }
  }
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translate(50px);
  }
  to {
    opacity: 1;
    transform: translate(0);
  }
}

.fixed-notification-wrapper {
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 99999;
  @media (max-width: 480px) {
    right: 20px;
    left: 20px;
  }

  .fixed-notification {
    position: relative;
    background: #fff;
    padding: 25px 25px 25px 30px;
    display: flex;
    align-items: flex-start;
    max-width: 350px;
    min-width: 270px;
    box-shadow: 0 2px 20px 0 rgba(#000, 0.1);
    animation: slideLeft 0.3s ease-in-out forwards;
    border-radius: 3px;
    overflow: hidden;
    @media (max-width: 480px) {
      width: 100%;
      max-width: none;
    }

    .progress-bar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 5px;
      background: rgba(#fff, 0.4);

      > div {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #3c61ea;
        animation: progressBarWidth 7s linear forwards;

        @keyframes progressBarWidth {
          0% {
            width: 100%;
          }
          100% {
            width: 0;
          }
        }
      }
    }

    .close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      color: #6b7280;
      font-size: 10px;
    }

    .icon-content i {
      margin-right: 15px;
      font-size: 18px;
    }

    .title {
      color: #000;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      margin-right: 25px;
    }

    .desc {
      margin-top: 5px;
      color: #000;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 5px;

      .button.primary {
        padding: 8px 12px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 7px;
      background: #3c61ea;
      opacity: 0;
      //	FIXME
    }

    &.success {
      .icon-content i {
        color: green;
      }

      &:before {
        background: green;
      }
    }

    &.error {
      .icon-content i {
        color: red;
      }

      &:before {
        background: red;
      }
    }
  }

  &.preview-view {
    position: static;
  }
}

.overflow-sm-visible {
  overflow: auto;
  @media (max-width: 767px) {
    overflow: visible !important;
  }
}

.overflow-visible {
  overflow: visible;
}

#dropdown-default {
  [class*='-control'] {
    box-shadow: 0 1px 1px 0 rgba(#000, 0.07);
    color: #6b7280;
    border-radius: 5px;
    min-height: 35px;
    background: transparent;
    border: 1px solid #e5e7eb;
    cursor: pointer;

    > div:first-of-type {
      padding: 0 8px;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;

      [class*='-placeholder'] {
      }
    }

    [class*='-singleValue'] {
      border-color: inherit !important;
      background: inherit !important;
      color: inherit !important;
    }

    [class*='-indicatorSeparator'] {
      display: none;
    }

    [class*='-indicatorContainer'] {
      position: relative;
      margin-right: 10px;
      color: inherit;

      svg {
        display: none;
      }

      &:before {
        content: '\e903';
        font-family: selmo;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 8px;
        color: inherit;
      }
    }
  }

  [class*='-menu'] {
    box-shadow: 0 4px 16px 0 rgba(#000, 0.12), 0 1px 3px 0 rgba(#000, 0.08);

    > div {
      padding: 0;

      > [class*='-option'] {
        cursor: pointer;
        font-size: 12px;
        padding: 7px 9px;
        color: #101827;
        background: transparent;
        transition: all 0.1s ease-in-out;

        &:hover {
          background: #f3f4f6;
        }
      }
    }

    [class*='-NoOptionsMessage'] {
      font-size: 12px;
      color: #6b7280;
    }
  }

  &.error-group {
    [class*='-control'] {
      border: 1px solid #ef4444 !important;

      [class*='-placeholder'] {
        color: #ef4444;
      }

      [class*='-indicatorContainer'] {
        color: #ef4444;
      }
    }
  }
}

.status-dropdown {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  text-align: left;
  width: 100%;
  color: #6b7280;
  border-color: #e5e7eb;

  [class*='-placeholder'] {
    color: #6b7280;
    font-weight: 500;
  }

  &.blue,
  &.waiting {
    color: #3c61ea;
    border-color: #3c61ea;
    background: #eff6ff;
  }

  &.orange,
  &.to-send {
    color: #de8e09;
    border-color: #de8e09;
    background: #fcf6e5;
  }

  &.purple,
  &.already-send {
    color: #7c3aed;
    border-color: #7c3aed;
    background: #efeffd;
  }

  &.green,
  &.finished {
    color: #069769;
    border-color: #069769;
    background: #edf8f4;
  }

  &.grey {
    color: #6b7280;
    border-color: #6b7280;
    background: #f0f1f3;
  }

  &.red,
  &.not-paid {
    color: #e64747;
    border-color: #e64747;
    background: #fdf0f0;
  }

  &.pink,
  &.edited {
    color: #ff2bad;
    border-color: #ff2bad;
    background: #ffe8f8;
  }

  &.light-blue,
  &.open-cart {
    color: #00bcef;
    border-color: #00bcef;
    background: #edfafe;
  }

  &.cancelled {
    background: #f0f1f3;
    color: #6b7280;
    border-color: #6b7280;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:after {
    display: none;
  }

  [class*='-control'] {
    border-color: inherit !important;
    background: inherit !important;
    color: inherit !important;
    border-radius: 5px;
    min-height: 35px;
    box-shadow: none !important;

    > div:first-of-type {
      padding: 0 14px;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
    }

    [class*='-singleValue'] {
      border-color: inherit !important;
      background: inherit !important;
      color: inherit !important;
    }

    [class*='-indicatorSeparator'] {
      display: none;
    }

    [class*='-indicatorContainer'] {
      position: relative;
      margin-right: 10px;
      color: inherit;

      svg {
        display: none;
      }

      &:before {
        content: '\e903';
        font-family: selmo;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 8px;
        color: inherit;
      }
    }
  }

  [class*='-menu'] {
    box-shadow: 0 4px 16px 0 rgba(#000, 0.12), 0 1px 3px 0 rgba(#000, 0.08);

    > div {
      > [class*='-option'] {
        cursor: pointer;
        font-size: 12px;
        padding: 7px 9px;
        color: #101827;
        background: transparent;
        transition: all 0.1s ease-in-out;

        &:hover {
          background: #f3f4f6;
        }
      }
    }
  }
}

.easypack-dropdown__list.loader-parent {
  min-height: auto;
}

.easypack-widget .easypack-dropdown__list li {
  transition: all 0.3s ease-in-out;
}

body {
  &.messeger-container {
    @media (max-width: 991px) {
      #root {
        height: 100%;

        .wrapper-section {
          height: 100%;
          display: flex;
          flex-direction: column;

          main {
            flex-grow: 1;

            .messenger-wrapper {
              position: relative;
            }
          }
        }
      }
    }
  }

  .intro-loader {
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background: white;
    z-index: 99999;

    svg {
      width: 180px !important;
    }

    &.higher-index {
      z-index: 999999;
    }
  }
}

body {
  &.table-page {
    main {
      overflow: hidden;
    }
  }

  &.with-blur {
    > .main-container {
      filter: blur(3px);
    }

    .modal-backdrop {
      opacity: 0.8;
    }
  }
}

.react-tag-container {
  position: relative;
  margin-top: 10px;
  margin-left: -5px;
  display: flex;
  align-items: center;
  min-height: 36px;
  @media (max-width: 991px) {
    margin-bottom: 10px;
    margin-left: 0;
  }

  .react-tags-wrapper {
    flex-grow: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .ReactTags__selected {
      background-color: #fafbfb;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-radius: 5px;
      position: relative;
      border: 1px solid #e5e7eb;
      min-height: 36px;
      padding: 2.5px 0;
      padding-right: 15px;
      box-shadow: 0 1px 10px 0 rgba(#000, 0.08);

      .ReactTags__tagInput {
        flex-grow: 1;

        input {
          border: 0;
          background: transparent;
          font-size: 12px;
          width: calc(100% - 5px);
          padding: 5px 30px 5px 15px;

          &:focus {
            outline: none;
          }
        }

        .ReactTags__suggestions {
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          border: 1px solid #e5e7eb;
          border-radius: 5px;
          background-color: #ffffff;
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
          z-index: 1;
          overflow: hidden;
          margin-top: 8px;

          ul {
            list-style: none;
            padding: 0;
            background: white;
            margin: 0;

            li {
              font-size: 12px;
              padding: 6px;
              transition: all 0.1s ease-in-out;

              .tag-option {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              .tag {
                cursor: pointer;
              }

              span {
                mark {
                  padding: 0;
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  .hide-tag-input {
    font-size: 7px;
    position: absolute;
    top: 4px;
    right: 3px;
    background: transparent;
    border: 0;
    color: #9ca3af;
    padding: 10px;
    z-index: 1;
  }

  &:not(.active) {
    .hide-tag-input {
      display: none;
    }
  }

  .custom-dropdown {
    box-shadow: 0 4px 16px 0 rgba(#000, 0.12), 0 1px 3px 0 rgba(#000, 0.08);
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 5px 12px;
    margin-top: 5px;
    border-radius: 5px;
    min-width: 100%;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tag {
      border: 0;
      cursor: pointer;
      display: inline-block;
    }

    .remove-suggestion {
      background: transparent;
      padding: 0;
      margin: 0;
      font-size: 12px;
      border: 0;
      color: #4b5563;
    }
  }

  &.in-order {
    margin-left: 0;

    .react-tags-wrapper {
      position: static;

      .ReactTags__selected {
        box-shadow: none;
        background: transparent;
        padding-right: 30px;
      }
    }
  }

  &.big-size {
    .react-tags-wrapper {
      .ReactTags__selected {
        .ReactTags__tagInput {
          input {
            font-size: 14px;
            min-height: 44px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.tag,
.tag-wrapper {
  position: relative;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  padding: 7px 8px;
  border-radius: 3px;
  white-space: nowrap;
  margin: 5px;
  margin-left: 0;
  padding-right: 18px;
  display: flex;
  align-items: center;

  > button {
    position: absolute;
    top: 8px;
    right: 7px;
    color: inherit;
    background: transparent;
    padding: 0;
    border: 0;
    margin-left: 5px;
    font-size: 6px;

    &:focus {
      outline: none;
    }
  }

  &.more {
    margin-right: 5px;
    background-color: transparent;
    color: #4b5563;
    border: 0;
  }

  &.green {
    background-color: rgba(14, 185, 129, 0.1);
    color: #044b36;
  }

  &.yellow {
    background-color: #fdedc8;
    color: #592a05;
  }

  &.grey {
    color: #4b5563;
    background-color: #f3f4f6;
  }

  &.blue {
    color: #1d40af;
    background-color: #eff6ff;
  }

  &.purple {
    color: #5b21b6;
    background-color: #f6f3ff;
  }

  &.pink {
    color: #9d174d;
    background-color: #fef5fa;
  }

  &.red {
    color: #7f1d1e;
    background-color: #fef2f2;
  }

  &.no-remove {
    position: static;
    padding-right: 8px;
  }

  &.big-size {
    font-size: 14px;
    padding: 10px 22px 10px 9px;
    font-weight: 500;

    > button {
      font-size: 16px;
      top: 8px;
      color: #929292;
    }
  }
}

.tag-wrapper {
  margin: 2.5px 0 2.5px 5px;

  > button {
    font-size: 10px;
    top: 7px;
  }

  + .ReactTags__tagInput {
    input {
      padding-left: 5px !important;
    }
  }
}

.badge {
  &.badge-success {
    color: #0eb981 !important;
    background-color: #e6f8f2 !important;
  }

  &.badge-info {
    color: #4b5563;
    background-color: #f3f4f6;
  }
}

.email-status-wrapper {
  .button {
    justify-content: center;
    padding: 10px 0;
  }

  h2 {
    @media (max-width: 991px) {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  &__list {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #e5e7eb;

    ul {
      padding: 0;
      margin: 0;
      margin-top: -9px;

      li {
        display: flex;
        align-items: center;
        padding: 9px 0;
        border-bottom: 1px solid #e5e7eb;

        &:last-of-type {
          border: 0;
        }

        .name {
          flex-grow: 1;
          color: #4b5563;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
        }
      }
    }
  }

  .sent-emails {
    padding-top: 15px;
    border-top: 1px solid #e5e7eb;
    margin-top: 20px;

    h2 {
      margin-bottom: 10px;
    }

    .emails-sent-wrapper {
      .email-row {
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e7eb;
        padding: 7px 0;

        .history-type {
          color: #4b5563;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
          flex-grow: 1;

          strong {
            display: block;
          }
        }

        .date {
          color: #4b5563;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
          flex-grow: 1;
        }

        &:first-of-type {
          border-top: 0;
        }
      }
    }
  }
}

.payment-received-wrapper {
  h2 {
    color: #101827;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 0;
  }

  .payment-received-value {
    font-size: 16px;
  }
}

.toggle-content-wrapper {
  .toggle-header {
    display: flex;
    align-items: center;
    cursor: pointer;

    .toggle-title {
      color: #101827;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
    }

    .button {
      color: #6b7280;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
      margin-left: auto;

      i {
        position: relative;
        top: -1px;
        transform: rotate(0);
      }
    }

    &.open {
      border-bottom: 0;
      padding-bottom: 0;

      .button {
        i {
          top: 0;
          transform: rotate(180deg);
        }
      }
    }
  }

  &.with-border {
    border-top: 1px solid #f3f4f6;
  }
}

.settings-page.sub-settings-page.list-with-tabs {
  background: transparent;
  box-shadow: none;

  > .top-part {
    background: #fff;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    padding: 26px 20px 0;

    .title {
      color: #101827;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .button {
      white-space: nowrap;
      margin-left: auto;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }

    + .tabs-wrapper {
      border-radius: 0;
    }
  }

  .tabs-wrapper {
    padding: 0 4px;
    background: #fff;
    border-radius: 5px 5px 0 0;

    + .absolute-btn {
      right: 0;
    }

    &.scroll-enabled {
      display: flex;
      align-items: center;
      overflow-x: auto;
      padding-bottom: 1px;
      //overflow-y: hidden;
      @include custom-thin-scrollbar;

      > button {
        margin: 0 10px;
        white-space: nowrap;

        &:first-child {
          margin-left: 18px;
        }

        &:last-child {
          margin-right: 18px;
        }
      }
    }
  }

  .table-list-wrapper {
    .default-shadow-box {
      border-radius: 0 0 12px 12px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04),
        0 1px 2px 0 rgba(0, 0, 0, 0.07);
    }
  }

  &.separated-box {
    .page-title {
      color: #101827;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
      padding: 22px 22px 0;
      @media (max-width: 767px) {
        padding: 15px 15px 0;
      }

      .description {
        color: #6b7280;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
      }

      &.big-size {
        font-size: 22px;
        line-height: 26px;
      }
    }

    .tabs-header {
      border-radius: 5px;
      background: #fff;

      .breadcrumbs {
        padding: 20px 20px 0;

        li {
          color: #9ca3af;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 23px;

          a {
            color: inherit;
          }

          &.active {
            color: #101827;
          }
        }
      }

      &.live-details-tabs-header {
        display: flex;
        align-items: center;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04),
          0 1px 2px 0 rgba(0, 0, 0, 0.07);

        @media (max-width: 767px) {
          border-radius: 0;
        }

        .tabs-wrapper {
          border-bottom: 0;
          background: transparent;
          @media (max-width: 767px) {
            padding: 0;
            margin-left: 5px;
            margin-right: 5px;
          }

          a {
            @media (max-width: 767px) {
              margin: 0 12px;
            }

            .new-tag {
              pointer-events: none;
              position: absolute;
              top: 50%;
              left: calc(100% + 5px);
              padding: 2px 5px;
              min-width: auto;
              transform: translateY(-50%);
              font-size: 8px;
            }
          }

          a:after {
            top: calc(100% - 1px);
          }
        }

        .right-column {
          margin-left: auto;
          display: flex;
          align-items: center;
          align-self: center;
          padding: 0 22px;
          @media (max-width: 767px) {
            padding: 15px;
            margin-left: 0;
            flex-grow: 1;
            border-top: 1px solid #f6f7f8;
          }

          .form-group {
            .form-control {
              max-width: 320px;
              min-height: 36px;

              .copy-btn {
                width: auto;

                i {
                  margin-left: 3px;
                }
              }
            }
          }

          .price-info {
            display: flex;
            align-items: center;

            .label {
              color: #4b5563;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 18px;
            }

            .price-box {
              line-height: 18px;

              .price {
                display: flex;
                align-items: center;
                color: #101827;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 20px;
                text-align: right;
                white-space: nowrap;

                .refresh-price {
                  font-size: 13px;
                  line-height: 13px;
                  color: inherit;
                  margin-left: 8px;
                }
              }
            }
          }
        }

        .form-group {
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }

  &.with-bg {
    background: #fff;
  }
}

.tabs-wrapper {
  border-bottom: 1px solid #e5e7eb;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: 0;
    margin-right: 0;
    padding: 0 5px;
  }

  > button,
  > a,
  > li button {
    display: inline-block;
    position: relative;
    background: transparent;
    border: 0;
    color: #6b7280;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    padding: 18px 0;
    margin: 0 18px;
    transition: all 0.2s ease-in-out;
    @media (max-width: 767px) {
      padding: 14px 0;
      margin: 0 15px;
      white-space: nowrap;
    }

    span {
      color: #3c61ea;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 12px;
      text-align: center;
      margin-left: 6px;
      border-radius: 3px;
      background-color: #f0f3fe;
      padding: 2px 5px;

      &.grey {
        color: #6b7280;
        background-color: #f3f4f6;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: calc(100%);
      left: 0;
      right: 0;
      height: 1px;
      background: #2464eb;
      opacity: 0;
      @media (max-width: 767px) {
        top: calc(100% - 1px);
      }
    }

    &.active {
      color: #101827;

      &:after {
        opacity: 1;
      }
    }

    &:hover {
      color: #101827;
      text-decoration: none;
    }
  }

  .nav-link {
    border: 0;
  }

  //.scroll-button {
  //	position: absolute;
  //	right: 0;
  //	width: 25px;
  //	height: 25px;
  //	border-radius: 50%;
  //	background: white;
  //	display: flex;
  //	align-items: center;
  //	justify-content: center;
  //	color: #3C61EA;
  //	font-size: 10px;
  //	padding: 0;
  //	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  //
  //	i {
  //		transform: rotate(-90deg);
  //	}
  //}
  &.smaller {
    button {
      padding: 16px 0;
      margin-left: 25px;
      margin-right: 5px;
    }
  }
}

.skeleton {
  animation: 1.5s ease-in-out skeleton infinite;

  .skeleton-box {
    position: relative;
    height: 16px;
    width: 200px;
    border-radius: 3px;
    background: #e7e7e7;
    overflow: hidden;

    &.title {
      width: 50%;
      height: 21px;
      margin-bottom: 8px;
    }

    &.logo {
      width: 85px;
      height: 25px;
    }

    &.big-title {
      width: 50%;
      height: 40px;
    }

    &.switch-toggle-skeleton {
      height: 112px;
      width: 100%;
    }

    &.above-title {
      width: 35%;
      height: 16px;
      margin-bottom: 8px;
    }

    &.price {
      width: 25%;
    }

    &.huge {
      width: 100%;
    }

    &.big {
      width: 95%;
    }

    &.medium {
      width: 90%;
    }

    &.normal {
      width: 85%;
    }

    &.small {
      width: 80%;
    }

    &.tiny {
      width: 75%;
    }

    &.very-small {
      width: 70%;
    }
  }

  .skeleton-circle {
    position: relative;
    background: #e9ecfa;
    height: 40px;
    width: 40px;
    border-radius: 50%;

    &.big {
      width: 86px;
      height: 86px;
    }
  }

  &.message-box {
    display: flex;
    align-items: center;

    .content {
      margin-left: 10px;

      .top-row {
        margin-bottom: 8px;
      }
    }
  }

  &.profile {
    margin-bottom: 0;

    .content {
      margin-left: 0;
    }

    .top-row {
      height: 24px;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  &.intro-skeleton {
    .skeleton-box {
      background: #263c86;
    }
  }
}

@keyframes skeleton {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

.standard-transfer {
  max-width: 454px;
  margin: 75px auto 0;

  @media (max-width: 991px) {
    margin-top: 25px;
  }

  &__top {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #f3f4f6;

    @media (max-width: 767px) {
      margin-bottom: 15px;
      padding-bottom: 15px;
    }

    .top-text {
      color: #101827;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 3px;
    }

    h1 {
      color: #101827;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.2px;
      margin-bottom: 0;
      line-height: 25px;
    }

    img {
      border-radius: 5px;
    }
  }

  &__bottom {
    &__title {
      color: #101827;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.2px;
      line-height: 21px;
      margin-bottom: 15px;
    }

    .standard-transfer-box {
      display: flex;
      align-items: center;
      padding: 15px;
      height: 81px;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      background-color: #ffffff;
      margin-bottom: 12px;

      @media (max-width: 767px) {
        height: 65px;
        //margin: 0 -15px 15px -15px;
        //border-radius: 0;
        //border-left: 0;
      }

      & > div {
        &:first-of-type {
          margin-right: 15px;
        }
      }

      .link-button {
        min-width: 105px;
        min-height: 39px;
        text-align: center;
        justify-content: center;
        @media (max-width: 767px) {
          min-width: 75px;
          font-size: 10px;
          min-height: 33px;
        }

        &:hover {
          background: #e5e7eb;
          color: #000;
        }
      }

      .top-text {
        color: #9ca3af;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 3px;
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 14px;
        }
      }

      .bottom-text {
        color: #101827;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;

        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 14px;
        }

        &.underline {
          text-decoration: underline;
        }
      }

      &.with-prices {
        height: auto;
        flex-direction: column;
        align-items: stretch;

        .bottom-text {
          display: flex;
          align-items: center;
          color: #4b5563;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.17px;
          line-height: 19px;
          margin-bottom: 10px;

          .price {
            color: #101827;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.17px;
            line-height: 19px;
            margin-left: auto;

            &.huge {
              font-size: 16px;
              font-weight: bold;
              letter-spacing: -0.2px;
              line-height: 19px;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .btn-copy {
        @media (max-width: 480px) {
          padding: 8px 11px;
          width: auto;
          font-size: 10px;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__button-place {
      display: flex;
      justify-content: flex-end;
      margin-top: 25px;
      @media (max-width: 480px) {
        width: 100%;
        .button {
          width: 100%;
        }
      }

      .button {
        height: 50px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.pulse-icon {
  color: #3c61ea !important;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% + 1px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    animation: pulse 2s;
    animation-iteration-count: 5;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(#3c61ea, 0.7);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(#3c61ea, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(#3c61ea, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(#3c61ea, 0.7);
    box-shadow: 0 0 0 0 rgba(#3c61ea, 0.7);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(#3c61ea, 0);
    box-shadow: 0 0 0 10px rgba(#3c61ea, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(#3c61ea, 0);
    box-shadow: 0 0 0 0 rgba(#3c61ea, 0);
  }
}

.tags-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  min-height: 36px;
  max-width: 660px;
  overflow: hidden;
  @media (max-width: 767px) {
    margin-top: 0;
  }

  .tag {
    &:last-of-type {
      display: inline-flex;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .add-new-tag {
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    padding: 6px;
    margin-right: 5px;
    border: 1px dashed #d1d5db;
    background-color: #f9fafb;
    color: #4b5563;
    border-radius: 3px;
    white-space: nowrap;
  }

  &.tooltip-parent {
    overflow: visible;

    .tooltip-content {
      display: flex;
      align-items: center;
      background: #fff;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
      border-radius: 5px;
      bottom: calc(100% + 4px);
      padding: 5px;

      .tag:last-of-type {
        margin-right: 0;
      }

      &:after {
        border-top-color: #fff;
      }
    }
  }

  &.discount-tags {
    margin-top: 8px;

    .tag {
      color: #4b5563;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      background-color: #e5e7eb;
      padding-right: 12px;

      > button {
        position: static;
        margin-top: 1px;
        right: 9px;
        color: #9ca3af;
        font-size: 8px;
        top: 10px;
        margin-left: 8px;
      }
    }
  }
}

.active-plan-date {
  .date {
    border-radius: 5px;
    background-color: #e3fdf4;
    color: #067857;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    padding: 8px 12px;
    flex-grow: 1;
  }

  .button.small-size {
    height: 33px;
    padding: 0 12px;
  }
}

.payment-benefits-wrapper {
  .label {
    color: #1f2937;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 12px;
  }

  .benefits-list {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #64748b;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      i {
        align-self: flex-start;
        margin-right: 10px;
        color: #34d299;
        font-size: 20px;
      }
    }
  }

  figure {
    position: relative;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 0;
  }
}

.hidden-trial-bar {
  .selmo-trial-info {
    display: none;
  }
}

.inpost-chooser {
  margin-top: 10px;

  &__title {
    color: #101827;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 10px;
  }

  &__box {
    display: flex;
    align-items: center;
    position: relative;
    height: 61px;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    padding: 10px;
    margin-bottom: 8px;

    &.active {
      background: rgba(255, 203, 1, 0.1);
      border-color: #ffcb01;

      &.furgonetka-chooser {
        background-color: rgba(73, 145, 195, 0.08);
        border-color: #4991c3;
      }
    }

    &__text-wrapper {
      flex-grow: 1;

      &__top {
        color: #101827;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
      }

      &__bottom {
        color: #4b5563;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 13px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .checkmark {
      position: relative;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: 1px;
      border: 1px solid #e5e7eb;
      transition: all 0.2s ease-in-out;
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        width: 0;
        height: 0;
        border-radius: 50%;
        transition: all 0.2s ease-in-out 0.1s;
      }
    }

    input {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;

      &:checked {
        & + .checkmark {
          border-color: transparent;
          background-color: #ffcb01;

          &:after {
            width: 5px;
            height: 5px;
          }
        }
      }
    }

    &.furgonetka-chooser {
      input {
        &:checked {
          & + .checkmark {
            background-color: #4991c3;
          }
        }
      }
    }
  }

  .button-place {
    margin-top: 15px;

    button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 5px;
      background-color: #ffcb01;
      color: #101827;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
      border: 0;
      width: 100%;

      .loader-container {
        background: #ffcb01;

        .circle-loader {
          .circle {
            border-color: #ffcb01;
            border-top-color: #101827;
          }
        }
      }

      &.furgonetka-button {
        background: #4991c3;
        color: #fff;

        .loader-container {
          background: #4991c3;

          .circle-loader {
            .circle {
              border-color: #4991c3;
              border-top-color: #fff;
            }
          }
        }
      }
    }
  }

  &.error-group {
    .inpost-chooser__box {
      border-color: #f87171;
      background-color: #fffafa;
      box-shadow: 0 0 0 1px #f87171;
    }
  }
}

.custom-dropdown-menu {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: 0;
  padding: 5px;
  max-width: none;
  overflow-y: auto;
  list-style: none;
  min-width: 100px;

  .custom-option {
    display: block;
    white-space: nowrap;
    color: #697281;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    background: transparent;
    border: 0;
    padding: 10px;
    border-radius: 3px;
    transition: all 0.1s ease-in-out;
    width: 100%;
    text-align: left;

    &:before {
      content: '';
      width: 3px;
      height: 3px;
      border-radius: 100%;
      background: #101827;
      margin-right: 5px;
      display: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover:not(:disabled):not(.disabled) {
      text-decoration: none;
      background-color: #f3f4f6;
      color: #101827;
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.active {
      color: #101827;
      display: flex;
      align-items: center;

      &:before {
        display: block;
      }
    }
  }

  hr {
    margin: 0 -5px;
  }
}

.aside-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  background-color: #ffffff;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.12);
  z-index: 9;
  display: flex;
  flex-direction: column;
  animation: slideInLeft 0.3s forwards;
  @media (max-width: 480px) {
    width: 100%;
    z-index: 999;
  }

  .aside-header {
    position: relative;
    border-bottom: 1px solid #e5e7eb;
    padding: 20px;
    background-color: #f9fafb;
    display: flex;
    justify-content: center;
    min-height: 62px;

    .title {
      color: #101827;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 21px;
    }

    .icon-button {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: #6b7280;
      font-size: 10px;
      background: 0;
      border: 0;
      padding: 10px;
    }
  }

  .aside-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .aside-scroll {
      margin: 0 -20px;
      padding: 0 20px;
      overflow-y: auto;
      margin-bottom: 10px;
      @include custom-scrollbar;
    }
  }

  .toggle-filter-wrapper {
    border-bottom: 1px solid #f3f4f6;

    .toggle-filter-button {
      padding: 18px 0;
      display: flex;
      align-items: center;
      color: #101827;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.09px;
      line-height: 18px;
      background: transparent;
      border: 0;
      width: 100%;

      .badge {
        height: 15px;
        width: 15px;
        border-radius: 3px;
        background-color: rgba(60, 97, 234, 0.1);
        color: #3c61ea;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 12px;
        margin-left: 5px;
      }

      > i {
        margin-left: auto;
        color: #9ca3af;
        font-size: 10px;
      }

      &.open {
        border-bottom: 0;
      }
    }

    .toggle-filter-footer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      .primary {
        padding: 8px 18px;
        font-size: 12px;
        letter-spacing: -0.17px;
        line-height: 15px;
      }

      .clear-button {
        color: #6b7280;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.17px;
        line-height: 15px;
      }
    }

    &:first-of-type {
      .toggle-filter-button {
        border-top: 0;
        padding-top: 0;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.lgrey-txt {
  color: #c4c4c4;
}

.blue-txt {
  color: #2464eb !important;
}

.active-product-wrapper {
  margin-bottom: 20px;

  .label {
    display: flex;
    align-items: center;
    color: #101827;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 10px;

    .badge {
      color: #ffffff;
      font-size: 8px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #ef4444;
      padding: 3px 5px;
      text-transform: uppercase;
      margin-left: 5px;
    }
  }

  .active-product-box {
    border: 1px solid #ef4444;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 0 0 1px #ef4444;

    .top-part {
      display: flex;
      align-items: flex-start;

      .buttons-place {
        margin-left: auto;
        display: flex;
        align-items: center;

        a {
          margin-left: 15px;
          padding: 9px 22px;
        }
      }
    }

    .product {
      display: flex;

      .photo {
        height: 32px;
        width: 32px;
        display: flex;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 3px;
        }
      }

      .name {
        color: #101827;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
      }

      .code {
        color: #4b5563;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
      }
    }

    &.empty-active-product {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px 15px;

      .title {
        color: #101827;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.1px;
        line-height: 23px;
        margin-bottom: 2px;
        margin-top: 2px;
        text-align: center;
        justify-content: center;
      }

      .desc {
        color: #6b7280;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.08px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}

.right-aside-wrapper {
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    position: absolute;
    top: 13px;
    right: 0;
    z-index: 1000;
  }
}

.profile-wrapper {
  position: relative;

  .profile-header-dropdown {
    z-index: 9;
    list-style: none;
    margin: 0;
    position: absolute;
    top: calc(100% + 7px);
    right: 0;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.1);
    min-width: 210px;
    padding: 3px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.2s ease-in-out;

    li {
      .user-email {
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 14px;
        width: 100%;

        strong {
          font-weight: 600;
        }
      }

      a,
      button {
        text-align: left;
        background: transparent;
        border: 0;
        display: flex;
        align-items: center;
        color: #101827;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 12px;
        padding: 10px 8px;
        width: 100%;
        transition: all 0.2s ease-in-out;

        i:not(.icon-arrows) {
          display: inline-block;
          width: 18px;
          font-size: 16px;
          color: #6b7280;
          margin-right: 7px;
        }

        &.active,
        &:hover {
          border-radius: 3px;
          background-color: #f3f4f6;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &.open {
    .profile-header-dropdown {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }
  }

  &.in-settings {
    @media (max-width: 991px) {
      position: absolute;
      right: 0;
      top: 16px;
    }
  }
}

.functionalities-wrapper {
  //position: absolute;
  display: flex;
  align-items: center;
  //top: 12px;
  //right: 110px;
  z-index: 9;
  @media (max-width: 991px) {
    position: static;
    margin-left: -20px;
    margin-right: -20px;
    display: block;
  }

  .functionalities-alert-box {
    text-align: left;
    display: flex;
    align-items: center;
    border: 1px solid #f87171;
    border-radius: 5px;
    background-color: #fdecec;
    padding: 4px 8px;
    color: #101827;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 10px;
    @media (max-width: 991px) {
      margin-right: 0;
      border-radius: 0;
      width: 100%;
      border: 0;
      margin-bottom: 2px;
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0;
      line-height: 15px;
      background-color: #ef4444;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .date {
      font-weight: normal;
      color: #4b5563;
      @media (max-width: 991px) {
        color: #fff;
        margin-left: 5px;
      }
    }

    .icon-place {
      margin-left: 6px;

      i {
        font-size: 14px;
        color: #f87171;
      }
    }

    .button {
      color: #ef4444;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
      padding: 3px 6px;
      margin-left: auto;
      background: #fff;
      border-radius: 3px;
    }
  }
}

body.modal-open {
  padding: 0 !important;
}

.language-wrapper {
  .language-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px;

    > div {
      width: 33.333%;
      padding: 3px;
      @media (max-width: 767px) {
        width: 50%;
      }

      .lang-box {
        padding: 9px 8px;
        background: transparent;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        border-radius: 5px;
        width: 100%;
        transition: all 0.2s ease-in-out;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 7px;
          object-fit: cover;
          border: 1px solid #e8e8e8;
        }

        .name {
          color: #1f2937;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.14px;
          line-height: 18px;
        }

        &:hover:not(.active) {
          background-color: #f9fafb;
        }

        &.active {
          border: 1px solid #3c61ea;
          background-color: rgba(60, 97, 234, 0.04);
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.language-picker {
  position: relative;
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 18px;
    border: 1.5px solid #6b7280;
    padding: 1.5px;
    border-radius: 50%;
    object-fit: cover;
  }

  i {
    font-size: 6px;
    color: #6b7280;
    margin-left: 4px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 42px;
    height: 32px;
    background: #f4f4f4;
    z-index: -1;
    opacity: 0;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &.big-size {
    position: relative;
    //top: -1px;

    img {
      width: 22px;
      height: 22px;
    }

    i {
      font-size: 7px;
    }
  }

  &.custom-hover {
    &:after {
      display: none;
    }
  }

  &.with-border {
    border: 1px solid #dadada;
  }

  &.in-login-page {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.ck-rounded-corners {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ck-toolbar_grouping {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.cart-auth-wrapper {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .container {
    max-width: 425px;
  }

  h1 {
    @media (max-width: 991px) {
      font-size: 22px;
    }
  }

  .desc {
    color: #9ca3af;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
  }

  .button-with-info {
    padding: 25px;
    border-radius: 8px;
    background-color: rgba(60, 97, 234, 0.04);
    @media (max-width: 991px) {
      padding: 25px 20px;
    }

    .form-info-box {
      background: transparent;
      text-align: left;
      padding: 0;
      margin-top: 12px;

      .title {
        color: #4b5563;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.12px;
        line-height: 21.57px;
        @media (max-width: 991px) {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }

  .button {
    font-size: 16px !important;
    padding: 17px 16px !important;
    width: 100%;
    @media (max-width: 480px) {
      font-size: 14px !important;
    }

    &.primary {
      i {
        margin-right: 5px;
        font-size: 18px;
        @media (max-width: 480px) {
          font-size: 16px;
        }
      }
    }
  }

  .selmo-bottom-info {
    padding: 18px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6b7280;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: auto;
    border-top: 1px solid #e5e7eb;

    img {
      margin-left: 5px;
      width: 71px;
    }
  }
}

.shop-name-bar {
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0 0;
  background-color: #f9fafb;
  padding: 10px 25px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding: 12px 15px;
  }

  .active-change {
    font-size: 12px;
    color: #4b5563;
    line-height: 12px;
    font-weight: 500;

    .loader-container {
      min-height: auto;
      margin-right: 6px;
      background: transparent;
    }
  }

  .sub-name {
    font-size: 12px;
    line-height: 12px;
    color: #6b7280;
    margin-bottom: 3px;
  }

  .name {
    color: #101827;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .client-link {
    @media (max-width: 767px) {
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .form-group.with-preview {
      .form-control {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        border: 0 !important;
        background-color: #f0f2f4 !important;
        min-height: auto;

        .short-link {
          color: #4b5563;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 13px;
          margin-right: 10px;
        }

        .border-button {
          background: #fff;
        }
      }
    }
  }

  ~ .settings-page,
  ~ .table-list-wrapper .default-shadow-box {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  ~ .settings-page ~ .settings-page {
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }

  &.only-link {
    background: transparent;
    padding: 0;
  }
}

.client-link {
  padding-bottom: 25px;
  border-bottom: 1px solid #e5e7eb;
  @media (max-width: 991px) {
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .form-group {
    margin-bottom: 0;

    .form-control {
      display: flex;
      align-items: center;
      background: transparent;
    }
  }

  .button.border-button i {
    margin-right: 0;
  }
}

.sub-category-element {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 3px;
    width: 10px;
    height: 1px;
    background: #e5e7eb;
    transition: all 0.2s ease-in-out;
  }

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    bottom: 9px;
    left: 3px;
    width: 1px;
    background: #e5e7eb;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &:before,
    &:after {
      opacity: 0;
    }
  }
}

.not-connected-view {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #1f2937;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.13px;
    line-height: 22px;
    text-align: center;
    margin-top: 20px;
  }

  p {
    color: #6b7280;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 25px;
  }

  .button.primary.small-size {
    background-color: #0095f6;
    font-size: 14px;
    padding: 14px 22px;

    &:hover {
      background-color: #1977f2;
    }
  }

  &.extra {
    border-radius: 5px;
    background-color: #f9fafb;
    padding-bottom: 15px;
    padding-top: 30px;

    .button {
      width: 90%;
    }

    p {
      margin-bottom: 15px;
    }
  }

  &.with-padd {
    padding: 75px;
    @media (max-width: 480px) {
      padding: 75px 30px;
    }
  }
}

.histories-info {
  .info-label {
    position: relative;
    margin-bottom: 15px;
    color: #6b7280;
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      top: calc(100% + 2px);
      left: 6px;
      height: 12px;
      width: 1px;
      background: #d1d5db;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }

    &.blue-icon {
      i {
        color: #3c61ea;
      }
    }

    &.green-icon {
      i {
        color: #34d299;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.loading-box {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
  border: 1px solid #f5a218;
  border-radius: 5px !important;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05),
    0 0 0 1px #f5a218;

  .loader-container.static-loader {
    min-height: auto;

    .circle-loader {
      width: 17px;
      height: 17px;

      .circle {
        height: 100%;
        width: 100%;
        border-color: #f5a218;
        border-top-color: #e5e7eb;
      }
    }
  }

  &.small-size {
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    .title {
      font-size: 14px;
    }
  }
}

.benefits-point-list {
  margin-top: 15px;
  margin-bottom: 4px;

  > div {
    padding: 9px 11px;
    align-items: center;
    margin-bottom: 6px;
    background-color: #f7f9fe;
    font-size: 12px;
    letter-spacing: 0;
    font-weight: 500;
    line-height: 12px;
    border-radius: 5px;

    i {
      font-size: 12px;
      color: #3c61ea;
      margin-right: 5px;
    }
  }
}

.characters-counter-parent {
  position: relative;

  .characters-counter {
    position: absolute;
    right: 6px;
    bottom: 4px;
    font-size: 10px;
    line-height: 0.75rem;
    color: #101827;
    font-weight: 400;
    opacity: 0.6;
    pointer-events: none;
  }
}

.info-box {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
  padding: 22px 20px;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;

  .cat {
    font-size: 11px;
    font-weight: 800;
    letter-spacing: 0.92px;
    line-height: 12px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .box-title {
    color: #101827;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .box-desc {
    color: #4b5563;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 20px;
  }

  .button.grey-button {
    margin-top: auto;
    border-radius: 5px;
    background-color: #f3f4f6;
    color: #1f2937;
    font-size: 12px;
    font-weight: bold;
    line-height: 13px;
    text-align: center;
    padding: 13px 0;
  }

  &.blue {
    .cat {
      color: #7ab4fa;
    }
  }

  &.yellow {
    .cat {
      color: #facc14;
    }
  }

  &.pink {
    .cat {
      color: #f273b8;
    }
  }

  &:hover {
    text-decoration: none;
    background: #f9fafb;

    .button.grey-button {
      background: #eff1f3;
    }
  }
}

.settings-box {
  > a,
  > div {
    height: 100%;
    text-decoration: none;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);
    padding: 18px;
    color: #101827;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;

    .title {
      display: flex;
      align-items: center;

      > img {
        margin-right: 8px;
        width: 16px;
      }

      .right-img {
        margin-left: auto;
        width: 28px;
        height: 18px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &.furgonetka-img {
          width: 68px;
          height: 26px;
        }

        &.inpost-img {
          width: 38px;
          height: 26px;
        }
      }
    }

    .desc {
      color: #6b7280;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 12px;
      margin-top: 8px;
    }

    .button-place {
      display: flex;
      align-items: center;
      margin-bottom: -5px;
      margin-right: -5px;
      margin-top: auto;

      .button {
        margin-left: auto;
        padding-right: 7px;

        i {
          transform: rotate(-90deg);
          font-size: 6px;
          margin-left: 5px;
          font-weight: bold;
        }

        &:hover {
          background: transparent;
          color: #6b7280;
        }
      }
    }

    &:hover {
      background: #f9fafb;

      .button-place {
        .button {
          background: #fff;
          color: #101827;
        }
      }
    }
  }
}

.border-boxes {
  .settings-box {
    > a,
    > div {
      box-shadow: none;
      border: 1px solid #e5e7eb;
    }
  }
}

.card-details {
  padding: 25px 20px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #dadada;
}

.country-row {
  margin-left: -5px;
  margin-right: -5px;
  @media (max-width: 991px) {
    flex-grow: 1;
  }

  .country-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
      width: 100%;
    }

    > div {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      button {
        border: 0;
        padding: 0;
        overflow: hidden;
        height: 75px;
        background: #f9f9f9;
        width: 100%;
        border-radius: 10px;
        border: 1px solid transparent;
        @media (max-width: 991px) {
          height: 40px;
        }

        img {
          object-fit: cover;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          @media (max-width: 991px) {
            height: 20px;
            width: 20px;
          }
        }

        &.active {
          border-color: #c5d1ff;
          background-color: #f2f6fc;
        }

        &.bigger {
          img {
            width: 30px;
            height: 30px;
            @media (max-width: 991px) {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}

.hidden-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.border-switch-content {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
  padding: 22px 15px;
}

@layer base {
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.player-wrapper {
  position: relative;
  overflow: hidden;
  margin-top: 5px;
  //padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 30%;
  //height: 300px;
  //.react-player {
  //	position: absolute !important;
  //	height: 100% !important;
  //	width: 100% !important;
  //	left: 0;
  //	top: 0;
  //}
  #facebook-player-fqeft {
    position: static;
  }

  iframe {
    position: absolute !important;
    height: 100% !important;
    width: 100% !important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 10px;
  }
}

.selmo-video-wrapper {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
}

.fanpages-wrapper {
  width: 100%;
  max-height: 310px;
  overflow-y: auto;
  padding: 2px 6px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #d1d5db;
  }

  .fanpage {
    padding: 13px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    background-color: #ffffff;
    width: 100%;

    > input {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    figure {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
      margin: 0;
      margin-right: 12px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      color: #1f2937;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }

    .button {
      margin-left: auto;
      padding: 13px 25px;
      min-width: 106px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &.active {
      border-color: #3c61ea;
      background-color: rgba(60, 97, 234, 0.05);
      box-shadow: 0 0 0 1px #3c61ea;
    }
  }
}

.dot-flashing {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #3c61ea;
  color: #3c61ea;
  animation: dot-flashing 0.8s infinite linear alternate;
  animation-delay: 0.4s;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: inherit;
    color: inherit;
    animation: dot-flashing 0.8s infinite alternate;
    animation-delay: 0s;
  }

  &:after {
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: inherit;
    color: inherit;
    animation: dot-flashing 0.8s infinite alternate;
    animation-delay: 0.8s;
  }

  &.bigger {
    scale: 1.5;
  }

  &.light {
    background-color: #bbbbbb;
    color: #bbbbbb;
    animation: dot-flashing-light 0.8s infinite alternate;
    animation-delay: 0.4s;

    &:before,
    &:after {
      animation: dot-flashing-light 0.8s infinite alternate;
      animation-delay: 0s;
    }

    &:after {
      animation-delay: 0.8s;
    }
  }

  &.blue {
    background-color: #279bff;
    color: #279bff;
    animation: dot-flashing-blue 0.8s infinite alternate;
    animation-delay: 0.4s;

    &:before,
    &:after {
      animation: dot-flashing-blue 0.8s infinite alternate;
      animation-delay: 0s;
    }

    &:after {
      animation-delay: 0.8s;
    }
  }
}

@keyframes dot-flashing {
  0% {
    background-color: #3c61ea;
  }
  50%,
  100% {
    background-color: rgba(#3c61ea, 0.2);
  }
}

@keyframes dot-flashing-light {
  0% {
    background-color: #bbbbbb;
  }
  50%,
  100% {
    background-color: rgba(#bbbbbb, 0.4);
  }
}

@keyframes dot-flashing-blue {
  0% {
    background-color: #279bff;
  }
  50%,
  100% {
    background-color: rgba(#279bff, 0.3);
  }
}

.bg-lightBlue {
  background: #ddecfe;
}

.dashboard-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  padding: 24px;
  @media (max-width: 991px) {
    padding: 16px;
  }

  &.newsletter {
    @media (max-width: 767px) {
      box-shadow: none;
      border-radius: 0;
    }
  }

  &.only-border {
    box-shadow: none;
    border: 1px solid #f3f3f3;
  }
}

.dashboard-card-title {
  font-size: 20px;
  line-height: 28px;
  color: #101827;
  font-weight: bold;
}

.dashboard-card-subtitle {
  color: #000;

  p {
    margin-bottom: 1rem;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fadeInProgressAnimation > div,
.fadeInProgressAnimation tr {
  opacity: 0;
  @apply animate-tutorialSlideAndFadeIn;
  //animation: fadeIn 0.3s ease-in-out forwards;
}

@for $i from 1 through 100 {
  .fadeInProgressAnimation tr:nth-child(#{$i}),
  .fadeInProgressAnimation > div:nth-child(#{$i}) {
    animation-delay: 0.2s * $i;
  }
}

.news-row {
  .news {
    padding: 20px;
    @media (max-width: 767px) {
      padding: 15px;
    }

    .top-bar {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      min-height: 33px;

      .date {
        color: #6b7280;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.07px;
        line-height: 15px;

        i {
          font-size: 14px;
          margin-right: 5px;
        }
      }

      .button {
        margin-left: auto;
        padding: 9px 13px;

        i {
          position: relative;
          top: -1px;
        }
      }
    }

    .title {
      color: #101827;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.2px;
      line-height: 21px;
      margin-bottom: 6px;
      margin-top: 10px;
    }

    .desc,
    .dashboard-card-subtitle {
      //color: #101827;
      //font-size: 14px;
      //font-weight: 500;
      //letter-spacing: 0;
      //line-height: 21px;

      p,
      div,
      span,
      a,
      ol,
      ul {
        line-height: inherit;
        font-size: inherit;
        color: inherit;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
          margin-bottom: -1rem;
        }
      }

      a {
        color: #3c61ea;
      }

      h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }

      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }

      h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      ul {
        padding-left: 20px;
      }

      blockquote {
        border-left: 5px solid #ccc;
        font-style: italic;
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
        padding-left: 1.5em;
        padding-right: 1.5em;
      }

      p {
        margin-bottom: 1rem;
      }
    }

    figure {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      margin-top: 25px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > div {
        width: 100%;
      }
    }

    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}

.header-profile-btn {
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;

  .icon-profile {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #e5e7eb;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #4b5563;
    @media (max-width: 991px) {
      width: 24px;
      height: 24px;
      font-size: 12px;
    }
  }

  .icon-arrows {
    display: inline-block;
    font-size: 9px;
    color: #9ca3af;
    margin-left: 5px;
    transition: all 0.2s ease-in-out;
    @media (max-width: 991px) {
      font-size: 6px;
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.text-with-gradient {
  font-size: 62px;
  line-height: 78px;
  font-weight: bold;
  letter-spacing: -0.8px;
  @media (max-width: 991px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: 480px) {
    font-size: 32px;
    line-height: 40px;
  }

  span {
    display: inline-block;
    background: linear-gradient(to right, #0062e0, #0e8cf1, #53c0ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.simple-text {
    span {
      background: linear-gradient(to top, #0062e0, #0e8cf1, #53c0ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.custom-select {
  border: 0;
  padding: 0;
  background: none;
  height: auto;
}

.custom-select [class*='-indicatorSeparator'] {
  display: none;
}

.custom-select [class*='-control'] {
  border-radius: 8px;
  border-color: #eff0f1;
  cursor: pointer;
  background: transparent;
  font-size: 14px;
  height: 53px;
  box-shadow: 0 0 0 1px #eff0f1;
}

.custom-select [class*='-control']:hover {
  border-color: #e5e7eb;
}

.custom-select [class*='-control'] > div:first-of-type {
  padding: 6px 12px;
  color: #6b7280;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}

.custom-select [class*='-menu'] {
  margin-top: 8px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.custom-select [class*='-menu'] > div {
  padding: 0;
  margin: 5px;
}

.custom-select [class*='-menu'] > div [class*='-option'] {
  border-radius: 3px;
  color: #1f2937;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  padding: 12px 14px;
  background: transparent;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.custom-select [class*='-menu'] > div [class*='-option']:not(.disabled):hover {
  background-color: #f8f8fa;
}

.custom-select [class*='-menu'] > div [class*='-option'].disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-thin-scrollbar {
  padding-bottom: 8px;
  @include custom-thin-scrollbar;
}

.button-with-arrow {
  border-radius: 5px;

  .react-dropdown {
    .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 100%;
      border-left: 1px solid #224adf;
      color: #fff;
      font-size: 10px;

      &:before {
        display: none;
      }
    }

    .dropdown-menu {
      margin-top: 5px;

      .custom-option {
        padding: 8px;
      }
    }
  }

  &.primary {
    background: #3c61ea;
  }

  &.not-active {
    background: #d1d5db;

    .react-dropdown {
      .dropdown-toggle {
        border-left: 1px solid #b6b6b6;
      }
    }
  }
}

.or-separator {
  position: relative;
  margin: 18px 0;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: #e5e7eb;
  }

  > div {
    display: inline-block;
    position: relative;
    z-index: 1;
    background: #fff;
    padding: 3px 10px;
    font-size: 12px;
    color: #9ca3af;
    font-weight: bold;
  }

  &.big-size {
    > div {
      font-size: 16px;
      color: #4b5563;
    }
  }
}

.completing-scanner {
  svg {
    //position: absolute!important;
    //top: 50px!important;
    //bottom: 50px!important;
    //left: 0!important;
    //right: 0!important;
    //height: calc(100% - 100px)!important;
    //width: 100% !important;
    border: 0 !important;
    //margin: 50px 0 !important;
    //padding: 3px !important;
    //margin: 0 -5px !important;
  }

  video {
    object-fit: cover;
    width: 100vh;
    height: 45vh;
  }

  &.padd-border {
    svg {
      padding: 30px !important;
    }
  }
}

#custom-dropdown-default.payment-status-select {
  width: 100%;

  [class*='-menu'] {
    width: 100%;

    [class*='-option'] {
      font-weight: normal;
      width: 100%;
    }
  }

  [class*='-control'] {
    min-height: auto;
    box-shadow: none;
    border: 0;
    width: 100%;

    .chosen-option {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 10px;
      border-radius: 3px;
      padding: 10px;

      i {
        position: relative;
        top: -1px;
        font-size: 14px;
        margin-right: 4px;
      }

      > div {
        padding: 0;
      }

      &.red-option {
        color: #bc1010;
        background-color: #fdf0f0;

        i {
          color: #e64747 !important;
        }
      }

      &.green-option {
        color: #075a3f;
        background-color: #f2faf7;

        i {
          color: #34d299 !important;
        }
      }

      [class*='-singleValue'] {
        overflow: visible;
      }
    }

    [class*='-ValueContainer'] {
      padding: 0;
    }

    [class*='-indicatorContainer'] {
      position: relative;
      top: 1px;
      margin-right: 0;
    }
  }
}

.selected-row {
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    flex-grow: 1;
  }

  .selected-checkbox {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 5px;
    background: transparent;
    margin: 0;

    > .form-group {
      margin-right: 7px;
    }

    > span {
      color: #6b7280;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 15px;
    }
  }

  .react-dropdown {
    margin-left: 8px;

    .button.border-button {
      color: #6b7280;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      padding: 8px;

      &:before {
        display: none;
      }

      i {
        font-size: 8px;
        transform: none;
        margin-left: 5px;
        position: relative;
        top: 1px;
        color: #6b7280;
      }

      &:hover {
        color: #1f2937;
        background: transparent;
      }
    }

    .dropdown-menu {
      margin-top: 5px;
    }
  }
}

.checkbox-col {
  transition: none;
  max-width: 43px;
  width: 43px;
  padding: 0;
  @media (max-width: 991px) {
    width: 100%;
    max-width: none;
    display: block;
    padding: 0;
    margin-bottom: 8px;
  }

  .form-group.checkbox-form {
    padding: 12px;
    @media (max-width: 991px) {
      padding: 8px;
    }

    .checkbox-input {
      width: calc(100% + 12px);
    }
  }

  .checkbox-form {
    .checkbox-checkmark {
      margin-right: 0;
    }
  }

  &.selected {
    padding-bottom: 8px;
    padding-top: 0;
    padding-left: 12px;
    @media (max-width: 991px) {
      padding: 0;
    }

    .selected-row {
      .selected-checkbox {
        border-color: #dfe1e4;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
        padding: 8px;

        .form-group.checkbox-form {
          padding: 0;
        }
      }
    }
  }

  + th,
  + td {
    @media (min-width: 991px) {
      padding-left: 8px;
    }
  }
}

.scan-type-no-bold {
  > strong {
    font-weight: 500;
  }
}

.employee-history {
  > div {
    position: relative;
    padding-bottom: 10px;
    zoom: 1;

    &:after {
      content: '';
      position: absolute;
      left: 10px;
      top: 24px;
      bottom: 4px;
      width: 1px;
      background: #d1d5db;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &.tracking-history {
    > div {
      padding-bottom: 35px;
      @media (max-width: 767px) {
        padding-bottom: 25px;
      }

      &:after {
        width: 2px;
        left: 16px;
        top: 40px;
        bottom: 8px;
        background: #34d299;
      }

      &.blue-border {
        &:after {
          background: #2464eb;
        }
      }
    }
  }
}

.full-width-input {
  .form-control {
    width: 100% !important;
  }
}

.full-sm-width-input {
  .form-control {
    @media (max-width: 480px) {
      width: 100% !important;
    }
  }
}

.qr-reader {
  width: 100%;
  height: 50vh;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.text-seaGreen {
  padding: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 8px;
  background: linear-gradient(180deg, #f85f91 0%, #ed3158 100%);
}

#my-popper {
  position: relative;
  z-index: 9999 !important;

  .react-datepicker {
    box-shadow: 0 2px 20px rgba(#000, 0.05);
    border-radius: 5px;
    border: 0;
    margin-top: 5px;
    padding: 10px;
    position: relative;
    z-index: 9999;

    &__navigation-icon {
      top: 14px;
    }

    &__triangle {
      &:after,
      &:before {
        display: none;
      }
    }

    &__header {
      background: transparent;
      border: 0;
    }

    &__day-names {
    }

    &__day-name {
      font-weight: bold;
    }
  }
}

.big-text-vacation {
  span {
    background: #5fa5fa;
    padding: 1px 10px 6px 10px;
    border-radius: 8px;
    color: #fff;
  }
}

.big-text-black-week {
  span {
    background: #76ffd7;
    padding: 1px 7px;
    border-radius: 7px;
    color: #000;
  }
}

.big-text-grey-span {
  span {
    background: #b6bcc4;
    padding: 1px 7px;
    border-radius: 7px;
    color: #000;
  }
}

.gradient-border {
  position: relative;
  display: inline-block;
}

.selmo-pro-landing-title {
  span {
    display: block;
    background: linear-gradient(
      0deg,
      rgba(182, 188, 196, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.inline {
    span {
      display: inline-block;
    }
  }
}

.grey-subtitle {
  span {
    white-space: pre-wrap;
    color: #646468;
  }
}
.light-grey-subtitle {
  span {
    @media (min-width: 991px) {
      white-space: pre-wrap;
    }
    color: #b6bcc4;
  }
}

.search-active-section {
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  animation: fadeBackground 3s ease-in-out forwards;

  .form-col {
    border-bottom: 0 !important;
  }
}

@keyframes fadeBackground {
  0% {
    background-color: rgb(232, 232, 232);
  }
  100% {
    background-color: rgba(232, 232, 232, 0);
  }
}

.text-with-red-box {
  span {
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
    background: #ff3456;
    letter-spacing: 0.4px;
  }
}

.tracking-page-title {
  span {
    color: #b6bcc4;
    @media (max-width: 767px) {
      display: block;
    }
  }
}

.bg-selmo-app-aside-gradient {
  background: linear-gradient(
    200deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 231, 206, 1) 100%
  );
}

.bg-selmo-pro-aside-gradient {
  background: linear-gradient(
    133deg,
    rgba(0, 0, 0, 1) 50%,
    rgba(3, 27, 149, 1) 100%
  );
}

.onboarding-selmo-app-gradient-1 {
  background: linear-gradient(
    219.22deg,
    #c9f5fd 0%,
    #daf4e3 60.92%,
    #e6f3d1 100%
  );
}

.onboarding-selmo-app-gradient-2 {
  background: linear-gradient(219.22deg, #c8f5fe 0%, #b9e7fc 100%);
}

.onboarding-selmo-app-gradient-3 {
  background: linear-gradient(219.22deg, #cdc9fe 0%, #b2defc 40%, #aee4e9 100%);
}

.messenger-selmo-pro-gradient {
  background: linear-gradient(
    347.02deg,
    #00205d 0%,
    #000e26 25%,
    #000 45%,
    #000000 100%
  );
}

.selmo-pro-message-gradient {
  background: linear-gradient(90deg, #121212 0%, #1f2126 40%, #1f2126 99.96%);
}

.notification-description {
  //color: #101827;
  //font-size: 14px;
  //font-weight: 500;
  //letter-spacing: 0;
  //line-height: 21px;

  p,
  div,
  span,
  a,
  ol,
  ul {
    line-height: inherit;
    font-size: inherit;
    color: inherit;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
      //margin-bottom: -1rem;
    }
  }

  a {
    color: #3c61ea;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  ul {
    padding-left: 20px;
  }

  blockquote {
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  p {
    margin-bottom: 1rem;
  }

  figure {
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 25px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > div {
      width: 100%;
    }
  }
}

.marketing-card {
  padding: 35px 15px;
  border-radius: 14px;
  background-image: radial-gradient(circle at 75% 50%, #f7f7f8 0%, #f6f9ff 70%);

  .title {
    color: #4b5563;
    font-weight: 500;
    line-height: 1.33;

    strong {
      display: block;
      color: #000;
    }
  }
  &.selmo-paczka-card {
    padding: 0;
  }
}

.marketing-top-gradient {
  background-image: radial-gradient(circle at 50% -23%, #e0ebff, #fff 44%);

  &.green {
    background-image: radial-gradient(circle at 50% -23%, #e1ffe6, #fff 44%);
  }
  &.red {
    background-image: radial-gradient(circle at 50% -23%, #ffeaea, #fff 44%);
  }
}

.budget-header-label {
  font-size: 12px;
  color: #6b7280;
  font-weight: 500;
  strong {
    font-size: 16px;
    font-weight: bold;
    color: #101827;
  }
}

.image-loader-wrapper {
  .loader-container {
    min-height: auto;
    background: rgba(#fff, 0.8);

    .circle-loader {
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }
}

.actions-to-do-gradient {
  &.red {
    background-image: linear-gradient(
      180deg,
      rgb(255, 244, 245) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &.green {
    background-image: linear-gradient(
      180deg,
      rgb(249, 255, 244) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.swiper.reviews-swiper {
  overflow: visible;
}


.swiper-pagination {
  position: static !important;
  margin-top: 35px !important;
}

.swiper-pagination .swiper-pagination-bullet {
  height: 6px;
  width: 6px;
  background-color: #E5E7EB;
  opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #3C61EA;
}

.reviews-swiper {
  .swiper-pagination .swiper-pagination-bullet {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
  }

  .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #3c61ea;
  }

  .swiper-pagination {
    margin-top: 15px !important;
  }


  .swiper-slide {
    transition: opacity .2s ease-in-out;
  }

  .swiper-slide:not(.swiper-slide-active) {
    @media(min-width: 480px) {
      opacity: 0.3;
    }
  }
}

.marketing-swiper-bg {
  background-image: linear-gradient(to bottom, #f0faff 71%, #fff);
}

.loader-container {
  &.in-black-button, &.in-white-button {
    background-color: #000;
    .circle-loader {
      margin: 0;
      transform: translate(-50%, -50%);
      top: 50%;
      width: 16px;
      height: 16px;
      .circle {
        border-top-color: #000;
        width: 16px;
        height: 16px;
      }
    }
  }
  &.in-white-button {
    background-color: #fff;
  }
}