.shop-landing-wrapper {
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
	@media(max-width: 767px) {
		border-radius: 0;
	}

	section {
		@media(max-width: 767px) {
			padding: 0 20px;
		}

		img {
			max-width: 100%;
		}

		.search-img-col {
			@media(max-width: 480px) {
				margin-left: -7px;
				margin-right: -7px;
				width: auto;
			}
		}

		.section-title {
			color: #191918;
			font-size: 32px;
			font-weight: 200;
			letter-spacing: -0.8px;
			line-height: 37px;
			@media(max-width: 767px) {
				font-size: 24px;
				line-height: 30px;
			}

			&.small {
				font-size: 28px;
				line-height: 35px;
			}

			strong {
				font-weight: bold;
				position: relative;
				display: inline;
			}
			&.font-bold {
				font-weight: bold;
			}
		}

		.desc {
			color: #6B7280;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: -0.2px;
			line-height: 25px;
		}

		.section-sub-title {
			color: #5FA5FA;
			text-transform: uppercase;
			font-size: 10px;
			font-weight: bold;
			letter-spacing: 1.2px;
			line-height: 23px;
			margin-bottom: 5px;
		}

		.button.primary {
			font-size: 14px;
			padding: 12px 28px;
			border-radius: 8px;
			line-height: 20px;
			@media(max-width: 767px) {
				width: 100%;
			}

			&.green {
				background-color: #78FDFF !important;
				color: #122487 !important;
				font-weight: bold !important;
				padding: 15px 25px !important;
			}

			&.special {
				background: linear-gradient(180deg, #78FDFF 0%, #3C61EA 100%);
			}
		}

		.benefits {
			list-style: none;

			li {
				display: flex;

				figure {
					height: 46px;
					width: 46px;
					border-radius: 8px;
					overflow: hidden;
					background-color: #F3F4F6;
					margin-right: 16px;

					img {
						width: 100%;
						object-fit: contain;
						height: 100%;
					}
				}

				.content {
					border-bottom: 1px solid #F3F4F6;
					padding-bottom: 25px;
					margin-bottom: 25px;

					.title {
						color: #101827;
						font-size: 16px;
						font-weight: bold;
						letter-spacing: -0.27px;
						line-height: 21px;
						margin-bottom: 3px;
						@media(max-width: 767px) {
							letter-spacing: -0.1;
						}
					}

					p {
						color: #6B7280;
						font-size: 14px;
						font-weight: 500;
						letter-spacing: -0.2px;
						line-height: 23px;
						margin-bottom: 0;
					}
				}

				&:last-child {
					.content {
						border-bottom: 0;
						padding-bottom: 0;
						margin-bottom: 0;
					}
				}
			}
		}

		img {
			@media (max-width: 767px) {
				width: 100%;
			}
		}

		&.intro-section {
			padding-top: 75px;
			text-align: center;
			z-index: 1;
			border-radius: 5px 5px 0 0;
			@media(max-width: 767px) {
				border-radius: 0;
			}

			p {
				color: rgba(#191918, 0.7);
				font-size: 14px;
				letter-spacing: -0.17px;
				line-height: 32px;
				text-align: center;
				@media(max-width: 767px) {
					line-height: 20px;
				}
			}

			.button.primary:not(.special) {
				border-radius: 8px;
				background-color: #191918;
				color: #FFFFFF;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0;
				line-height: 21px;
				text-align: center;
				padding: 14px 39px;
				display: block;
				margin: 0 auto;
				transition: all .2s ease-in-out;

				&:hover {
					background: #333A47;
				}
			}

			img {
				@media(max-width: 767px) {
					width: 100%;
				}
			}

			.bg-icons {
				padding: 70px 140px;
				border-radius: 15px;
				background: #fff;
				margin: 0 110px;
				@media(max-width: 991px) {
					margin: 0;
					padding: 40px 15px;
					border-radius: 10px;
				}
			}
		}


		&.dark-bg {
			padding-bottom: 80px;
			border-radius: 0;
			//TODO
			//background-image: url("/public/assets/images/shop-landing/dark-bg.svg");
			background-size: cover;
			background-position: center;
			@media(max-width: 767px) {
				padding-bottom: 0;
			}

			.section-title {
				color: #fff;
			}

			.section-sub-title {
				color: #78FDFF;
			}

			p {
				color: #B3B3CF;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: -0.2px;
				line-height: 23px;
				text-align: center;
				max-width: 340px;
				margin: 0 auto;
			}

			.button.primary {
				font-size: 14px;
				line-height: 21px;
				padding: 14px 42px;
			}

			img {
				border-radius: 8px;
				overflow: hidden;
				@media(max-width: 767px) {
					border-radius: 0;
				}

			}
		}

		&.light-bg {
			padding-bottom: 110px;
			border-radius: 0;
			background: #E2F7FF;
			@media(max-width: 991px) {
				padding-bottom: 65px;
			}

			.section-title {
				color: #101827;
			}

			p {
				max-width: 420px;
				color: #4B5563;
				margin: 0 auto;
				font-weight: 500;
				letter-spacing: -0.2px;
				line-height: 23px;
				text-align: center;
			}
		}

		&.padd-section {
			padding: 110px;
			@media(max-width: 767px) {
				padding: 30px 15px;
			}
		}

		&.padd-small-section {
			padding: 30px 100px;
			@media(max-width: 767px) {
				padding: 25px 15px;
			}
		}

		&:last-of-type {
			border-radius: 0 0 5px 5px;
			@media(max-width: 767px) {
				border-radius: 0;
			}
		}
	}
}
