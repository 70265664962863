@use '../variables';

.settings-page {
    @media(max-width: 991px) {
        //margin: 0 -20px;

        .section-title {
            margin-top: 20px;
        }
    }

    .section-header {
        padding: 25px 25px 0 25px;
        @media(max-width: 767px) {
            border-top: 1px solid #E5E7EB;
        }

        .title {
            color: #101827;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 25px;
        }

        .desc {
            color: #6B7280;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
        }
    }

    .payments-list {
        padding: 25px !important;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);

        &__box {
            display: flex;
            margin-bottom: 25px;
            height: 325px;
            border: 1px solid #DFE1E4;
            border-radius: 5px;
            background-color: #FFFFFF;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
            text-decoration: none;

            @media(max-width: 991px) {
                flex-wrap: wrap;
                height: auto;
            }

            .small-status {
                border-radius: 3px;
                background-color: #FDECEC;
                color: #EF4444;
                font-size: 10px;
                font-weight: bold;
                letter-spacing: 0;
                padding: 5px 12px;
                line-height: 12px;

                &.green {
                    background-color: #e7faf5;
                    color: #34D299;
                }
            }

            &__left {
                padding: 30px;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                @media(max-width: 991px) {
                    width: 100%;
                }

                img {
                    max-width: 100%;
                }

                h2 {
                    color: #0F172B;
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: -0.5px;
                    line-height: 30px;

                    @media(max-width: 991px) {
                        font-size: 16px;
                        letter-spacing: -0.18px;
                        line-height: 21px;
                    }
                }

                .description {
                    color: #9CA3AF;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 18px;
                    @media(max-width: 991px) {
                        color: #9CA3AF;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 15px;
                    }
                }

                .avaiable-payments {
                    flex-grow: 1;
                }

                .bottom-info {
                    color: #94A3B8;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: -0.2px;
                    line-height: 20px;
                    border-top: 1px solid #F3F4F6;
                    padding-top: 15px;
                    margin-top: 15px;

                    @media(max-width: 991px) {
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: -0.17px;
                        line-height: 17px;
                    }

                    & > div {
                        max-width: 65%;
                    }
                }
            }

            &__right {
                border-left: 1px solid #DFE1E4;
                @media(max-width: 991px) {
                    width: 100%;
                }

                .inner-box {
                    display: flex;
                    flex-direction: column;
                    width: 300px;
                    height: 100%;
                    background-color: #F9FAFB;
                    padding: 30px;
                    @media(max-width: 991px) {
                        width: 100%;
                    }
                }

                img {
                    height: 26px;
                    max-width: 100%;
                }

                button {
                    height: 54px;
                    width: 100%;
                    border-radius: 8px;
                    background-color: #3C61EA;
                    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
                    border: 0;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: -0.2px;
                    line-height: 21px;
                    text-align: center;

                    @media(max-width: 991px) {
                        height: 44px;
                        color: #FFFFFF;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 18px;
                        text-align: center;
                    }
                }

                @media(max-width: 991px) {
                    .button-place {
                        margin-top: 25px;
                    }
                }

                .top-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    .most-chosen-status {
                        color: #066046;
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: -0.2px;
                        line-height: 15px;
                        border-radius: 5px;
                        background-color: #E1F9F2;
                        text-align: center;
                        margin-right: 5px;
                        padding: 5px;
                    }
                }

                ul {
                    padding: 0;
                    margin: 0;
                    flex-grow: 1;
                    list-style-type: none;

                    li {
                        display: flex;
                        align-items: center;
                        color: #64748B;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 21px;
                        margin-bottom: 10px;

                        @media(max-width: 991px) {
                            color: #64748B;
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 18px;
                        }

                        &:before {
                            display: block;
                            content: "";
                            height: 20px;
                            margin-right: 5px;
                            width: 20px;
                            //TODO
                            //background: url('/public/assets/images/settings/tick.svg') no-repeat;
                            background-size: 100% 100%;

                            @media(max-width: 991px) {
                                height: 18px;
                                width: 18px;
                            }
                        }

                        &.not {
                            &:before {
                                //TODO
                                //background: url('/public/assets/images/settings/cross.svg') no-repeat;
                                background-size: 100% 100%;
                            }
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.active {
                border-color: #34D299;
                border-left: 3px solid #34D299;
                overflow: hidden;
            }
        }
    }

    .payments-chooser {
        border-radius: 5px;
        background-color: #FFFFFF;
        //TODO
        //background-image: url('/public/assets/images/payments/payments.jpeg');
        background-size: cover;
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
        padding: 100px 15px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 767px) {
            padding: 70px 0 !important;
        }

        .text-separator {
            position: relative;
            height: 1px;
            width: 100%;
            background: #E5E7EB;
            margin: 25px 0;

            span {
                color: #9CA3AF;
                font-size: 10px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 12px;
                text-align: center;
                position: absolute;
                background: #fff;
                padding: 0 10px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .no-nip-link {
            display: flex;
            justify-content: center;

            a, button {
                color: #9CA3AF;
                font-size: 14px;
                line-height: 14px;
                font-weight: 500;
                letter-spacing: 0;
                background: transparent;
                border: 0;
                box-shadow: none;
            }
        }

        &__box {
            border: 1px solid #E5E7EB;
            border-radius: 5px;
            background-color: #FFFFFF;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 5px 20px 0 rgba(0, 0, 0, 0.07);
            padding: 40px 30px;
            width: 520px;

            .header-text {
                h2 {
                    color: #101827;
                    font-size: 22px;
                    font-weight: bold;
                    letter-spacing: -0.18px;
                    line-height: 28px;
                    margin: 0 0 5px;
                }

                .description-text {
                    color: #6B7280;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }

            .form-group {
                flex-grow: 1;
                margin-bottom: 0;

                label {
                    color: #101827;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 15px;
                }

                .form-control {
                    height: 54px;
                    border: 1px solid #DFE1E4;
                    border-radius: 5px;
                    background-color: #FFFFFF;
                    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                }

                &.absolute-error {
                    .error-text {
                        position: absolute;
                        @media(max-width: 480px) {
                            position: static;
                        }
                    }
                }
            }

            .flex-row {
                margin: 15px -5px 0;
                align-items: flex-end;

                & > div {
                    padding: 0 5px;
                }
            }

            .button-place {
                @media(max-width: 767px) {
                    width: 100%;
                    margin-top: 16px;
                }

                .button {
                    height: 54px;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                    @media(max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .invoice-wrapper {
        max-width: 500px;
        margin: 0 auto;
        padding-bottom: 25px;
        @media(max-width: 991px) {
            padding: 0 25px;
            .section-title {
                margin-left: 0;
            }

            .form-info-box {
                margin-bottom: 20px;
            }
        }
    }

    .pricing-wrapper {
        .pricing-container {
            max-width: 900px;
            margin: 50px auto;
            text-align: center;
            @media(max-width: 991px) {
                padding: 0 25px;
            }
            @media(max-width: 767px) {
                margin: 0;
            }
        }

        .pricing-row {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 -15px;
            @media(max-width: 767px) {
                flex-wrap: wrap;
            }

            & > div {
                padding: 0 15px;
                @media(max-width: 991px) {
                    width: 50%;
                }
                @media(max-width: 767px) {
                    width: 100%;
                    margin-bottom: 60px;
                    &:last-of-type {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .pricing-box {
            display: block;
            position: relative;
            border-radius: 5px;
            width: 350px;
            background-color: #fff;
            text-align: center;
            padding: 15px;
            text-decoration: none;
            height: 330px;
            border: 1px solid #E5E7EB;
            @media(max-width: 991px) {
                width: 100%;
                height: auto;
            }

            &__extra-months {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: calc(100% + 15px);
                left: 50%;
                transform: translateX(-50%);
                color: #0eb981;
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 23px;
                text-align: center;
                white-space: nowrap;

                &__text {
                    margin-left: 0.25rem;
                }

                img {
                    height: 16px;
                    position: relative;
                    top: 4px;
                }
            }

            &__title {
                color: #101827;
                font-size: 22px;
                letter-spacing: 0;
                line-height: 28px;
                margin-top: 25px;
                margin-bottom: 40px;
                @media(max-width: 767px) {
                    font-size: 20px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
            }

            &__price {
                text-align: center;
                position: relative;
                display: inline-block;
                color: #101827;
                font-size: 62.12px;
                font-weight: 700;
                letter-spacing: -.89px;
                line-height: 78px;
                margin-bottom: 35px;
                @media(max-width: 767px) {
                    font-size: 50px;
                    line-height: 60px;
                    margin-bottom: 20px;
                }

                span {
                    position: absolute;
                    top: 13px;
                    left: 100%;
                    color: #101827;
                    font-size: 20.29px;
                    font-weight: 500;
                    letter-spacing: -.68px;
                    line-height: 26px;
                    white-space: nowrap;
                    @media(max-width: 767px) {
                        font-size: 16px;
                    }
                }

                .normal-price {
                    color: #101827;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 23px;
                    text-align: center;
                    @media(max-width: 767px) {
                        font-size: 14px;
                    }

                }

                .old-price {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: calc(100% - 5px);
                    white-space: nowrap;
                    color: #9ca3af;
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                    text-decoration: line-through;
                }

                .smaller-price {
                    left: calc(100% - 10px);
                }
            }

            button {
                display: block;
                border: 0;
                margin: 0 auto;
                box-shadow: none;
                min-height: 56px;
                border-radius: 5px;
                background-color: #3c61ea;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 21px;
                text-align: center;
                padding: 17px 30px;
                text-decoration: none;
                width: 60%;
                @media(max-width: 767px) {
                    width: 100%;
                    font-size: 14px;
                    min-height: auto;
                    padding-top: 14px;
                    padding-bottom: 14px;
                }
            }
        }
    }

    .selmo-plan-payment-container {
        max-width: 551px;
        margin: 0 auto;
        padding-bottom: 100px;
    }

    .card-info-wrapper {
        padding: 10px;

        .flex-row {
            margin: 0 -5px;
            //width: 100%;

            & > div {
                width: 50%;
                padding: 0 5px;
            }
        }
    }

    .invoice-box-wrapper {
        padding: 32px;
        border-radius: 5px;
        border: 1px solid #E5E7EB;

        .form-group {
            margin-bottom: 0;
        }
    }

    .data-boxes-wrapper {
        .content {
            display: block;

            &__top-part {
                width: 100%;
            }
        }

        .flex-grow {
            flex-grow: 1;
        }
    }

    .buttons-row {
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;

        .safety-payment {
            color: #9CA3AF;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0.2px;
            line-height: 12px;
            text-align: center;
            margin-top: 15px;
        }
    }

    .selmo-new-plan {
        border: 1px solid #E5E7EB;
        border-radius: 5px;
        padding: 25px;
        margin: 25px 0;

        &__top {
            display: flex;
            align-items: center;
            padding-bottom: 20px;

            &__photo {
                margin-right: 10px;
            }

            &__main-text {
                color: #101827;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 21px;
            }

            &__bottom-text {
                color: #6B7280;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;
            }
        }

        &__description {
            padding: 20px 0;
            color: #6B7280;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
            border-top: 1px solid #F3F4F6;
            border-bottom: 1px solid #F3F4F6;
        }

        &__details {
            padding: 20px 0;
            border-bottom: 1px solid #F3F4F6;

            &:last-of-type {
                border-bottom: 0;
                padding-bottom: 0;
            }

            &__top {
                color: #6B7280;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;

                span {
                    color: #0EB981;
                    font-weight: bold;
                }
            }

            &__bottom {
                color: #101827;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 18px;
            }
        }
    }

    .big-price-box {
        border: 1px solid #F3F4F6;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 10px 14px 0 rgba(0, 0, 0, 0.1);
        padding: 42px;
        max-width: 620px;
        margin: 35px auto 0;
        position: relative;

        @media(max-width: 991px) {
            padding: 25px;
            margin-top: 15px;
        }

        .badge-success {
            position: absolute;
            left: 50%;
            padding: 6px 12px;
            transform: translateX(-50%);
            top: -13px;
            border-radius: 3px;
            background-color: #0EB981;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            text-align: center;
            @media(max-width: 991px) {
                white-space: inherit;
                position: inherit;
            }
        }

        .switch-part {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media(max-width: 767px) {
                flex-wrap: wrap;
                justify-content: center;
            }

            .form-group.switch-form.light-version input:checked + .switch .slider:before {
                transform: translateX(20px);
            }

            .switch-input {
                display: flex;
                align-items: center;
                color: #101827;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 21px;

                .switch {
                    height: 32px;
                    width: 52px;

                    .slider {
                        border-radius: 50px;

                        &:before {
                            width: 26px;
                            height: 26px;
                            bottom: 3px;
                            left: 3px;
                        }
                    }
                }

                .promo-discount {
                    text-align: left;
                    display: block;
                    color: #0EB981;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: -0.2px;
                    line-height: 18px;
                }
            }

            .price-part {
                color: #101827;
                font-size: 38px;
                font-weight: bold;
                line-height: 48px;
                @media(max-width: 767px) {
                    margin-top: 15px;
                }

                .smaller {
                    color: #4B5563;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 23px;
                    margin-left: 5px;
                    @media(max-width: 767px) {
                        display: block;
                        margin-left: 0;
                    }
                }
            }
        }

        > button {
            width: 100%;
            font-size: 18px;
            height: 60px;
            margin-top: 24px;
            @media(max-width: 767px) {
                font-size: 14px;
                height: 50px;
                padding: 0;
            }
        }

        .small-text {
            display: flex;
            align-items: center;
            color: #9CA3AF;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
            margin-top: 20px;
            text-align: center;
            justify-content: center;

            img {
                margin-right: 10px;
            }
        }
    }

    .extra-benefits {
        width: 100%;
        max-width: 920px;
        margin: 20px auto;

        .extra-benefits-row {
            display: flex;
            align-items: center;
            margin-left: -10px;
            margin-right: -10px;
            @media(max-width: 767px) {
                flex-wrap: wrap;
                padding: 0 20px;
            }

            .extra-benefits-col {
                width: 25%;
                padding: 0 10px;
                @media(max-width: 767px) {
                    width: 100%;
                    margin-bottom: 20px;
                }

                > div {
                    position: relative;
                    border-radius: 10px;
                    background-color: #F3F4F6;
                    height: 215px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    padding: 20px;

                    .photo {
                        margin: auto 0;

                        img {
                            width: 140px;
                        }

                        &.labels-photo {
                            @media(min-width: 767px) {
                                right: 0;
                            }
                            position: absolute;
                            top: 0;

                            + .title {
                                margin-top: auto;
                            }
                        }

                        &.chat-photo {
                            position: absolute;
                            top: 0;

                            + .title {
                                margin-top: auto;
                            }
                        }
                    }

                    .title {
                        color: #101827;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 21px;
                        text-align: center;
                        margin-bottom: 3px;
                    }

                    .desc {
                        color: #6B7280;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 15px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .top-pricing-wrapper {
        border-bottom: 1px solid #F3F4F6;
        margin: 0 25px 50px;
        padding-bottom: 50px;
        @media(max-width: 991px) {
            margin-bottom: 25px;
            padding-bottom: 25px;
        }
    }

    .benefits-row {
        max-width: 920px;
        margin: 20px auto;
        border-radius: 10px;
        background: linear-gradient(180deg, #F3F4F6 0%, #FAFAFB 100%);
        display: flex;
        align-items: center;
        width: 100%;
        @media(max-width: 767px) {
            flex-wrap: wrap;
            border-radius: 0;
        }

        img {
            max-width: 100%;
        }

        > div {
            width: 50%;
            @media(max-width: 767px) {
                width: 100%;
            }

            &.image-col {
                @media(max-width: 767px) {
                    order: 2;
                }
            }
        }
    }

    .faq-wrapper {
        max-width: 730px;
        margin: 100px auto;
        padding-bottom: 110px !important;

        @media(max-width: 991px) {
            padding-bottom: 50px !important;
            margin-top: 50px;
        }

        img {
            width: 50px;
            display: block;
            margin: 0 auto 25px;
        }

        h2 {
            color: #101827;
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 35px;
            @media(max-width: 767px) {
                font-size: 24px;
                line-height: 27px;
            }
        }

        .faq-question {
            .question {
                padding: 28px 0;
                border-bottom: 1px solid #F3F4F6;

                &:last-of-type {
                    border: 0;
                }

                .title {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    color: #101827;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 20px;
                    justify-content: space-between;
                    cursor: pointer;

                    i {
                        display: inline-block;
                        font-size: 12px;
                    }
                }

                .desc {
                    font-size: 14px;
                    text-align: left;
                    display: none;
                    margin-top: 10px;
                    line-height: 20px;
                    color: #6B7280;
                }

                &.active {
                    .title {
                        i {
                            transform: rotate(180deg);
                        }
                    }

                    .desc {
                        display: block;
                    }
                }
            }
        }
    }

    .text-col {
        text-align: left;
        margin-left: 15px;
        @media(max-width: 767px) {
            text-align: center;
            margin-left: 0;
            margin-top: 40px;
        }

        h3 {
            color: #101827;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 36px;
            margin-bottom: 25px;
            margin-top: 0;

            @media(max-width: 767px) {
                font-size: 24px;
                line-height: 28px;
                margin-top: 10px;
            }
        }

        .points-box {
            @media(max-width: 767px) {
                text-align: left;
                padding-left: 25px;
            }

            .point {
                display: flex;
                align-items: flex-start;
                margin-bottom: 18px;

                .text {
                    margin-left: 14px;
                }

                .title {
                    color: #101827;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 18px;
                    margin-top: -2px;
                    margin-bottom: 4px;
                }

                .desc {
                    color: #6B7280;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }
        }
    }

    .current-selmo-plan {
        border: 1px solid #E5E7EB;
        background-color: #F9FAFB;
        border-radius: 5px;
        padding: 15px;
        max-width: 80%;

        @media(max-width: 1200px) {
            max-width: 100%;
        }

        &__top {
            color: #101827;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 18px;
        }

        &__bottom {
            color: #6B7280;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 18px;

            strong {
                color: #0EB981;

                &.red {
                    color: #EF4444;
                }
            }
        }
    }

    section {
        border-bottom: 1px solid variables.$greyBg;
        padding-bottom: 15px;
        margin-bottom: 25px;

        .section-title {
            color: #101827;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 10px;
        }

        .settings-box-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-left: -11px;
            margin-right: -11px;
            @media(max-width: 991px) {
                //margin-left: 0;
                //margin-right: 0;
            }

            .settings-box {
                max-width: 33.33%;
                flex: 0 0 33.33%;
                padding: 11px;
                @media (max-width: 767px) {
                    max-width: 50%;
                    flex: 0 0 50%;
                    padding: 6px;
                }
                @media (max-width: 480px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                > a {
                    height: 100%;
                    text-decoration: none;
                    border-radius: 5px;
                    background-color: #FFFFFF;
                    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);
                    padding: 18px;
                    color: #101827;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 18px;
                    display: flex;
                    flex-direction: column;
                    transition: all .3s ease-in-out;

                    .title {
                        display: flex;
                        align-items: center;

                        > img {
                            margin-right: 8px;
                            width: 16px;
                        }

                        .right-img {
                            margin-left: auto;
                            width: 28px;
                            height: 18px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;

                            }

                            &.furgonetka-img {
                                width: 68px;
                                height: 26px;
                            }

                            &.inpost-img {
                                width: 38px;
                                height: 26px;
                            }
                        }
                    }

                    .desc {
                        color: #6B7280;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 18px;
                        margin-bottom: 12px;
                        margin-top: 8px;
                    }

                    .button-place {
                        display: flex;
                        align-items: center;
                        margin-bottom: -5px;
                        margin-right: -5px;
                        margin-top: auto;

                        .button {
                            margin-left: auto;
                            padding-right: 7px;

                            i {
                                transform: rotate(-90deg);
                                font-size: 6px;
                                margin-left: 5px;
                                font-weight: bold;
                            }

                            &:hover {
                                background: transparent;
                                color: #6B7280;
                            }
                        }
                    }

                    &:hover {
                        background: #f9fafb;

                        .button-place {
                            .button {
                                background: #fff;
                                color: variables.$darkColor;
                            }
                        }
                    }
                }
            }

            &.payments-settings-box-wrapper {
                .right-column {
                    .settings-box {
                        @media(min-width: 767px) {
                            max-width: 50%;
                            flex: 0 0 50%;
                        }
                    }
                }

                .settings-box {
                    &.blue-media {
                        > a {
                            border-top: 4px solid variables.$blue;
                        }

                        .title {
                            color: #101827;
                            font-size: 18px;

                            > img {
                                width: auto;
                                margin-right: 0;
                                height: 20px;
                            }
                        }

                        .desc {
                            margin-top: 7px;
                        }

                        .benefits {
                            margin-top: 14px;
                            margin-bottom: 4px;
                            border-top: 1px solid #F3F4F6;
                            padding-top: 15px;

                            > div {
                                padding: 7px 4px;
                                align-items: center;
                                margin-bottom: 4px;
                                background-color: #F7F9FE;
                                font-size: 10px;
                                letter-spacing: 0;
                                font-weight: 500;
                                line-height: 12px;
                                border-radius: 5px;

                                i {
                                    font-size: 12px;
                                    color: #3C61EA;
                                    margin-right: 3px;
                                }
                            }
                        }

                        .button-place {
                            .button {
                                padding: 8px 8px 8px 12px;
                            }
                        }

                        &:hover {
                            .button-place {
                                .button {
                                    color: #fff;
                                    background-color: #1c4ed8;
                                }
                            }
                        }
                    }
                }
            }
            &.border-boxes {
                margin-left: -8px;
                margin-right: -8px;
                .settings-box {
                    padding: 8px;
                    > a {
                        box-shadow: none;
                        border: 1px solid #E5E7EB;
                        .title {
                            align-items: flex-start;
                            img {
                                order: 2;
                                margin-left: auto;
                                margin-right: 0;
                                width: 18px;
                            }
                        }
                    }
                }
            }
        }

        &:last-of-type {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: 0;
        }
    }

    &.no-borders {
        section {
            border-bottom: 0;
            padding-bottom: 15px;
            margin-bottom: 30px;
        }
    }

    .settings-wrapper {
        background: #fff;
        padding: 20px;
        padding-top: 0.5rem;
        @media (min-width: 640px) {
            padding: 2rem;
            padding-top: 35px;
        }
    }

    &.payments-settings-page {
        @media(max-width: 991px) {
            .pricing-wrapper {
                overflow: hidden;
            }
        }
    }

    @media(max-width: 991px) {
        .blue-media-bottom-row {
            button {
                margin-top: 15px;
                width: 100%;
                margin-left: 0 !important;
            }
        }
    }

    &.sub-settings-page {
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
        @media (max-width: 991px) {
            margin-bottom: 20px;
        }

        .tabs-wrapper {
            @media(min-width: 991px) {
                padding: 0 10px;
            }
        }

        .header-wrapper {
            padding: 32px 25px;
            border-bottom: 1px solid variables.$greyBg;
            @media(max-width: 991px) {
                padding: 25px 15px;
            }

            h1 {
                display: flex;
                align-items: center;
                color: #101827;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 25px;

                img {
                    width: 17px;
                    height: 17px;
                    margin-right: 8px;
                }

                i {
                    margin-right: 8px;
                }
            }

            .description {
                color: #6B7280;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
            }

            &.no-border {
                border-bottom: 0;
            }
        }

        .settings-form {
            padding: 32px 25px;
            @media(max-width: 991px) {
                padding: 25px 15px;
            }

            > .form-info-box {
                @media(min-width: 991px) {
                    max-width: 72%;
                }
            }

            section[id] {
                padding: 18px;
                margin-left: -18px;
                margin-right: -18px;
            }

            section {
                display: flex;
                margin-bottom: 0;
                border-bottom: 0;

                @media(max-width: 767px) {
                    flex-wrap: wrap;
                }

                .left-side {
                    width: 20%;
                    margin-right: 5%;
                    @media(max-width: 767px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 10px;
                    }

                    .connect-facebook-btn {
                        margin-top: 32px;
                        display: inline-block;
                    }

                    h2 {
                        font-weight: bold;

                        span {
                            display: block;
                            color: #6B7280;
                            font-weight: 500;
                            @media(max-width: 991px) {
                                display: inline;
                            }
                        }
                    }

                    .sub-label {
                        color: #6B7280;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 22px;
                        margin-top: 5px;
                    }
                }

                .form-col {
                    flex-grow: 1;
                    border-bottom: 1px solid variables.$greyBg;
                    padding-bottom: 36px;
                    width: 75%;
                    max-width: 75%;

                    @media(max-width: 991px) {
                        margin-bottom: 24px;
                        padding-bottom: 24px;
                        max-width: none;

                    }

                    .form-group {
                        &:last-of-type {
                            @media (min-width: 991px) {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .video-wrapper {
                        margin-top: 35px;
                        display: flex;
                        height: 180px;
                        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
                        border-radius: 5px;
                        overflow: hidden;
                        transition: all .2s ease-in-out;
                        @media (max-width: 767px) {
                            flex-wrap: wrap;
                            height: auto;
                        }

                        &:hover {
                            cursor: pointer;
                            background: #E5E7EB;
                        }

                        .video {
                            position: relative;
                            height: 100%;
                            width: 240px;
                            @media (max-width: 767px) {
                                width: 100%;
                                height: 180px;
                            }

                            .play-button {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #000;
                                height: 38px;
                                width: 50px;
                                font-size: 12px;
                                border-radius: 7px;
                                border: 0;
                                background-color: #FFFFFF;
                                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);

                                i {
                                    position: relative;
                                    right: -1px;
                                }

                                &:focus {
                                    outline: none;
                                }
                            }

                            video, img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .right-content {
                            border: 1px solid variables.$greyBg;
                            border-left: 0;
                            padding: 25px;
                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                            border-radius: 0 5px 5px 0;

                            @media(max-width: 991px) {
                                padding-left: 0;
                                border-bottom: 0;
                            }

                            .small-label {
                                color: #9CA3AF;
                                font-size: 12px;
                                font-weight: 500;
                                letter-spacing: 0;
                                line-height: 15px;
                                margin-bottom: 4px;
                            }

                            h2 {
                                flex-grow: 1;
                                width: 312px;
                            }
                        }
                    }

                    .shop-info-wrapper {
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        border: 0.9px solid #DFE1E4;
                        border-radius: 5px;
                        background-color: #FFFFFF;
                        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);

                        figure {
                            margin: 0;
                            margin-right: 12px;
                            width: 50px;
                            height: 50px;
                            border-radius: 5px;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .content {
                            display: flex;
                            align-items: center;
                            flex-grow: 1;

                            .name {
                                color: #1F2937;
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 20px;
                                margin-bottom: 2px;
                            }

                            .grey-txt {
                                color: #6B7280;
                                font-size: 12px;
                                letter-spacing: 0;
                                line-height: 13px;

                                i {
                                    color: #34D299;
                                    margin-right: 3px;
                                }
                            }

                            > .button {
                                margin-left: auto;
                            }
                        }
                    }

                    .switcher-text-inline-wrapper {

                        > div {
                            position: relative;
                            display: flex;
                            margin-bottom: 35px;
                            cursor: pointer;

                            .form-group {
                                position: static;
                                margin-right: 10px;

                                input {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .title {
                                color: #101827;
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 18px;
                                margin-bottom: 6px;
                            }

                            .desc {
                                color: #6B7280;
                                font-size: 14px;
                                font-weight: 500;
                                letter-spacing: 0;
                                line-height: 22px;
                            }
                        }
                    }

                    .shipping-form-flex {
                        @media(max-width: 767px) {
                            flex-wrap: wrap;
                            margin-left: -5px;
                            margin-right: -5px;
                        }

                        .form-group {
                            @media(max-width: 767px) {
                                width: 50%;
                                padding: 0 5px;
                            }

                            &:first-of-type {
                                @media(max-width: 767px) {
                                    width: 100%;
                                }
                            }

                            &.form-group-addon {
                                @media(max-width: 767px) {
                                    input {
                                        max-width: none;
                                    }
                                }
                            }
                        }
                    }

                    .product-code-row {
                        .button-place {
                            .button {
                                padding: 15px 36px;
                            }
                        }
                    }

                    //&:last-of-type {
                    //    border-bottom: 0;
                    //    margin-bottom: 0;
                    //    padding-bottom: 0;
                    //}
                }

                &:last-of-type {
                    .form-col {
                        border-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }

            .button-place {
                text-align: right;
            }

            h2 {
                color: #101827;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 21px;

                &.bigger-size {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

            h3 {
                color: #1F2937;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: -0.2px;
                line-height: 24px;
                margin-bottom: 25px;
            }

            .remove-delivery-section {
                padding-top: 25px;
                border-top: 1px solid variables.$greyBg;
                margin-top: 40px;
            }

            .users-wrapper {
                .single-user {
                    padding: 12px 0;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #F3F4F6;


                    figure {
                        height: 28px;
                        width: 28px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 9px;
                        margin-bottom: 0;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .name {
                        color: #101827;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 18px;
                    }

                    .date {
                        color: #6B7280;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 15px;
                    }

                    .button {
                        min-width: 70px;
                        margin-left: auto;

                        &.primary {
                            padding: 8px 13px;
                        }
                    }

                    &:last-of-type {
                        border-bottom: 0;
                    }

                    &:first-of-type {
                        padding-top: 0;
                    }
                }
            }

            .ck-content {
                color: #101827;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 21px;

                p, div, span, a, ol, ul, {
                    line-height: inherit;
                    font-size: inherit;
                    color: inherit;
                }

                h2 {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                }

                h3 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                }

                h4 {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .center-step {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid #E5E7EB;
                border-radius: 5px;
                padding: 50px 0;
                @media(max-width: 991px) {
                    padding: 30px;
                }

                img {
                    height: 24px;
                }

                .title {
                    color: #101827;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: -0.25px;
                    line-height: 25px;
                    text-align: center;
                    margin-top: 20px;
                    @media(max-width: 991px) {
                        font-size: 16px;
                        line-height: 20px;
                        margin-top: 15px;
                    }
                }

                .desc {
                    color: #6B7280;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: -0.1px;
                    line-height: 22px;
                }

                .button {
                    margin-top: 30px;
                    @media(max-width: 991px) {
                        margin-top: 15px;
                    }
                }
            }

            .integration-status {
                margin: 25px 0;

                .title {
                    color: #101827;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 18px;
                    margin-bottom: 10px;
                }

                .status-boxes {
                    .box {
                        border: 1px solid #E5E7EB;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        color: #64748B;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: -0.1px;
                        line-height: 18px;
                        min-height: 50px;
                        padding: 0 12px;
                        margin-bottom: 6px;

                        i {
                            color: #F87171;
                            font-size: 20px;
                            margin-right: 8px;
                        }

                        .button {
                            padding: 9px 14px;
                        }

                        &.done {
                            color: #067857;

                            i {
                                color: #34D299;
                            }
                        }
                    }
                }
            }

            .section-header {
                margin-left: -25px;
                margin-right: -25px;
                padding: 0 25px 25px;
                margin-bottom: 25px;
                border-bottom: 1px solid #F3F4F6;
                @media(max-width: 767px) {
                    padding: 25px;
                }

                h1 {
                    color: #101827;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 23px;
                    margin-bottom: 0;
                }

                .desc {
                    color: #6B7280;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }

            &.shop-desktop-settings {

                .section-title {
                    font-size: 22px;
                    font-weight: bold;
                    letter-spacing: -0.2px;
                    line-height: 24px;
                    margin-bottom: 20px;

                    span {
                        color: #6B7280;

                    }
                }

                .row {
                    margin-left: -10px;
                    margin-right: -10px;

                    > div {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }

                .settings-box a {
                    border: 1px solid #E5E7EB;
                    border-radius: 5px;
                    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
                    min-height: 126px;

                    .desc {
                        margin-top: 5px;
                    }
                }

                .shop-name-bar {
                    padding: 20px;

                    .client-link {
                        margin-bottom: 12px;
                        max-width: 550px;
                    }
                }

                > div:not(:first-of-type) {
                    border-top: 1px solid #F3F4F6;
                    padding-top: 30px;
                    margin-top: 30px;
                }
            }
        }
    }

    &.export-sub-settings {
        margin-bottom: 20px;
    }

    .form-button-row {
        display: flex;
        align-items: flex-end;
        @media(max-width: 576px) {
            flex-wrap: wrap;
        }

        > .button {
            padding-top: 15px;
            padding-bottom: 15px;
            margin-left: 15px;
            @media(max-width: 576px) {
                margin-left: 0;
                margin-top: 10px;
                padding-top: 12px;
                padding-bottom: 12px;
                margin-left: auto;
            }
        }
    }

    &.help-contact-box {
        padding: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 991px) {
            padding: 30px 25px;
        }

        .messenger-btn {
            height: 50px;
            min-width: 230px;
            justify-content: center;
            padding: 5px 35px;
            border-radius: 8px;
            background: linear-gradient(90deg, rgba(0, 153, 255, 1) 0%, rgba(160, 51, 255, 1) 100%);
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;

            i {
                color: #fff !important;
                font-size: 17px;
            }
        }

        h2 {
            color: #101827;
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
            margin-bottom: 0;
            @media (max-width: 991px) {
                font-size: 20px;
            }
        }

        .description {
            max-width: 564px;
            color: #6B7280;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
            margin-top: 20px;
            @media (max-width: 991px) {
                font-size: 14px;
                line-height: 20px;
                margin-top: 10px;
            }
        }

        .contact-boxes {
            display: flex;
            align-items: center;
            margin-top: 35px;
            @media (max-width: 767px) {
                flex-wrap: wrap;
                margin-top: 15px;
            }

            > a {
                display: flex;
                align-items: center;
                padding: 15px 12px;
                border: 1px solid #E5E7EB;
                border-radius: 8px;
                margin-right: 18px;
                @media (max-width: 767px) {
                    width: 100%;
                    margin-right: 0;
                    margin-top: 15px;
                    justify-content: center;
                }

                i:not(.icon-arrow-left) {
                    color: #3C61EA;
                    margin-right: 10px;
                }

                span {
                    text-decoration: underline;
                    color: #101827;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 18px;
                }

                i.icon-arrow-left {
                    display: block;
                    transform: rotate(180deg);
                    margin-left: 8px;
                    font-size: 12px;
                    color: #E5E7EB;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .border-step-box {
            border: 1px solid #E5E7EB;
            border-radius: 5px;
            padding: 25px 22px;
            margin-bottom: 15px;
            @media(max-width: 767px) {
                padding-left: 16px;
                padding-right: 16px;
            }

            .step-header {
                display: flex;

                .step-circle {
                    position: relative;
                    top: -4px;
                    width: 34px;
                    height: 34px;
                    min-width: 34px;
                    border: 1px solid #E5E7EB;
                    border-radius: 50%;
                    color: #101827;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: -0.16px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 12px;
                    @media(max-width: 767px) {
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                .title {
                    color: #101827;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: -0.1px;
                    line-height: 18px;
                    margin-bottom: 5px;
                    @media(max-width: 767px) {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }

                .description {
                    color: #6B7280;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 18px;
                    margin-top: 0;
                    text-align: left;
                    @media(max-width: 767px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .step-body {
                margin-top: 25px;
                margin-left: 46px;
                @media(max-width: 767px) {
                    margin-left: 0;
                }

                .form-group {
                    width: 365px;
                    @media(max-width: 767px) {
                        width: 100%;
                    }

                    .form-control {
                        &.loader-parent {
                            .loader-container {
                                min-height: auto;

                                .circle-loader {
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    margin: 0;
                                    height: 20px;
                                    width: 20px;

                                    .circle {
                                        height: 20px;
                                        width: 20px;
                                        border-width: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.partners-page {
        .partners-page-container {
            max-width: 550px;

            h1 {
                color: #101827;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: -0.33px;
                line-height: 27px;
                margin-bottom: 6px;
                text-align: center;
                @media(max-width: 767px) {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

            .sub-title {
                color: #6B7280;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.2px;
                line-height: 24px;
                margin-bottom: 35px;
                text-align: center;
                @media(max-width: 767px) {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 20px;
                }
            }

            .icon-place {
                width: 87px;
                margin-bottom: 25px;
                margin-right: auto;
                margin-left: auto;
                @media(max-width: 767px) {
                    width: 60px;
                }

                img {
                    width: 100%;
                }
            }
        }
    }

    &.blue-media-settings-page {
        .section-header {
            background: #fff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .settings-form {
            padding: 0;

            .step-group {
                overflow: hidden;
                border-radius: 5px;
                background: #fff;
                box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);

                .step {
                    padding: 0 25px;
                    background: #fff;
                    padding-top: 30px;

                    .step-body {
                        margin-bottom: 0;
                        padding-bottom: 30px;
                    }

                    &.optional-step {
                        .step-header {
                            padding-bottom: 25px;
                        }
                    }
                }
            }

        }
    }
}

.instagram-connected-page {
    .magic-link {
        height: 44px;
        border-radius: 5px;
        background-color: #1977F2;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }

    .tutorial-picture {
        max-width: 100%;
        border-radius: 5px;
    }

    .step-body {
        margin-top: 15px !important;
    }

    .border-step-box .step-header .title {
        line-height: 20px !important;
        font-weight: 400 !important;
    }
}

.steps-wrapper {
    .step {
        .link-info {
            display: block;
            color: #3C61EA;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
            text-decoration: underline;

            &.bigger {
                font-size: 14px;
            }
        }

        .step-header {
            display: flex;
            align-items: center;

            .number {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                border: 1.5px solid #D1D5DB;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #101827;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.16px;
                line-height: 18px;
                margin-right: 20px;
                @media(max-width: 991px) {
                    margin-right: 15px;
                    font-size: 12px;
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                }
                @media(max-width: 480px) {
                    margin-right: 10px;
                    font-size: 10px;
                    width: 26px;
                    height: 26px;
                    min-width: 26px;
                }
            }

            .title {
                display: flex;
                align-items: center;
                color: #101827;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 22px;
                margin-right: 5px;
                @media(max-width: 576px) {
                    flex-wrap: wrap;
                }
                @media(max-width: 480px) {
                    font-size: 14px;
                    line-height: 20px;
                }

                a {
                    img {
                        width: 100px;
                        height: auto;
                        position: relative;
                        top: -3px;
                        margin-left: 5px;
                    }
                }

                img {
                    width: 45px;
                    height: 25px;
                    object-fit: contain;
                    margin-left: 5px;
                }
            }

            > .button {
                margin-left: auto;
            }

            .sub-title {
                color: #6B7280;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;

                a {
                    color: inherit;
                    text-decoration: underline;
                }
            }

            .standard-transfer-box {
                max-width: 460px;
                border: 1px solid #E5E7EB;
                background-color: #F9FAFB;

                .top-text {
                    color: #6B7280;
                }

                .button.border-button {
                    background: #fff;
                }
            }
        }

        .step-body {
            margin: 15px 0 30px 55px;
            padding-bottom: 40px;
            border-bottom: 1px solid variables.$greyBg;
            @media(max-width: 767px) {
                margin-left: 44px;
            }
            @media(max-width: 480px) {
                margin-left: 36px;
            }

            .new-account-step-body {
                display: flex;
                align-items: center;
                @media(max-width: 991px) {
                    flex-wrap: wrap;
                }

                .form-group {
                    margin-right: 10px;
                    @media(max-width: 767px) {
                        width: 100%;
                        margin-right: 0;
                    }

                    .form-control {
                        padding-right: 40px;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 18px;

                        a {
                            color: #3C61EA;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                > .button {
                    padding-top: 13px;
                    padding-bottom: 13px;
                    @media(max-width: 767px) {
                        width: 100%;
                        margin-top: 10px;
                        text-align: center;
                    }
                }
            }

            .data-row-body {
                display: flex;
                align-items: center;
                margin-top: 20px;
                @media(max-width: 1200px) {
                    flex-wrap: wrap;
                }

                .form-group {
                    margin-right: 15px;
                    @media(max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                    }

                    &.switch-form {
                        margin-top: 5px;
                    }
                }

                > .button {
                    @media(max-width: 480px) {
                        width: 100%;
                        margin-top: 15px;
                    }
                }
            }

            .link-info {
                margin-top: -20px;
            }

        }

        &:last-of-type {
            .step-body {
                border-bottom: 0;
                margin-bottom: 0;
            }
        }

        &.border-step {
            border: 1px solid #E5E7EB;
            border-radius: 5px;
            padding: 20px;

            .step-header {
                .title {
                    margin-right: 0;
                }

                .number {
                    margin-right: 10px;
                }

                .button.small-size {
                    padding: 10px 16px;
                }
            }
        }
    }

    &.full-filled-numbers {
        .step {
            .step-header {

                .number {
                    height: 28px;
                    width: 28px;
                    background-color: #F9FAFB;
                    border: 0;
                    min-width: 28px;
                    color: #4B5563;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: -0.13px;
                    line-height: 15px;
                    margin-right: 12px;

                }
            }

            .step-body {
                margin-left: 40px;
            }
        }
    }
}

.div-table {
    .table-head, .table-body {
        .table-row {
            display: flex;
            align-items: center;
            @media(max-width: 767px) {
                flex-wrap: wrap;
            }

            > div {
                color: #6B7280;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;
                padding: 0 20px 10px;
                @media (max-width: 767px) {
                    padding: 0 8px 16px;
                    width: 20%;
                }

                &.name {
                    width: 40%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    @media(max-width: 767px) {
                        width: 30%;
                    }
                }

                &.small-col, &.action-button-col {
                    width: 15%;
                    @media(max-width: 767px) {
                        white-space: nowrap;
                        width: 23.33%;
                    }
                }

                &.medium-col, {
                    width: 20%;
                    @media(max-width: 767px) {
                        white-space: nowrap;
                        width: 23.33%;
                    }
                }

                &.action-button-col {
                    width: 20%;
                    text-align: right;
                }

                &.action-button-col {
                    @media(max-width: 767px) {
                        display: none;
                    }
                }
            }
        }
    }

    .table-body {
        .table-row {
            border: 1px solid #E5E7EB;
            border-radius: 5px;
            margin-bottom: 8px;
            transition: all .3s ease-in-out;

            > div {
                color: #101827;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
                padding: 13px 20px;
                @media (max-width: 767px) {
                    padding: 12px;
                }

                > i {
                    color: #9CA3AF;
                    font-size: 10px;
                }

                i {
                    color: #9CA3AF;
                    font-size: 10px;

                    &.icon-cross {
                        color: variables.$status-red;
                    }

                    &.icon-tick {
                        color: variables.$status-green;
                    }
                }

                &.action-button-col {
                    @media(max-width: 767px) {
                        display: block;
                        width: 100%;
                        padding-top: 0;
                        .button {
                            width: 100%;
                            justify-content: center;
                        }
                    }
                }
            }

            &:hover {
                text-decoration: none;
                background-color: #F9FAFB;
            }
        }
    }
}

.add-order-table {
    &.button.primary {
        color: #fff;
        margin-right: 0;
    }
}

.sizes-wrapper-inputs {
    .inputs-row {
        margin-left: -7px;
        margin-right: -7px;
        @media(max-width: 767px) {
            border-bottom: 1px solid #E5E7EB;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        @media(max-width: 480px) {
            padding-top: 0;
            border-bottom: 0;
        }

        > div {
            padding-left: 7px;
            padding-right: 7px;

            .form-group {
                .control-label {
                    white-space: nowrap;
                }

                .error-text {
                    display: none;
                }

                .form-control {
                    min-width: 60px;
                }

                &.form-group-addon {
                    .form-control {
                        min-width: 100px;
                    }
                }
            }

            &.name-col {
                @media(max-width: 480px) {
                    .form-group {
                        border-top: 1px solid #E5E7EB;
                        padding-top: 32px;
                    }
                }
            }

            &.logo-upload-wrapper {
                position: relative;
                width: auto;
                display: flex;
                flex-wrap: wrap;

                figure {
                    width: 40px;
                    height: 40px;
                    margin: 0;
                }

                .action-buttons {
                    //position: absolute;
                    //bottom: 0;
                    //left: 0;
                    //right: 0;
                    .button {
                        span {
                            display: none;
                        }

                        i {
                            margin-right: 0;
                        }
                    }
                }

                .bottom-text {
                    display: none;
                }
            }
        }

        .code-col {
            width: 260px;
            min-width: 260px;
            @media(max-width: 1200px) {
                min-width: 180px;
                width: 180px;
            }
            @media(max-width: 480px) {
                width: 100%;
            }

            &.smaller-width {
                min-width: 180px;
                width: 180px;
                @media(max-width: 1200px) {
                    min-width: 140px;
                    width: 140px;
                }
                @media(max-width: 480px) {
                    width: 100%;
                }
            }
        }

        .button-col {
            position: relative;
            top: 5px;
            @media(max-width: 991px) {
                top: 0;
            }
        }

        &:last-of-type {
            border-bottom: 0;
            @media(max-width: 767px) {
                margin-bottom: 0;
            }
        }

        &:first-of-type {
            @media(max-width: 480px) {
                > div.name-col {
                    .form-group {
                        border-top: 0;
                        padding-top: 0;
                    }
                }
            }
        }

        &.compressed-row {
            margin-left: -3px;
            margin-right: -3px;

            > div {
                padding: 0 3px;

                &.name-col {
                    width: 145px;
                }

                &.price-col {
                    width: 100px;
                }

                &.squ-col {
                    width: 100px;
                }

                &.code-col {
                    width: 145px;
                }
            }

            &:not(:first-of-type) {
                .control-label {
                    height: 0;
                    opacity: 0;
                }
            }
        }
    }
}

.blue-media-landing {
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;
    @media(max-width: 767px) {
        border-radius: 0;
        padding-top: 10px;
        border-top: 1px solid #F3F4F6;
    }

    .blue-media-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 260px;
        background: #1C37D1;
        z-index: 1;
        @media(max-width: 767px) {
            padding: 0 10px;
        }

        .img-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            @media(max-width: 767px) {
                display: none;
            }
        }

        .title {
            color: #fff;
            font-size: 32px;
            font-weight: bold;
            letter-spacing: -0.51px;
            line-height: 45.01px;
            text-align: center;
            @media(max-width: 767px) {
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 0;
            }
        }

        .sub-title {
            opacity: 0.7;
            color: #FFFFFF;
            font-size: 14px;
            letter-spacing: -0.17px;
            line-height: 32px;
            text-align: center;
        }

        > a, > button {
            border-radius: 10px;
            background-color: #FFFFFF;
            color: #1C37D1;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
            text-align: center;
            padding: 13px 22px;
            margin-top: 10px;
            transition: all .2s ease-in-out;

            &:hover {
                background: #eaedff;
            }
        }

        .benefits {
            color: #FFFFFF;
            font-size: 10px;
            letter-spacing: 0;
            line-height: 18px;
            margin-top: 40px;
            @media(max-width: 767px) {
                max-width: 280px;
            }

            > div {
                align-items: center;
                margin-right: 35px;
                @media(max-width: 767px) {
                    width: 100%
                }

                i {
                    font-size: 12px;
                    color: #3DFFFA;
                    margin-right: 4px;
                }

                strong {
                    margin-right: 3px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .blue-media-partners {
        border-bottom: 1px solid #F3F4F6;
        padding: 15px;
        text-align: center;

        img {
            @media(min-width: 767px) {
                max-width: 75%;
                margin: 0 auto;
            }
        }
    }

    .blue-media-content {
        padding: 60px 100px;
        @media(max-width: 767px) {
            padding: 40px 20px;
        }

        .sub-label {
            text-transform: uppercase;
            color: #5FA5FA;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.83px;
            line-height: 23px;
        }

        .title {
            color: #101827;
            font-size: 36px;
            letter-spacing: -1.2px;
            line-height: 40px;
            font-weight: 300;
            @media(max-width: 767px) {
                font-size: 30px;
                line-height: 36px;
                letter-spacing: -1px;
            }

            strong {
                display: block;
                font-weight: bold;
            }

            &.smaller {
                font-size: 32px;
                @media(max-width: 767px) {
                    font-size: 26px;
                    line-height: 32px;
                    letter-spacing: -1px;
                }
            }
        }

        .desc {
            color: #6B7280;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.2px;
            line-height: 25px;
            margin-top: 15px;
        }

        .button.primary {
            border-radius: 8px;
            font-size: 14px;
            padding: 13px 28px;
        }

        > .row {
            margin-bottom: 140px;
            @media(max-width: 767px) {
                margin-bottom: 40px;
                > .col-md-5 {
                    order: 2;
                }
            }

            figure {
                border-radius: 10px;
                overflow: hidden;
            }
        }
    }

    &.automatic-transfers {
        .blue-media-content {
            .title {
                font-size: 32px;
                @media(max-width: 767px) {
                    font-size: 26px;
                }
            }

            > .row {
                @media(max-width: 767px) {
                    margin-bottom: 25px;
                }
            }
        }
    }
}
