@use 'variables';

.form-group {
    position: relative;

    &__addon {
        position: absolute;
        bottom: 14px;
        right: 7px;
        color: #9aa3b0;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
    }

    .control-label {
        display: block;
        color: #6b7280;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        margin-bottom: 7px;

        &__main {
            color: #101827;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 18px;
        }

        &__description {
            color: #6b7280;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 21px;
            margin-top: 2px;
        }

        img {
            width: 30px;
            height: 20px;
            margin-right: 5px;
            object-fit: contain;

            &.source-icon {
                width: 16px;
                height: 16px;
                object-fit: unset;
            }
        }

        &.with-img {
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                display: inline-block;
            }
        }

        &.with-light-text {
            font-weight: 500 !important;
        }

        .optional {
            font-size: 10px;
            margin-left: 2px;
        }
        &.dark {
            color: #101827;
        }
    }

    .prefix-group {
        border: 1px solid #e5e7eb;
        border-radius: 5px;

        .input-prefix {
            font-size: 12px;
            font-weight: bold;
            border-right: 1px solid #e5e7eb;
            padding: 0 12px;
        }

        .form-control {
            border-radius: 0 !important;
            border: 0 !important;
        }
    }

    &.big-bottom-padd {
        .form-control {
            padding-bottom: 40px;
        }
    }

    .form-control {
        border-radius: 5px;
        border: 1px solid #e5e7eb;
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        padding: 6px 15px;
        color: variables.$darkColor;
        min-height: 45px;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        transition:
            border 0.2s ease-in-out,
            box-shadow 0.2s ease-in;

        &::placeholder {
            color: #9aa3b0;
            font-weight: 400;
        }

        &:focus {
            border-color: variables.$blue;
        }
    }

    .radio-block,
    .radio-inline {
        position: relative;
        cursor: pointer;
        margin-right: 20px;
        padding-left: 28px;
        width: 100%;
        margin-bottom: 0;

        input.form-control {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked {
                height: 100px;

                ~ span {
                    border-color: transparent;
                    background: #3c61ea;

                    &:after {
                        opacity: 1;
                        width: 4px;
                        height: 4px;
                    }
                }
            }

            &:disabled {
                ~ span,
                ~ .input-txt {
                    opacity: 0.6;
                    cursor: not-allowed;
                }
            }
        }

        > span {
            position: absolute;
            top: 3px;
            left: 0;
            height: 20px;
            width: 20px;
            border: 1px solid #fff;
            border-radius: 50%;
            transition: all 0.3s ease-in-out;

            &:after {
                content: '';
                position: absolute;
                opacity: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0;
                height: 0;
                border-radius: 50%;
                background: #fff;
                transition: all 0.3s ease-in-out;
            }
        }

        .input-txt {
            color: #1f2937;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &.with-border {
            border: 1px solid #e5e7eb;
            padding: 13px 10px 13px 40px;
            border-radius: 5px;

            .indicator {
                top: 50%;
                transform: translateY(-50%);
                left: 10px;
                background: transparent;
                border: 1px solid #d1d5db;
            }

            + .radio-block {
                border-top: 0;
            }
        }
    }

    .floated-label {
        pointer-events: none;
        position: absolute;
        left: 16px;
        top: 16px;
        font-size: 14px;
        font-weight: 500;
        color: #6b7280;
        transition: all 0.2s linear;

        &.selected {
            font-size: 12px;
            top: 4px;

            + .form-control {
                padding-top: 19px;
            }
        }

        ~ .input-addon {
            top: 0 !important;
            bottom: 0;
            height: 100% !important;
        }
    }

    &.big-size {
        .control-label {
            color: #101827;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
        }

        .form-control {
            height: 53px !important;
            border-radius: 8px;
            border-color: #eff0f1;
            box-shadow: 0 0 0 1px #eff0f1;
            transition: all 0.2s ease;
            font-size: 16px;

            &:focus {
                border-color: #3c61ea !important;
                box-shadow: 0 0 0 1px #3c61ea;
                outline: none;
            }

            &::placeholder {
                color: #9ca3af;
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
            }
        }

        .floated-label {
            &.selected {
                font-size: 12px;
                top: 8px;

                + .form-control {
                    padding-top: 22px;
                }
            }
        }

        &.smaller {
            .form-control {
                height: 46px;
            }
        }
    }

    &.black {
        .form-control {
            &:focus {
                border-color: #000 !important;
                box-shadow: 0 0 0 1px #000;
            }
        }
    }

    &.small-size {
        .control-label {
            color: #101827;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
        }

        .form-control {
            height: 40px !important;
            padding: 6px 8px !important;
            min-height: 40px !important;
            font-size: 12px !important;

            &::placeholder {
                color: #9ca3af;
                font-size: 12px !important;
                font-weight: 500;
                line-height: 16px !important;
            }
        }

        .input-addon {
            height: 40px !important;
        }

        .floated-label {
            top: 9px;
            left: 8px;

            &.selected {
                top: 4px;
                font-size: 10px !important;

                + .form-control {
                    padding-top: 20px !important;
                }
            }
        }

        &.error-group {
            .error-text {
                font-size: 10px;
                line-height: 14px;
            }
        }
    }

    &.with-copy-btn,
    &.with-save-btn,
    &.with-link {
        .form-control {
            height: auto;
            padding: 6px 8px 6px 15px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;

            > .short-link {
                font-size: 15px;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: variables.$darkColor;
                flex-grow: 1;
                max-width: 400px;
            }

            > .button {
                min-height: 31px;
                margin-left: auto;

                &.small-size {
                    display: flex;
                    align-items: center;
                    padding: 7px 10px;

                    i {
                        margin-right: 3px;
                    }
                }

                &.only-icon {
                    width: 31px;
                }
            }

            &.grey-bg {
                background: #f3f4f6;

                .button {
                    background: #fff;
                }
            }
        }

        &.absolute-btn {
            position: relative;

            .form-control {
                padding-right: 90px;
            }

            > .button {
                position: absolute;
                right: 8px;
                bottom: 7px;
            }
        }

        &.with-invoice-data {
            .form-control {
                padding-right: 135px;
            }

            > .button {
                bottom: auto;
                top: 9px;
            }
        }
    }

    &.with-group-buttons {
        .input-with-prefix {
            @media (max-width: 640px) {
                flex-wrap: wrap;
            }
        }

        .form-control {
            padding-right: 10px;
        }

        .button {
            display: inline-block;
            margin-right: 5px;
            position: static;
            bottom: 7px;
            right: 5px;
            height: 31px;
            padding-top: 0;
            padding-bottom: 0;
            white-space: nowrap;

            &.copy-btn {
                right: 92px;
            }
        }
    }

    &.code-form {
        max-width: 436px;

        .input-with-prefix {
            display: flex;
            align-items: center;
            border-radius: 5px;
            box-shadow: none;
            border: 1px solid #e5e7eb;
            min-height: 45px;
            padding-left: 10px;

            .code-prefix {
                text-align: center;
                font-size: 14px;
                color: #5e5e5e;
                cursor: default;
                white-space: nowrap;
            }

            input.form-control {
                border-radius: 0;
                border: 0;
                padding-left: 10px;
                background: transparent;
            }

            &.with-save-button {
                padding-left: 0;

                .button.primary {
                    top: 0;
                    margin-right: 5px;
                    padding: 9px 22px;
                }
            }
        }

        .loader-container {
            min-height: auto;
            top: auto;
            bottom: 0;
            height: calc(100% - 21px);
            background: rgba(#fff, 0.7);

            .circle-loader {
                margin: 0;
                transform: translate(-50%, -50%);
                top: 50%;
            }
        }

        .button {
            top: 29px;
            bottom: auto;
        }
    }

    &.error-group.code-form {
        .input-with-prefix {
            border: 1px solid #ef4444 !important;

            .form-control {
                border: 0 !important;
                background: transparent;
            }
        }
    }

    &.prefix-m-top {
        .input-with-prefix {
            .code-prefix {
                position: relative;
                top: 1px;
            }
        }
    }

    &.with-link {
        .form-control {
            > .short-link {
                text-decoration: underline;
                color: #6b7280;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
                transition: all 0.2s ease-in-out;
            }

            > a {
                &:hover {
                    color: #000;
                }
            }
        }
    }

    &.min-width {
        .form-control {
            min-width: 120px;
        }
    }

    &.with-preview {
        .form-control {
            overflow: visible;
        }

        .button {
            margin-right: 7px;

            &:last-of-type {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    &.with-save-btn {
        &.absolute-btn {
            > .button {
                padding: 0 20px;
                border: 0;
                border-left: 1px solid #e5e7eb;
                box-shadow: none;
                height: 44px;
                bottom: 0;
                border-radius: 0;
                right: 0;

                &.border-button {
                    background: transparent;
                }
            }
        }
    }

    &.small-width {
        .form-control {
            max-width: 150px;
        }
    }

    &.error-group {
        .form-control,
        #custom-dropdown-default [class*='-control'] {
            border: 1px solid #ef4444 !important;

            .select-variant-label {
                color: #ef4444;
            }
        }

        .error-text {
            font-size: 12px;
            color: #ef4444;
            margin-top: 2px;
            font-weight: normal;
        }
    }

    &.hidden-error-label {
        .error-text {
            display: none;
        }
    }

    textarea {
        resize: none;

        &.form-control {
            height: auto;
            resize: none;
            padding: 15px;
            line-height: 20px;

            &::placeholder {
                color: #9aa3b0;
            }
        }
    }

    &.required-field {
        .control-label {
            &:after {
                content: '*';
                margin-left: 2px;
                color: #ef4444;
            }
        }
    }

    &.form-group-addon {
        position: relative;

        .form-control {
            padding-right: 40px;
        }

        .input-addon,
        .select2 {
            position: absolute;
            right: 0;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 14px;
            color: #9ca3af;
            //top: 22px;
            bottom: 0;
            padding: 0 10px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        > #custom-dropdown-default {
            position: absolute;
            bottom: 0;
            right: 0;

            [class*='-control'] {
                border-radius: 0 5px 5px 0;
            }
        }

        .select2 {
            padding: 0;
            width: 55px !important;

            .select2-selection--single {
                border: 0;
                min-height: 43px;
                padding: 0;
                margin-right: 15px;
            }
        }

        &.left {
            .input-addon {
                right: auto;
                top: 37px;
                left: 10px;
            }

            .form-control {
                padding-right: 10px;
                padding-left: 45px;
            }
        }

        &.bm-group-addon {
            input {
                min-height: 39px;
            }

            .select2 {
                padding: 0;
                width: 68px !important;
                border-left: 0;
                height: 39px;

                .select2-selection--single {
                    border: 0;
                    min-height: 43px;
                    padding: 0;
                    color: #9ca3af;
                    margin-right: 15px;
                    background: transparent;

                    > span {
                        color: inherit;
                        top: 6px;
                    }
                }
            }
        }
    }

    .logo-upload-wrapper {
        display: flex;
        align-items: center;
        margin-top: 3px;

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: center;
            height: 70px;
            width: 70px;
            border-radius: 5px;
            background-color: #ffffff;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
            //background-image: url('/public/assets/images/default-photo.svg');
        }

        .buttons-wrapper {
            margin-left: 15px;

            .top-part {
                display: flex;
                align-items: center;

                button {
                    border-radius: 5px;
                    padding: 0 15px;

                    &:first-of-type {
                        margin-right: 10px;
                    }
                }

                a {
                    border-radius: 5px;
                    padding: 0 15px;
                }
            }

            .bottom-text {
                color: #4b5563;
                font-size: 13px;
                letter-spacing: -0.14px;
                line-height: 20px;
                margin-top: 8px;
                @media (max-width: 767px) {
                    font-size: 11px;
                }
            }
        }
    }

    &.switch-form {
        position: relative;

        input {
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            opacity: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            &:checked {
                + {
                    .switch .slider {
                        background-color: #0eb981;

                        &:before {
                            transform: translateX(13px);
                        }
                    }
                }
            }
        }

        .sub-label {
            pointer-events: none;
            color: #6b7280;
            font-size: 11px;
            font-weight: 300;
            opacity: 0.8;
            line-height: 14px;
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 32px;
            height: 19px;
            margin-bottom: 0;
            min-width: 32px;

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #9ca3af;
                transition: 0.4s;
                border-radius: 10px;

                &:before {
                    position: absolute;
                    content: '';
                    height: 15px;
                    width: 15px;
                    left: 2px;
                    bottom: 2px;
                    background-color: white;
                    transition: 0.4s;
                    border-radius: 50%;
                }
            }
        }

        &.inline-label {
            display: inline-flex;
            align-items: center;

            .control-label {
                margin-bottom: 0;
                margin-left: 5px;
                order: 2;

                i {
                    position: relative;
                    z-index: 1050;
                    top: 0;
                    font-size: 12px;
                    margin-left: 3px;
                }
            }
        }

        &.extra-text {
            align-items: flex-start;
        }

        &.big-label {
            .control-label {
                color: #101827;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 18px;
                margin-left: 7px;
            }

            .sub-label {
                margin-top: 3px;
                font-size: 14px;
                margin-bottom: 0;
                line-height: 21px;
                font-weight: 500;
            }
        }

        &.light-version {
            input:checked {
                + .switch .slider {
                    &:before {
                        transform: translateX(15px);
                    }
                }
            }

            .switch {
                width: 36px;
                height: 21px;
                min-width: 36px;
            }

            .slider {
                background: #dfe2e7;

                &:before {
                    width: 17px;
                    height: 17px;
                }
            }

            .control-label {
                position: relative;
                top: -1px;
            }
        }

        &.blue-version {
            input:checked {
                + .switch {
                    background-color: #3c61ea;
                }
            }
        }

        &.light-blue-version {
            input:checked {
                + .switch {
                    background-color: #3c82f6;
                }
            }
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;

            .checkbox {
                cursor: not-allowed;
            }
        }
    }

    &.checkbox-form {
        display: flex;
        align-items: center;

        .control-label {
            margin-bottom: 0;
            line-height: 16px;

            a,
            button {
                font-weight: bold;
                color: #6b7280;
                text-decoration: none;
                position: relative;
                z-index: 1;
                border: 0;
                background: transparent;
                padding: 0;
                margin: 0;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .country-label {
                display: block;
                pointer-events: none;
                font-size: 10px;
                opacity: 0.7;
                margin-left: 0;
            }
        }

        .checkbox-input {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            min-height: auto;

            ~ .control-label .sub-label {
                pointer-events: none;
                color: #6b7280;
                font-size: 11px;
                font-weight: 300;
                opacity: 0.8;
                line-height: 14px;
            }

            &.with-sub-label {
                ~ .checkbox-checkmark {
                    top: -9px;
                }
            }

            &:checked {
                + .checkbox-checkmark {
                    background: #3c61ea;
                    border-color: transparent;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        .checkbox-checkmark {
            position: relative;
            display: block;
            height: 16px;
            width: 16px;
            min-width: 16px;
            border: 1px solid #d1d5db;
            border-radius: 3px;
            transition: all 0.2s ease-in-out;
            margin-right: 8px;
            pointer-events: none;

            &:after {
                content: '\e921';
                font-family: selmo;
                font-size: 7px;
                color: variables.$white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: all 0.2s ease-in-out;
            }
        }

        &.big-circle {
            .checkbox-checkmark {
                height: 24px;
                width: 24px;
                min-width: 24px;
                border-radius: 50%;

                &:after {
                    font-size: 9px;
                }
            }
        }

        &.radio-type {
            .checkbox-checkmark {
                border-radius: 50%;
            }
        }

        &.big-size {
            .checkbox-checkmark {
                height: 18px;
                width: 18px;
                min-width: 18px;
            }
        }

        &.big-label {
            .control-label {
                font-size: 14px;
                font-weight: bold;
                color: #101827;
            }
        }
    }

    .border-area {
        position: relative;
        border: 1px solid #e5e7eb;
        border-radius: 5px;

        .loader-container {
            z-index: 1051;
        }

        textarea.form-control {
            height: auto;
            resize: none;
            border: 0;
            margin-bottom: 55px;
            box-shadow: none;
            @media (max-width: 767px) {
                padding: 10px;
                margin-bottom: 47px;
            }
        }

        .bottom-part {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 10px;
            @media (max-width: 767px) {
                padding: 8px 10px;
            }

            .form-group {
                margin-bottom: 0;
            }

            .send-button {
                color: #fff;
                background: #3c61ea;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;
                padding: 8px 20px;
                border-radius: 5px;
                border: 0;
                transition: all 0.1s ease-in-out;
                margin-left: auto;

                &:hover {
                    background-color: #1c4ed8;
                }
            }
        }

        .loader-container {
            background: rgba(#fff, 0.9);
        }
    }

    &.search-form {
        position: relative;
        margin-bottom: 0;

        .form-control {
            border-radius: 5px;
            height: 33px;
            width: 120px;
            color: #101827;
            font-size: 0.75rem;
            line-height: 1rem;
            padding: 8px 8px 8px 30px;
            background-color: #f3f4f6;
            border: 0;
            min-height: auto;
        }

        > i {
            position: absolute;
            left: 8px;
            top: 9px;
            font-size: 14px;
            color: #9aa3b0;
        }

        &.big-size {
            .form-control {
                height: 53px;
                border-radius: 8px;
                border-color: #eff0f1;
                box-shadow: 0 0 0 1px #eff0f1;
                transition: all 0.2s ease;
                font-size: 16px;
                padding-left: 40px;
            }

            > i {
                left: 12px;
                top: 17px;
                font-size: 18px;
            }
        }

        &.big-icon {
            > i {
                left: 12px;
                top: 6px;
                font-size: 16px;
                color: #9ca3af;
            }
        }

        &.white-bg {
            .form-control {
                background-color: #fff;
                border: 1px solid #dfe1e4;

                &::placeholder {
                    color: #6b7280;
                    font-size: 12px !important;
                }
            }
        }
    }

    &.disabled-bg {
        .form-control {
            background-color: #eceff2;
            border: 0;
        }

        .copy-btn {
            border: 1px solid #dfe1e4;
            border-radius: 5px;
            background-color: #ffffff;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
            color: #9ca3af;
        }
    }

    .tooltip-error {
        position: absolute;
        background: #e5e7eb;
        font-size: 10px;
        padding: 3px 5px;
        border-radius: 3px;
        right: 0;
        white-space: nowrap;
        top: -10px;

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            right: 22px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 4px solid #e5e7eb;
        }
    }

    &.form-group-area {
        .drop-area {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            border: 1px dashed #e5e7eb;
            min-height: 100px;
            cursor: pointer;
            border-radius: 5px;
            padding: 20px;

            input {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                cursor: pointer;
            }

            .content {
                font-size: 14px;
                display: flex;
                align-items: center;

                i {
                    font-size: 30px;
                    margin-right: 15px;
                }
            }

            .uploaded-content {
                align-self: flex-start;

                .photos-row {
                    display: flex;
                    align-items: center;

                    .photo {
                        position: relative;
                        width: 100px;
                        height: 100px;
                        margin-right: 15px;

                        > button {
                            position: absolute;
                            top: -8px;
                            right: -8px;
                            background: #f87171;
                            color: white;
                            border-radius: 50%;
                            width: 22px;
                            height: 22px;
                            font-size: 8px;
                        }

                        img {
                            border-radius: 5px;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }

    .switcher-wrapper {
        display: inline-flex;
        align-items: center;

        span {
            font-weight: normal;
            font-size: 0.925rem;
            color: #000;
            margin-right: 15px;

            &:last-of-type {
                margin-right: 0;
                margin-left: 15px;
                color: #dfe2e7;
            }
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 36px;
            height: 21px;
            background-color: #dfe2e7;
            border-radius: 20px;
            transition: all 0.3s;
            cursor: pointer;
            margin-bottom: 0;
        }

        .switch:after {
            content: '';
            position: absolute;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background-color: #fff;
            top: 2px;
            left: 2px;
            transition: all 0.3s;
        }

        .checkbox:checked + .switch {
            background: #0eb981;

            &:after {
                left: 17px;
            }
        }

        .checkbox:checked ~ span {
            color: #000;
        }

        &.active {
            span:first-of-type {
                color: #0eb981;
            }
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;

            label {
                cursor: not-allowed;
            }
        }

        &.label-first {
            order: 2;
        }
    }

    .custom-dropdown {
        z-index: 9;
    }

    &.disabled {
        #custom-dropdown-default [class*='-control'] {
            background-color: #e9ecef;
            opacity: 1;
        }
    }

    &.form-control-bold {
        .form-control {
            font-weight: bold;
        }
    }
}

div.tagsinput {
    font-family: 'sofia-pro';
    height: auto !important;
    min-height: 50px !important;
    padding: 5px 8px;
    width: 100% !important;
    border: 1px solid #d1d5db;
    border-radius: 5px;

    span.tag {
        display: flex;
        align-items: center;
        margin: 5px 5px 5px 0;
        background: #f3f4f6;
        border: 0;
        color: #101827;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 13px;
        height: 32px;
        padding: 0 10px;
        position: relative;
        border-radius: 5px;
        padding-right: 15px;

        & > span:first-of-type {
            position: relative;
            top: -2px;
        }

        a {
            font-size: 0;

            &:before {
                content: '\e81a';
                font-family: 'selmo';
                display: inline-block;
                font-size: 8px;
                color: #000;
                margin-left: 4px;
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
            }
        }
    }

    div:not(.tags_clear) {
        min-width: 100px;
        height: 42px;
        display: flex;
        align-items: center;

        input {
            font-family: 'sofia-pro';
            width: 100%;
            margin-bottom: 0;
            position: relative;
            top: -1px;
        }
    }
}

.filter-box {
    display: flex;
    justify-content: flex-end;

    input:not(.daterange-input) {
        height: 30px;
        width: 135px;
        border-radius: 5px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        border: 0;
        position: relative;
        padding-left: 10px;
        font-size: 12px;
        font-weight: 500;

        &:hover {
            cursor: pointer;
        }

        &:after {
            font-family: 'selmo';
            content: '\e905';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }

        &:first-of-type {
            margin-right: 5px;
        }
    }

    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
        padding: 5px;
        border-radius: 5px;

        &:hover {
            cursor: pointer;
        }
    }
}

.form-wrapper {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;

    &.settings-page {
        .normal-sellfie-alert {
            width: 100%;
            margin-top: 0;
        }
    }

    .single-file-box {
        height: 61.5px;
        border: 1.5px dashed #d1d5db;
        border-radius: 5px;
        background-color: #f9fafb;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            width: 45px;
            border-radius: 5px;
            background-color: #e5e7eb;

            i {
                color: #101827;
                font-size: 18px;
            }
        }

        &__title {
            color: #101827;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            margin: 0 15px;
            flex-grow: 1;
        }

        &__buttons {
            display: flex;
            align-items: center;
            margin: 0 -5px;

            & > div {
                padding: 0 5px;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 34px;
                width: 34px;
                background-color: #fcd34c;
                border-radius: 5px;

                i {
                    color: #101827;

                    &.icon-arrow-left {
                        &:before {
                            transform: rotate(270deg);
                        }
                    }
                }
            }
        }
    }

    .button-place {
        display: flex;
        justify-content: center;
        margin: 35px 0;
    }

    .btn {
        height: 54px;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23px;
        width: 164px;
        padding-bottom: 2px;

        @media (max-width: 767px) {
            width: 100%;
            height: 48px;
        }
    }
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -9px;
    margin-right: -9px;

    > div {
        padding: 0 9px;
    }
}

.select2-container--default {
    .select2-selection--single {
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid #e5e7eb;
        transition: none;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        padding: 6px 15px;
        color: #101827;
        min-height: 45px;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;

        .select2-selection__rendered {
            margin: 0;
            padding: 0;
            line-height: normal;

            &:focus-visible {
                outline: none;
            }
        }

        .select2-selection__arrow {
            position: absolute;
            right: 10px;

            b {
                display: none;
            }

            &:after {
                content: '\e903';
                font-family: selmo;
                position: absolute;
                left: 50%;
                top: calc(50% + 2px);
                transform: translate(-50%, -50%);
                color: #9ca3af;
                font-size: 8px;
            }
        }
    }
}

.select2-container--open {
    .select2-selection--single .select2-selection__arrow:after {
        transform: translate(-50%, -50%) rotate(180deg);
    }
}

.select2-dropdown {
    border-radius: 5px;
    background-color: #ffffff !important;
    box-shadow:
        0 4px 16px 0 rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.08);
    padding: 7px;
    overflow: hidden;

    .select2-search {
        padding: 0;
        position: relative;

        .select2-search__field {
            border-radius: 3px;
            background-color: #f3f4f6;
            position: relative;
            border: 0;
            box-shadow: none;
            padding-left: 30px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            color: variables.$darkColor;
            height: 32px;

            &::placeholder {
                color: #6b7280;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &:after {
            content: '\e913';
            font-family: selmo;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: #9ca3af;
            font-size: 14px;
        }
    }

    @mixin custom-scrollbar {
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #dddfe3;
        }
    }

    .select2-results {
        box-shadow: none;
        margin-top: 0;
        border-radius: 0;
        width: 100%;

        .select2-results__options {
            margin: 0 -5px;
            padding: 0 5px;
            @include custom-scrollbar;
        }
    }

    &.select-dropdown {
        padding: 5px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
        margin-top: 8px;
        min-width: 140px !important;
        border-radius: 5px !important;
    }
}

.form-group {
    .react-time-picker-wrapper {
        .react-time-picker {
            padding-left: 40px;
        }

        > i {
            position: absolute;
            left: 15px;
            top: 37px;
            font-size: 17px;
            color: #cfd5dd;
            pointer-events: none;
        }
    }

    .react-datetime-picker,
    .react-time-picker {
        padding: 12px 15px;

        &__wrapper {
            border: 0;
        }

        &__inputGroup {
            padding: 0;

            input {
                &:focus,
                &:focus-visible {
                    outline: none !important;
                    box-shadow: none !important;
                }
            }
        }

        &__button {
            color: #cfd5dd;
            padding: 0;
            position: relative;
            top: -1px;

            &:focus {
                outline: none;
            }
        }
    }

    .react-calendar {
        box-shadow: 0 2px 20px rgba(#000, 0.05);
        border-radius: 5px;
        border: 0;
        margin-top: 10px;
        padding: 10px;

        &__month-view {
            &__weekdays {
                &__weekday {
                    text-transform: lowercase;
                    font-size: 12px;
                    font-weight: normal;

                    abbr {
                        cursor: default;
                        text-decoration: none;
                    }
                }
            }
        }

        &__tile {
            font-size: 14px;
            z-index: 1;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
                height: 30px;
                border-radius: 5px;
                background: transparent;
                z-index: -1;
            }

            &--active {
                background: transparent;
                transition: all 0.1s ease-in-out;

                &:before {
                    background: #3c61ea;
                }
            }

            &:hover {
                background: transparent !important;

                &:before {
                    background: #efefef;
                }
            }
        }
    }

    &.calendar-input {
        .react-datepicker-wrapper {
            @apply w-full;
        }

        .icon-calendar {
            position: absolute;
            left: 15px;
            top: 35px;
            font-size: 16px;
            color: #cfd5dd;
            pointer-events: none;
        }

        .form-control {
            padding-left: 40px;
        }

        .react-datepicker-popper {
            z-index: 2;

            .react-datepicker {
                box-shadow: 0 2px 20px rgba(#000, 0.05);
                border-radius: 5px;
                border: 0;
                margin-top: 10px;
                padding: 10px;

                &__navigation-icon {
                    top: 14px;
                }

                &__triangle {
                    &:after,
                    &:before {
                        display: none;
                    }
                }

                &__header {
                    background: transparent;
                    border: 0;
                }

                &__day-names {
                }

                &__day-name {
                    font-weight: bold;
                }
            }
        }
    }

    &.dark {
        .control-label {
            font-size: 14px;
            color: #bfbfbf;
        }

        .form-control {
            background: #313134;
            border-color: #3c3c3e;
            color: #fff;
            font-size: 16px;
            min-height: 48px;

            &::placeholder {
                color: #83868e;
                font-size: 16px;
            }
        }
    }
}

#custom-dropdown-default {
    [class*='-control'] {
        //box-shadow: 0 1px 1px 0 rgba(#000000, 0.07);
        box-shadow: none;
        color: #6b7280;
        border-radius: 5px;
        min-height: 35px;
        background: #fff;
        border: 1px solid #e5e7eb;
        cursor: pointer;

        > div:first-of-type {
            padding: 0 8px;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;

            [class*='-multiValue'] {
                border-radius: 3.5px;
                background-color: #f3f4f6;
                padding: 5px 3px;
                color: #4b5563;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 13px;

                .sub-category-element {
                    &:before,
                    &:after {
                        display: none;
                    }
                }

                > div:last-of-type {
                    position: relative;

                    &:after {
                        content: '\e909';
                        font-family: selmo;
                        font-size: 7px;
                        opacity: 0.7;
                        transition: all 0.2s ease-in-out;
                    }

                    svg {
                        display: none;
                    }

                    &:hover {
                        background: transparent;

                        &:after {
                            color: variables.$black;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .select-variant-label {
            font-size: 11px;
            color: #6b7280;
            line-height: 12px;
        }

        .custom-value-container {
            position: relative;
            min-height: 20px;
            overflow: visible;
        }

        .floated-small-label {
            position: absolute;
            left: 10px;
            top: -4px;
            font-size: 8px;

            &:after {
                display: none;
            }

            + .custom-value {
                top: 7px;
            }
        }

        .custom-value {
            position: absolute;
            top: 2px;
            left: 10px;
            right: 8px;
            bottom: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .hidden-value-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            height: 0;
        }

        [class*='-singleValue'] {
            border-color: inherit !important;
            background: inherit !important;
            color: inherit !important;
        }

        [class*='-indicatorSeparator'] {
            display: none;
        }

        [class*='-indicatorContainer'] {
            position: relative;
            margin-right: 10px;
            color: inherit;

            svg {
                display: none;
            }

            &:before {
                content: '\e903';
                font-family: selmo;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 8px;
                color: inherit;
            }
        }
    }

    [class*='-menu'] {
        box-shadow:
            0 4px 16px 0 rgba(#000000, 0.12),
            0 1px 3px 0 rgba(#000000, 0.08);
        z-index: 2;

        > div {
            padding: 0;

            > [class*='-option'] {
                cursor: pointer;
                font-size: 12px;
                padding: 7px 9px;
                color: #101827;
                background: transparent;
                transition: all 0.1s ease-in-out;

                &:hover {
                    background: #f3f4f6;
                }
            }
        }
    }

    &.order-status {
        [class*='-control'] {
            > div:first-of-type {
                [class*='-multiValue'] {
                    background-color: transparent;
                    padding: 0;
                    position: relative;

                    > div > div {
                        padding-left: 6px !important;
                    }

                    [class*='-MultiValueRemove'] {
                        position: absolute;
                        top: 8px;
                        right: 6px;
                        color: #000;
                    }
                }
            }
        }
    }
}

#custom-dropdown-default {
    &.default-size {
        [class*='-control'] {
            min-height: 45px;

            > div:first-of-type {
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
                color: #101827;
            }
        }
    }

    &.big-size {
        [class*='-menu'] > div > [class*='-option'] {
            font-size: 14px;
            border-radius: 5px;
            padding: 9px;
            font-weight: 500;
            margin: 2px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        [class*='-control'] {
            min-height: 53px;
            border-radius: 8px;
            box-shadow: 0 0 0 1px #eff0f1;
            border-color: #eff0f1;
            color: #101827;
            font-size: 16px;

            > div:first-of-type {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 20px;
                color: #101827;
                padding-left: 15px;
                padding-right: 15px;
                @media (max-width: 991px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            [class*='-placeholder'] {
                font-size: 16px;
                color: #6b7280;
                margin: 0;
                @media (max-width: 991px) {
                    font-size: 14px;
                }
            }

            [class*='-indicatorContainer'] {
                &:before,
                &:after {
                    position: absolute;
                    content: '';
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    transform: translateX(0);
                    border-radius: 3px;
                    padding: 0;
                    left: 0;
                }

                &:after {
                    top: 11px;
                    border-top: 7px solid #d8d8d8;
                }

                &:before {
                    bottom: 9px;
                    top: auto;
                    border-bottom: 7px solid #d8d8d8;
                }
            }
        }

        .floated-label.selected + .form-control {
            padding-top: 21px;
        }

        &.floated-label-select {
            .form-group .floated-label.selected {
                top: 8px;
            }
        }
    }

    &.small-select {
        [class*='-control'] {
            box-shadow: none;
        }
    }
}

.ck {
    .ck-toolbar,
    .ck-content {
        border-color: #e5e7eb !important;
    }

    .ck-content {
        min-height: 100px;
        box-shadow: none !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        letter-spacing: 0;
        line-height: 18px;
        padding: 0 15px !important;
    }
}

.big-min-height {
    .ck .ck-content {
        min-height: 500px;
    }
}

.droparea-with-photo-link {
    display: flex;
    align-items: center;

    figure {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 15px;
        margin-bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .format-text {
        color: #9ca3af;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        margin-top: 5px;
    }

    .button {
        &.text-only {
            color: #6b7280;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;
            margin-left: 15px;

            &:hover {
                color: #000;
            }
        }
    }
}

.custom-async-search {
    &.default-size {
        .form-group {
            .remove-product {
                top: 36px;
            }
        }
    }

    .form-group {
        &.selected-disabled {
            .form-control {
                background: transparent;
            }
        }

        .remove-product {
            position: absolute;
            right: 6px;
            background: transparent;
            border: 0;
            font-size: 8px;
            top: 32px;
            color: #9ca3af;
            transition: all 0.2s ease-in-out;
            z-index: 1;
            padding: 4px;

            &:hover {
                color: #101827;
            }
        }

        .form-control {
            &.selected-value {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding-right: 26px;
                transition: all 0.2s ease-in-out;

                .select-custom-option {
                    flex-grow: 1;
                }

                .placeholder {
                    color: #1f2937;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 15px;
                    min-height: 43px;
                }

                .remove-product {
                    z-index: auto;
                }
            }
        }
    }

    &.floated-label-select {
        .form-group {
            .form-control {
                transition: all 0.2s linear;
            }

            .remove-product {
                top: 16px;
            }

            .selected-value {
                padding: 0 !important;

                > div:not(.remove-product) {
                    height: 100%;
                    padding: 6px 26px 6px 15px;
                }
            }

            .floated-label.selected {
                + .selected-value .select-custom-option {
                    padding-top: 22px;
                }
            }
        }
    }

    .custom-dropdown {
        .loader-parent {
            padding: 0;
            min-height: auto;

            .loader-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                min-height: auto;
                top: 50%;
                transform: translateY(-50%);
                background: #fff !important;

                .circle-loader {
                    display: block;
                    transform: none;
                    margin: 0;
                }
            }

            &.active-loader-parent {
                height: 50px;
            }
        }

        .select-custom-option {
            font-weight: 500;
            cursor: pointer;
            padding: 8px;
            transition: all 0.1s ease-in-out;

            &.no-result-message {
                text-align: center;
                cursor: default;
            }

            .placeholder {
                font-weight: normal;
            }

            &:hover:not(.disabled):not(.no-result-message) {
                background: #f3f4f6;
            }
        }
    }

    &.order-selection-select {
        .form-control.selected-value {
            .placeholder {
                display: flex;
                align-items: center;
                min-height: 28px;
            }
        }
    }
}
