.daterange-wrapper {
	position: relative;

	.date-input {
		display: flex;
		align-items: center;
		border-radius: 5px;
		height: 33px;
		border: 1px solid #DFE1E4;
		font-size: 0.75rem;
		line-height: 1rem;
		padding: 8px 0 8px 25px;
		color: #6B7280;
		border-right: 0;
		width: 160px;
		background: transparent;

		.icon-calendar {
			position: absolute;
			left: 8px;
			top: 50%;
			transform: translateY(-50%);
			color: #DFE1E4;
			font-size: 14px;
		}

		&:after {
			content: '\e903';
			font-family: selmo;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 8px;
			width: 35px;
			border: 1px solid #DFE1E4;
			height: 33px;
			border-radius: 0 5px 5px 0;
			color: #6B7280;
			margin-left: auto;
		}


		.small-date {
			font-size: 10px;
		}
	}

	.daterange-absolute-place {
		margin-top: 10px;
		position: absolute;
		top: 100%;
		right: 0;
		z-index: 11;
		@media(max-width: 480px) {
			padding-bottom: 135px;
		}

		.daterange-absolute-wrapper {
			box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
			border-radius: 8px;
			background: #fff;
			overflow: hidden;
			@media(max-width: 767px) {
				left: 0;
				width: 100%;
			}

			.calendar-row {
				display: flex;
				@media(max-width: 991px) {
					flex-wrap: wrap;
				}
			}

			.rdrDefinedRangesWrapper {
				@media(max-width: 991px) {
					width: 100%;
				}

				.rdrStaticRange {
					.rdrStaticRangeLabel {
						transition: all .2s ease-in-out;
						padding: 0;

						.extra-range {
							font-weight: normal;
							color: #797979;
							padding: 12px 20px;
							display: block;
							transition: all .1s ease-in-out;
							@media(max-width: 991px) {
								padding: 8px 12px;
							}

							&.active {
								color: black;
								font-weight: bold;
								background: rgba(#000, 0.04);
							}
						}
					}

					&:hover {
						.rdrStaticRangeLabel {
							background: rgba(#000, 0.04);
						}
					}
				}
			}

			.rdrCalendarWrapper {
				@media(max-width: 767px) {
					width: 100%;
				}

				.rdrMonthAndYearWrapper {
					@media(max-width: 480px) {
						height: 50px;
					}
				}

				.rdrNextPrevButton {
					position: relative;
					background: transparent;
					border: 1px solid #E5E7EB;
					color: #000;
					transition: all .2s ease-in-out;

					&:after {
						color: #000;
						font-size: 9px;
						content: '\e903';
						font-family: selmo;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: rotate(-90deg) translate(-50%, -50%);
						transform-origin: 0 0;
						margin-top: 1px;
					}

					i {
						display: none;
					}

					&:hover {
						background: rgba(#000, 0.04);
						border-color: transparent;
					}

					&.rdrPprevButton {
						&:after {
							transform: rotate(90deg) translate(-50%, -50%);
						}
					}
				}
			}

			.bottom-buttons {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 10px 20px;
				background: #fff;
				margin-left: 225px;
				border-left: 1px solid #eff2f7;

				@media(max-width: 991px) {
					margin-left: 0;
					border-left: 0;
				}

				@media(max-width: 480px) {
					padding: 0 10px 15px;
				}

				> button {
					margin: 0 10px;
					padding: 9px 15px;
					@media(max-width: 480px) {
						min-width: auto;
						width: 50%;
						margin: 0 7px;
						padding-top: 8px;
						padding-bottom: 8px;
						&.neutral {
							padding-top: 7px;
							padding-bottom: 7px;
						}
					}
					&.border-button {
						margin-left: 0;
					}
				}
			}
		}
	}

	.rdrMonths {
		.rdrMonth {
			@media(max-width: 767px) {
				width: 100%;
				padding-bottom: 10px;
			}

			.rdrMonthName {
				@media(max-width: 767px) {
					padding-top: 0;
					padding-bottom: 0;
				}
			}

			.rdrWeekDays {
				.rdrWeekDay {
					color: #000;
				}
			}

			.rdrDays {
				.rdrDay {

					&.rdrDayDisabled {
						cursor: not-allowed !important;
						opacity: 0.5;
						background-color: transparent;
					}

					.rdrEndEdge + .rdrDayNumber {
						span {
							color: #000;
						}
					}

					.rdrDayNumber {
						span {
							color: #000 !important;
							font-weight: normal;

							&:after {
								background: #f0f0f0;
							}
						}
					}

					&.rdrDayPassive {
						opacity: 0;
						visibility: hidden;
					}

					&.rdrDayWeekend {
						.rdrDayNumber {
							span {
								color: #888 !important;
							}
						}
					}

					&.rdrDayWeekend {
						.rdrInRange ~ .rdrDayNumber, .rdrStartEdge ~ .rdrDayNumber {
							span {
								color: #000 !important;
							}
						}
					}

					&.rdrDayHovered {
						span:first-of-type {
							border-color: #e2e2e2 !important;
						}
					}
				}

				span:not(.rdrDay) {
					border-color: #e2e2e2 !important;
				}
			}
		}
	}

	&:not(.cancel-datepicker) {
		.rdrInRange, .rdrStartEdge, .rdrEndEdge {
			color: #f0f0f0 !important;
		}
	}
}
