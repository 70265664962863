.changelog-wrapper {
	.date {
		text-align: center;
		font-weight: bold;
		color: #101827;
		font-size: 18px;
		letter-spacing: 0;
		line-height: 23px;
		margin-bottom: 15px;
		.change-by {
			color: #6B7280;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: -0.1px;
			line-height: 21px;
		}
	}
}
