@font-face {
	font-family: 'selmo';
	src: url('../../public/assets/font/selmo.eot?c1dv1s');
	src: url('../../public/assets/font/selmo.eot?c1dv1s#iefix') format('embedded-opentype'),
	url('../../public/assets/font/selmo.ttf?c1dv1s') format('truetype'),
	url('../../public/assets/font/selmo.woff?c1dv1s') format('woff'),
	url('../../public/assets/font/selmo.svg?c1dv1s#selmo') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'selmo' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-selmo-marketing:before {
	content: "\e962";
}

.icon-marketing:before {
	content: "\e961";
}
.icon-gift-icon-lp:before {
	content: "\e960";
}
.icon-meta-small:before {
	content: "\e95f";
}

.icon-integrations:before {
	content: "\e95c";
}
.icon-meta-logo-s:before {
	content: "\e95d";
}
.icon-wysylka:before {
	content: "\e95e";
}

.icon-analytics:before {
	content: "\e95b";
}

.icon-balance:before {
	content: "\e95a";
	color: #6b7280;
}
.icon-more:before {
	content: "\e959";
}
.icon-upgrade:before {
	content: "\e958";
}
.icon-overlay:before {
	content: "\e957";
}
.icon-chat1:before {
	content: "\e955";
}
.icon-emoji1:before {
	content: "\e956";
}

.icon-team:before {
	content: "\e954";
}

.icon-cart1:before {
	content: "\e950";
}
.icon-custom:before {
	content: "\e951";
}
.icon-mail1:before {
	content: "\e952";
}
.icon-link:before {
	content: "\e953";
}

.icon-forecast:before {
	content: "\e94c";
}
.icon-new-2:before {
	content: "\e94d";
}
.icon-alert:before {
	content: "\e94e";
}

.icon-qr-code:before {
	content: "\e94b";
}
.icon-sign-out:before {
	content: "\e94a";
}

.icon-downloads:before {
	content: "\e946";
}
.icon-support:before {
	content: "\e947";
}
.icon-news:before {
	content: "\e948";
}
.icon-knowledge-base:before {
	content: "\e949";
}


.icon-messenger-2-f:before {
	content: '\e82b';
}

/* '' */
.icon-messenger-2:before {
	content: '\e82c';
}

/* '' */
.icon-messenger-s:before {
	content: '\e82d';
}

/* '' */
.icon-orders-f:before {
	content: '\e82e';
}

/* '' */
.icon-products-f:before {
	content: '\e82f';
}

/* '' */
.icon-settings-f:before {
	content: '\e830';
}

/* '' */
.icon-stats-f:before {
	content: '\e832';
}

/* '' */
.icon-products-1:before {
	content: '\e833';
}

/* '' */
.icon-clients:before {
	content: '\e834';
}

/* '' */
.icon-gift:before {
	content: '\e83e';
}

/* '' */
.icon-add:before {
	content: '\e900';
}

/* '' */
.icon-alert-c:before {
	content: '\e901';
}

/* '' */
.icon-arrow-left:before {
	content: '\e902';
}

/* '' */
.icon-arrows:before {
	content: '\e903';
}

/* '' */
.icon-bin:before {
	content: '\e904';
}

/* '' */
.icon-calendar:before {
	content: '\e905';
}

/* '' */
.icon-copy:before {
	content: '\e906';
}

/* '' */
.icon-cross-c:before {
	content: '\e907';
}

/* '' */
.icon-cross-r:before {
	content: '\e908';
}

/* '' */
.icon-cross:before {
	content: '\e909';
}

/* '' */
.icon-edit:before {
	content: '\e90a';
}

/* '' */
.icon-emoji:before {
	content: '\e90b';
}

/* '' */
.icon-filter:before {
	content: '\e90c';
}

/* '' */
.icon-google:before {
	content: '\e90d';
}

/* '' */
.icon-help:before {
	content: '\e90e';
}

/* '' */
.icon-home:before {
	content: '\e90f';
}

/* '' */
.icon-img:before {
	content: '\e910';
}

/* '' */
.icon-info-c:before {
	content: '\e911';
}

/* '' */
.icon-info:before {
	content: '\e912';
}

/* '' */
.icon-magnifier:before {
	content: '\e913';
}

/* '' */
.icon-messenger:before {
	content: '\e914';
}

/* '' */
.icon-notify:before {
	content: '\e915';
}

/* '' */
.icon-orders:before {
	content: '\e916';
}

/* '' */
.icon-plus:before {
	content: '\e917';
}

/* '' */
.icon-preview:before {
	content: '\e918';
}

/* '' */
.icon-dots:before {
	content: '\e919';
}

/* '' */
.icon-profile:before {
	content: '\e91a';
}

/* '' */
.icon-safe:before {
	content: '\e91b';
}

/* '' */
.icon-send:before {
	content: '\e91c';
}

/* '' */
.icon-settings:before {
	content: '\e91d';
}

/* '' */
.icon-stats:before {
	content: '\e91e';
}

/* '' */
.icon-tick-c:before {
	content: '\e91f';
}

/* '' */
.icon-tick-r:before {
	content: '\e920';
}

/* '' */
.icon-tick:before {
	content: '\e921';
}

/* '' */
.icon-upload:before {
	content: '\e922';
}

/* '' */
.icon-fb:before {
	content: '\e923';
}

/* '' */
.icon-mail:before {
	content: '\e924';
}

/* '' */
.icon-play:before {
	content: '\e925';
}

/* '' */
.icon-tel:before {
	content: '\e926';
}

/* '' */
.icon-cart:before {
	content: '\e927';
}

/* '' */
.icon-arrow3:before {
	content: '\e928';
}

/* '' */
.icon-full-screen:before {
	content: '\e929';
}

/* '' */
.icon-default-screen:before {
	content: '\e92a';
}

/* '' */
.icon-live:before {
	content: '\e92b';
}

/* '' */
.icon-refresh:before {
	content: '\e92c';
}

/* '' */
.icon-unpacked:before {
	content: '\e92d';
}

/* '' */
.icon-packed:before {
	content: '\e92e';
}

/* '' */
.icon-download:before {
	content: '\e92f';
}

/* '' */
.icon-date:before {
	content: '\e930';
}

/* '' */
.icon-print:before {
	content: '\e931';
}

/* '' */
.icon-summary:before {
	content: '\e932';
}

/* '' */
.icon-cart-fill:before {
	content: '\e933';
}

/* '' */
.icon-new:before {
	content: '\e934';
}

/* '' */
.icon-sort:before {
	content: "\e935";
}

.icon-sent-c:before {
	content: "\e936";
}

.icon-eye:before {
	content: "\e937";
}

.icon-generate:before {
	content: "\e939";
}

.icon-created-c:before {
	content: "\e93a";
}

.icon-info1:before {
	content: "\e938";
}

.icon-arrow-down:before {
	content: "\e93b";
}

.icon-arrow-up:before {
	content: "\e93c";
}

.icon-respond:before {
	content: "\e93d";
}
.icon-read:before {
	content: "\e93e";
}
.icon-unread:before {
	content: "\e93f";
}
.icon-delivery:before {
	content: "\e940";
}
.icon-info-thin:before {
	content: "\e941";
}
.icon-payment:before {
	content: "\e942";
}
.icon-time:before {
	content: "\e943";
}
.icon-meta-logo:before {
	content: "\e944";
}
.icon-drag:before {
	content: "\e945";
}
.icon-automate:before {
	content: "\e94f";
}
