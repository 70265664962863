@use "variables";

@font-face {
	font-family: 'Plus Jakarta Sans';
	src: url("../../public/assets/font/PlusJakartaSans-Bold.woff");
	font-weight: bold;
}
@font-face {
	font-family: 'Plus Jakarta Sans';
	src: url("../../public/assets/font/PlusJakartaSans-SemiBold.woff");
	font-weight: 600;
}

@font-face {
	font-family: 'Plus Jakarta Sans';
	src: url("../../public/assets/font/PlusJakartaSans-Medium.woff");
	font-weight: 500;
}

@font-face {
	font-family: 'Plus Jakarta Sans';
	src: url("../../public/assets/font/PlusJakartaSans-Regular.woff");
	font-weight: 400;
}

@font-face {
	font-family: 'Plus Jakarta Sans';
	src: url("../../public/assets/font/PlusJakartaSans-Light.woff");
	font-weight: 300;
}

@font-face {
	font-family: 'HateYourWriting';
	src: url("../../public/assets/font/HateYourWriting.woff");
	font-weight: 400;
}
@font-face {
	font-family: 'HateYourWriting';
	src: url("../../public/assets/font/HateYourWriting.woff2");
	font-weight: 400;
}


  h1 {
	color: variables.$darkColor;
	font-size: 28px;
	font-weight: 600;
	line-height: 28px;
	margin-top: 0;
	@media(max-width: 991px) {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 21px;
	}

	&.small-size {
		font-size: 1.125rem;
		font-weight: bold;
		line-height: 23px;
	}
}

.header-description {
	color: #6B7280;
	font-size: 15px;
	letter-spacing: 0;
	line-height: 15px;

	@media(max-width: 767px) {
		font-size: 13px;
		line-height: 13px;
	}
}

.sub-label {
	color: #101827;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: -0.1px;
	line-height: 22px;
	margin-bottom: 5px;
}

.section-label {
	color: #101827;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 21px;
	margin-bottom: 8px;
}

.info-text {
	display: flex;
	align-items: center;
	color: #3C61EA;
	font-size: 10px;
	font-weight: bold;
	letter-spacing: -0.1px;
	line-height: 12px;

	i {
		font-size: 14px;
		margin-right: 5px;
	}

	a {
		text-decoration: underline;
	}

	&.grey {
		color: #4B5563;
		font-size: 12px;
		line-height: 16px;

		a {
			color: inherit;
			margin-left: 3px;
		}

		i {
			color: #5fa5fa;
		}
	}
}

.default-size {
	font-size: 16px !important;
}

.small-text {
	font-size: 14px !important;
}

.extra-small-text {
	font-size: 12px !important;
}

.grey-txt {
	color: #6B7280;
}

.extra-small-font-size {
	font-size: 12px;
	line-height: 14px;
	font-weight: normal;
}

.grey-txt {
	color: #6B7280;
}

.-mt-1 {
	margin-top: -1px;
}
