@use '../variables';

.tutorial-page {
	background: #fff;

	.main-container {
		max-width: none;

		.wrapper-section {
			padding: 0;
			align-items: center;
			justify-content: center;
		}
	}
}

.selmo-input-wrapper {
	position: relative;

	.form-label {
		font-size: 12px;
		font-weight: normal;
		position: absolute;
		top: 0;
		width: 100%;
		margin-top: 6px;
		margin-left: 1px;
		padding: 0 0.9166666667em;
		text-align: left;
		z-index: 1;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-transform: translateY(3px);
		transform: translateY(3px);
		pointer-events: none;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		opacity: 0;
		color: #737373;
	}

	.checkbox-input {
		cursor: pointer;
	}

	.form-control {
		height: 48px;
		transition: all 0.2s ease-out;
		background-color: white;
		color: #333333;
		border: 1px transparent solid;
		border-color: #d9d9d9;
		background-clip: padding-box;
		border-radius: 5px;
		display: block;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		padding: 0.9285714286em 0.7857142857em;
		word-break: normal;
		line-height: inherit;
		font-size: 14px;
		@media(max-width: 767px) {
			padding-top: 0;
			padding-bottom: 0;
		}

		&:focus {
			border-color: variables.$blue;
			box-shadow: 0 0 0 1px variables.$blue;
		}

		&::-webkit-input-placeholder {
			color: #737373
		}

		&:-moz-placeholder {
			color: #737373;
			opacity: 1
		}

		&::-moz-placeholder {
			color: #737373;
			opacity: 1
		}

		&:-ms-input-placeholder {
			color: #737373
		}

		@media(max-width: 767px) {
			font-size: 16px;
		}
	}

	select.form-control {
		padding-left: 7px;
	}

	&.error-group {
		.error-text {
			font-size: 12px;
			color: #ef4444;
			margin-top: 2px;
		}

		.control-label {
			color: #ef4444;

			a {
				color: #ef4444 !important;
			}
		}

		.form-control {
			border-color: #ef4444;
			box-shadow: 0 0 0 1px #ef4444;
		}
	}

	&.show-floating-label {
		.form-control {
			&::-webkit-input-placeholder {
				color: transparent
			}

			&:-moz-placeholder {
				color: transparent;
				opacity: 1
			}

			&:-ms-input-placeholder {
				color: transparent
			}
		}

		.form-control {
			padding-top: 18px;
			padding-bottom: 0.3571428571em;
		}

		.form-label {
			-webkit-transform: none;
			transform: none;
			opacity: 1;
		}
	}
}

.tutorial-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	height: 100%;
	padding-top: 75px;
	min-height: 100vh;
	background: #fff;
	@media(max-width: 767px) {
		padding-top: 25px;
	}

	.btn-small {
		height: 40px;
		display: flex !important;
		align-items: center;
		border-radius: 5px;
		color: #FFFFFF;
		font-size: 13px !important;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 16px;
		background-color: #3C61EA;
	}

	.floating-button-form {
		position: relative;
		margin-top: 10px;

		.button-place {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 5px;
		}
	}

	&__toggle-box {
		padding: 15px 0;
		border-bottom: 1px solid #E5E7EB;

		.inpost-info {
			text-align: left;
			color: #101827;
			font-size: 14px;
			letter-spacing: 0.23px;
			line-height: 16px;
			margin-top: 5px;

			img {
				height: 22px;
				position: relative;
				top: -1px;
				margin-right: 8px;
			}
		}

		.flex-row {
			margin: 15px -5px 10px;

			& > div {
				width: 50%;
				padding: 0 5px;
			}
		}

		&:last-of-type {
			border: 0;
		}

		.switch-form {
			display: flex;
			align-items: center;
			width: 100%;

			.control-label {
				margin-bottom: 0;
				flex-grow: 1;
				color: #101827;
				font-size: 16px;
				//font-weight: bold;
				letter-spacing: 0;
				line-height: 21px;
			}
		}

		&__top {
			display: flex;
			align-items: center;
			text-align: left;
			color: #101827;
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 21px;


			&__name {
				flex-grow: 1;
			}

			.form-group {
				margin-bottom: 0;
			}
		}
	}

	form {
		width: 100%;
	}

	&__pros {
		padding: 0;
		margin: 0;
		text-align: left;
		list-style-type: none;

		li {
			color: #1F2937;
			font-size: 18px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 20px;
			padding: 0 15px;
			display: flex;
			align-items: center;


			& > div {
				border-bottom: 1px solid #E5E7EB;
				padding: 22px 0;
				width: 100%;
			}

			&:last-of-type {
				& > div {
					border: 0;
				}
			}

			i {
				color: #0EB981;
				font-size: 10px;
				margin-right: 15px;
			}
		}
	}

	&__top {
		list-style-type: none;
		display: flex;
		padding: 0;
		margin: 0 0 60px;

		@media(max-width: 767px) {
			margin-bottom: 30px;
		}

		li {
			//margin: 0 15px;

			a, button {
				background: transparent;
				border: 0;
				color: #9CA3AF;
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 15px;
				text-align: center;
				text-decoration: none !important;
				transition: all .2s ease-in-out;

				&.active {
					color: #101827;
					font-weight: bold;
				}
			}

			&:after {
				content: "\e903";
				font-family: selmo;
				display: inline-block;
				margin: 0 15px;
				transform: rotate(270deg);
				font-size: 7px;
				color: #D1D5DB;
				position: relative;
				top: -1px;
			}

			&:last-of-type {
				&:after {
					display: none;
				}
			}

			&:hover {
				a {
					color: #101827;
				}
			}
		}
	}

	&__info {
		color: #4B5563;
		font-size: 13px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 22px;
		text-align: left;

		i {
			color: #5FA5FA;
			margin-right: 5px;
			position: relative;
			top: 1px;
			font-size: 15px;
		}
	}

	&__skip {
		display: block;
		margin-top: 25px;
		color: #9CA3AF;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 21px;
		text-decoration: none !important;
		transition: all .2s ease-in-out;

		&:hover {
			color: #101214;
		}
	}

	&__box {
		border-radius: 5px;
		background-color: #FFFFFF;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 5px 20px 0 rgba(0, 0, 0, 0.1);
		padding: 35px;
		margin: 30px auto 60px;
		max-width: 545px;
		width: 100%;

		@media(max-width: 991px) {
			padding: 25px 15px;
			margin-bottom: 30px;
		}

		#custom-dropdown-default [class*='-control'] [class*='-singleValue'] {
			.name {
				color: #1F2937;
				font-size: 16px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 21px;
			}
		}

		#custom-dropdown-default.default-size [class*='-control'] {
			min-height: 56px;

			[class*='-placeholder'] {
				line-height: normal;
				font-size: 16px;
			}

			> div:first-of-type {
				padding: 0 15px;
			}
		}

		#custom-dropdown-default [class*='-menu'] > div {
			margin: 5px;

			> [class*='-option'] {
				border-radius: 3px;
				padding-top: 12px;
				padding-bottom: 12px;
				margin-bottom: 5px;

				.name {
					color: #1F2937;
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 21px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.form-group {
			margin-bottom: 5px;
		}

		&__step {
			display: flex;

			&:last-of-type {
				margin-bottom: 0;
			}

			&.bordered {
				.flex-grow-1 {
					padding-bottom: 25px;
					margin-bottom: 25px;
					border-bottom: 1px solid #F3F4F6;
				}
			}

			.step-number {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 30px;
				width: 30px;
				background-color: #F3F4F6;
				color: #4B5563;
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0;
				line-height: 18px;
				border-radius: 50%;
				margin-right: 15px;

				@media(max-width: 991px) {
					display: none;
				}
			}

			.label-wrapper {
				margin-bottom: 15px;

				&__top {
					color: #101827;
					font-size: 16px;
					font-weight: bold;
					letter-spacing: -0.1px;
					line-height: 24px;
				}

				&__bottom {
					color: #6B7280;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 18px;

					@media(max-width: 991px) {
						font-size: 13px;
					}
				}
			}
		}

		&.select-type {
			box-shadow: none;
			max-width: 470px;
			padding: 0 15px;

			.form-info-box {
				i {
					top: 0;
				}

				.title, .title strong {
					font-weight: 600;
				}
			}
		}

		&.first-step {
			box-shadow: none;
			padding: 0;
			max-width: 420px;
			margin-bottom: 25px;

			.label-wrapper {
				margin-bottom: 8px;

				&__top {
					font-size: 14px;
					margin-bottom: 0;
				}
			}

			> .tutorial-wrapper__box__step {
				margin-bottom: 25px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.form-group {
				.form-control {
					height: 56px;
				}
			}

			+ .tutorial-wrapper__proceed {
				width: 100%;
				max-width: 420px;
			}
		}
	}

	&__description {
		color: #9CA3AF;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 21px;
		margin-top: 5px;
	}

	&__proceed {
		border-radius: 5px;
		background-color: #3C61EA;
		height: 56px;
		padding: 15px 45px;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		width: 350px;
		line-height: 19px !important;
		margin: 0 auto;
		@media(max-width: 767px) {
			width: 100%;
		}

		&.disabled {
			background-color: #E5E7EB;
			box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);

			&:hover {
				background-color: #E5E7EB;
				box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
			}
		}
	}

	.logo {
		width: 100px;
		margin-bottom: 30px;
		margin-left: auto;
		margin-right: auto;

		img {
			width: 100%;
		}
	}

	h1 {
		margin-bottom: 5px;
		color: #101827;
		font-size: 24px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 30px;

		strong {
			font-weight: bold;
		}

		@media(max-width: 991px) {
			font-size: 20px;
			line-height: 24px;
		}
	}

	//.form-info-box {
	//	.desc {
	//		color: #9CA3AF;
	//		font-size: 18px;
	//		font-weight: 500;
	//		letter-spacing: 0;
	//		line-height: 23px;
	//		margin-top: 10px;
	//		margin-bottom: 45px;
	//
	//		@media(max-width: 991px) {
	//			font-size: 14px;
	//			line-height: 18px;
	//		}
	//	}
	//}

	.referral-code-wrapper {
		margin-top: 25px;
		color: #898E99;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: -0.1px;
		line-height: 18px;
		cursor: pointer;

		span {
			text-decoration: underline;
		}
	}

	.form-group {
		text-align: left;

		label {
			text-align: left;
			color: #101827;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 15px;
		}

		.form-control {
			width: 100%;
			border-radius: 5px;
			border: 0.9px solid #DFE1E4;
			background-color: #FFFFFF;
			box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
			height: 50px;
			font-size: 16px;

			&::placeholder {
				font-weight: 500;
				font-size: 16px;
				color: #9CA3AF;
			}
		}
	}

	.button {
		display: block;

		&.primary {
			font-size: 16px;
			padding: 18px;

			&.small-size {
				width: auto;
				padding-left: 40px;
				padding-right: 40px;
			}
		}

		&.text-only {
			color: #9CA3AF;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 21px;

			&:hover {
				color: variables.$darkColor;
			}
		}
	}

	.buttons-combo {
		margin-top: 15px;

		.primary {
			margin-bottom: 15px;
		}
	}

	.facebook-logo {
		width: 40px;
		margin-bottom: 30px;

		img {
			width: 100%;
		}
	}

	&.facebook-step {
		flex-direction: row;
		justify-content: normal;
		height: 100%;

		.left-col {
			text-align: left;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 30%;
			background-color: #F9FAFB;
			padding: 80px;
			min-width: 550px;

			.facebook-content {
				margin: auto 0;

				.title {
					color: #101827;
					font-size: 32px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 44px;
					margin-bottom: 40px;
				}
			}

			.selmo-logo {
				margin-top: auto;
				width: 100px;

				img {
					width: 100%;
				}
			}
		}

		.right-col {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 70%;
			height: 100%;
			padding: 80px;

			.content {
				margin: auto;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;

				.icon-box {
					height: 95px;
					width: 95px;
					border: 1.5px solid #E5E7EB;
					border-radius: 10px;
					background-color: #F9FAFB;
					margin-bottom: 32px;
				}

				&.fanpages-content {
					align-items: flex-start;

					.desc {
						margin-bottom: 5px;
					}

					> form {
						width: 100%;
					}

					.button-place {
						display: flex;
						justify-content: flex-end;
						margin-top: 40px;
					}
				}
			}
		}

		.form-label {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 18px;
			margin-bottom: 0;
		}

		.circle-steps {
			position: absolute;
			bottom: 80px;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			align-items: center;

			.circle {
				height: 8px;
				width: 8px;
				background-color: #E5E7EB;
				border-radius: 50%;
				margin-right: 14px;

				&.active {
					background: #9CA3AF;
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}
}

.data-boxes-wrapper.separated-boxes {
	border-radius: 5px;
	border: 0;

	.content {
		border: 1px solid variables.$greyBg;
		margin-bottom: 7px;
		position: relative;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 19px;
		border-radius: 5px;
		transition: all .2s ease-in-out;

		.btn-change {
			&:hover {
				background: #E5E7EB;
				color: #000;
			}
		}

		&__top-part {
			display: flex;
			align-items: center;
			position: relative;
			padding: 20px;

			&.parcel-content {
				@media(max-width: 480px) {
					flex-wrap: wrap;
				}
			}

			> input {
				cursor: pointer;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;

				&:checked {
					+ .checkmark {
						border-color: transparent;
						background-color: #3C61EA;

						&:after {
							width: 5px;
							height: 5px;
						}
					}
				}
			}

			figure {
				margin: 0;

				img {
					height: 28px;
				}
			}

			img {
				height: 26px;
			}

			.checkmark {
				position: relative;
				height: 20px;
				width: 20px;
				min-width: 20px;
				border-radius: 50%;
				margin-right: 10px;
				margin-top: 1px;
				border: 1px solid variables.$greyBg;
				transition: all .2s ease-in-out;
				pointer-events: none;

				&:after {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: variables.$white;
					width: 0;
					height: 0;
					border-radius: 50%;
					transition: all .2s ease-in-out .1s;
				}
			}

			> i {
				color: #9CA3AF;
				font-size: 10px;
			}

			.button-place {
				@media(max-width: 480px) {
					margin-top: 10px;
					button {
						width: 100%;
					}
				}
			}

			.icon-place {
				position: relative;
				top: -1px;
				font-size: 18px;
				margin-right: 10px;
				width: 16px;
				pointer-events: none;
			}

			.text-only {
				transition: all .2s ease;

				&:hover {
					color: variables.$black;
				}
			}
		}

		.label {
			font-weight: bold;
			color: #101827;
		}

		.data {
			color: variables.$greyColor;
		}

		> a {
			text-decoration: none;
			margin-left: auto;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 15px;
			text-align: center;
			color: variables.$greyColor;
			padding: 11px 20px;
			border: 1px solid variables.$greyBg;
			border-radius: 5px;
			box-shadow: 0 1px 1px 0 rgba(variables.$black, 0.07);
			transition: all .2s ease-in-out;

			&:hover {
				background: variables.$greyBg;
				color: variables.$black;
			}
		}

		> figure {
			margin: auto 0;
			padding: 0;
			margin-left: auto;
			width: 54px;
			height: 26px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&.radio-content {
			align-items: flex-start;
		}

		.bottom-part {
			display: none;
		}

		.inpost-results-wrapper {
			margin: 0 20px 20px;

			.inpost-row {
				align-items: flex-start;
				border-radius: 5px;
				background-color: #F9FAFB;
				padding: 13px 15px;
				border: 0;
				margin-bottom: 0;

				.button {
					border: 1px solid #DFE1E4;
					border-radius: 5px;
					background-color: #FFFFFF;
					box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
					margin-top: 8px;
					padding: 6px 10px;
				}

				.photo {
					margin-right: 0;
				}
			}
		}

		&.active {
			border: 1px solid #3C61EA;
			border-radius: 5px;
			background-color: rgba(60, 97, 234, 0.04);
			box-shadow: 0 0 0 1px variables.$blue;

			.bottom-part {
				display: block;
			}
		}

		&.error {
			border-color: #F87171;
			background-color: #FEF2F2;
			box-shadow: 0 0 0 1px #F87171;

			.checkmark {
				background: #F87171!important;
			}

			.button.border-button {
				border-color: #F87171;
				background: #fff;
				box-shadow: 0 0 0 1px #F87171;
			}
		}

		&.last-of-type {
			margin-bottom: 0;
		}

		&:hover:not(.no-hover) {
			//box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
		}

	}

	&.error-group {
		.content {
			border-color: #F87171;
			background-color: #FFFAFA;
		}
	}
}
