.live-list-wrapper {
	min-height: 40vh;

	.default-shadow-box {
		@media(max-width: 991px) {
			display: block;
		}

		.header {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			@media(max-width: 991px) {
				align-items: flex-start;
				margin: -15px -15px 0;
				padding: 15px;
			}
			@media(max-width: 767px) {
				flex-wrap: wrap;
			}

			> div:first-child {
				@media(max-width: 767px) {
					width: 100%;
					margin-bottom: 15px;
				}
			}

			.title {
				margin-bottom: 3px;
			}

			.search-form {
				margin-left: auto;
				margin-right: 15px;
				@media(max-width: 767px) {
					margin-left: 0;
				}

				.form-control {
					height: 37px;
				}
				> i {
					top: 11px;
				}
			}


			.desc {
				color: #6B7280;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: -0.1px;
				line-height: 22px;
				@media(max-width: 991px) {
					font-size: 12px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 17px;
				}
			}

			.button {
				padding-top: 10px;
				padding-bottom: 10px;
				white-space: nowrap;
				@media(max-width: 991px) {
					padding: 10px;
				}
			}
		}
	}
}
