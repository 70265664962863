.brand-name.bm-preview {
	display: block;
	max-width: 113px;
	margin: 65px auto 30px;
}

.bm-preview-wrapper {
	margin-bottom: 100px;
	background: #fff;
	padding: 60px;
	border-radius: 5px;
	box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);

	&__top {
		display: flex;
		align-items: center;
		margin-bottom: 25px;

		.logo {
			margin-right: 15px;

			img {
				height: 46px;
				width: 46px;
				border-radius: 5px;
			}
		}

		h1 {
			color: #101827;
			font-size: 20px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 25px;
			margin: 0;
		}
	}

	&__products {
		padding-top: 45px;
		margin-top: 25px;
		border-top: 1px solid #E5E7EB;

		h2 {
			color: #101827;
			font-size: 20px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 25px;
			margin-bottom: 25px;
		}

		.preview-products-wrapper {
			&__box {
				display: flex;
				margin-bottom: 25px;

				&:last-of-type {
					margin-bottom: 0;
				}

				&__photo {
					img {
						border-radius: 5px;
					}
				}

				&__text {
					margin-left: 25px;

					&__name {
						font-size: 18px;
						font-weight: 600;
					}

					&__price {

					}

					&__description {
						margin-top: 15px;
					}
				}
			}
		}
	}

	&__info {
		& > div {
			color: #6B7280;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 28px;

			strong {
				color: #101827;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0;
				line-height: 28px;
			}

			&.extra-margin {
				margin-top: 25px;
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				color: #101827;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0;
				line-height: 28px;
				margin-left: 3px;

				&:before {
					content: "•";
					color: #D1D5DB;
					display: inline-block;
					height: 4px;
					width: 4px;
					margin-right: 10px;
				}
			}
		}
	}

	&__terms {
		padding-top: 45px;
		margin-top: 45px;
		border-top: 1px solid #E5E7EB;

		h2 {
			color: #101827;
			font-size: 20px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 25px;
			margin-bottom: 25px;
		}
	}
}


