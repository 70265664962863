.live-transmissions-wrapper {

	.loader-parent {
		.loader-container:not(.static-loader) {
			.circle-loader {
				margin: 0;
			}
		}
	}
}

.live-container {
	height: auto;

	.main-container {
		main {
			overflow: visible;
		}

		.header-bar {
			@media(max-width: 991px) {
				margin: 0;
			}
		}
	}
}

.live-details-wrapper {
	.inputs-row.small-inputs > div .form-group.with-client-number .form-control {
		font-weight: 800;
	}
}
