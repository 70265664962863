@use 'mixins';

.main-aside {
    @media(max-width: 991px) {
        margin-left: -20px;
        margin-right: -20px;
    }

    & > div {
        height: calc(100vh - 30px);
        display: flex;
        flex-direction: column;
        width: 210px;
        @media(min-width: 991px) {
            overflow-y: auto;
            position: sticky;
            top: 15px;
        }
        @media(max-width: 991px) {
            height: auto;
            width: 100%;
            flex-direction: row;
            border-bottom: 1px solid #E5E7EB;
            background: #F9FAFB;
            padding: 14px 0;
        }
    }

    .hamburger-btn {
        position: absolute;
        left: -10px;
        top: 9px;
        z-index: 3;
        padding: 10px;


        .bar1, .bar2, .bar3 {
            width: 18px;
            height: 2px;
            background-color: #6B7280;
            border-radius: 5px;
            margin: 5px 0;
            transition: 0.4s;
        }

        @media (min-width: 991px) {
            display: none;
        }
    }

    .brand-name {
        width: 100px;
        display: block;
        margin-bottom: 20px;
        @media(max-width: 991px) {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    .shop-info-box {
        display: flex;
        align-items: center;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);
        padding: 5px;
        color: #6B7280;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 12px;
        text-decoration: none;

        @media(max-width: 991px) {
            z-index: 2;
            box-shadow: none;
            background: transparent;
            margin: 0 auto;
            padding: 0 45px;
        }
        @media(max-width: 480px) {
            padding: 0 70px 0 50px;
            margin-left: 0;
            line-height: 16px;
        }

        figure {
            margin: 0;
            padding: 0;
            width: 28px;
            height: 28px;
            margin-right: 8px;

            img {
                border-radius: 3px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .main-aside-wrapper {
        @media(min-width: 991px) {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            @include mixins.custom-scrollbar;
            &::-webkit-scrollbar-thumb {
                background: #E5E7EB;
            }
        }
    }

    > div, > div .bottom-part, .main-aside-wrapper {

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        > ul {
            padding: 0;
            margin: 0;
            list-style: none;
            @media(max-width: 991px) {
                position: absolute;
                top: 56px;
                left: -20px;
                right: -20px;
                bottom: 0;
                background: #fff;
                z-index: 999;
                padding: 14px 20px 0 20px;
                opacity: 0;
                visibility: hidden;
                overflow: auto;
                //height: calc(100vh - 57px);
            }

            li {
                a, .menu-name {
                    display: flex;
                    align-items: center;
                    color: #697281;
                    text-decoration: none;
                    font-size: 0.75rem;
                    padding: 5px 7px;
                    font-weight: 600;
                    border-radius: 5px;
                    transition: all .2s ease-in-out;
                    margin-bottom: 0;
                    @media(min-width: 1550px) {
                        font-size: 0.875rem;
                    }
                    @media(max-width: 991px) {
                        font-size: 0.875rem;
                    }
                    @media(max-width: 991px) {
                        padding: 7px;
                    }

                    img {
                        display: none;
                    }

                    i {
                        width: 13px;
                        font-size: 0.875rem;
                        color: #6b7280;
                        margin-right: 8px;
                        position: relative;
                        top: -0.5px;

                        @media(min-width: 1550px) {
                            width: 17px;
                            font-size: 1rem;
                            top: -1.5px;
                        }

                        &.icon-automate {
                            font-size: 14px;
                            text-align: center;
                        }
                    }

                    &.active {
                        background: #ffff;
                        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07), 0 1px 3px 0 rgba(0, 0, 0, 0.04);
                        @media(max-width: 991px) {
                            background: #F9FAFB;
                            box-shadow: none;
                        }
                    }

                    &:hover {
                        color: #000;
                    }

                    &.choose-plan {
                        background: #000;
                        color: #fff;
                        justify-content: center;

                        &:hover {
                            background: #1c1c1c;
                        }
                    }

                    &.selmo-pro-box {
                        &:hover {
                            background: #1c1c1c;
                        }

                        &.light-version {
                            &:hover {
                                background: #f0faff;
                            }
                        }
                    }

                    &.active {
                        color: #000;

                        i {
                            color: #000;
                        }
                    }

                    &.help-link {
                        align-items: flex-start;

                        i {
                            position: relative;
                            top: 2px;
                        }


                        .bottom-text {
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }

                    &.go-back {
                        color: #6B7280;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;
                        font-size: 12px;

                        @media(min-width: 1550px) {
                            line-height: 18px;
                            font-size: 14px;
                        }

                        i {
                            font-size: 10px;
                            color: #9CA3AF;
                            display: inline-block;
                            transform: rotate(90deg);
                            width: auto;
                            height: 17px;
                            position: relative;
                            left: -4px;
                            margin-right: 10px;

                            @media(min-width: 1550px) {
                                margin-right: 14px;

                            }

                        }
                    }
                }

                .menu-name {
                    display: flex;
                    background: transparent;
                    width: 100%;
                    border: 0;

                    > i.icon-arrows {
                        font-size: 10px;
                        color: #9CA3AF;
                        margin-left: auto;
                    }
                }

                &.menu-label {
                    color: #697281;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 14px;
                    padding: 12px 8px 4px;
                    margin-bottom: 5px;

                    i {
                        display: inline-block;
                        font-size: 1rem;
                        color: #9CA3AF;
                        margin-right: 6px;
                        position: relative;
                        top: 1px;
                        width: 16px;
                        text-align: center;
                    }
                }

                &.menu-children {
                    > a {
                        padding-left: 34px;
                        color: #9CA3AF;
                    }
                }

                &.hidden-desktop {
                    @media(min-width: 991px) {
                        display: none;
                    }
                }
            }

            .with-sub-menu {
                overflow: hidden;
                margin-bottom: 0px;

                .menu-name {
                    margin-bottom: 0;

                    &.active {
                        background: transparent;
                        color: #000;
                        box-shadow: none;

                        i {
                            color: #697281;
                        }
                    }
                }

                ul {
                    position: relative;
                    max-height: 0;

                    li {
                        a {
                            position: relative;
                            padding: 4px 12px 4px 34px;
                            //margin-left: 30px;
                            background: transparent;
                            margin-bottom: 0px;

                            &.active {
                                background: #fff;
                                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07), 0 1px 3px 0 rgba(0, 0, 0, 0.04);
                                @media(max-width: 991px) {
                                    background: #F9FAFB;
                                    box-shadow: none;
                                }
                                @keyframes fadein {
                                    from {
                                        opacity: 0;
                                    }
                                    to {
                                        opacity: 1;
                                    }
                                }
                                &:before {
                                    content: "";
                                    position: absolute;
                                    left: 10px;
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 50%;
                                    background-color: #dadada;
                                    border: 3px solid #fff;
                                    z-index: 1;
                                    opacity: 0;
                                    animation: fadein .3s ease-in-out forwards;
                                    @media(max-width: 991px) {
                                        border-color: #F9FAFB;
                                    }
                                }
                            }
                            &:hover {
                                color: #000;
                            }
                        }
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        left: 15px;
                        width: 1px;
                        background-color: #dadada;
                        z-index: 1;
                        top: 10px;
                        bottom: 10px;
                    }
                }

                &.active {
                    ul {
                        max-height: 10000px;
                        margin-top: 2px;
                    }
                }
            }
        }

        &.open ul {
            li {
                a, .menu-name {
                    &.active {
                        background: transparent;
                        color: #697281;
                        box-shadow: none!important;

                        i {
                            color: #9CA3AF;
                        }
                    }

                    &:hover {
                        color: #000;
                        background-color: transparent!important;
                    }
                }
            }

            .with-sub-menu {
                overflow: hidden;
                //margin-bottom: 4px;

                .menu-name {
                    margin-bottom: 0;

                    &.active {
                        background: transparent;
                        color: #697281;

                        i {
                            color: #9CA3AF;
                        }
                    }
                }

                ul {
                    li {
                        a {
                            &.active {
                                background: transparent;
                            }

                            &:hover {
                                background: #F9FAFB;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom-part {
        margin-top: auto;
        @media(max-width: 991px) {
            display: none;
        }
    }
}

.mobile-navbar {
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    padding: 8px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            text-align: center;
            font-size: 20px;

            a {
                &.active {
                    i {
                        &:before {
                            color: #3C61EA;
                        }
                    }

                    .icon-messenger {
                        &:before {
                            content: '\e82b';
                        }
                    }

                    .icon-settings {
                        &:before {
                            content: '\e830';
                        }
                    }

                    .icon-stats {
                        &:before {
                            content: '\e832';
                        }
                    }

                    .icon-products {
                        &:before {
                            content: '\e82f';
                        }
                    }

                    .icon-orders {
                        &:before {
                            content: '\e82e';
                        }
                    }

                    span {
                        color: #3C61EA;
                        font-weight: 600;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }

            i {
                color: #9CA3AF;
            }

            span {
                color: #1F2937;
                font-size: 9px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 11px;
                display: block;
            }


            &.hidden-mobile-navbar {
                display: none;
            }
        }
    }

    @media(min-width: 991px) {
        display: none;
    }

    + main {
        @media(max-width: 991px) {
            padding-bottom: 55px !important;
        }
    }
}

body.open-menu {
    overflow: hidden;

    .hamburger-btn {
        .bar1 {
            transform: rotate(-45deg) translate(-3px, 3px);
        }

        .bar2 {
            opacity: 0;
        }

        .bar3 {
            transform: rotate(45deg) translate(-2px, -2px);
        }
    }

    @media(max-width: 991px) {
        .main-aside > div {
            background: #fff;
        }


        .main-aside-wrapper ul, ul {
            opacity: 1;
            visibility: visible;
        }
    }
}
