@use 'variables';

.button {
    display: inline-block;
    background: transparent;
    padding: 0;
    border: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.primary {
        position: relative;
        color: variables.$white;
        background: variables.$blue;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        padding: 12px 46px;
        border-radius: 5px;

        &.big-size {
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 16px;
        }

        &.medium-size {
            padding: 18px 28px;
            font-size: 16px;
        }

        &.small-size {
            padding: 12px 22px;
        }

        &.the-smallest-size {
            padding: 10px 18px;
        }

        .loader-container {
            background: variables.$blue;
            min-height: auto;
            border-radius: inherit;

            .circle-loader {
                top: 50%;
                transform: translate(-50%, -50%);
                width: 16px;
                height: 16px;
                margin: 0;

                .circle {
                    width: 16px;
                    height: 16px;
                    border-width: 2px;
                }
            }
        }

        i.icon-arrows {
            color: #fff;
        }

        &.selmo-bg {
            background: #5fa5fa;

            .loader-container {
                background: #5fa5fa;
                border-radius: inherit;

                .circle-loader {
                    .circle {
                        border: 2px solid #fff;
                        border-top-color: #5fa5fa;
                    }
                }
            }

            &:hover {
                background: #3e93fc;
            }
        }

        &:hover {
            background-color: #1c4ed8;
        }

        &.bigger-radius .loader-container {
            border-radius: 8px;
        }
    }

    &.blue-button,
    &.green-button {
        background-color: #e7f3ff;
        color: #3c61ea;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
        padding: 12px 46px;
        border-radius: 5px;

        &.big-size {
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 16px;
        }

        &.small-size {
            padding: 12px 22px;
        }

        &.the-smallest-size {
            padding: 10px 18px;
        }
    }

    &.green-button {
        color: #0eb981;
        background-color: #e6f8f2;
    }

    &.danger {
        position: relative;
        color: variables.$white;
        background-color: #da504c;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        padding: 12px 46px;
        border-radius: 5px;

        .loader-container {
            background-color: #da504c;
            border-radius: inherit;

            .circle {
                border-top-color: #da504c;
            }
        }

        &.small-size {
            padding: 12px 22px;
        }
    }

    &.not-active,
    &.not-active:hover {
        background: #d1d5db;
        cursor: not-allowed;
    }

    &.black {
        position: relative;
        color: variables.$white;
        background: #212529;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        padding: 14px 46px;
        border-radius: 5px;

        .loader-container {
            background: #212529;
            border-radius: inherit;

            .circle-loader {
                .circle {
                    border-top-color: #212529;
                }
            }
        }

        &.small-size {
            font-size: 12px;
            padding: 7px 10px;
        }

        &:hover {
            background-color: #35383b;
        }
    }

    &.white {
        position: relative;

        .loader-container {
            background: #fff;
            min-height: auto;
            border-radius: inherit;

            .circle-loader {
                top: 50%;
                transform: translate(-50%, -50%);
                width: 16px;
                height: 16px;
                margin: 0;

                .circle {
                    width: 16px;
                    height: 16px;
                    border-width: 2px;
                }
            }
        }
    }

    &.text-blue-button {
        display: inline-flex;
        align-items: center;
        color: #3c61ea;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;

        i {
            position: relative;
            top: 1px;
            margin-right: 5px;
            font-size: 8px;
        }

        &.add-new-row {
            &:focus,
            &:focus-visible {
                box-shadow: 0 0 0 3px rgba(0, 97, 254, 0.5) !important;
                outline: none !important;
            }
        }

        &.no-focus {
            &:focus {
                box-shadow: none !important;
            }
        }
    }

    &.with-icon {
        i {
            font-size: 10px;
            color: #d1d5db;
            margin-right: 5px;
        }
    }

    &.text-only {
        font-size: 0.75rem;
        line-height: 1rem;
        color: #6b7280;

        &.bold {
            font-weight: bold;
        }

        &.lgrey {
            color: variables.$lgreyColor;
        }

        i {
            transition-delay: 0;
            transition: inherit;
        }

        span {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: calc(100% + 3px);
                left: 0;
                right: 0;
                background: variables.$greyBg;
                height: 1px;
                transition: all 0.2s ease-in-out;
            }
        }

        &.small-icon {
            i {
                font-size: 8px;
                position: relative;
                top: -1px;
            }
        }

        &:hover {
            color: variables.$darkColor;

            span:before {
                background: variables.$darkColor;
            }

            i {
                color: variables.$darkColor;
            }
        }
    }

    &.copy-btn.only-icon {
        width: 31px;
        min-height: 31px;

        i {
            position: relative;
            top: 1px;
            margin-left: 0;
        }
    }

    i.icon-arrows {
        margin-left: 5px;
        font-size: 7px;
        color: #9ca3af;
        margin-right: 0;
        transform: rotate(-90deg);
        display: inline-block;
    }

    &.border-button {
        text-decoration: none;
        border: 1px solid #dfe1e4;
        border-radius: 5px;
        //box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
        //box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
        background: #fff;
        color: #6b7280;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
        padding: 7px 11px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        &:hover:not(:disabled):not(.no-hover):not(.disabled) {
            background: #f9fafb;
            color: #4b5563;
        }

        i {
            color: #9ca3af;
            margin-left: 3px;
        }

        &.small-size {
            padding: 7px 10px;

            i.icon-plus {
                position: relative;
                top: -1px;
                font-size: 8px;
                color: #9ca3af;
            }
        }

        &.extra-small-size {
            min-height: 22px;
            width: 22px;
            padding: 0;
        }

        &.big-size {
            padding: 13px 10px;
        }

        &.big-width {
            padding: 9px 16px;
        }

        &.left-icon {
            i {
                margin-left: 0;
                margin-right: 3px;
            }
        }

        .loader-container {
            background-color: #fff;
            border-radius: inherit;
            min-height: 31px;

            .circle {
                border-top-color: #6b7280;
            }
        }

        &:disabled,
        &.disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }

    &.dark-grey {
        background-color: #b9b9b9;
    }

    &.light-grey {
        background-color: #f9fafb;
        color: #4b5563;

        &:hover {
            background-color: #e5e7eb;
        }
    }

    &.grey-button,
    &.primary-login {
        border-radius: 8px;
        background-color: #f3f4f6;
        color: #6b7280;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        padding: 17px 24px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        .loader-container {
            background-color: #f3f4f6;
            border-radius: inherit;

            .circle {
                border-top-color: #6b7280;
            }
        }
    }

    &.grey-button {
        &:hover {
            background-color: #e9e9ea;
            color: #5e6470;
        }
    }

    &.primary-login {
        color: variables.$white;
        background: variables.$blue;

        .loader-container {
            background: variables.$blue;
            border-radius: inherit;

            .circle-loader {
                .circle {
                    border: 2px solid #3c61ea;
                    border-top-color: #f6f7f8;
                }
            }
        }

        &:hover {
            background-color: #1c4ed8;
        }
    }

    &.loading-button {
        position: relative;
        pointer-events: none;
    }

    &.back-to-panels {
        display: inline-flex;
        align-items: center;
        color: #101827;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 21px;

        i {
            height: 24px;
            width: 24px;
            border-radius: 2.57px;
            background-color: #ffffff;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
            font-size: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #9ca3af;
            margin-right: 8px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &:focus {
        outline: none;
    }

    &:hover:not(:disabled) {
        text-decoration: none;
    }

    &.go-to-conversation {
        border: 1px solid #dfe1e4;
        border-radius: 5px;
        color: #3c61ea;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0;

        i {
            margin-right: 5px;
        }

        &:hover {
            background: #f7f9fe;
        }

        &.important {
            background: #3c61ea;
            border-color: #3c61ea;
            color: #fff;

            &:hover {
                background-color: #1c4ed8;
            }
        }
    }

    .loader-container {
        background: variables.$blue;
        min-height: auto;
        border-radius: inherit;

        .circle-loader {
            top: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 16px;
            margin: 0;

            .circle {
                width: 16px;
                height: 16px;
                border-width: 2px;
            }
        }
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.upload-button-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 1;
        cursor: pointer !important;
        font-size: 0;

        &:hover {
            cursor: pointer;

            + button {
                cursor: pointer;
                background: #e5e7eb;
                color: #000;
            }
        }
    }
}

.add-orders-messages-modal {
    .button.text-blue-button:focus {
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
    }
}

button:focus {
    outline: none;
    box-shadow: none;
}

.icon-button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    transition: all 0.2s ease-in-out;

    &.lgrey {
        color: variables.$lgreyColor;

        &:hover {
            color: #000;
        }
    }

    &.small-font-size {
        font-size: 12px;
    }

    &:hover {
        text-decoration: none;
    }

    &.grey {
        color: #4b5563;

        &:hover {
            color: #000;
        }
    }

    &.circle-icon {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: #f9fafb;
        font-size: 12px;

        &:hover {
            background: #eceff1;
        }
    }

    &.circle-on-hover {
        position: relative;
        border: 0;
        padding: 0;
        font-size: 14px;
        color: #9ca3af;

        i {
            position: relative;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            background: #f3f4f6;
            opacity: 0;
            border-radius: 50%;
            transition: all 0.1s ease-in-out;
        }

        &:hover:before {
            opacity: 1;
        }
    }

    .circle-count {
        position: absolute;
        top: -2px;
        right: -5px;
        color: #fff;
        border-radius: 3px;
        height: 12px;
        min-width: 12px;
        padding: 0 2px;
        background-color: #7d838b;
        font-size: 8px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 12px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Plus Jakarta Sans';
    }

    > span {
        @media (max-width: 991px) {
            font-weight: bold;
            font-size: 12px;
            font-family: 'Plus Jakarta Sans';
            color: #000;
            margin-right: 5px;
        }
    }
}

.packed-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    transition: all 0.1s ease-in-out;
    color: #b0b5bf;
    font-size: 17px;
    z-index: 0;
    width: 26px;

    &.packed {
        color: #101827;
        font-size: 15px;
    }

    &:hover {
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }

    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 30px;
        width: 30px;
        border-radius: 50%;
        z-index: -1;
        opacity: 0;
        background-color: #eceff1;
        visibility: hidden;
        transition: all 0.1s ease-in-out;
    }
}

.toggle-filters {
    background: transparent;
    margin-left: auto;
    padding: 0;
    height: 31px;
    min-width: 31px;
    width: 31px;
    border: 1px solid #dfe1e4;
    border-radius: 4.5px;
    box-shadow: 0 1px 1px 0 rgba(black, 0.07);
    margin-right: 10px;
    color: #6b7280;
    font-size: 12px;
    display: none;
    @media (max-width: 767px) {
        display: block;
    }

    &.with-text {
        @media (max-width: 767px) {
            margin: 0;
            width: auto;
            color: #6b7280;
            text-align: left;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: -0.12px;
            line-height: 15px;
            display: inline-flex;
            align-items: center;
            padding: 0 10px;
            height: 34px;
            i {
                margin-right: 6px;
            }
        }
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.button.primary.facebook-button {
    padding: 16px 24px;
    border-radius: 8px;
    display: inline-flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    min-height: 50px;
}

.button.border-button.google-button {
    font-size: 14px;
    padding: 15px 24px;
    border-radius: 8px;
    min-height: 50px;
}

.button.assign-in-product {
    @media (max-width: 480px) {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.tracking-search {
    @media (max-width: 480px) {
        padding: 0 20px !important;
    }
}
