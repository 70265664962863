.payments-page {
	//&:after {
	//	content: "";
	//	position: fixed;
	//	left: 50%;
	//	right: 0;
	//	background: #F9FAFB;
	//	top: 0;
	//	bottom: 0;
	//	z-index: -1;
	//	@media(max-width: 767px) {
	//		display: none;
	//	}
	//}

	&:before {
		content: "";
		position: fixed;
		right: 0;
		left: 0;
		background: #F9FAFB;
		top: 0;
		bottom: 0;
		z-index: 0;
		pointer-events: none;
		@media(max-width: 767px) {
			border-right: 0;
			right: 0;
			background: #fff;
		}
	}

	.go-back {
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		color: #999EA8;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: -0.2px;
		line-height: 18px;
		text-decoration: none;
		padding: 16px 0 40px 0;
		background: transparent;
		border: 0;
		transition: all .2s ease;
		@media(max-width: 767px) {
			padding-bottom: 20px;
		}

		i {
			margin-right: 5px;
			font-size: 10px;
		}

		&.logout {
			margin: 30px;
			position: fixed;
			right: 0;
			bottom: 45px;
			font-weight: normal;
			@media(max-width: 991px) {
				display: none !important;
			}

			div:last-child {
				text-decoration: underline;
			}
		}

		&:hover {
			color: #1F2937;
			transform: translateX(-3px);
		}
	}

	&.transfer-payment-page {
		&:after {
			display: none;
		}

		&:before {
			right: 0;
			border: 0;
			background: #fff;
			z-index: -1;
		}

		.payments-container {
			max-width: 460px;

			.transfer-page-header {
				display: flex;
				padding-bottom: 20px;
				border-bottom: 1px solid #F3F4F6;

				.label {
					color: #6B7280;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: -0.1px;
					line-height: 18px;
				}

				h1 {
					color: #101827;
					font-size: 20px;
					font-weight: bold;
					letter-spacing: -0.2px;
					line-height: 25px;
				}
			}

			.standard-transfer {
				margin: 0;
				margin-top: 35px;
				@media(max-width: 767px) {
					margin-top: 20px;
				}
			}
		}

		&.in-modal {
			&:before {
				display: none;
			}

			.payments-container {
				max-width: none;

				.standard-transfer {
					max-width: none;
				}
			}
		}
	}
}

.payments-container {
	max-width: 1010px;
	margin-left: auto;
	margin-right: auto;

	.custom-row {
		display: flex;
		margin-left: -50px;
		margin-right: -50px;
		@media(max-width: 767px) {
			flex-wrap: wrap;
		}
	}

	.right-column {
		width: 42%;
		padding: 0 50px;
		@media(max-width: 767px) {
			width: 100%;
			position: sticky;
			bottom: 0;
			background: #fff;
			padding-top: 1rem;
			z-index: 9;
			box-shadow: 0 2px 18px 0 rgba(0,0,0,.12);

		}

		.submit-place {
			.button.primary {
				display: flex;
				align-items: center;
				padding: 9px 9px 9px 15px;
				border-radius: 8px;
				.price {
					margin-left: auto;
					border-radius: 5px;
					background-color: #1C4ED8;
					color: #FFFFFF;
					font-size: 12px;
					font-weight: bold;
					padding: 8px 10px;
				}
			}
		}

		.toggle-content {
			@media(max-width: 767px) {
				.toggle-header {
					color: #101827;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					display: flex;
					align-items: center;
					background: transparent;
					border: 0;
					width: 100%;
					margin-bottom: 12px;
					padding: 0;

					i {
						margin-left: auto;
						color: #9CA3AF;
						font-size: 10px;
						transform: rotate(180deg);
						transition: all .3s linear;
					}
				}
				.toggle-body {
					max-height: 0;
					transition: max-height .3s ease-out;
					overflow: hidden;
				}
			}
			&.open {
				.toggle-header {
					i {
						transform: rotate(0);
					}
				}
				.toggle-body {
					max-height: 1000px;
					transition: max-height .3s ease-in;
				}
			}
		}
	}

	.left-column {
		position: relative;
		width: 58%;
		padding: 0 50px;
		z-index: 1;
		@media(max-width: 767px) {
			width: 100%;
		}

		&:after {
			content: "";
			position: absolute;
			right: 0;
			left: -20000px;
			background: #ffff;
			top: -74px;
			bottom: -48px;
			z-index: -1;
			border-right: 1px solid #E5E7EB;
			min-height: 100vh;
			@media(max-width: 767px) {
				display: none;
			}
		}

		.boxes-wrapper {
			.select-boxes-wrapper {
				margin-top: 40px;
				&:first-child {
					@media(max-width: 480px) {
						margin-top: 15px;
					}
				}
			}
		}
	}

	.invoice-wrapper {
		.form-place {
			margin-top: 6px;

			.loader-container {
				background: rgba(#fff, 0.8);

				.circle-loader .circle {
					border-color: transparent;
					border-top-color: #3C61EA;
				}
			}

			.form-group {
				margin-bottom: 12px;

				.floated-label {
					top: 14px;
					pointer-events: none;

					&.selected {
						top: 7px;

						+ .form-control {
							padding-top: 20px;
						}
					}
				}

				.form-control {
					height: 48px;
				}
			}
		}
	}

	.brand-name {
		width: 85px;

		img {
			width: 100%;
		}
	}

	.title {
		h1 {
			color: #101827;
			font-size: 32px;
			font-weight: bold;
			letter-spacing: -0.46px;
			line-height: 40px;
			margin-bottom: 6px;
			@media(max-width: 767px) {
				font-size: 24px;
				line-height: 30px;
			}
		}

		p {
			color: #6B7280;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: -0.2px;
			line-height: 21px;
			@media(max-width: 767px) {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	.select-boxes-wrapper {
		.current-plan-box {
			padding: 20px;
			border: 1px solid #E5E7EB;
			border-radius: 5px;

			.top-part {
				color: #101827;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0;
				line-height: 18px;

				.small-label {
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #6B7280;
					font-size: 12px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 15px;

					.price {
						color: #101827;
						font-size: 14px;
						letter-spacing: 0;
						line-height: 18px;
					}
				}
			}

			.active-date {
				border-radius: 3px;
				background-color: #E3FDF4;
				color: #067857;
				font-size: 12px;
				letter-spacing: 0;
				line-height: 15px;
				padding: 7px;
				margin-top: 10px;
				margin-bottom: 3px;
			}

			.switch-form {
				.control-label {
					color: #101827;
					font-size: 14px;
					font-weight: bold;
					letter-spacing: -0.09px;
					line-height: 18px;
					margin-left: 7px;
				}
			}

			.button.text-only {
				text-decoration: underline;
			}

			.data-boxes-wrapper {
				display: flex;
				flex-wrap: wrap;
				border: 0;
				margin-left: -3px;
				margin-right: -3px;
				@media(max-width: 370px) {
					flex-wrap: wrap;
				}

				> div {
					width: 100%;
					padding: 3px;
					@media(max-width: 370px) {
						width: 100%;
					}

					.payment-content {
						border: 1px solid #E5E7EB;
						border-radius: 5px;

						.content__top-part {
							> div {
								@media(max-width: 480px) {
									margin-top: 1px;
								}
							}
						}
					}
				}
			}
		}
	}

	.submit-place {
		.button {
			@media(max-width: 767px) {
				padding-top: 16px;
				padding-bottom: 16px;
				font-size: 14px;
			}
		}

		.small-text {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #6B7280;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 18px;
			text-align: center;
			margin-top: 10px;
			@media(max-width: 767px) {
				font-size: 10px !important;
			}

			i {
				font-size: 8px;
				margin-right: 5px;
				@media(max-width: 767px) {
					font-size: 10px;
				}
			}
		}
	}

	.summary-info-wrapper {
		position: sticky;
		top: 3rem;
		@media(max-width: 767px) {
			position: static;
		}

		.sub-section-label {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 18px;
			margin-top: 4px;
			color: #4B5563;

			strong {
				color: #101827;
			}
		}
	}

	.prices-wrapper {
		border-top: 1px solid #E5E7EB;

		.partials-prices {

			> div:first-of-type {
				padding-top: 15px;
			}

			> div:last-of-type {
				margin-bottom: 0;
				border-bottom: 1px solid #E5E7EB;
				padding-bottom: 15px;
			}
		}


		.partials-prices, .total-price {
			> div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 10px;

				.label {
					color: #4B5563;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 18px;

					.sub-label {
						color: #6B7280;
						font-size: 12px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 15px;
						margin-top: 2px;
					}
				}

				.value {
					color: #101827;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 18px;
					text-align: right;
					white-space: nowrap;

					.sub-value {
						color: #6B7280;
						font-size: 12px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 15px;
						margin-top: 2px;
					}

					&.green {
						color: #009866;
					}
				}
			}
		}

		.total-price {
			padding-top: 10px;

			> div {
				.label {
					font-size: 16px;
					letter-spacing: -0.2px;
					line-height: 21px;
					@media(max-width: 767px) {
						font-size: 14px;
						line-height: 20px;
					}
				}

				.value {
					font-size: 20px;
					font-weight: bold;
					letter-spacing: -0.29px;
					line-height: 25px;
					@media(max-width: 767px) {
						font-size: 16px;
						line-height: 20px;
						letter-spacing: 0;
						white-space: nowrap;
					}
				}

				&.price-for-month {
					.label {
						font-size: 14px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 18px;
					}

					.value {
						color: #101827;
						font-size: 14px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 18px;

						.old-price {
							text-decoration: line-through;
							margin-right: 5px;
							opacity: 0.6;
						}
					}
				}
			}
		}
	}
}
