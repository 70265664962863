@use 'variables';

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #dce2e5;
  }
}

.horizontal-custom-scroll {
  @include custom-scrollbar;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f3f3f3;
    border-radius: 5px;
  }
}

.vertical-custom-scroll {
  @include custom-scrollbar;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f3f3f3;
    border-radius: 5px;
  }
}

.table-responsive {
  @include custom-scrollbar;

  .table {
    width: 100%;
    margin-bottom: 0;

    .checkbox-col:not(.selected) {
      padding-left: 8px !important;
    }

    .checkbox-col {
      transition: none;
      max-width: 43px;
      width: 43px;
      padding: 0;
      @media (max-width: 991px) {
        &:not(.mobile-visible) {
          display: none;
        }
      }

      &.mobile-visible {
        @media (max-width: 991px) {
          position: absolute;
          top: 22px;
          right: 10px;
        }
      }

      .form-group.checkbox-form {
        padding: 12px;

        .checkbox-input {
          width: calc(100% + 12px);
        }
      }

      .checkbox-form {
        .checkbox-checkmark {
          margin-right: 0;
        }
      }

      &.selected {
        padding-bottom: 8px;
        padding-top: 0;
        padding-left: 12px;

        .selected-row {
          .selected-checkbox {
            border-color: #dfe1e4;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
            padding: 8px;

            .form-group.checkbox-form {
              padding: 0;
            }
          }
        }
      }

      + th,
      + td {
        @media (min-width: 991px) {
          padding-left: 8px;
        }
      }
    }

    thead {
      @media (max-width: 991px) {
        display: none;
      }

      tr.sticky-row {
        position: sticky;
        top: 0;
        background: white;
        z-index: 1;
        box-shadow: 0 1px 0 0 rgba(#000, 0.04),
        0px -1px 0px 0px rgba(#000, 0.07);
      }

      tr th {
        color: variables.$greyColor;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: bold;
        border: 0;
        padding-left: 20px;
        padding-right: 20px;

        &.sort-button {
          cursor: pointer;

          span {
            position: relative;
            margin-right: 15px;
            display: inline-block;

            &:before {
              content: '\e903';
              font-family: selmo;
              position: absolute;
              transition: all 0.3s ease-in-out;
              top: 50%;
              left: calc(100% + 8px);
              transform: translateY(-50%);
              transition: all 0.3s ease-in-out;
              font-size: 8px;
            }
          }

          &.desc,
          &.asc {
            color: #000;
          }

          &.asc {
            span:before {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }

        &.small-width {
          max-width: 100px;

          .extra-wrapper {
            max-width: 100px;
          }
        }

        &.sorting_desc,
        &.sorting_asc {
          span {
            color: variables.$darkColor;
          }
        }

        &.sorting_desc {
          span:before {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        &:first-of-type {
          padding-left: 22px;
        }

        &:last-of-type {
          padding-right: 22px;
        }
      }
    }

    tbody tr {
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.clicked {
        background: #e8ebf0d1;
      }

      @media (max-width: 991px) {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 15px 15px;
        border-top: 1px solid variables.$border;

        &:before {
          content: '\e903';
          display: inline-block;
          font-family: selmo;
          position: absolute;
          right: 20px;
          bottom: 18px;
          transform: rotate(-90deg);
          font-size: 10px;
          color: variables.$tableGrey;
        }
      }

      td {
        color: variables.$darkColor;
        font-size: 0.875rem;
        line-height: 1.125rem;
        font-weight: 500;
        border-top: 1px solid variables.$border;
        vertical-align: middle;
        padding-left: 20px;
        padding-right: 20px;

        @media (max-width: 991px) {
          display: block;
          padding: 0;
          border-top: 0;
        }

        .react-dropdown {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .status-bg {
          @media (max-width: 991px) {
            min-width: auto;
          }
        }

        .product-name {
          @media (max-width: 991px) {
            font-weight: bold;
            span {
              font-weight: normal;
              font-size: 14px;
            }
          }
        }

        &.short-number {
          max-width: 50px;
          width: 50px;
          @media (max-width: 991px) {
            max-width: none;
            width: auto;
          }
        }

        &.name {
          @media (max-width: 991px) {
            color: #101827;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 18px;
            width: 100%;
            margin-bottom: 3px;
            padding-right: 35px;
          }

          .avatar-row {
            display: flex;
            align-items: center;
            white-space: nowrap;

            figure {
              height: 25px;
              width: 25px;
              min-width: 25px;
              margin-bottom: 0;
              margin-right: 10px;

              .fb-pic {
                height: 100%;
                width: 100%;

                > img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }

              &.user-avatar {
                img:not(.msg-logo) {
                  width: 100%;
                  height: 100%;
                }
              }

              &.not-full-radius {
                border-radius: 5px;
              }
            }
          }

          .client-number {
            color: #6b7280;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
          }
        }

        &.small-font-size {
          @media (min-width: 991px) {
            font-size: 12px;
          }
        }

        &.mobile-small-font-size {
          @media (max-width: 991px) {
            font-size: 12px !important;
          }
        }

        &.photo-col.with-photo {
          @media (max-width: 991px) {
            > div {
              font-weight: bold;

              span {
                margin-top: 5px;
              }
            }
            figure {
              margin-right: 10px;
            }
          }
        }

        &.product-quantity {
          @media (max-width: 991px) {
            padding-left: 50px;
            padding-top: 5px;
          }
        }

        &.product-price {
          @media (max-width: 991px) {
            width: 100%;
            padding-left: 50px;
            margin-top: -14px;
            color: #6b7280;
          }
        }

        &.status-col {
          white-space: nowrap;
          @media (max-width: 991px) {
            width: 100%;
            order: 4;
            margin-top: 8px;
          }
        }

        &.tags-col {
          padding-top: 0;
          padding-bottom: 0;

          .tags-row {
            min-height: auto;

            .tag {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }

        &.status-in-storage {
          @media (max-width: 991px) {
            width: 100%;
            order: 1;
            margin-top: 0;
            margin-bottom: 3px;
          }
        }

        &.price {
          @media (max-width: 991px) {
            position: absolute;
            top: 10px;
            right: 20px;
          }
        }

        &.date,
        &.type {
          @media (max-width: 991px) {
            color: variables.$tableGrey;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
          }
        }

        &.date {
          @media (max-width: 991px) {
            margin-bottom: 3px;
            margin-right: 5px;
            .mobile-label {
              color: darken(variables.$tableGrey, 25%);
            }
          }

          span {
            display: inline-flex;
            align-items: center;

            &:after {
              content: '';
              display: inline-block;
              width: 3px;
              height: 3px;
              margin: 0 8px;
              background: variables.$tableGrey;
              border-radius: 50%;
            }

            &:last-of-type {
              &:after {
                display: none;
              }
            }
          }
        }

        &.type {
          @media (max-width: 991px) {
            display: flex;
            align-items: center;
            order: 3;
            &:after {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: variables.$tableGrey;
              margin: 5px;
            }
          }
        }

        &.shipping {
          line-height: 11px;
          @media (max-width: 991px) {
            margin-left: 5px;
            &:after {
              display: none;
            }
          }
        }

        &.number,
        &.date {
          color: variables.$tableGrey;
        }

        &.left-mobile-margin {
          @media (max-width: 991px) {
            margin-left: 35px;
          }
        }

        &.icon-status {
          font-family: 'Plus Jakarta Sans' !important;
          @media (max-width: 991px) {
            display: flex;
            align-items: center;
            padding-top: 5px;
            margin-left: 15px;
          }

          i {
            color: #9ca3af;
            font-size: 10px;

            &.icon-cross {
              color: #9ca3af;

              + span {
                color: #9ca3af;
              }
            }

            &.icon-tick {
              color: variables.$status-green;

              + span {
                color: variables.$status-green;
              }
            }
          }

          span {
            font-size: 12px;
            display: none;
            vertical-align: middle;
            @media (max-width: 991px) {
              display: inline-block;
              margin-left: 3px;
            }
          }

          &.with-mobile-thead {
            @media (max-width: 991px) {
              display: block;
              margin-top: 0;
              padding-top: 0;
              margin-left: 0;
            }
          }
        }

        &:first-of-type {
          @media (min-width: 991px) {
            padding-left: 22px;
          }
        }

        &:last-of-type {
          @media (min-width: 991px) {
            padding-right: 22px;
          }
        }

        &.with-photo {
          figure {
            width: 40px;
            height: 40px;
            margin: 0;
            margin-right: 15px;

            img:not(.msg-logo) {
              border-radius: 5px;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .mobile-price {
            color: #101827;
          }

          &.full-radius {
            figure img {
              border-radius: 50%;
            }
          }

          &.medium-size {
            figure {
              width: 36px;
              height: 36px;
              margin-right: 8px;
            }
          }

          &.small-client {
            figure {
              width: 30px;
              height: 30px;
              margin-right: 8px;
            }
          }
        }

        &.small-width {
          .extra-wrapper {
            max-width: 100px;
          }
        }

        .button {
          &.danger,
          &.primary,
          &.blue-button,
          &.green-button {
            padding: 8px 18px;
          }
        }

        .country-label {
          display: inline;
          font-size: 10px;
          opacity: 0.7;
          line-height: 10px;
        }

        &.with-mobile-thead {
          @media (max-width: 991px) {
            color: #6b7280;
            font-size: 14px;
            font-weight: 500;
          }

          &:before {
            @media (max-width: 991px) {
              content: attr(data-thead);
              display: block;
              margin-right: 10px;
              color: #6b7280;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 18px;
            }
          }

          &.icon-status {
            @media (max-width: 991px) {
              display: flex;
              align-items: center;
              border-radius: 3px;
              background-color: #f6f7f8;
              height: 24px;
              padding: 0 6px;
            }

            &:before {
              @media (max-width: 991px) {
                order: 2;
                margin-top: 0;
                font-weight: bold;
                font-size: 10px;
                line-height: 12px;
                color: #6b7280;
              }
            }

            i {
              @media (max-width: 991px) {
                margin-right: 5px;
                &.icon-cross {
                  font-size: 8px;
                }
              }
            }

            &.circle-mobile-separator {
              &:after {
                order: 3;
                margin: 5px 9px 5px 5px;
              }
            }
          }
        }

        &.mobile-grey {
          @media (max-width: 991px) {
            color: #6b7280;
          }
        }

        &.inline-thead {
          @media (max-width: 991px) {
            display: flex;
            align-items: center;
            &:before {
              margin-top: 0;
              margin-right: 4px;
            }
          }
        }

        &.circle-mobile-separator {
          @media (max-width: 991px) {
            display: flex;
            align-items: center;
            &:after {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #9a9a9a;
              margin: 5px 0 5px 8px;
            }
          }
        }

        &.payment-col {
          .icon-circle {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #eeeff2;
            color: #8e96a4;
            border-radius: 100%;
            position: relative;
            top: -3px;
            @media (max-width: 991px) {
              top: -1px;
            }

            &.paid {
              background: #3cd9a0;
              color: #ffffff;
            }

            i {
              position: relative;
              top: -0.5px;
              left: 0;
              font-size: 7px;

              &.icon-tick {
                top: -0.5px;
                font-size: 8px;
              }
            }
          }
        }

        &.mobile-break {
          display: none;
          @media (max-width: 991px) {
            display: block;
            flex-basis: 100%;
            height: 0;
          }
        }

        .id-number {
          @media (max-width: 991px) {
            font-size: 12px;
            min-width: 20px;
            height: 18px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: #f3f4f6;
            color: #4b5563;
            border-radius: 3px;
            margin-right: 5px;
          }
        }

        &.absolute-dropdown-dots {
          @media (max-width: 991px) {
            position: absolute;
            top: 15px;
            right: 12px;
          }
        }
      }

      &.not-clicked-tr {
        cursor: default;

        &:before {
          display: none;
        }
      }

      &:hover:not(.not-clicked-tr) {
        @media(min-width: 771px) {
          background: variables.$tableHover;
        }
      }
    }

    &.empty-table {
      tbody tr {
        &:before {
          display: none;
        }

        td {
          width: 100%;
        }
      }
    }

    &.changelog-table {
      tr {
        &:first-of-type {
          td {
            border-top: 0;
          }
        }

        td {
          padding: 8px 0;

          .change {
            display: inline-block;
            font-size: 12px;
            padding: 2px 8px;
            border-radius: 4px;
            color: #3c61ea;
            background: #eff6ff;

            &.old {
              color: #e64747;
              background: #fdf0f0;
              text-decoration: line-through;
              text-decoration-color: rgba(230, 71, 71, 0.55);
            }

            &.new {
              color: #069769;
              background: #edf8f4;
            }

            &.grey {
              color: #6b7280;
              background: #f3f4f6;
            }
          }

          .icon-arrow-left {
            display: inline-block;
            transform: rotate(180deg);
            color: #6b7280;
            font-size: 8px;
            margin: 0 10px;
          }

          &:first-of-type {
            width: 250px;
          }
        }
      }
    }

    &.live-table {
      thead tr th {
        &.client-number {
          @media (min-width: 991px) {
            width: 70px;
          }
        }
      }

      tbody tr {
        @media (max-width: 991px) {
          flex-wrap: wrap;
        }

        td {
          &.date-td {
            @media (max-width: 991px) {
              width: 60%;
              white-space: nowrap;
              margin-bottom: 6px;
              margin-right: auto;
            }
          }

          &.name-td {
            max-width: 410px;
            word-break: break-word;
            @media (max-width: 991px) {
              width: 100%;
              margin-bottom: 6px;
            }
          }

          &.client-td {
            @media (max-width: 991px) {
              order: 3;
              color: #6b7280;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 15px;
              position: relative;
              display: flex;
              align-items: center;
              span {
                font-weight: 400;
                margin-left: 3px;
              }
              &:after {
                content: '';
                position: relative;
                top: 1px;
                width: 3px;
                height: 3px;
                border-radius: 5px;
                background: #6b7280;
                margin: 0 5px;
              }
            }
          }

          &.sold-td {
            @media (max-width: 991px) {
              order: 4;
              color: #6b7280;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 15px;
              span {
                font-weight: 400;
                margin-left: 3px;
              }
            }
          }

          &.total-price-td {
            @media (max-width: 991px) {
              width: 40%;
              white-space: nowrap;
            }
          }
        }
      }
    }

    &.default-list-table {
      tbody tr {
        td {
          @media (max-width: 991px) {
            color: #6b7280;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            &:before {
              font-size: inherit;
              color: inherit;
              line-height: inherit;
            }
          }

          &.name {
            @media (max-width: 991px) {
              color: #101827;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 18px;
            }
          }

          &.number {
            @media (max-width: 991px) {
              color: #101827;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 15px;
              &:before {
                font-size: inherit;
                color: inherit;
                line-height: inherit;
                margin-right: 0;
              }
              &:after {
                background: rgba(16, 24, 39, 0.65);
              }
            }
          }

          &.date {
            @media (max-width: 991px) {
              margin-bottom: 0;
            }
          }

          &.price {
            @media (max-width: 991px) {
              color: #101827;
            }
          }

          &.status-col {
            @media (max-width: 991px) {
              display: flex;
              align-items: center;
              order: 6;
              margin-top: 0;
              .status-bg {
                order: 1;
              }
              .packed-button {
                display: none;
              }
            }
          }
        }
      }
    }

    &.smaller-padd {
      tr td,
      tr th {
        padding-left: 10px;
        padding-right: 10px;
        @media (max-width: 991px) {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }

    &.assigned-products-table {
      tbody tr {
        td {
          padding-top: 5px;
          padding-bottom: 5px;
          border-top: 0;

          .code-col {
            .form-group .form-control {
              min-height: 40px;
              padding: 3px 8px 3px 15px;
            }
          }
        }
      }
    }

    &.non-interactive-table {
      tbody tr {
        &:hover {
          background: variables.$white;
        }

        td {
          cursor: default;
        }
      }
    }

    &.mobile-block {
      @media(max-width: 767px) {
        display: block;
        padding: 0 10px;
        tbody {
          tr {
            border: 1px solid #F6F7F8;
            margin-bottom: 8px;
            padding: 10px;
            border-radius: 8px;

            td {
              padding: 0;
            }
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.table-wrapper {
  .custom-header {
    background: variables.$white;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04),
    0 1px 3px 0 rgba(0, 0, 0, 0.05);
    padding: 28px 22px;
    border-radius: 5px 5px 0 0;
    @media (max-width: 991px) {
      border-radius: 0;
      box-shadow: none;
    }
  }

  .table-responsive {
    background: variables.$white;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04),
    0 1px 3px 0 rgba(0, 0, 0, 0.05);
    border-radius: 0 0 5px 5px;
    @media (max-width: 991px) {
      border-radius: 0;
      box-shadow: none;
    }

    &.rounded-b-medium {
      @media (min-width: 991px) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  &.light-version {
    .table-responsive {
      box-shadow: none;
    }
  }
}

.table-placeholder-wrapper {
  .flex-row {
    margin-bottom: 25px;
    align-items: center;
    @media (max-width: 767px) {
      .btn {
        display: none;
      }
    }
  }

  .btn {
    height: 54px;
    padding: 0 20px;
    border-radius: 10px;
    background-color: #fcd34c;
    color: #101827;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
  }

  .table-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 767px) {
      height: 150px;

      .main-img {
        display: none;
      }

      .btn {
        width: 100%;
      }
    }

    &__box {
      z-index: 99;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: 480px) {
        min-width: 200px;
      }

      .icon {
        text-align: center;
        margin-bottom: 5px;

        img {
          height: 45px;
          @media (max-width: 480px) {
            height: 40px;
          }
        }
      }

      .text {
        color: #101827;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -0.27px;
        line-height: 37px;
        margin-bottom: 20px;

        @media (max-width: 480px) {
          font-size: 18px;
          line-height: 28px;
          text-align: center;
        }
      }

      .btn {
        padding-bottom: 4px;

        @media (max-width: 480px) {
          height: 46px;
          font-size: 14px;
        }
      }
    }
  }
}

.orders-page-table {
  .toolbar {
    flex-grow: 1;
  }

  .button.text-only span {
    white-space: nowrap;
  }
}

.storage-page-table {
  margin: 0 !important;

  .table-responsive {
    .table {
      tbody {
        tr {
          //margin: 0 15px;
        }
      }
    }
  }
}

.table-box-wrapper {
  > .default-shadow-box {
    padding-bottom: 0;
    padding-top: 15px;
    overflow: hidden;
    @media (max-width: 991px) {
    }
  }

  .boxes-wrapper {
    margin-left: -25px;
    margin-right: -25px;
    @media (max-width: 991px) {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
    }

    .box {
      padding: 15px 25px;
      display: flex;
      align-items: center;
      text-decoration: none;
      border-bottom: 1px solid #f6f7f8;
      transition: all 0.2s ease-in-out;
      @media (max-width: 991px) {
        padding: 15px;
      }
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }

      .left-side {
        flex-grow: 1;

        .title {
          color: #101827;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 18px;
          margin-bottom: 0;
        }

        .bottom-text {
          display: flex;
          align-items: center;
          color: #6b7280;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
          @media (max-width: 1200px) {
            flex-wrap: wrap;
          }

          span {
            display: flex;
            align-items: center;
            white-space: nowrap;
            max-width: 134px;
            text-overflow: ellipsis;
            overflow: hidden;
            @media (max-width: 767px) {
              flex-wrap: wrap;
              max-width: none;
              white-space: normal;
            }

            > div {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              @media (max-width: 767px) {
                white-space: normal;
              }
            }

            &:after {
              content: '';
              display: inline-block;
              width: 4px;
              min-width: 4px;
              height: 4px;
              margin: 0 5px;
              border-radius: 100%;
              background: #6b7280;
            }

            &:last-of-type {
              &:after {
                display: none;
              }
            }

            .country-label {
              margin-left: 5px;
            }
          }
        }
      }

      .right-side {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
          width: 100%;
          margin-top: 10px;
        }

        .price {
          color: #101827;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          min-width: 60px;
          text-align: right;
          @media (max-width: 767px) {
            margin-left: auto;
          }
        }
      }

      &:last-of-type {
        border-bottom: 0;
      }

      &:hover {
        background: #f9fafb;
      }
    }
  }

  .filters-part {
    display: flex;
    align-items: center;
    background: #fff;
    width: 100%;
    margin-bottom: 15px;

    .title {
      margin-bottom: 0;
    }
  }
}
