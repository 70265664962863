.sellfie-summary {
	margin-bottom: 25px;

	.header-row {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		@media(max-width: 767px) {
			flex-wrap: wrap;
			margin-bottom: 15px;
		}

		h2 {
			flex-grow: 1;
			font-size: 24px;
			font-weight: 600;
			letter-spacing: -0.27px;
			line-height: 37px;
			margin-bottom: 0;
			@media(max-width: 767px) {
				margin-bottom: 10px;
				font-size: 17px;
				line-height: 24px;
			}
		}
	}

	.flex-row {
		display: flex;
		align-items: center;
		margin: 0 -8px;

		@media(max-width: 767px) {
			flex-wrap: wrap;
			margin: 0 -5px;
		}

		& > div {
			width: 25%;
			padding: 0 8px;

			@media(max-width: 767px) {
				width: 50%;
				padding: 5px;
			}
		}

		&.chart-row {
			@media(max-width: 767px) {
				margin-top: 0;
			}

			& > div {
				width: 50%;

				@media(max-width: 767px) {
					width: 100%;
				}
			}
		}
	}

	.chart-placeholder-box {
		background-color: red;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
		padding: 15px;
		height: 300px;
		border-radius: 5px;
		position: relative;

		img {
			max-width: 100%;
		}

		@media(max-width: 767px) {
			height: 200px;
			width: 100%;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		&__text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;

			.main-text {
				font-size: 20px;
				white-space: nowrap;
				font-weight: 600;
				@media(max-width: 767px) {
					font-size: 14px;
					white-space: normal;
				}
			}
		}

		&__button-place {
			text-align: center;
			margin-top: 15px;

			.btn {
				margin: 0 auto;
				height: 48px;
				font-size: 16px;
				padding: 0 20px;

				i {
					font-size: 14px;
				}

				@media(max-width: 767px) {
					font-size: 14px;
				}
			}
		}

		img {
			max-width: 100%;
		}
	}

	.btn-primary:not(:disabled):not(.disabled):active:focus {
		box-shadow: none !important;
	}

	@media(max-width: 767px) {
		.chart-responsive-wrapper {
			z-index: 999;
			overflow-x: auto;
			background: radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
			radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 100% 0;
			background-color: #fff;
			background-repeat: no-repeat;
			background-size: 15px 100%;

			.chart-responsive {
				min-width: 400px !important;
				position: relative;

				background: linear-gradient(to right, #fff 30%, rgba(255, 255, 255, 0)),
				linear-gradient(to left, #fff 30%, rgba(255, 255, 255, 0)) 100% 0;
				background-size: 50px 100%;
				background-repeat: no-repeat;
				max-width: none;
				margin-bottom: 0;
			}
		}
	}

	.chart-box {
		height: 315px;
		border-radius: 5px;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
		padding: 20px;

		@media(max-width: 767px) {
			height: auto;
		}

		&__title {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			@media(max-width: 991px) {
				margin-bottom: 0;
			}

			.flex-grow {
				flex-grow: 1;
				font-weight: 600;
				font-size: 15px;
			}

			.date {
				font-size: 12px;
				color: gray;
			}
		}
	}

	.summary-box {
		border-radius: 5px;
		background-color: #FFFFFF;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
		height: 120px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		padding: 20px;
		text-decoration: none;
		transition: all .2s ease-in-out;

		@media(max-width: 767px) {
			height: 100px;
		}

		&:hover {
			box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
		}

		.icon {
			border-radius: 5px;
			position: absolute;
			top: 11px;
			right: 15px;

			i {
				color: #FCD34C;
				font-size: 22px;

				&.icon-done {
					color: #34d299;
				}

				&.icon-unpaid {
					color: #F87171;
				}

				&.icon-income2 {
					color: #5FA5FA;
				}

				@media(max-width: 767px) {
					font-size: 16px;
				}
			}
		}

		.text-wrapper {
			.title {
				color: #101827;
				font-size: 15px;
				letter-spacing: 0;
				line-height: 13px;
				margin-bottom: 5px;

				@media(max-width: 767px) {
					font-size: 13px;
				}
			}

			.value {
				font-size: 26px;
				font-weight: 600;
				letter-spacing: -0.5px;
				line-height: 26px;
				color: #101827;

				@media(max-width: 767px) {
					font-size: 24px;
				}
			}
		}
	}
}

.sellfie-sender {
	h1 {
		margin-bottom: 5px;
		@media(max-width: 767px) {
			color: #101827;
			font-size: 20px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 24px;
		}
	}

	.custom-header {
		@media (max-width: 767px) {
			margin-bottom: 15px;

			button {
				margin-top: 10px;
			}
		}
	}

	.header-description {
		color: #101827;
		font-size: 15px;
		letter-spacing: 0;
		line-height: 23px;
		margin-bottom: 15px;

		@media(max-width: 767px) {
			color: #101827;
			font-size: 13px;
			letter-spacing: 0;
			line-height: 18px;
		}
	}

	.explanation-box {
		height: 160px;
		border-radius: 5px;
		background-color: #FCD34C;
		display: flex;
		align-items: center;
		padding: 25px;
		position: relative;

		@media(max-width: 767px) {
			flex-wrap: wrap;
			height: auto;
			margin: 0 -15px;

			& > div {
				width: 100%;
				text-align: center;
			}
		}

		& > div {
			&.bigger-z-index {
				z-index: 99;
			}
		}

		.text-wrapper {
			color: #101827;
			font-size: 22px;
			letter-spacing: -0.24px;
			line-height: 30px;
			max-width: 300px;
			margin-right: 25px;
			@media(max-width: 767px) {
				margin-right: 0;
				max-width: none;
				margin-bottom: 15px;
				color: #101827;
				font-size: 15px;
				letter-spacing: -0.17px;
				line-height: 23px;
				text-align: center;
			}
		}

		.button-place {
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				color: #101827;
				font-size: 13px;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 20px;
				height: 46px;
				border-radius: 5px;
				background-color: #FFFFFF;
				border: 0;
				box-shadow: none;
				padding: 0 25px;
			}
		}

		.illustration-box {
			//TODO
			//background-image: url(/public/assets/images/send-orders/packed-illustration2.svg);
			background-size: cover;
			height: 147px;
			width: 541px;
			background-repeat: no-repeat;
			position: absolute;
			right: 0;
			bottom: 0;

			@media(max-width: 991px) {
				display: none;
			}
		}
	}

	.orders-page-table {
		margin-bottom: 0;
	}

	section {
		padding: 25px 0;
		border-bottom: 1px solid #E5E7EB;

		&:last-of-type {
			border: 0;
		}

		.top-header {
			display: flex;
			align-items: center;

			@media(max-width: 767px) {
				display: block;
			}

			.header-info {
				@media(max-width: 767px) {
					display: none;
				}
			}

			h2 {
				flex-grow: 1;
				margin-bottom: 0;
			}

			.all-orders-sent {
				border-radius: 5px;
				background-color: #818DF8;
				height: 38px;
				display: flex;
				padding: 0 15px;
				align-items: center;
				justify-content: center;
				color: #FFFFFF;
				font-size: 13px;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 20px;
				text-align: right;
				box-shadow: none;
				border: 0;
				transition: all .2s ease-in-out;
				@media(max-width: 767px) {
					margin-top: 10px;
				}

				&:hover {
					background-color: #6366F1;
				}
			}
		}
	}

	.placeholder-box {
		height: 290px;
		//TODO
		//background-image: url('/public/assets/images/send-orders/packed-placeholder.svg');
		background-size: contain;
		background-repeat: no-repeat;
		position: relative;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			margin-bottom: 15px;

			img {
				height: 38px;
				width: 67px;
			}
		}

		.text {
			color: #101827;
			font-size: 19px;
			font-weight: 600;
			letter-spacing: -0.21px;
			line-height: 29px;
		}
	}
}

.last-7-days {
	margin-top: 45px;
	padding-top: 25px;
	border-top: 1px solid #D1D5DB;
}

.stats-wrapper {
	.header-bar {
		@media(max-width: 991px) {
			flex-grow: 1;
		}

		#custom-dropdown-default [class*='-control'] {
			height: 33px;
			box-shadow: none;

			[class*='-singleValue'] {
				font-weight: normal;
			}
		}
	}

	.title-with-legend {
		display: flex;
		align-items: center;

		.custom-legends {
			display: flex;
			align-items: center;
			margin-left: auto;
			color: #1F2937;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 15px;

			> div {
				display: flex;
				align-items: center;
				margin-left: 15px;
				white-space: nowrap;

				&:before {
					background: #3C61EA;
					width: 4px;
					height: 4px;
					border-radius: 100%;
					content: "";
					margin-right: 5px;
				}

				&:nth-of-type(2) {
					&:before {
						background: #0EB981;
					}
				}
			}

			&.orders-value {
				> div {
					&:before {
						background: #1C4ED8;
					}

					&:nth-of-type(2) {
						&:before {
							background: #5FA5FA;
						}
					}

					&:nth-of-type(3) {
						&:before {
							background: #90E8EE;
						}
					}

					&:nth-of-type(4) {
						&:before {
							background: #3ee1f6;
						}
					}
				}
			}
		}
	}

	.stats-box {
		padding-bottom: 30px;
		@media(max-width: 576px) {
			padding-bottom: 0;
		}

		.header-bar {
			display: flex;
			align-items: center;
			@media(max-width: 767px) {
				flex-wrap: wrap;
			}

			.title {
				flex-grow: 1;
				margin-bottom: 0;
				@media(max-width: 767px) {
					width: auto;
				}
			}

			.filters-part {
				@media(max-width: 767px) {
					width: 100%;
					margin-top: 10px;
				}
				@media(max-width: 480px) {
					flex-wrap: wrap;
					justify-content: space-between;
				}

				.switch-form {
					@media(max-width: 767px) {
						flex-grow: 1;
					}
					@media(max-width: 480px) {
						width: 100%;
					}
				}

				.form-group {
					@media(max-width: 480px) {
						width: 100%;
					}
				}

				.daterange-wrapper {
					@media(max-width: 480px) {
						width: 100%;
						margin-left: 0 !important;
						margin-top: 8px;
						> button {
							width: 100%;
						}
					}
				}
			}
		}
	}

	#apexchartsbasicxbar {
		@media(max-width: 991px) {
			width: 100% !important;
		}
	}

	.chart-wrapper {
		@media(max-width: 991px) {
			width: 100% !important;
		}
	}

	.stats-row {
		display: flex;
		border: 1px solid #E5E7EB;
		border-radius: 5px;
		@media(max-width: 991px) {
			flex-wrap: wrap;
			border-bottom: 0;
			margin-top: 20px;
		}

		@media(max-width: 576px) {
			border: 0;
			margin-left: -15px;
			margin-right: -15px;
			margin-top: 14px;
		}

		> div {
			display: flex;
			flex-direction: column;
			width: 25%;
			border-right: 1px solid #E5E7EB;
			padding: 16px;
			@media(max-width: 991px) {
				width: 50%;
				border-top: 1px solid #E5E7EB;
			}
			@media(max-width: 576px) {
				width: 100%;
				border-right: 0;
			}


			a {
				height: 100%;
				display: flex;
				flex-direction: column;
				text-decoration: none;

				&:hover {
					text-decoration: none;
				}
			}

			.label {
				display: flex;
				align-items: center;
				color: #4B5563;
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 15px;

				img {
					width: 15px;
					margin-right: 5px;
				}
			}

			.value {
				display: flex;
				align-items: flex-end;
				color: #101827;
				font-size: 24px;
				font-weight: bold;
				letter-spacing: -0.5px;
				line-height: 30px;
				margin-top: 12px;
			}

			.progress-value {
				display: flex;
				align-items: center;
				color: #9CA3AF;
				font-size: 13.07px;
				font-weight: 500;
				letter-spacing: -0.22px;
				line-height: 17px;
				margin-left: 8px;
				margin-bottom: 2px;

				strong {
					display: flex;
					align-items: center;
					color: #0eb981;
					font-size: 13.07px;
					font-weight: bold;
					letter-spacing: -0.22px;
					line-height: 17px;
					margin-right: 5px;

					i {
						font-size: 10px;
						color: inherit;
						margin-right: 5px;
					}
				}

				&.red {
					strong {
						color: #f15b5b;
					}
				}

				&.default {
					strong {
						color: #9CA3AF;
					}
				}
			}

			&:last-of-type {
				border-right: 0;
				@media(max-width: 576px) {
					border-bottom: 0;
				}
			}
		}
		&.in-marketing {
			display: grid;
			border: 0;
			> div {
				height: 125px;
				display: flex;
				flex-direction: column;
				padding: 25px 15px;
				border-radius: 8px;
				width: auto;
				//border-width: 1px;
				border: 1px solid #E5E7EB;
				.label {
					color: #4b5563;
					display: flex;
					align-content: center;
					justify-content: space-between;
					font-weight: bold;
				}
				.value {
					margin-top: auto;
					font-size: 32px;
					line-height: 36px;
					align-items: center;
				}
			}
		}
	}

	.charts-section {
		margin-top: 16px;

		.loader-container {
			z-index: 12;
		}

		.donuts-chart-wrapper {
			width: 100%;

			.donuts-chart {
				width: 170px;
				margin-left: auto;
				margin-right: auto;
				&.bigger-size {
					width: 200px;
				}
			}

			.custom-legend {
				> div {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 10px 0;
					border-bottom: 1px solid #E5E7EB;

					.label {
						display: flex;
						align-items: center;
						color: #1F2937;
						font-size: 12px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 15px;

						&:before {
							display: block;
							border-radius: 100%;
							content: "";
							height: 4px;
							width: 4px;
							background-color: #3C61EA;
							margin-right: 5px;
						}

						&.no-circle {
							&:before {
								display: none;
							}
						}

						&.inline-styles {
							display: inline-block;
							&:before {
								display: inline-block;
								vertical-align: middle;
							}
						}
					}

					.value {
						color: #6B7280;
						font-size: 12px;
						font-weight: 500;
						line-height: 15px;
						white-space: nowrap;

						strong {
							color: #1F2937;
							font-weight: bold;
							margin-left: 15px;
						}
					}

					&:nth-of-type(2) {
						.label:before {
							background: #5FA5FA;
						}
					}

					&:nth-of-type(3) {
						.label:before {
							background: #90E8EE;
						}
					}

					&:nth-of-type(4) {
						.label:before {
							background: #FF8100;
						}
					}

					&:nth-of-type(5) {
						.label:before {
							background: #FFD133;
						}
					}

					&:nth-of-type(6) {
						.label:before {
							background: #3B29CC;
						}
					}

					&:nth-of-type(7) {
						.label:before {
							background: #D3D3D3;
						}
					}

					&:nth-of-type(8) {
						.label:before {
							background: #808080;
						}
					}

					&:nth-of-type(9) {
						.label:before {
							background: #FFFFFF;
						}
					}

					&:nth-of-type(10) {
						.label:before {
							background: #A9A9A9;
						}
					}

					&:nth-of-type(10) {
						.label:before {
							background: #C0C0C0;
						}
					}

					&:last-of-type {
						border-bottom: 0;
					}
				}
			}
		}
	}
}

.apexcharts-text {
	text-transform: capitalize;
}

.apexcharts-tooltip {
	color: #fff;
	border: 0 !important;
	background-color: #1F2937 !important;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.chart-tooltip {
	padding: 8px 10px;
	font-family: "Plus Jakarta Sans";

	.date-label {
		margin-left: -10px;
		margin-right: -10px;
		padding: 0 10px 3px;
		margin-bottom: 3px;
		text-transform: capitalize;
	}

	.label, .date-label {
		color: #D1D5DB;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 12px;
	}

	.value {
		color: #FFFFFF;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
		margin-top: 2px;
	}
}
