$greyColor: #6B7280;
$lgreyColor: #9CA3AF;
$greyBg: #E5E7EB;
$black: #000;
$white: #fff;
$mainBg: #EFF2F6;
$darkColor: #101827;
$border: #F6F7F8;
$darkBorder: #DFE1E4;
$tableGrey: #9A9A9A;
$tableHover: #f4f4f4;
$blue: #3C61EA;

//status
$status-blue: #3C61EA;
$status-green: #0EB981;
$status-orange: #F59E0C;
$status-grey: #6B7280;
$status-red: #EF4444;
