@use '../variables';

.orders-wrapper {
    > .loader-container {
        min-height: 300px;
    }

    .active-loader-parent {
        position: static;
    }
}

.order-details-wrapper {
    display: flex;
    align-items: flex-start;
    margin-left: -8px;
    margin-right: -8px;

    @media(max-width: 767px) {
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
    }

    .mobile-toggle-wrapper {
        .mobile-toggle-header {
            display: none;
            @media(max-width: 991px) {
                display: block;
            }

            .top-part {
                padding: 20px 25px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #E5E7EB;
                margin-left: -25px;
                margin-right: -25px;

                h2 {
                    margin-bottom: 0;
                }

                .button {
                    color: #6B7280;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 15px;

                    i {
                        position: relative;
                        top: -1px;
                        transform: rotate(0);
                    }
                }

                &.open {
                    border-bottom: 0;
                    padding-bottom: 0;

                    .button {
                        i {
                            top: 0;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        .mobile-toggle-body {
            @media(max-width: 991px) {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
        }

        &.product-mobile-toggle-header {
            @media(max-width: 991px) {
                .mobile-toggle-header {
                    .top-part {
                        border-bottom: 0;

                        &:not(.open) {
                            margin-bottom: -20px;
                        }
                    }
                }
            }
        }
    }

    .transactions-history {
        border-radius: 5px;
        background-color: rgba(14, 185, 129, 0.08);
        display: flex;
        padding: 15px 24px 15px 15px;

        i {
            position: relative;
            top: -2px;
            font-size: 16px;
            color: #34D299;
            margin-right: 7px;
        }

        .title {
            color: #101827;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
            margin-bottom: 5px;
        }

        .histories {
            > div {
                border-bottom: 1px solid rgba(#D1D5DB, 0.5);
                display: flex;
                align-items: center;

                span {
                    display: flex;
                    align-items: center;
                    color: #4B5563;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                    padding: 4px 0;

                    &:after {
                        content: "";
                        display: inline-block;
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        margin: 0 5px;
                        background: #4B5563;
                    }

                    &:last-of-type {
                        &:after {
                            display: none;
                        }
                    }
                }

                strong {
                    margin-left: auto;
                    color: #1F2937;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 21px;
                    text-align: right;
                }

                &:last-of-type {
                    border-bottom: 0;
                }
            }
        }

        .total {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            border-top: 1px solid rgba(#D1D5DB, 0.5);
            padding-top: 4px;

            .label {
                color: #4B5563;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 21px;
            }
        }
    }

    .status-header {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        @media(max-width: 576px) {
            flex-wrap: wrap;
        }

        .title {
            flex-grow: 1;
            margin-bottom: 0;
            @media(max-width: 991px) {
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 23px;
            }
        }

        .description {
            @media(max-width: 991px) {
                color: #6B7280;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 15px;
            }
        }

        .status-dropdown {
            @media(max-width: 576px) {
                flex-grow: 1;
            }
        }


        .d-flex-packed {
            @media(max-width: 576px) {
                width: 100%;
                margin-top: 16px;
            }

            .icon-button {
                @media(max-width: 576px) {
                    order: 2;
                }
            }

            .print-order-button {
                @media(max-width: 576px) {
                    margin-left: auto;
                }
            }
        }
    }

    .selmo-integration-box {
        display: block;
        background: #fff;
        border-radius: 5px;
        padding: 15px;
        position: relative;
        margin-bottom: 15px;
        text-decoration: none;

        .flex-row {
            flex-wrap: nowrap;
        }

        &__photo {
            width: 75px;
            text-align: center;

            img {
                display: block;
                margin: 0 auto;
                max-width: 50px;
            }
        }

        &__text {
            padding-right: 15px;

            &:after {
                font-family: 'selmo';
                content: "\e902";
                position: absolute;
                color: #9CA3AF;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                right: 15px;
                font-size: 10px;
            }

            &__top {
                color: #101827;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 19px;
            }

            &__bottom {
                color: #6B7280;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 22px;
            }
        }
    }

    .flex-row-labels {
        margin: 0 -3px;

        & > div {
            padding: 0 3px;
            width: 50%;
        }
    }

    h1 {
        color: #101827;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 25px;
        margin-top: 0;
        margin-bottom: 26px;
    }

    h2 {
        color: #101827;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 21px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    h3 {
        color: #101827;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
    }

    .section-header {
        display: flex;
        align-items: center;
        @media(max-width: 480px) {
            flex-wrap: wrap;
        }

        .w-xs-100 {
            @media(max-width: 330px) {
                width: 100%;
                margin: 10px 0;
                justify-content: center;
                text-align: center;
            }
        }

        h2 {
            a {
                color: inherit;
            }

            @media(max-width: 991px) {
                font-size: 14px;
            }
        }

        h2, h3 {
            flex-grow: 1;
            margin-bottom: 0;
        }

        &__description {
            color: #6B7280;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
        }

        &.bordered {
            border-bottom: 1px solid #E5E7EB;
            margin: 0 -25px;
            padding: 0 25px 15px 25px;
            margin-bottom: 20px;
        }

        .react-dropdown {
            top: -3px;

            .dropdown-menu {
                min-width: auto;

                .custom-option {
                    padding: 6px 8px;
                }
            }
        }
    }

    hr {
        border-color: #E5E7EB;
    }

    .tripple-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: -5px;
        margin-right: -5px;

        > div {
            width: 33.33%;
            padding: 0 5px;
            @media(max-width: 991px) {
                width: 50%;
                &:first-of-type {
                    //display: none;
                }
            }

            button, a {
                &.big-width {
                    padding: 10px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    .info-label {
        display: flex;
        align-items: center;
        color: #1F2937;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;

        i {
            color: #34D299;
            margin-right: 5px;
        }

        .date-box {
            display: flex;
            align-items: center;
            margin-left: 5px;

            span {
                display: flex;
                align-items: center;
                color: #6B7280;
                font-weight: 500;

                &:after {
                    content: "";
                    display: inline-block;
                    width: 3px;
                    height: 3px;
                    border-radius: 5px;
                    background: #6B7280;
                    margin: 0 3px;
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .section-value {
        color: #6B7280;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23px;
    }

    .form-group {
        margin-bottom: 20px;

        .control-label {
            display: block;
            color: variables.$greyColor;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            margin-bottom: 7px;
        }

        .form-control {
            border-radius: 5px;
            box-shadow: none;
            border: 1px solid #E5E7EB;
            transition: none;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
            padding: 6px 8px 6px 16px;
            color: variables.$darkColor;
            min-height: 45px;
            box-sizing: border-box;
            width: 100%;
            height: auto;
        }

        textarea.form-control {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    > div {
        padding: 0 8px;
        @media(max-width: 767px) {
            padding: 0;
            width: 100%;
        }

        &:first-of-type {
            width: 60%;
            @media(max-width: 767px) {
                width: 100%;
            }
        }

        &:last-of-type {
            width: 40%;
            @media(max-width: 767px) {
                width: 100%;
            }
        }

        .details-content, .summary-section {
            section {
                border-bottom: 1px solid #F3F4F6;
                margin-bottom: 20px;
                padding-bottom: 20px;

                &.full-border {
                    margin-left: -25px;
                    margin-right: -25px;
                    padding-left: 25px;
                    padding-right: 25px;
                }

                &:last-of-type {
                    border-bottom: 0;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }

                &.address-section {
                    padding-bottom: 10px;
                }
            }

        }

        .details-section, .summary-section {
            border-radius: 5px;
            background-color: #FFFFFF;
            box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
            padding: 32px 25px;
            @media(max-width: 767px) {
                border-radius: 0;
                box-shadow: none;
                padding: 20px 25px;
            }
        }

        .summary-section {
            padding: 25px 25px;

            > .loader-parent {
                min-height: auto;

                .loader-container {
                    min-height: auto;
                    background: rgba(#fff, 0.8);

                    .circle-loader {
                        transform: translate(-50%, -50%);
                        top: 50%;
                        margin: 0;
                    }

                }
            }

            .avatar-box {
                .avatar-row {
                    display: flex;
                    align-items: center;
                }

                figure {
                    width: 50px;
                    height: 50px;
                    padding: 0;
                    margin-bottom: 0;

                    img:not(.msg-logo) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }

                    .app-status {
                        right: -1px;
                        bottom: -1px;
                        width: 16px;
                        height: 16px;

                        i {
                            font-size: 16px;
                            //width: 20px;
                            //height: 20px;
                        }

                        &.with-social {
                            bottom: 1px;
                            right: -6px;
                        }
                    }

                    .msg-logo {
                        right: -2px;
                        bottom: -2px;
                        width: 20px;
                        height: 20px;

                        &.row-icons {
                            right: 2px;
                        }
                    }
                }

                .name {
                    color: #101827;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 23px;
                    margin-left: 10px;

                    a {
                        color: inherit;
                    }

                    .client-number {
                        font-weight: 400;
                        color: #6B7280;
                        font-size: 13px;
                        line-height: 18px;
                    }
                }
            }

            .value-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                color: #101827;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 18px;

                .value {
                    font-weight: 500;
                    margin-left: auto;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                &.final-wrapper {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 25px;
                }
            }

            &.mobile-summary-section {
                display: none;
                margin-top: 16px;
                @media(max-width: 767px) {
                    display: block;
                }
            }

            &.mobile-remove-section {
                display: none;
                @media(max-width: 767px) {
                    display: block;
                }
            }

            .form-group.inline-label {
                .control-label {
                    color: #1F2937;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    line-height: 16px;
                }
            }

            [class*='-menu'] {
                z-index: 9;
            }
        }

        .remove-section {
            @media(max-width: 767px) {
                display: none;
            }
        }

        .delivery-section {
            .tracking-link {
                .form-group {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.open-cart-products {
    border: 1px solid #DFE1E4;
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
    padding: 4px 20px;
    margin-top: 8px;
    transition: background-color .2s ease-in-out;

    &__title {
        display: flex;
        align-items: flex-start;
        padding: 15px 0;

        &:hover {
            cursor: pointer;
        }

        h3 {
            display: flex;
            align-items: center;
            color: #101827;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
            flex-grow: 1;
            margin-bottom: 0;

            > span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 5px;
                height: 20px;
                width: 20px;
                border-radius: 3.6px;
                background-color: #F3F4F6;
                color: #1F2937;
                font-size: 10px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 12px;
            }

            .payment-status {
                display: flex;
                align-items: center;
                color: #101827;
                font-size: 10px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 20px;
                border-radius: 3px;
                background-color: #E6F8F2;
                padding: 0 5px;
                margin-left: 5px;

                i {
                    position: relative;
                    top: -1px;
                    margin-right: 5px;
                    color: #34D299;
                    font-size: 12px;
                }

                .info {
                    display: flex;
                    align-items: center;

                    span, strong {
                        display: flex;
                        align-items: center;
                        white-space: nowrap;

                        &:after {
                            content: "";
                            width: 3px;
                            height: 3px;
                            border-radius: 100%;
                            background: #000;
                            margin: 0 5px;
                            display: block;
                        }
                    }

                    span:last-of-type {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .price {
            font-size: 14px;
            font-weight: 500;
        }

        .icon-arrows {
            font-size: 10px;
            margin-left: 5px;
            color: #9CA3AF;
        }

        .date {
            color: #6B7280;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
        }
    }

    &__hidden {
        display: none;
    }

    .order-summary {
        &__products {
            padding: 0;

            li {
                align-items: flex-start;

                figure {
                    margin-top: 15px;
                }
            }

            &__content {
                padding: 15px 0;

                &__name {
                    margin-top: -3px;
                }
            }
        }
    }

    &.active {
        .icon-arrows {
            transform: rotate(180deg);
        }

        .hidden-part {
            display: block;
        }
    }

    &:hover:not(.active) {
        background: #F9FAFB;
    }
}

.data-boxes-wrapper {
    border: 1px solid variables.$greyBg;
    border-radius: 8px;

    .content {
        position: relative;
        padding: 12px 8px 12px 12px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
        //display: flex;
        //align-items: center;
        border-bottom: 1px solid variables.$greyBg;

        &__top-part {
            display: flex;
            align-items: center;
            position: relative;
            padding: 20px;
            //@media(max-width: 480px) {
            //    flex-wrap: wrap;
            //}

            > input {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                opacity: 0;

                &:checked {
                    + .checkmark, + div .checkmark {
                        border-color: transparent;
                        background-color: #3C61EA;

                        &:after {
                            width: 5px;
                            height: 5px;
                        }
                    }
                }
            }

            figure {
                margin: 0;

                img {
                    height: 30px;
                }
            }

            img {
                height: 26px;
            }

            .checkmark {
                position: relative;
                height: 20px;
                width: 20px;
                min-width: 20px;
                border-radius: 50%;
                margin-right: 10px;
                margin-top: 1px;
                border: 1px solid variables.$greyBg;
                pointer-events: none;
                transition: all .2s ease-in-out;

                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: variables.$white;
                    width: 0;
                    height: 0;
                    border-radius: 50%;
                    transition: all .2s ease-in-out .1s;
                }
            }

            > i {
                color: #9CA3AF;
                font-size: 10px;
            }

            .right-place {
                font-size: 14px;
                font-weight: 500;
                color: variables.$greyColor;
            }

            &.pb-default {
                padding-bottom: 20px !important;
                @media(max-width: 480px) {
                    padding-bottom: 3.5rem !important;
                }
            }
        }

        .label {
            font-weight: bold;
            color: #101827;
        }

        .payment-img {
            @media(max-width: 767px) {
                height: 16px !important;
                position: absolute;
                right: 10px;
                top: 11px
            }
        }

        .data {
            color: variables.$greyColor;

            .popular-text {
                display: inline-block;
                color: #3C61EA;
                font-size: 10px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 12px;
                text-align: center;
                border-radius: 5px;
                background-color: #F0F4FF;
                padding: 4px 8px;
                margin-top: 8px;
                transition: all .2s ease-in-out;
                @media(max-width: 767px) {
                    text-align: left;
                    margin-top: 5px;
                }

                i {
                    position: relative;
                    top: 0.5px;
                    margin-right: 4px;
                    font-size: 12px;
                }

                &.grey {
                    background-color: #F3F4F6;
                    color: #6B7280;
                }
            }
        }

        > a {
            margin-left: auto;
        }

        &:last-of-type {
            border-bottom: 0;
        }

        &.radio-content {
            align-items: flex-start;
        }

        .bottom-part {
            display: none;
        }

        &.active {
            .bottom-part {
                display: block;
            }

            .data .popular-text {
                background: #f0f0f0;
            }
        }

        &.with-left-border {

            &.blue-border {
                border-left: 4px solid variables.$blue;
            }

            &.grey-border {
                border-left: 4px solid #E5E7EB;
            }
        }

        &.blue-border.active {
            .data {
                .popular-text {
                    background: #ecf2ff;
                }
            }
        }
    }

    &.popular-black-border {
        .content {
            &.blue-border {
                border-left-color: #000 !important;
            }

            &.active {
                box-shadow: 0 0 0 1px #000;
                border-color: #000 !important;
                background: transparent !important;
            }

            .data {
                .popular-text {
                    background: #F3F4F6 !important;
                    color: #191918 !important;
                }
            }

            input:checked + .checkmark {
                background: #000;
            }
        }
    }

    &.small-padd {
        .content {
            padding: 0;

            &__top-part {
                width: 100%;
                color: #101827;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 19px;
                padding: 15px;
                @media(max-width: 767px) {
                    padding: 12px 10px;
                    font-size: 12px;
                    line-height: 16px;
                    flex-wrap: nowrap;
                    .checkmark {
                        width: 18px;
                        height: 18px;
                        margin-right: 8px;
                        min-width: 18px;

                        &:after {
                            width: 3px;
                            height: 3px;
                        }
                    }
                }

                .data {
                    color: #6B7280;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: -0.09px;
                    line-height: 20px;
                    @media(max-width: 767px) {
                        font-size: 12px;
                        margin-top: 2px;
                        line-height: 16px;
                        letter-spacing: 0;
                    }
                }

                .price {
                    color: #1F2937;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 18px;
                    @media(max-width: 767px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                .button.text-only {
                    text-decoration: underline;
                    z-index: 1;
                    position: relative;
                }

                .switch-form {
                    position: static;
                    margin-top: 2px;

                    .switcher-wrapper {

                        .checkbox:checked {
                            ~ .switch:after {
                                left: 12px;
                            }
                        }

                        .switch {
                            height: 20px;
                            width: 30px;
                            min-width: auto;

                            &:after {
                                width: 16px;
                                height: 16px;

                            }
                        }
                    }
                }
            }

            &.with-info-box {

                .form-info-box {
                    margin: 0 12px 12px;
                    padding: 14px;
                    align-self: stretch;

                    .title {
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 0.1px;
                        line-height: 16px;
                    }

                    .desc {
                        font-size: 12px;
                        letter-spacing: 0.1px;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    &.extra-small-padd {
        .content {
            padding: 0;

            &__top-part {
                width: 100%;
                color: #101827;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 16px;
                padding: 10px;
            }
        }
    }

    &.inline {
        @apply flex space-x-3.5 border-0;

        @media(max-width: 370px) {
            flex-wrap: wrap;
        }

        > div {
            flex: 1;
            @media(max-width: 370px) {
                width: 100%;
            }

            .payment-content, .content {
                border: 1px solid #E5E7EB;
                border-radius: 5px;
            }
        }
    }

    &.grid {
        @apply space-x-0;
        display: grid;

        &.grid-col-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    &.single-option {
        > div {
            width: 100% !important;
            padding: 6px;
        }
    }

    &.separated {
        border: 0;
        border-radius: 0;

        .content {
            border: 1px solid #E5E7EB;
            border-radius: 8px;
            margin-bottom: 8px;
            transition: all .2s ease-in-out;

            &.active {
                border-color: #3C61EA;
                background-color: #FAFBFF;
                box-shadow: 0 0 0 1px #3C61EA;
            }
        }
    }

    &.big-size {
        .content {
            border-radius: 8px;
            border-color: #eff0f1;
            box-shadow: 0 0 0 1px #eff0f1;

            .label {
                color: #6B7280;
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;
                @media(max-width: 767px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            &.active {
                box-shadow: 0 0 0 1px #3C61EA;

                .label {
                    color: #1F2937;
                }
            }
        }
    }

    &.products-boxes {
        border: 0;
        border-radius: 0;

        .content {
            border: 1px solid #E5E7EB;
            border-radius: 5px;
            margin-bottom: 5px;
            transition: all .2s ease-in-out;

            input:checked {
                + .checkmark:after {
                    opacity: 1;
                    width: auto;
                    height: auto;
                }
            }

            .product-avatar {
                width: 32px;
                min-width: 32px;
                height: 32px;
                margin-right: 8px;
            }

            .data {
                color: #101827;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 15px;
            }

            .checkmark {
                width: 18px;
                height: 18px;
                min-width: 18px;


                &:after {
                    content: '\e921';
                    font-family: selmo;
                    background: transparent;
                    width: auto;
                    height: auto;
                    color: #fff;
                    font-size: 8px;
                    opacity: 0;
                    margin-top: -0.5px;
                    margin-left: 0.5px;
                }
            }

            &.active {
                border-color: #3C61EA;
                background-color: #F5F7FE;
            }

            &.disabled {
                .content__top-part {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.inputs-row {
    margin-top: 20px;
    flex-wrap: nowrap;
    align-items: center;
    @media (max-width: 576px) {
        flex-wrap: wrap;
    }

    .form-group {
        margin-bottom: 12px;
    }

    .product-input-col {
        min-width: 45%;
        @media (max-width: 576px) {
            width: 100%;
        }


        + > div {
            flex-grow: 1;
        }
    }

    > div {
        width: 30%;
        @media (max-width: 576px) {
            width: 50%;
        }
    }

    .button-col {
        flex-grow: 0;
        width: auto;

        &.tooltip-parent {
            .tooltip-content {
                @media(max-width: 767px) {
                    left: 0;
                    transform: translateX(0);
                    text-align: left;
                    &:after {
                        left: 15px;
                    }
                }
            }
        }
    }

    .remove-row-button {
        color: #9CA3AF;
        position: relative;
        top: 5px;

        span {
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;
            color: #6B7280;
            margin-left: 5px;
        }

        &:disabled {
            opacity: 0.6;
        }
    }

    &.sizes-row {
        @media(max-width: 991px) {
            margin-bottom: 20px;
        }

        > div {
            &:first-of-type {
                @media(max-width: 575px) {
                    width: 100%;
                }
            }
        }

        //.form-group {
        //	input::-webkit-outer-spin-button,
        //	input::-webkit-inner-spin-button {
        //		-webkit-appearance: none;
        //		margin: 0;
        //	}
        //
        //	input[type=number] {
        //		-moz-appearance: textfield;
        //	}
        //}
    }

    &.with-variant {
        > div {
            &.quantity-col {
                width: 15%;
                min-width: 42px;
            }

            &.product-input-col {
                flex-grow: 1;
            }
        }
    }
}

.manual-sizes-wrapper, .storage-sizes-wrapper {
    .inputs-row {
        border-bottom: 1px solid #F3F4F6;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}
