@use 'variables';

.dataTables_paginate {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 3rem;
	@media(max-width: 480px) {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.paginate_button {
		cursor: pointer;

		&.previous, &.next {
			color: variables.$greyColor;
			font-size: 10px;

			&:before {
				content: '\e903';
				display: inline-block;
				font-family: selmo;
				transform: rotate(-90deg);
			}
		}

		&.previous {
			margin-right: 16px;

			&:before {
				transform: rotate(90deg);
			}
		}

		&.next {
			margin-left: 16px;
		}
	}

	> span {
		display: flex;
		align-items: center;

		.paginate_button {
			display: flex;
			align-items: center;
			justify-content: center;
			color: variables.$greyColor;
			width: 32px;
			height: 32px;
			border-radius: 5px;
			font-size: 0.875rem;
			font-weight: 500;
			margin-right: 10px;

			&.current {
				color: variables.$darkColor;
				background-color: variables.$white;
				box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}

.react-pagination-wrapper {
	position: relative;
	min-height: 92px;
	@media(max-width: 480px) {
		margin-top: 20px;
		min-height: auto;
	}

	.pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 30px 0;
		margin-bottom: 0;
		@media(max-width: 480px) {
			padding-top: 0;
			padding-bottom: 15px;
		}

		li {
			button {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #6B7280;
				width: 32px;
				height: 32px;
				border-radius: 5px;
				font-size: 0.875rem;
				font-weight: 500;
				margin-right: 10px;
				text-decoration: none;
				background: transparent;
				border: 0;

				i {
					font-size: 10px;
					transform: rotate(90deg);
				}
			}

			&.active {
				a, button {
					color: #101827;
					background-color: #fff;
					box-shadow: 0 12px 16px rgba(0, 0, 0, .04), 0 1px 3px rgba(0, 0, 0, .05);

				}
			}

			&.next-button {
				button {
					i {
						transform: rotate(-90deg);
					}
				}
			}
		}
	}

	.items-per-page-select {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		font-size: 12px;
		color: #6B7280;
		@media(max-width: 991px) {
			position: static;
			transform: none;
			justify-content: center;
			margin-bottom: 15px;
		}

		#custom-dropdown-default {
			margin: 0 5px;
		}
	}
}

