@use '../../variables';

.login-page-wrapper {
    min-height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    -webkit-animation: fadein .5s;
    -moz-animation: fadein .5s;
    -ms-animation: fadein .5s;
    -o-animation: fadein .5s;
    animation: fadein .5s;
    @media(max-width: 991px) {
        padding-left: 20px;
        padding-right: 20px;
        min-height: 100%;
        flex-direction: column;
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .alert {
        background-color: rgba(247, 82, 114, 0.1);
        color: #f75272;
        padding: 10px;
        font-size: 14px;
        border-radius: 5px;
        margin-bottom: 8px;
        margin-top: 15px;
        font-weight: 500;

        .desc {
            margin-bottom: 0;
        }

        &.alert-success {
            background-color: rgba(33, 241, 68, 0.1);
            color: #26d008;
        }
    }

    .logo {
        display: block;
        text-align: center;
        margin-bottom: 90px;

        @media(max-width: 991px) {
            margin-bottom: 25px;
        }

        img {
            margin: 0 auto;
            width: 96px;
        }

        &.forget-logo {
            margin-bottom: 60px;
            @media(max-width: 480px) {
                margin-bottom: 30px;
            }
        }
    }

    .button-place {
        margin-top: 25px;

        &.forget-button-place {
            margin-top: 15px;
        }
    }

    .text-center {
        text-align: center;
    }

    .left-side {
        margin: auto;
        width: 360px;

        @media(max-width: 991px) {
            width: 100%;
        }

        .change-page {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #6B7280;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            text-align: center;
            margin-top: 25px;

            a {
                font-size: inherit;
                margin-left: 5px;
                font-weight: bold;
                color: #3C61EA;
                text-decoration: underline;
                transition: all .2s ease-in-out;

                &:hover {
                    color: #000;
                }
            }

            &.change-page-forget-password {
                margin-top: 80px;
            }
        }

        .small-description {
            color: #4B5563;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 30.8px;
            text-align: center;
            margin-top: 5px;
        }

        .action-buttons {
            margin-bottom: 80px;

            a {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                border: 1px solid #D1D5DB;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                padding: 10px;
                box-sizing: border-box;
                width: 100%;
                color: #6B7280;
                font-weight: bold;
                text-align: center;
                text-decoration: none;
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
                margin-bottom: 10px;


                i {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .text-separator {
            position: relative;
            height: 1px;
            width: 100%;
            background: #E5E7EB;
            margin: 25px 0;

            span {
                color: #9CA3AF;
                font-size: 10px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 12px;
                text-align: center;
                position: absolute;
                background: #fff;
                padding: 0 10px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .special-link {
            color: #9CA3AF;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            transition: all .2s;
            position: relative;
            text-decoration: underline;

            &:hover {
                color: #000;
            }
        }

        .top-label {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            height: 20px;

            .text {
                flex-grow: 1;
                color: #FFFFFF;
                font-size: 11px;
                font-weight: 600;
                letter-spacing: 1.38px;
                line-height: 14px;
                text-transform: uppercase;
            }

            .info-label {
                display: none;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;

                &.free {
                    display: block;
                    color: #3AEEAC;
                }

                &.taken {
                    display: block;
                    color: red;
                }
            }
        }

        .form-group {
            position: relative;
            margin-bottom: 8px;

            .control-label {
                display: block;
                color: variables.$greyColor;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;
                margin-bottom: 7px;
            }

            &.checkbox-form {
                display: flex;
                align-items: center;

                .control-label {
                    margin-bottom: 0;

                    a {
                        font-weight: bold;
                        color: #6B7280;
                        text-decoration: none;
                        position: relative;
                        z-index: 1;
                    }
                }

                .checkbox-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;

                    &:checked {
                        + .checkbox-checkmark {
                            background: #3C61EA;

                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus {
                -webkit-text-fill-color: #101827;
                //-webkit-box-shadow: 0 0 0 1000px #fff inset;
                transition: background-color 5000s ease-in-out 0s;
            }
        }

        .bottom-small-text {
            color: #9CA3AF;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0.22px;
            line-height: 16px;
            margin-top: 150px;
            text-align: center;
        }

        &.connect-ig {
            margin: auto;
            width: auto;
            flex-grow: 1;
            background: #eff2f6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media(max-width: 991px) {
                margin-left: -20px;
                margin-right: -20px;
            }
        }
    }

    .right-side {
        flex-grow: 1;
        background: #C9EDFF;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 100px;
        color: #fff;
        @media(max-width: 991px) {
            display: none;
        }

        .right-side-container {
            width: 700px;
            height: 700px;
            margin: 50px 0;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .go-back-link {
                position: absolute;
                right: 0;
                top: 0;
            }

            .slider-box {
                width: 100%;

                .photo {
                    width: 100%;
                    height: 591px;
                    background-size: 60% !important;
                    background-position: center !important;
                }

                .name-wrapper {
                    text-align: center;

                    .main-text {
                        color: #101827;
                        font-size: 38px;
                        font-weight: bold;
                        letter-spacing: -0.5px;
                        line-height: 30.8px;
                        text-align: center;
                    }

                    .bottom-description {
                        color: #4B5563;
                        font-size: 22px;
                        font-weight: 400;
                        letter-spacing: -0.5px;
                        line-height: 30.8px;
                        text-align: center;
                        margin-top: 15px;
                    }
                }
            }
        }

        .go-back-link {
            border: 1.5px solid #444759;
            height: 28px;
            width: 28px;
            display: flex;
            align-items: center;
            border-radius: 50%;
            margin-left: 10px;
            transition: all .2s ease-in-out;

            &:before, &:after {
                content: "";
                height: 14px;
                width: 1.5px;
                position: absolute;
                left: 50%;
                top: 50%;
                background: #444759;
            }

            &:before {
                transform: translate(-50%, -50%) rotate(223deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(138deg);
            }

            &:hover {
                border-color: #fff;
                cursor: pointer;

                &:before, &:after {
                    background: #fff;
                }
            }
        }
    }
}
