.knowledge-base-page {
	.main-container {
		max-width: 100%;
		margin: 0;
	}

	.knowledge-base-hello-page {
		max-width: 920px;
		margin-left: auto;
		margin-right: auto;

		h1 {
			color: #101827;
			font-size: 32px;
			font-weight: bold;
			letter-spacing: -0.2px;
			line-height: 28px;
			@media(max-width: 767px) {
				font-size: 24px;
			}
		}

		p {
			color: #6B7280;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 23px;
		}

		.intro-img {
			margin: 20px 0 30px;

			img {
				border-radius: 8px;
			}
		}

		.boxes-wrapper {
			.box-with-border {
				border: 1px solid #DFE1E4;
				border-radius: 5px;
				display: flex;
				align-items: center;
				padding: 18px 28px;
				cursor: pointer;
				margin-bottom: 15px;
				transition: all .2s ease 0s;
				@media(max-width: 991px) {
					padding: 16px;
				}

				.title {
					color: #101827;
					font-size: 18px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 28px;
				}

				.desc {
					color: #6B7280;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 23px;
					@media(max-width: 991px) {
						line-height: 20px;
					}
				}

				.button-place {
					margin-left: auto;
					@media(max-width: 991px) {
						align-self: flex-end;
					}

					.button {
						border: 0;
						padding: 0;
						color: #6B7280;
						font-size: 14px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 18px;
						text-align: right;

						i {
							font-size: 9px;
							color: #6B7280;
							position: relative;
							top: -1px;
						}
					}
				}

				&:hover {
					text-decoration: none;
					box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);

				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		.contact-boxes {
			margin-top: 20px;

			a {
				display: block;
				padding: 15px;
				border: 1px solid #E5E7EB;
				border-radius: 8px;
				color: #101827;
				font-size: 14px;
				font-weight: bold;
				letter-spacing: -0.2px;
				line-height: 18px;
				margin-bottom: 12px;
				white-space: nowrap;
				transition: all .2s ease 0s;

				i {
					margin-right: 10px;
					color: #3C61EA;

					&.icon-messenger-2-f, &.icon-tel {
						font-size: 16px;
					}
				}

				&:hover {
					text-decoration: none;
					box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);
				}

				&:last-of-type {
					margin-bottom: 0;
				}

			}
		}
	}
}

.knowledge-base-wrapper {
	min-height: 100vh;
	background: #fff;

	nav {
		position: sticky;
		top: 0;
		background: rgba(#fff, 0.75);
		backdrop-filter: blur(8px);
		z-index: 9;
		box-shadow: 0 2px 4px rgba(#000, 0.01);
		border-bottom: 1px solid #E5E7EB;
		display: flex;
		align-items: center;
		padding: 1rem;
		@media(max-width: 991px) {
			backdrop-filter: none;
			background: #fff;
			justify-content: space-between;
		}

		.knowledge-base-label {
			border: 1px solid #5FA5FA;
			border-radius: 3px;
			color: #5FA5FA;
			font-size: 10px;
			font-weight: bold;
			letter-spacing: 0.5px;
			line-height: 16px;
			text-transform: uppercase;
			padding: 2px 6px;
			margin-left: 20px;
			text-decoration: none;
			@media(max-width: 991px) {
				margin-left: 10px;
				margin-top: 4px;
			}
		}

		.brand-name {
			display: block;

			img {
				width: 110px;
				@media(max-width: 767px) {
					width: 85px;
				}
			}
		}

		.form-group {
			@media(max-width: 991px) {
				order: 3;
				width: 16px;
			}

			.form-control {
				border: 0;
				background-color: #F3F4F6;
				width: auto;
				min-width: 200px;
				color: #9CA3AF;
				@media(max-width: 991px) {
					display: none;
				}
			}

			i {
				color: #9CA3AF;
				@media(max-width: 991px) {
					position: static;
				}
			}
		}

		.hamburger-btn {
			.bar1, .bar2, .bar3 {
				width: 18px;
				height: 2px;
				background-color: #6B7280;
				margin: 5px 0;
				transition: 0.4s;
			}

			@media (min-width: 991px) {
				display: none;
			}
		}
	}

	.main-aside {
		@media(min-width: 991px) {
			width: 20rem;
			border-right: 1px solid #E5E7EB;
		}
		@media(max-width: 991px) {
			margin-left: 0;
			margin-right: 0;
		}

		> div {
			@media(min-width: 991px) {
				top: 66px;
				overflow-y: auto;
				padding: 1rem;
				width: 100%;
				max-height: calc(100vh - 66px);

			}

			button.menu-name {
				color: #6B7280;
				font-weight: 600;
				justify-content: normal;
				margin-bottom: 0;
				transition: color 0.2s ease 0s;
				@media(max-width: 480px) {
					padding-left: 0;
					padding-right: 0;
				}

				&:before {
					content: "";
					border-top: 4px solid transparent;
					border-bottom: 4px solid transparent;
					border-left: 7px solid #D1D5DB;
					margin-right: 10px;
					border-radius: 3px;
					transition: transform 0.2s ease 0s;
				}

				&.active {
					color: #718096;
					background: transparent;

					&:before {
						position: relative;
						transform: rotate(90deg);
					}
				}

				&:hover {
					color: #101827;
					background: transparent;
				}
			}


			> ul {
				top: 59px;
				left: 0;
				right: 0;
				@media(max-width: 991px) {
					bottom: 0;
					padding: 10px 16px;
				}

				li {
					a {
						transition: color 0.2s ease 0s;
						line-height: 18px;
						font-weight: 600;
						color: #697280;

						&:before {
							content: "";
							width: 3px;
							min-width: 3px;
							height: 3px;
							margin-right: 10px;
							border-radius: 100%;
							background: #D1D5DB;
						}

						&:hover {
							background: transparent;
							color: #101827;
						}

						&.active {
							color: #101827;

							&:before {
								background: #000;
							}
						}
					}
				}
			}
		}
	}

	.knowledge-base-container {
		width: 100%;
		padding-top: 3rem;
		@media(min-width: 1280px) {
			padding-right: 3rem;
			padding-left: 3rem;
		}
		@media(min-width: 767px) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		@media(max-width: 991px) {
			padding: 30px 16px;
		}

		> main {
			@media(min-width: 1280px) {
				max-width: 700px;
				width: 100%;
				margin: 0 auto;
				position: relative;
				padding: 0 15px 100px;
			}

			.post-container {
				font-size: 1em;
				line-height: 1.5rem;
				color: #101827;

				.go-back {
					color: #6b7280;
					font-size: 14px;
					font-weight: 700;
					letter-spacing: -.14px;
					line-height: 20px;
					position: absolute;
					top: -35px;
					left: -90px;
					transition: all .2s ease;
					@media(max-width: 991px) {
						left: 0;
					}

					i {
						font-size: 10px;
						position: relative;
						top: -1px;
						margin-right: 8px;
					}

					&:hover {
						color: #101827;
						text-decoration: none;
					}
				}

				h1, h2 {
					color: #101827;
					font-size: 32px;
					font-weight: 700;
					letter-spacing: -.2px;
					line-height: 41px;
					margin-top: 35px;
					@media(max-width: 480px) {
						font-size: 27px;
						line-height: 32px;
						margin-top: 30px;
						margin-bottom: 0;
						font-weight: 700;
						letter-spacing: -.2px;
					}
				}

				.date {
					color: #6b7280;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: -.2px;
					line-height: 24px;
					margin: 30px 0 45px;
					@media(max-width: 480px) {
						margin-bottom: 25px;
						margin-top: 15px;
					}
				}

				h3 {
					color: #101827;
					font-size: 1.5em;
					margin-top: 1.1em;
					font-weight: 600;
					line-height: 1.3;
					@media(max-width: 488px) {
						font-size: 22px;
						line-height: 26px;
					}
				}


				h4, h5 {
					font-weight: 500;
				}

				h4 {
					font-size: .75rem;
					line-height: 1rem;
				}

				p {
					color: #1f2937;
					font-size: 17px;
					font-weight: 500;
					letter-spacing: -.1px;
					line-height: 32px;
					@media(max-width: 480px) {
						font-size: 16px;
						line-height: 28px;
					}

					&.small {
						font-size: .875rem;
						line-height: 1.625rem;
					}

					img {
						display: block;
						width: 100%;
						border-radius: 5px;
					}
				}

				figure {
					margin: 50px 0 40px;
					display: block;
					font-size: 0.875rem;
					border-radius: 8px;
					@media(max-width: 480px) {
						border-radius: 5px;
					}

					img {
						display: block;
						width: 100%;
						margin-bottom: 10px;
						border-radius: 8px;
						@media(max-width: 480px) {
							border-radius: 5px;
						}
					}

					&.media {
						position: relative;
						height: 0;
						padding-bottom: 56.2493%;

						oembed {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
						}

						iframe {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							border-radius: 8px;
							overflow: hidden;
							@media(max-width: 480px) {
								border-radius: 5px;
							}
						}
					}
				}

				.table {
					margin: 0;
					width: 100%;
					font-size: 1rem;

					table {
						width: 100%;

						tr {
							td {
								border-left: 1px solid #EDF2F7FF;

								&:last-of-type {
									border-right: 1px solid #EDF2F7FF;
								}
							}

							&:last-of-type {
								td {
									border-bottom: 1px solid #EDF2F7FF;
								}
							}
						}
					}
				}

				ul {
					padding-left: 25px;
					list-style: disc;
				}
			}
		}
	}
}
