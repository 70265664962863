@use '../variables';
.help-wrapper {
	max-width: 600px;
	margin: 0 auto;

	h1 {
		color: #101827;
		font-size: 28px;
		font-weight: 600;
		letter-spacing: -0.31px;
		line-height: 43px;
		margin-bottom: 0;
	}

	&__description {
		color: #6B7280;
		font-size: 15px;
		letter-spacing: 0;
		line-height: 23px;
	}

	&__email-box {
		height: 48px;
		border-radius: 5px;
		background-color: #FFFFFF;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
		display: flex;
		align-items: center;
		justify-content: center;
		color: #3B82F6;
		font-size: 15px;
		letter-spacing: 0;
		line-height: 18px;
		margin: 25px 0;
	}

	.email-box-wrapper {
		border-bottom: 1px solid #D1D5DB;
		margin-bottom: 25px;
	}

	.faq-section {
		margin: 0 -15px;

		h1 {
			color: #101827;
			font-size: 22px;
			font-weight: 600;
			letter-spacing: -0.31px;
			line-height: 26px;
			margin-bottom: 15px;
		}

		.faq-box {
			&__title {
				color: #101827;
				font-size: 18px;
				font-weight: 600;
				letter-spacing: -0.24px;
				line-height: 21px;
			}

			&__description {
				color: #6B7280;
				font-size: 15px;
				letter-spacing: 0;
				line-height: 23px;
				@media (max-width: 480px) {
					font-size: 14px;
				}
			}
		}
	}
}

.faq-section {

	.faq-box {
		border-bottom: 1px solid variables.$greyBg;
		padding: 20px 0;

		@media(max-width: 767px) {
			padding: 25px 0;
		}

		&:last-of-type {
			padding-bottom: 0;
			border-bottom: 0;
		}

		&__title {
			color: #101827;
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0;
			position: relative;
			padding-right: 35px;

			&:after {
				content: '\e903';
				font-family: 'selmo';
				position: absolute;
				color: #D1D5DB;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				font-size: 11px;
				transition: all .3s ease-in-out;
			}

			@media(max-width: 767px) {
				font-size: 15px;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 20px;
			}
		}

		&__description {
			color: #6B7280;
			font-size: 18px;
			letter-spacing: 0;
			line-height: 34px;
			display: none;
			margin-top: 15px;
			cursor: default;

			@media(max-width: 767px) {
				font-size: 15px;
				letter-spacing: 0;
				line-height: 22.76px;
			}
		}

		&.active {
			.faq-box__description {
				display: block;
			}

			.faq-box__title {
				&:after {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}

		&:first-of-type {
			padding-top: 0;
		}

		&:hover {
			cursor: pointer;
		}
	}


	.faq-box {
		&__title {
			color: #101827;
			font-size: 16px;
			font-weight: bold;
			line-height: 21px;
		}

		&__description {
			color: #6B7280;
			font-size: 16px;
			letter-spacing: 0;
			line-height: 32px;
			font-weight: 400;
			@media (max-width: 480px) {
				font-size: 14px;
				line-height: 22px;
			}
		}
	}
}
