@use 'variables';
@use 'mixins';

.create-order-modal {
    .modal-body {
        padding: 25px;
    }

    .modal-header {
        padding: 15px 25px;

        h5 {
            color: #101827;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 31px;
        }
    }

    .modal-footer {
        border-top: 0;
        padding-bottom: 35px;

        .btn {
            height: 54px;
            width: 176px;
            border-radius: 10px;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 23px;
            margin: 0 auto;
            padding-bottom: 3px;
        }
    }

    section {
        margin-bottom: 25px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .form-group {
        margin-bottom: 10px;
    }

    .section-title {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .icon {
            height: 25px;
            width: 25px;
            border: 1.38px solid #d1d5db;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: -0.14px;
            line-height: 20px;
            margin-right: 10px;
            border-radius: 50%;
        }

        .text {
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 23px;
            flex-grow: 1;

            @media (max-width: 767px) {
                font-size: 13px;
                line-height: 17px;
            }
        }

        .btn {
            height: 34px;
            width: 67px;
            border-radius: 5px;
            @media (max-width: 767px) {
                font-size: 12px;
                width: 55px;
                margin-left: 10px;
            }
        }
    }

    .flex-row {
        flex-wrap: wrap;
        margin: -10px;

        & > div {
            width: 50%;
            padding: 5px 10px;

            &.full-width {
                width: 100%;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &.hidden-part {
            display: none;

            &.active {
                display: flex;
            }
        }
    }

    .form-group {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;

        label {
            color: #101827;
            font-size: 13px;
            letter-spacing: 0;
            font-weight: 400;
            line-height: 14px;
            margin-bottom: 5px;
        }

        .form-control {
            border: 1px solid #d1d5db;
            min-height: 49px;
            height: auto;
            box-shadow: none;
            padding-bottom: 3px;
            color: variables.$darkColor;
        }

        textarea.form-control {
            padding: 10px 15px;
        }
    }
}

.tutorial-video-modal {
    max-width: none;
    margin: 0;
    height: 100%;
    padding: 100px;

    .video-wrapper {
        height: 100% !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: auto;

        video,
        iframe {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    ~ .btn-close {
        cursor: pointer;
        position: absolute;
        top: 50px;
        right: 50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #000;
        z-index: 1;
    }
}

.modal-backdrop {
    background: #b2b8c0;

    &.show {
        opacity: 0.9;
    }
}

.modal {
    &.align-bottom {
        @media (max-width: 991px) {
            height: auto;
            bottom: 0;
            top: auto;
            .modal-dialog {
                border-radius: 0;
            }
        }
    }

    .modal-dialog {
        &.default-modal {
            max-width: 580px;
            @media (max-width: 576px) {
                margin: 0;
            }

            .modal-content {
                background: #fff;
                border-radius: 8px;

                &.grey-bg {
                    background: #f9fafb;
                }

                &.rounded-xl {
                    border-radius: 16px;
                    @media (max-width: 480px) {
                        border-radius: 0;
                    }
                }
            }

            &.small-modal {
                max-width: 360px;

                @media (max-width: 576px) {
                    margin: 15px auto;
                }

                .data-boxes-wrapper {
                    .content {
                        .label {
                            pointer-events: none;
                            position: relative;
                            top: 2px;
                            color: #1f2937;
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 18px;
                        }
                    }
                }

                .modal-header {
                    align-items: flex-start !important;
                    padding-top: 25px !important;

                    .modal-title {
                        &.h4 {
                            color: #1f2937;
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 21px;
                        }

                        .description {
                            color: #6b7280;
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 18px;
                        }
                    }
                }

                .modal-body {
                    padding: 15px 25px !important;
                }

                .modal-footer {
                    .button {
                        width: 120px;

                        &.border-button {
                            margin-right: 10px !important;
                            @media (max-width: 480px) {
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
            }

            &.preview-img-modal {
                max-width: 700px;
                width: 100%;

                @media (max-width: 767px) {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &.preview-video-modal {
                height: 100%;
                display: flex;
                margin: auto !important;

                .modal-content {
                    margin: auto;
                }
            }

            &.w-380 {
                max-width: 380px;
            }

            &.w-400 {
                max-width: 400px;
            }

            &.w-420 {
                max-width: 420px;
            }

            &.w-450 {
                max-width: 450px;
            }

            &.w-600 {
                max-width: 600px;
            }

            &.w-650 {
                max-width: 650px;
            }

            &.w-850 {
                max-width: 850px;
            }

            &.w-900 {
                max-width: 900px;
            }

            &.medium-modal {
                max-width: 550px;
            }

            &.big-modal {
                max-width: 750px;
            }

            &.huge-modal {
                max-width: 920px;
            }

            &.sm-size {
                max-width: 480px;
            }

            .modal-content {
                border: 0;

                .modal-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 0;
                    padding: 35px 25px 5px 25px;

                    .sub-title {
                        color: #6b7280;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 18px;
                    }

                    .small-subtitle {
                        color: #101827;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 15px;
                    }

                    .modal-title {
                        color: #1f2937;
                        font-size: 20px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 25px;
                    }

                    .mobile-close-modal {
                        display: none;
                        @media (max-width: 576px) {
                            display: block;
                        }
                    }

                    .tabs-switcher {
                        @media (max-width: 576px) {
                            width: 100%;
                            margin-bottom: 0 !important;
                            margin-top: 15px;
                            li {
                                width: 50%;

                                > button {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    > button {
                        color: #6b7280;
                        font-size: 9px;
                        margin-left: auto;
                        @media (max-width: 991px) {
                            font-size: 14px;
                        }
                    }

                    .brand-logo {
                        width: 86px;
                        margin-bottom: 8px;

                        img {
                            width: 100%;
                        }
                    }

                    &.with-border {
                        border-bottom: 1px solid #f3f4f6;
                        padding: 25px;
                    }

                    &.with-tabs {
                        display: block;
                        padding-top: 22px;

                        .modal-title {
                            color: #101827;
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 21px;
                            margin-bottom: 8px;
                        }

                        .tabs-wrapper {
                            display: flex;
                            margin-left: -25px;
                            margin-right: -25px;

                            button {
                                margin: 0 16px;
                                padding: 12px 0;

                                &:first-of-type {
                                    margin-left: 25px;
                                }
                            }
                        }

                        > .button {
                            position: absolute;
                            top: 22px;
                            right: 25px;
                        }
                    }
                }

                .modal-body {
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-bottom: 40px;
                    word-break: break-word;

                    .info-part {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 50px;

                        .icon-box {
                            height: 90px;
                            width: 90px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        h2 {
                            color: #101827;
                            font-size: 20px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 25px;
                            text-align: center;
                            margin-bottom: 5px;
                        }

                        .small-description {
                            color: #9ca3af;
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 18px;
                            text-align: center;
                        }
                    }

                    .orders-labels {
                        .labels-header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .labels-title {
                                color: #101827;
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 19px;
                            }
                        }

                        .modal-footer {
                            margin-left: -25px;
                            margin-right: -25px;
                        }
                    }

                    .subtitle {
                        color: #1f2937;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: -0.2px;
                        line-height: 24px;
                        margin-bottom: 14px;
                    }

                    .bottom-total-price {
                        color: #6b7280;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 21px;
                        text-align: right;
                        text-align: right;
                        margin-bottom: -10px;
                        margin-top: 40px;

                        span {
                            font-weight: bold;
                            color: #101827;
                        }
                    }

                    .data-boxes-wrapper:not(.small-padd):not(
                            .extra-small-padd
                        ) {
                        .content {
                            padding: 0;

                            &__top-part {
                                align-items: flex-start;
                                width: 100%;
                            }
                        }
                    }

                    .select-size-wrapper {
                        .product-details {
                            display: flex;
                            align-items: center;
                            margin-bottom: 15px;

                            figure {
                                height: 36px;
                                width: 36px;
                                min-width: 36px;
                                margin-right: 8px;
                                margin-bottom: 0;
                                border-radius: 3px;
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .product-name {
                                color: #101827;
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: -0.1px;
                                line-height: 18px;
                            }

                            .code {
                                color: #6b7280;
                                font-size: 12px;
                                letter-spacing: -0.12px;
                                line-height: 15px;
                            }
                        }

                        .sizes-wrapper {
                            .size-box {
                                display: flex;
                                align-items: center;
                                border: 1px solid #e5e7eb;
                                border-radius: 5px;
                                color: #1f2937;
                                font-size: 14px;
                                font-weight: bold;
                                letter-spacing: -0.16px;
                                line-height: 18px;
                                padding: 13px 15px;
                                background: transparent;
                                margin: 0 0 6px;
                                width: 100%;
                                transition: all 0.2s ease-in-out;

                                .count {
                                    margin-left: auto;
                                    color: #1f2937;
                                    font-size: 12px;
                                    font-weight: 500;
                                    letter-spacing: -0.13px;
                                    line-height: 15px;
                                }

                                .price {
                                    text-align: right;
                                    color: #101827;
                                    font-size: 12px;
                                    font-weight: 500;
                                    letter-spacing: -0.13px;
                                    line-height: 15px;
                                    width: 40px;
                                    margin-left: 5px;
                                    white-space: nowrap;
                                }

                                &.active:not(:disabled) {
                                    border: 1px solid #3c61ea;
                                    background-color: #f5f7fe;
                                }

                                &:disabled {
                                    opacity: 0.5;
                                    cursor: not-allowed;
                                }
                            }
                            &.upsell-sizes-wrapper {
                                .size-box {
                                    &:hover:not(:disabled) {
                                        border: 1px solid #3C61EA;
                                        background-color: #F5F7FE;
                                    }
                                }
                            }
                        }
                    }

                    .inputs-row {
                        .product-input-col {
                            width: 54%;
                            min-width: 54%;

                            .form-group {
                                &.storage-product {
                                    figure {
                                        height: 30px;
                                        width: 30px;
                                    }

                                    .form-control {
                                        padding-left: 44px !important;
                                        font-size: 12px;
                                    }

                                    .small-label {
                                        left: 44px;
                                        bottom: 8px;
                                    }
                                }
                            }
                        }

                        > div {
                            flex-grow: 1;
                        }

                        .button-col {
                            .remove-row-button {
                                top: 4px;
                                font-size: 12px;
                            }

                            &.remove-bin-col {
                                top: 0;

                                .remove-row-button {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    &.add-order-modal-body {
                        .flex-row-top {
                            display: flex;
                            margin: 0 -10px;

                            @media (max-width: 480px) {
                                margin: 0 -5px;
                            }

                            & > div {
                                width: 50%;
                                padding: 0 10px;
                                @media (max-width: 480px) {
                                    padding: 0 5px;
                                }
                            }
                        }

                        .user-data-row {
                            display: flex;
                            margin: 0 - 5px;

                            & > div {
                                width: 50%;
                                padding: 0 5px;
                            }
                        }

                        .inputs-row {
                            margin-top: 5px;
                        }
                    }

                    &.functional-payments-modal-body {
                        .payments-container {
                            > div {
                                margin-top: 30px;
                            }
                        }

                        .submit-place {
                            .button {
                                padding-top: 18px;
                                padding-bottom: 18px;
                                font-size: 14px;
                            }
                        }
                    }

                    &.defined-variants-modal-body {
                        .react-tag-container {
                            margin: 0;

                            .react-tags-wrapper {
                                position: static;

                                .ReactTags__selected {
                                    background: transparent;
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    &.add-product-modal-body {
                        .form-group {
                            &.code-form {
                                max-width: none;
                            }

                            &.with-group-buttons {
                                .form-control {
                                    padding-right: 90px;
                                }

                                .copy-btn {
                                    right: 5px;
                                }
                            }

                            &.switch-form {
                                margin-top: 15px;
                            }
                        }

                        .sizes-wrapper {
                            .sizes-wrapper-inputs {
                                .inputs-row {
                                    margin-left: -3px;
                                    margin-right: -3px;
                                    margin-top: 10px;

                                    > div {
                                        padding-left: 3px;
                                        padding-right: 3px;

                                        .form-group {
                                            margin-bottom: 0;

                                            .form-control {
                                                padding-left: 8px;
                                                padding-right: 8px;
                                            }

                                            .input-addon {
                                                display: none;
                                            }

                                            &.form-group-addon {
                                                .form-control {
                                                    min-width: auto;
                                                }
                                            }
                                        }
                                    }

                                    .price-col {
                                        max-width: 90px;
                                    }

                                    .name-col {
                                        width: 25%;
                                        flex-grow: 1;
                                    }

                                    .code-col {
                                        min-width: auto;
                                        width: 39%;
                                    }

                                    .remove-bin-col {
                                        .remove-row-button {
                                            top: 10px;
                                        }
                                    }

                                    .squ-col {
                                        max-width: 70px;
                                    }
                                }
                            }
                        }
                    }

                    &.assign-order-modal-body {
                        .form-group {
                            .form-control,
                            #custom-dropdown-default [class*='-control'] {
                                min-height: 40px;

                                [class*='-placeholder'] {
                                    color: #1f2937;
                                }

                                .remove-product {
                                    bottom: auto;
                                    top: 31px;
                                }
                            }

                            &.with-client-number .form-control {
                                padding-bottom: 15px;
                            }

                            .remove-product {
                                bottom: 9px;
                            }

                            > .user-avatar {
                                bottom: 9px;
                            }

                            .client-number {
                                bottom: 6px;
                            }

                            &.with-error-alert {
                                > .user-avatar {
                                    top: 31px;
                                    bottom: auto;
                                }
                            }
                        }

                        .with-arrow-bottom {
                            position: relative;
                            margin-bottom: 35px;

                            .icon-arrow-left {
                                color: #9ca3af;
                                font-size: 8px;
                                position: absolute;
                                top: calc(100% + 15px);
                                left: 50%;
                                transform: translateX(-50%) rotate(-90deg);
                            }
                        }
                    }

                    .search-place {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #edf2f7ff;
                        padding: 15px 25px;
                        @media (max-width: 480px) {
                            padding: 15px;
                        }

                        .button {
                            color: #6b7280;
                            font-size: 9px;
                            margin-left: 10px;
                        }

                        .form-group {
                            flex-grow: 1;

                            .form-control {
                                width: 100%;
                                background: transparent;
                                padding-left: 25px;
                            }

                            i {
                                left: 0;
                            }
                        }
                    }

                    .list-place {
                        padding: 20px 25px;
                        max-height: 500px;
                        overflow-y: auto;
                        @include mixins.custom-scrollbar;
                        @media (max-width: 480px) {
                            padding: 15px;
                        }

                        &::-webkit-scrollbar {
                            height: 7px;
                            width: 7px;
                        }

                        .result {
                            margin-bottom: 10px;
                            transition: all 0.2s ease-in-out;
                            padding: 8px 15px;
                            margin-left: -15px;
                            margin-right: -15px;
                            border-radius: 8px;
                            display: block;
                            @media (max-width: 480px) {
                                margin-bottom: 5px;
                            }

                            .selected {
                                color: #3c61ea;
                                text-decoration: underline;
                            }

                            .title {
                                color: #1f2937;
                            }

                            .desc {
                                font-size: 14px;
                                color: #6b7280;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;

                                h1,
                                h2,
                                h3,
                                p {
                                    font-size: inherit;
                                    font-weight: normal;
                                }

                                p {
                                    margin-bottom: 0;
                                }
                            }

                            figure,
                            img,
                            ul,
                            ol,
                            table {
                                display: none;
                            }

                            &:hover {
                                text-decoration: none;
                                background: #f7fafc;
                            }
                        }

                        &.empty-results {
                            padding: 35px 25px;
                            color: #6b7280;
                            font-size: 14px;
                            text-align: center;
                        }
                    }

                    &.assign-products-body {
                        .search-form {
                            input.form-control {
                                width: 100%;
                            }
                        }
                    }

                    &.delivery-address-form-modal {
                        .form-group {
                            .floated-label.selected {
                                top: 8px;
                            }

                            .form-control {
                                height: 50px;
                            }
                        }
                    }
                }

                .modal-footer {
                    border-radius: 0 0 5px 5px;
                    background-color: #f3f4f6;
                    padding: 20px 25px;
                    border: 0;

                    .button {
                        margin: 0;
                        @media (max-width: 480px) {
                            width: 100%;
                            text-align: center;
                            justify-content: center;
                        }

                        &.border-button {
                            padding: 12px 41px;
                            background: white;
                            margin-right: 15px;
                            @media (max-width: 480px) {
                                order: 2;
                                margin-top: 10px;
                                margin-right: 0;
                                width: 100%;
                            }
                        }
                    }

                    &.btn-submit-trick {
                        .border-button {
                            @media (min-width: 480px) {
                                order: 1;
                            }
                        }

                        .primary {
                            order: 2;
                            @media (min-width: 480px) {
                                order: 2;
                            }
                        }
                    }

                    &.open-cart-footer-modal {
                        @media (max-width: 480px) {
                            .button {
                                width: 100% !important;
                                margin-bottom: 15px !important;
                                margin-left: 0 !important;
                                margin-right: 0 !important;

                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }

                .summary-generated-view {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 25px;

                    .close-modal {
                        position: absolute;
                        top: 22px;
                        right: 25px;
                        color: #6b7280;
                        font-size: 9px;
                    }

                    .circle-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        height: 50px;
                        width: 50px;
                        background-color: #e2faf1;
                        color: #3cd9a0;
                        margin-bottom: 7px;
                        margin-top: 30px;

                        &.red {
                            color: #e64747;
                            background: #fdf0f0;
                        }
                    }

                    .title {
                        color: #101827;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 23px;
                        text-align: center;
                        margin-bottom: 2px;
                    }

                    .desc {
                        color: #6b7280;
                        font-size: 14px;
                        letter-spacing: -0.2px;
                        line-height: 19px;
                        text-align: center;
                    }

                    .modal-footer {
                        display: block;
                        background: transparent;
                        border: 0;
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        margin-top: 20px;

                        > button {
                            display: block;
                            width: 100%;
                            margin-top: 5px;
                        }
                    }
                }
            }
            &.black-modal {
                .modal-content {
                    background: #161617;
                }
            }

            &.full-black-modal {
                .modal-content {
                    background: #000;
                }
            }
        }

        &.attention-basket-modal {
            @media (max-width: 767px) {
                height: 100%;
            }

            .modal-content {
                @media (max-width: 767px) {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .modal-header {
                    @media (max-width: 767px) {
                        padding-top: 0;
                    }

                    .button {
                        position: absolute;
                        top: 1.5rem;
                        right: 1.5rem;
                    }

                    .modal-title {
                        @media (max-width: 767px) {
                            text-align: center;
                        }

                        .title {
                            span {
                                font-weight: normal;
                                display: block;
                            }
                        }
                    }
                }

                .modal-body {
                    @media (max-width: 767px) {
                        flex-grow: 0;
                    }

                    .form-group {
                        .form-control {
                            align-items: normal;
                            background-color: #f7f9fe;
                            color: #6b7280;
                            border-color: #3c61ea;
                            border-width: 2px;
                            height: 58px;
                            border-radius: 8px;
                            padding: 10px !important;

                            .short-link {
                                display: flex;
                                align-items: center;
                            }

                            .button {
                                padding-left: 12px;
                                padding-right: 12px;
                                font-size: 14px;
                            }
                        }
                    }
                }

                .modal-footer {
                    @media (max-width: 767px) {
                        background-color: transparent !important;
                        padding-top: 0;
                    }

                    .button.primary {
                        @media (max-width: 767px) {
                            min-height: 54px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        &.gallery-modal {
            .modal-footer {
                .primary {
                    width: 120px;
                }
            }
        }

        &.video-tutorial-square {
            max-width: 40vw;
            @media (max-width: 767px) {
                max-width: 90vw;
                margin: auto;
            }

            .modal-content {
                border-radius: 10px;
                overflow: hidden;
            }
        }

        &.selmo-delete-modal {
            max-width: 450px;
            @media (max-width: 480px) {
                width: 100%;
                margin: 0;
            }

            .modal-content {
                .modal-header {
                    align-items: flex-start;

                    .modal-title {
                        @media (max-width: 480px) {
                            margin-right: 10px;
                        }
                    }

                    .button {
                        @media (max-width: 480px) {
                            position: absolute;
                            top: 34px;
                            right: 25px;
                        }
                    }
                }

                .modal-header {
                    padding-top: 25px;
                }

                .modal-footer {
                    .button {
                        width: 120px;

                        &.border-button {
                            @media (max-width: 480px) {
                                order: 1;
                                margin-top: 0;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }

            .modal-body {
                padding-top: 0;
                color: #4b5563;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 22px;
            }
        }
    }

    @media (max-width: 480px) {
        .full-height-mobile {
            height: 100%;
            margin: 0 !important;

            .modal-content {
                height: 100%;
            }
        }
    }

    @media (max-width: 480px) {
        .center-mobile {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    @media (max-width: 480px) {
        &.full-height-mobile {
            height: 100%;
            background: #fff;
        }
    }

    &.higher-index {
        z-index: 1055;
    }
}

.add-order-modal-backdrop {
    &.with-extra-modal {
        z-index: 1055;
    }
}

.modal-backdrop {
    &.higher-index {
        z-index: 1055;
    }
}
