.root {
  @apply sm:py-6 py-4 mb-2 px-5 sm:px-7 flex flex-col outline-none rounded-[15px] sm:rounded-[25px] transition-all duration-300;
}

.borderRoot {
  @apply border rounded-[5px] p-5;
}

.smallSize {
  @apply p-4 rounded-[10px] mb-1;
}

.activeRoot {
  @apply bg-[#F3F4F6];
}

.light.activeRoot {
  @apply bg-[#F9FAFB];
}

.light {
  @apply rounded-[10px];
}

.smallSize.activeRoot {
  @apply bg-[#F9FAFB]
}

.borderRoot.activeRoot {
  @apply bg-transparent
}

.header {
  @apply flex flex-row items-center;
}

.header .label {
  @apply text-base font-bold;
}

.content {
  @apply pt-2 overflow-hidden sm:text-base text-sm font-medium whitespace-pre-wrap text-[#4B5563];
}

.icon {
  @apply ml-auto;
  transition: transform 0.2s ease;
}

.icon.open {
  transform: rotate(90deg);
}

.root h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
}

.root h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.root h4 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}
