@use '../../variables';

.modal.orders-messages-modal {
	width: 350px;
	overflow-y: hidden;

	.modal-dialog {
		max-width: 478px;

		.modal-content {
			border: 0;
			border-radius: 5px;
			background-color: #FFFFFF;
			box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);

			.modal-header {
				padding: 19px 25px;
				border: 0;
				justify-content: center;
				border-bottom: 1px solid #E5E7EB;

				> button {
					position: absolute;
					left: 24px;
					margin-top: 2px;
					padding: 0;
					border: 0;
					background: transparent;
					color: #9CA3AF;
					font-size: 12px;
				}
			}

			.modal-body {
				overflow-y: auto;
				padding: 0;

				.header-part {
					display: flex;
					justify-content: space-between;
					margin-bottom: 20px;

					.name {
						color: #6B7280;
						font-size: 12px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 15px;
						margin-bottom: 2px;
					}

					.title {
						color: #1F2937;
						font-size: 20px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 25px
					}

					> button {
						color: #6B7280;
						font-size: 9px;
						background: transparent;
						border: 0;
						padding: 0;
						margin: 0;
					}
				}

				.orders-boxes {
					.order-box {
						display: flex;
						align-items: stretch;
						text-decoration: none;
						padding: 12px 20px;
						background: transparent;
						border: 0;
						border-bottom: 1px solid #E5E7EB;
						transition: all .1s ease-in-out;
						width: 100%;

						.left-side {
							text-align: left;
							flex-grow: 1;

							.number {
								color: #101827;
								font-size: 14px;
								font-weight: bold;
								letter-spacing: 0;
								line-height: 18px;
								margin-bottom: 2px;
							}

							.date-row {
								display: flex;
								align-items: center;
								color: #6B7280;
								font-size: 12px;
								font-weight: 500;
								letter-spacing: 0;
								line-height: 15px;
								margin-bottom: 5px;

								.payment {
									display: flex;
									align-items: center;

									&:before {
										content: "";
										width: 3px;
										height: 3px;
										border-radius: 50%;
										background: #6B7280;
										margin: 0 5px;
										display: block;
									}
								}
							}

							.status-row {
								display: flex;
								align-items: center;

								.products {
									margin-left: 10px;
									font-size: 12px;
									font-weight: 500;
									letter-spacing: 0;
									line-height: 15px;
									color: #6B7280;
								}
							}
						}

						.right-side {
							display: flex;
							flex-direction: column;

							.price {
								color: #101827;
								font-size: 14px;
								font-weight: 500;
								letter-spacing: 0;
								line-height: 18px;
								flex-grow: 1;
							}

							> i {
								color: #9CA3AF;
								transform: rotate(-90deg);
								display: inline-block;
								font-size: 9px;
								margin-top: auto;
								margin-bottom: 7px;
								margin-left: auto;
								margin-right: -2px;
							}
						}

						&:last-of-type {
							margin-bottom: 0;
						}

						&:hover {
							background: #f9fafb;
						}
					}
				}
			}
		}
	}
}

.add-orders-messages-modal {
	width: 350px;

	.modal-dialog {
		max-width: 580px;

		.modal-content {
			border: 0;

			.modal-header {
				display: block;
				border: 0;
				border-bottom: 1px solid #E5E7EB;
				padding: 25px 25px 0;

				.top-part {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 25px;

					.modal-title {
						color: #1F2937;
						font-size: 20px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 25px;

						a {
							color: inherit;
						}
					}

					> button {
						color: #9CA3AF;
						font-size: 16px;
						background: transparent;
						border: 0;
						padding: 0;
						box-shadow: none;

						&.back-button {
							font-size: 12px;
							margin-top: 2px;
							box-shadow: none;
						}

						i.icon-cross {
							font-size: 12px;
						}
					}
				}

				.switch-part {
					display: flex;
					align-items: center;

					> button {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 50%;
						background: transparent;
						border: 0;
						color: #6B7280;
						font-size: 12px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 15px;
						padding: 0 0 15px;
						transition: all .1s ease-in-out;
						border-bottom: 2px solid transparent;

						i {
							font-size: 16px;
							margin-right: 8px;
						}

						&.active {
							color: #1F2937;
							border-bottom: 2px solid #3B82F6;
						}

						&:hover {
							color: #1F2937;
						}

						&:focus, &:focus-visible {
							outline: none;
							box-shadow: none;
						}
					}
				}

				&.with-border {
					border-bottom: 1px solid #E5E7EB;
					padding: 25px;
				}
			}

			.modal-body {
				padding-left: 25px;
				padding-right: 25px;
				padding-bottom: 0;
				padding-top: 25px;
				display: flex;
				flex-direction: column;
				overflow: hidden;

				.status-dropdown {
					margin-bottom: 18px;
				}

				.info-part {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin-bottom: 50px;

					.icon-box {
						height: 76px;
						width: 76px;
						border-radius: 5px;
						background-color: #F3F4F6;
						margin-bottom: 25px;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							width: 40px;
						}
					}

					h2 {
						color: #101827;
						font-size: 20px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 25px;
						text-align: center;
						margin-bottom: 5px;
					}

					.small-description {
						color: #9CA3AF;
						font-size: 14px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 18px;
						text-align: center;
					}
				}

				.subtitle {
					color: #1F2937;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: -0.2px;
					line-height: 24px;
					margin-bottom: 14px;
				}

				.bottom-total-price {
					color: #6B7280;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 18px;
					margin-top: auto;
					display: flex;
					justify-content: space-between;

					span {
						font-weight: bold;
						color: #101827;
						font-size: 18px;
						line-height: 23px;
					}
				}

				.inputs-row {
					display: flex;
					margin-top: 5px;
					flex-wrap: nowrap;
					align-items: center;
					margin-left: -3px;
					margin-right: -3px;

					.form-group {
						position: relative;
						margin-bottom: 12px;
					}

					.product-input-col {
						width: 54%;
						min-width: 54%;

						+ > div {
							flex-grow: 1;
						}

						.form-group {
							> div {

								.wrapper {
									font-size: 14px;
									padding: 0;
									height: 35px;
									border-radius: 5px;

									> div {
										min-height: 35px;

										svg, .clear-icon {
											display: none;
										}

										input {
											&:focus {
												outline: none;
												box-shadow: none;
											}
										}
									}

									&:focus-within {
										outline: none;
										box-shadow: none;
									}
								}
							}

							ul {
								background: #fff;
								z-index: 1;
							}
						}
						&.taxable-row {
							width: 45%;
							min-width: 45%;

						}
					}

					> div {
						width: 22%;
						padding: 0 3px;
					}

					.button-col {
						flex-grow: 0;
						width: auto;

						img {
							display: block;
							width: 15px;
							position: relative;
							top: 1.5px;
						}

						i {
							width: 15px;
							display: block;
						}
					}

					.remove-row-button {
						color: #9CA3AF;
						position: relative;
						font-size: 8px;
						top: 2px;
						background: transparent;
						border: 0;
						padding: 0;
					}

					&.error {
						background: red;
					}

					&.removed, &.ready, &.unready {
						.form-group {
							cursor: not-allowed;

							.form-control {
								background-color: #F3F4F6;
								pointer-events: none;
							}
						}
					}

					&.removed {
						.form-group {
							.form-control {
								text-decoration: line-through;
							}
						}
					}
				}

				.form-group {
					margin-bottom: 1rem;

					.control-label {
						display: block;
						color: #6B7280;
						font-size: 10px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 12px;
						margin-bottom: 7px;

						img {
							width: 30px;
							height: 20px;
							margin-right: 5px;
							object-fit: contain;
						}

						&.with-img {
							display: flex;
							align-items: center;
						}

						&.with-light-text {
							font-weight: 500 !important;
						}
					}

					.form-control {
						border-radius: 5px;
						box-shadow: none;
						border: 1px solid #E5E7EB;
						transition: none;
						font-size: 12px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 18px;
						padding: 6px;
						color: variables.$darkColor;
						height: 35px;
						min-height: 35px;
						box-sizing: border-box;
						width: 100%;
						display: flex;
						align-items: center;

						&::placeholder {
							color: #9AA3B0;
						}

						&.with-option {
							color: #101827;
							font-size: 10px;
							font-weight: 500;
							letter-spacing: 0;
							line-height: 12px;
							overflow: hidden;
							text-overflow: ellipsis;

							.select-custom-option {
								overflow: hidden;
							}
						}

						.choose {
							width: 100%;
							display: flex;
							align-items: center;

							i {
								font-size: 8px;
								margin-left: auto;
							}

							@media(max-width: 480px) {
								font-size: 12px;
							}
						}

						&:focus, &:focus-visible {
							box-shadow: 0 0 10px 0 rgba(#000, 0.1);
						}
					}

					.border-area {
						position: relative;
						border: 1px solid #E5E7EB;
						border-radius: 5px;

						.loader-container {
							z-index: 1051;
						}

						textarea.form-control {
							height: auto;
							resize: none;
							border: 0;
							margin-bottom: 55px;
							box-shadow: none;
							@media(max-width: 767px) {
								padding: 10px;
								margin-bottom: 47px;
							}
						}

						.bottom-part {
							display: flex;
							align-items: center;
							justify-content: space-between;
							position: absolute;
							bottom: 0;
							left: 0;
							right: 0;
							padding: 10px;
							@media(max-width: 767px) {
								padding: 8px 10px;
							}

							.form-group {
								margin-bottom: 0;
							}

							.send-button {
								color: #fff;
								background: #3C61EA;
								font-size: 12px;
								font-weight: 500;
								letter-spacing: 0;
								line-height: 15px;
								padding: 8px 20px;
								border-radius: 5px;
								border: 0;
								transition: all .1s ease-in-out;
								margin-left: auto;

								&:hover {
									background-color: #1c4ed8;
								}
							}
						}

						.loader-container {
							background: rgba(#fff, 0.9)
						}
					}

					&.form-group-addon {
						position: relative;

						.form-control {
							padding-right: 55px;
						}

						.input-addon {
							position: absolute;
							right: 0;
							font-size: 14px;
							letter-spacing: 0;
							line-height: 14px;
							color: #9CA3AF;
							top: 22px;
							padding: 0 10px;
							height: 45px;
							display: flex;
							align-items: center;
							justify-content: center;

						}
					}

					&.with-copy-btn {
						.form-control {
							height: 45px;

							a {
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								color: #101827;
							}
						}
					}

					&.disabled {
						.form-control {
							cursor: not-allowed;
							//background: #cfcfcf;
							opacity: 0.5;
						}
					}

					&.error-group {
						.form-control {
							border: 1px solid #ef4444 !important;
						}

						.error-text {
							font-size: 12px;
							color: #ef4444;
							margin-top: 2px;
						}
					}

					&.switch-form {
						position: relative;

						input {
							position: absolute;
							top: 0;
							left: 0;
							cursor: pointer;
							opacity: 0;
							width: 100%;
							height: 100%;
							z-index: 1;

							&:checked {
								+ {
									.switch .slider {
										background-color: #0EB981;

										&:before {
											transform: translateX(13px);
										}
									}
								}
							}
						}

						.switch {
							position: relative;
							display: inline-block;
							width: 32px;
							height: 19px;
							margin-bottom: 0;
							min-width: 32px;

							.slider {
								position: absolute;
								cursor: pointer;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								background-color: #9CA3AF;
								transition: .4s;
								border-radius: 10px;

								&:before {
									position: absolute;
									content: "";
									height: 15px;
									width: 15px;
									left: 2px;
									bottom: 2px;
									background-color: white;
									transition: .4s;
									border-radius: 50%;
								}
							}
						}

						&.inline-label {
							display: inline-flex;
							align-items: center;

							.control-label {
								margin-bottom: 0;
								margin-left: 5px;

								i {
									position: relative;
									z-index: 1050;
									top: 1.5px;
									font-size: 12px;
									margin-left: 3px;
								}
							}
						}

						&.extra-text {
							align-items: flex-start;
						}

						&.big-label {
							.control-label {
								color: #101827;
								font-size: 14px;
								font-weight: bold;
								letter-spacing: 0;
								line-height: 18px;
								margin-left: 10px;
							}
						}

						&.light-version {
							input:checked {
								+ .switch .slider {
									&:before {
										transform: translateX(15px);
									}
								}
							}

							.switch {
								width: 36px;
								height: 21px;
								min-width: 36px;
							}

							.slider {
								background: #DFE2E7;

								&:before {
									width: 17px;
									height: 17px;
								}
							}

							.control-label {
								position: relative;
								top: -1px;
							}
						}
					}
				}

				.text-blue-button {
					display: inline-flex;
					align-items: center;
					color: #3C61EA;
					font-size: 12px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 15px;
					background: transparent;
					border: 0;
					margin-bottom: 17px;
					padding: 3px 2px 3px 0;
					margin-top: -3px;
					margin-left: 3px;

					i {
						position: relative;
						top: 1px;
						margin-right: 5px;
						font-size: 8px;
						color: #3C61EA;
					}

					&:focus, &:focus-visible {
						box-shadow: 0 0 0 3px rgba(0, 97, 254, 0.5) !important;
						outline: none !important;
						border-radius: 3px;
					}

					&.no-focus {
						&:focus {
							box-shadow: none !important;
							outline: none;
						}
					}
				}

				.subtitle {
					color: #1F2937;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: -0.2px;
					line-height: 24px;
					margin-bottom: 14px;
				}

				&.with-scroll {
					overflow-y: auto;
				}

				.email-status-wrapper {
					h2 {
						display: block;
						color: #6B7280;
						font-size: 10px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 12px;
						margin-bottom: 7px;
					}
				}
			}

			.modal-footer {
				padding: 15px 25px 20px;
				border: 0;

				.button {
					margin: 0;
					transition: all .1s ease-in-out;

					&.primary {
						color: variables.$white;
						background: variables.$blue;
						font-size: 12px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 15px;
						padding: 12px 46px;
						border-radius: 5px;
						border: 0;
						position: relative;
						width: 100%;

						.loader-container {
							background: variables.$blue;

							.circle-loader {
								width: 20px;
								height: 20px;

								.circle {
									width: 20px;
									height: 20px;
									border-width: 2px;
								}
							}
						}

						&.small-size {
							padding: 12px 22px;
						}

						&:hover {
							background-color: #1c4ed8;
						}

						&:focus, &:focus-visible {
							box-shadow: 0 0 0 3px rgba(0, 97, 254, 0.5) !important;
							outline: none !important;
						}
					}

					&.border-button {
						padding: 12px 41px;
						background: white;
						margin-right: 15px;
						text-decoration: none;
						border: 1px solid #DFE1E4;
						border-radius: 4.5px;
						box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
						color: #6B7280;
						font-size: 12px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 15px;
						display: inline-flex;
						align-items: center;

						&:hover:not(:disabled):not(.no-hover) {
							background: #E5E7EB;
							color: variables.$black;
						}

						i.icon-arrow {
							margin-left: 5px;
							font-size: 7px;
							color: #9CA3AF;
							margin-right: 0;
						}

						i {
							margin-right: 3px;
						}

						&.small-size {
							padding: 7px 10px;
						}

						&.big-size {
							padding: 13px 10px;
						}

						&.left-icon {
							i {
								margin-left: 0;
								margin-right: 3px;
							}
						}
					}
				}
			}
		}
	}

	&.sm-size {
		max-width: 480px;
	}

	&.selmo-delete-modal {
		max-width: 450px;

		.modal-content {
			.modal-header {
				align-items: flex-start;
			}

			.modal-header {
				padding-top: 25px;
			}

			.modal-footer {
				.button {
					width: 120px;
				}
			}
		}

		.modal-body {
			padding-top: 0;
			color: #4B5563;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 22px;
		}
	}

	.tabs-switcher {
		border-radius: 5px;
		background-color: #F3F4F6;
		padding: 3px;
		display: flex;
		align-items: center;
		list-style: none;
		margin: 0;
		margin-bottom: 20px;

		button {
			width: 50%;
			display: block;
			color: #6B7280;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 15px;
			text-align: center;
			padding: 10px 16px;
			background: transparent;
			white-space: nowrap;
			border: 1px solid transparent;
			transition: all .1s ease-in-out;

			&.active {
				border: 1px solid #DFE1E4;
				border-radius: 5px;
				background-color: #FFFFFF;
				box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
				color: #4B5563;
			}

			&:focus {
				outline: none;
			}

			&:hover:not(.active) {
				color: #4B5563;
			}
		}
	}

	#dropdown-basic {
		background: transparent;
		border: 0;
		padding: 0;
		margin: 0;
		text-align: left;
		width: 100%;

		&:focus {
			outline: none;
			box-shadow: none;
		}

		&:after {
			display: none;
		}
	}

	.dropdown-menu {
		padding: 0;
		margin: 0;
		border: 0;
		width: 100%;
		max-width: none;
		min-width: auto;
	}
}

.modal.add-orders-messages-modal, .modal.orders-messages-modal {
	animation: slideInLeft 0.3s forwards;
	width: 350px;
	right: 0;
	left: auto;
	box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.2);
	background: #fff;
	@media (max-width: 480px) {
		width: 100%;
		background: white;
	}

	//input {
	//	@media(max-width: 767px) {
	//		font-size: 16px !important;
	//	}
	//}

	.modal-dialog {
		margin: 0;
		height: 100%;

		.modal-content {
			height: 100%;
			border-radius: 0;

			.modal-header {
				background-color: #F9FAFB;

				.modal-title {
					color: #101827;
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 21px;
					text-align: center;
				}
			}

			.modal-body {
				> .loader-parent {
					display: flex;
					flex-direction: column;
					height: 100%;

					> section {
						display: flex;
						flex-direction: column;
						overflow-y: hidden;
						margin: 0 -10px;
						flex-grow: 1;

						.inputs-row-section {
							overflow-y: auto;
							overflow-x: hidden;
							padding: 0 10px;

							.inputs-row:first-child {
								margin-top: 10px;
							}

							@mixin custom-scrollbar {
								&::-webkit-scrollbar {
									width: 3px;
								}

								&::-webkit-scrollbar-track {
									background: transparent;
								}

								&::-webkit-scrollbar-thumb {
									border-radius: 10px;
									background: variables.$border;
								}
							}
							@include custom-scrollbar;
						}
					}
				}
			}
		}
	}

	.loader-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: 3px;
		z-index: 9;

		.circle-loader {
			position: absolute;
			display: block;
			left: 50%;
			transform: translate(-50%, -50%);
			top: 50%;
			width: 40px;
			height: 40px;

			.circle {
				display: block;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 3px solid variables.$border;
				border-top-color: variables.$blue;
				animation: spin 1s infinite linear;
			}
		}

		&.with-bg {
			background: #fff;
		}
	}

	.loader-parent {
		position: relative;
		min-height: 150px;
	}

	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	.status-bg {
		border-radius: 3px;
		padding: 6px;
		font-size: 0.625rem;
		line-height: 0.75rem;
		font-weight: bold;
		text-align: center;
		display: inline-block;
		min-width: 86px;

		&.blue {
			color: #3C61EA;
			background: #EFF6FF;
		}

		&.orange {
			color: #DE8E09;
			background: #FCF6E5;
		}

		&.purple {
			color: #7C3AED;
			background: #EFEFFD;
		}

		&.green {
			color: #069769;
			background: #EDF8F4;
		}

		&.grey {
			color: #6B7280;
			background: #F0F1F3;
		}

		&.red {
			color: #E64747;
			background: #FDF0F0;
		}

		&.edited {
			color: #ff2bad;
			background: #ffe8f8;
		}

		&.open-package {
			color: #6B7280;
			background: #F0F1F3;
		}
	}

	~ .modal-backdrop {
		z-index: 1055;
		background: #B2B8C0;

		&.show {
			opacity: 0.9;
		}

	}

	@keyframes slideInLeft {
		0% {
			right: -350px;
		}
		100% {
			right: 0;
		}
	}


	@keyframes slideInRight {
		0% {
			right: 0;
		}
		100% {
			right: -350px;
		}
	}

	&.hide-modal {
		animation: slideInRight 0.3s forwards;
	}

	.no-results-text {
		font-size: 18px;
		color: #1f2937;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 21px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		justify-content: center;
		height: 100%;

		img {
			width: 100px;
			margin: 30px 0;
		}


		.orders-number {
			display: block;
			margin-top: 10px;
			border: 1px solid #DFE1E4;
			border-radius: 5px;
			background-color: variables.$white;
			box-shadow: 0 1px 1px 0 rgba(variables.$black, 0.07);
			font-size: 12px;
			font-weight: 500;
			letter-spacing: -0.12px;
			line-height: 15px;
			padding: 10px 14px;
			color: #4B5563;
			transition: all .1s ease-in-out;

			@media(max-width: 991px) {
				width: 30px;
				height: 30px;
			}

			span {
				@media(max-width: 991px) {
					display: none;
				}
			}

			strong {
				margin: 0 5px;
			}

			i.icon-plus, i.icon-cart {
				margin-right: 5px;
				@media(max-width: 991px) {
					margin-right: 0;
				}
			}

			&.blue {
				border-color: #3C61EA;
				background: #3C61EA;
				color: #fff;

				&:hover {
					background-color: #1c4ed8;
				}
			}

			&:hover:not(.blue) {
				background-color: #f3f4f6;
			}
		}
	}
}


.modal.confirm-modal {
	z-index: 1056;
	@media(max-width: 480px) {
		z-index: 1081;
	}

	.modal-dialog {
		.modal-content {
			border: 0;
			border-radius: 5px;
			background-color: #FFFFFF;
			box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);

			.modal-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border: 0;
				padding: 25px 25px 0;

				.modal-title {
					color: #1F2937;
					font-size: 18px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 25px;
				}

				> button {
					color: #6B7280;
					font-size: 9px;
					margin-left: auto;
					background: transparent;
					border: 0;
					padding: 0;
				}
			}

			.modal-body {
				padding: 0 24px 50px 24px;
				font-size: 14px;
				font-weight: 500;
				color: #4B5563;
				letter-spacing: 0;
				line-height: 22px;
			}

			.modal-footer {
				border-radius: 0 0 5px 5px;
				background-color: #F3F4F6;
				padding: 20px 25px;
				border: 0;

				.button {
					margin: 0;
					transition: all .1s ease-in-out;

					&.primary, &.red {
						color: variables.$white;
						background: variables.$blue;
						font-size: 12px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 15px;
						padding: 12px 46px;
						border-radius: 5px;
						border: 0;
						position: relative;

						.loader-container {
							background: variables.$blue;

							.circle-loader {
								width: 20px;
								height: 20px;

								.circle {
									width: 20px;
									height: 20px;
									border-width: 2px;
								}
							}
						}

						&.small-size {
							padding: 12px 22px;
						}
					}

					&.red {
						background: #E64747;

						.loader-container {
							background: #E64747;

							.circle-loader .circle {
								border-top-color: #E64747;
							}
						}
					}

					&.border-button {
						padding: 12px 41px;
						background: white;
						margin-right: 15px;
						text-decoration: none;
						border: 1px solid #DFE1E4;
						border-radius: 4.5px;
						box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
						color: #6B7280;
						font-size: 12px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 15px;
						display: inline-flex;
						align-items: center;

						&:hover:not(:disabled):not(.no-hover) {
							background: #E5E7EB;
							color: variables.$black;
						}

						i.icon-arrow {
							margin-left: 5px;
							font-size: 7px;
							color: #9CA3AF;
							margin-right: 0;
						}

						i {
							margin-right: 3px;
						}

						&.small-size {
							padding: 7px 10px;
						}

						&.big-size {
							padding: 13px 10px;
						}

						&.left-icon {
							i {
								margin-left: 0;
								margin-right: 3px;
							}
						}
					}

					span {
						&.mobile-text {
							display: none;
							@media(max-width: 480px) {
								display: block;
							}
						}

						&.desktop-text {
							@media(max-width: 480px) {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	.loader-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: 3px;
		z-index: 9;

		.circle-loader {
			position: absolute;
			display: block;
			left: 50%;
			transform: translate(-50%, -50%);
			top: 50%;
			width: 40px;
			height: 40px;

			.circle {
				display: block;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 3px solid variables.$border;
				border-top-color: variables.$blue;
				animation: spin 1s infinite linear;
			}
		}

		&.with-bg {
			background: #fff;
		}
	}
}

.gallery-modal.confirm-modal {
	position: fixed;
	display: flex !important;
	align-items: center;
	justify-content: center;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	width: 100%;
	height: 100%;
	box-shadow: none;
	@media(max-width: 767px) {
		width: 100%;
		padding: 0 20px;
	}

	.modal-dialog {
		max-width: none;
		box-shadow: none;
		margin: 0;

		.modal-content {
			background: transparent;
			width: 100%;
			height: calc(100% - 50px);
			box-shadow: none;

			@media(max-width: 767px) {
				padding: 0;
			}

			.modal-header {
				position: absolute;
				right: 0;
				padding: 10px;
				z-index: 9;
				@media(max-width: 767px) {
					position: fixed;
					top: 0;
					right: 0;
				}

				button {
					background: #fff;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: variables.$blue;
					font-size: 8px;

					i {
						left: 0.5px;
						position: relative;
					}
				}
			}

			.header-modal {
				@media(max-width: 767px) {
					margin: 8px 0;
				}
			}

			.image-gallery-content {
				.image-gallery-icon {
					font-size: 35px;
					display: block;
					filter: none;

					&:before {
						display: none;
					}

					.icon-arrows {
						display: block;
						transition: all .2s ease-in-out;

						&.left {
							transform: rotate(90deg);
						}

						&.right {
							transform: rotate(-90deg);
						}

						&:hover {
							color: #ededed;
						}
					}

					&.image-gallery-left-nav {
						left: -80px;
					}

					&.image-gallery-right-nav {
						right: -80px;
					}
				}


				.image-gallery-slide {
					background: transparent;

					&.image-gallery-center {
						position: relative;
					}
				}


				.image-gallery-slide .image-gallery-image {
					max-height: calc(100vh - 250px);
					height: calc(100vh - 250px);
					display: block;
					border-radius: 5px;
					margin: 0 auto 20px;
					object-fit: contain;
					@media(max-width: 767px) {
						max-height: calc(100vh - 220px);
						height: calc(100vh - 220px);
					}
				}
			}

			.image-gallery-thumbnails-wrapper {
				.image-gallery-thumbnail {
					position: relative;
					width: auto;
					overflow: hidden;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-width: 0;
					background: transparent;
					transition: all .2s ease-in-out;
					padding: 0 1px;

					img {
						width: 100px;
						height: 100px;
						object-fit: cover;
						border: 2px solid transparent;
						transition: all .2s ease-in-out;
						border-radius: 3px;

						@media(max-width: 767px) {
							width: 75px;
							height: 75px;
						}
					}

					&.active, &:hover {
						border: 0;

						img {
							border: 2px solid #3B82F6;
						}
					}
				}
			}
		}
	}
}

.custom-hint-dropdown {
	box-shadow: 0 4px 16px 0 rgba(#000, .12), 0 1px 3px 0 rgba(#000, .08);
	background: #fff;
	position: absolute;
	padding: 0;
	margin-top: 5px;
	border-radius: 5px;
	min-width: 100%;
	z-index: 99999;
	max-height: 145px;
	overflow-y: auto;

	.select-custom-option {
		color: #101827;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;

		.name {
			color: #101827;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 16px;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.bottom-bar {
			color: #6B7280;
			font-size: 10px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 10px;
			display: flex;
			align-items: center;

			> div {
				display: flex;
				align-items: center;

				&:after {
					content: "";
					display: inline-block;
					width: 4px;
					height: 4px;
					border-radius: 5px;
					background: #a9b3cb;
					margin: 0 5px;
				}

				&:last-of-type {
					&:after {
						display: none;
					}
				}
			}
		}

		figure {
			img {
				border-radius: 3px;
			}
		}
		&.disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	.select-custom-option {
		font-size: 14px;
		font-weight: 500;
		cursor: pointer;
		padding: 8px;
		transition: all .1s ease-in-out;

		.name {
			font-weight: 500;
		}

		&:hover:not(.no-result-message) {
			background: #F3F4F6;
		}
	}

	&.custom-storage-hint {
		overflow-y: auto;

		.select-custom-dropdown {
			position: static;
			max-width: 250px;
		}
	}
}

.select-custom-dropdown {
	position: absolute;
	left: 0;
	right: 0;
	top: calc(100%);
	border-radius: 5px;
	background-color: #FFFFFF;
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
	padding: 7px;
	max-width: none;
	min-width: 200px;
	overflow-y: auto;

	.css-yt9ioa-option, .css-1n7v3ny-option {
		padding: 0;

		&:hover {
			background: transparent;
		}
	}

	.css-1pahdxg-control, .css-1s2u09g-control {
		position: relative;
		border: 0;
		box-shadow: none;
		border-radius: 3px;
		background-color: #F3F4F6;
		padding-left: 30px;

		> div:first-of-type {
			padding: 0;
			color: #6B7280;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 15px;

			input {
				@media(max-width: 480px) {
					font-size: 16px !important;
				}

				&::placeholder {
					font-size: 12px !important;
				}
			}
		}

		> div:last-of-type {
			display: none;
		}

		&:after {
			content: "\e913";
			font-family: selmo;
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			color: #9CA3AF;
			font-size: 14px;
		}
	}

	.css-26l3qy-menu {
		box-shadow: none;
		margin: 0;
		position: static;

		> div {
			> div {
				font-size: 12px;
				margin-top: 8px;
			}
		}

		.css-4ljt47-MenuList {
			border-radius: 3px;
			cursor: pointer;
			padding: 0;

			> div, .css-9gakcf-option {
				transition: all .2s ease-in-out;
				cursor: pointer;

				&:hover {
					background-color: #F9FAFB;
				}
			}
		}

		[id*='react-select-'] {
			margin-top: 0;
			background: transparent;
			border-radius: 3px;
			transition: all .2s ease-in-out;
			cursor: pointer;

			&:hover {
				background-color: #F9FAFB;
			}
		}
	}

	.css-1n7v3ny-option {
		background: transparent;
		box-shadow: none;
		border: 0;
		margin-top: 0;

		.css-4ljt47-MenuList {
			margin: 0;
		}
	}

	&.size-select {
		min-width: auto;

		.css-1s2u09g-control {
			display: none;
		}
	}
}

