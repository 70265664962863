@use '../../variables';

.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 8px;
    border: 4px solid #000;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.processing-html, .processing-html body {
    overflow: hidden;
}

.processing-html main {
    width: 100vw;
    height: 100vh;

    & > div {
        width: 100%;
        min-height: 0;
        padding: 0;
        text-align: center;
    }
}

.processing-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}


.delete-product-btn {
    display: flex;
    align-items: center;
    color: #9CA3AF;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    padding: 0;
    border: 0;
    box-shadow: none;
    background: transparent;
    margin-top: 5px;

    i {
        font-size: 9px;
        margin-right: 3px;
        position: relative;
        top: .5px;
    }

    &:focus {
        outline: none;
    }
}

* {
    box-sizing: border-box;
}

.payment-due-box {
    background: #E24140;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: -40px;
    z-index: 9;
    animation: slideIn .3s ease forwards;

    @keyframes slideIn {
        0% {
            opacity: 0;
        }
        100% {
            top: 0;
            opacity: 1;
        }
    }

    strong {
        width: 76px;

        span {
            &.divider {
                position: relative;
                top: -1px;
            }
        }
    }

    + .client-main {
        transition: all .3s ease;
        padding-top: 40px;
    }
}

.selmo-input-wrapper {
    position: relative;
    margin-bottom: 20px;

    .form-label {
        font-size: 12px;
        font-weight: normal;
        position: absolute;
        top: 0;
        width: 100%;
        margin-top: 6px;
        margin-left: 1px;
        padding: 0 0.9166666667em;
        z-index: 1;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-transform: translateY(3px);
        transform: translateY(3px);
        pointer-events: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        opacity: 0;
        color: #737373;
    }

    .checkbox-input {
        cursor: pointer;
    }

    .form-control {
        height: 48px;
        transition: all 0.2s ease-out;
        background-color: white;
        color: #333333;
        border: 1px transparent solid;
        border-color: #d9d9d9;
        background-clip: padding-box;
        border-radius: 5px;
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        padding: 0.9285714286em 0.7857142857em;
        word-break: normal;
        line-height: inherit;
        font-size: 14px;

        &:focus {
            border-color: variables.$blue !important;
            box-shadow: 0 0 0 1px variables.$blue;
        }

        &::-webkit-input-placeholder {
            color: #737373
        }

        &:-moz-placeholder {
            color: #737373;
            opacity: 1
        }

        &::-moz-placeholder {
            color: #737373;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: #737373;
        }
    }

    select.form-control {
        padding: 0 7px;
    }

    &.error-group {
        .error-text {
            font-size: 12px;
            color: #ef4444;
            margin-top: 2px;
        }

        .control-label {
            color: #ef4444;

            a {
                color: #ef4444 !important;
            }
        }

        .form-control {
            border-color: #ef4444;
            box-shadow: 0 0 0 1px #ef4444;

            &:focus {
                border-color: variables.$blue !important;

            }
        }
    }

    &.show-floating-label {
        .form-control {
            &::-webkit-input-placeholder {
                color: transparent
            }

            &:-moz-placeholder {
                color: transparent;
                opacity: 1
            }

            &:-ms-input-placeholder {
                color: transparent
            }
        }

        .form-control {
            padding-top: 18px;
            padding-bottom: 0.3571428571em;
        }

        .form-label {
            -webkit-transform: none;
            transform: none;
            opacity: 1;
        }
    }
}

main.client-order-main {
    display: flex;
    height: 100%;
    min-height: 100vh;
    background: #fff;

    &.full-width {
        & > div {
            width: 100%;
        }

        .client-wrapper {
            width: 100%;

            @media(max-width: 767px) {
                width: auto;
            }

            &__title, &__breadcrumbs {
                display: none;
            }
        }
    }

    &.order-finished {
        justify-content: center;

        & > div {
            width: 100%;
        }
    }

    @media(max-width: 767px) {
        display: block;
        min-height: 0;
        height: auto;
    }

    & > div {
        width: 55%;
        min-height: 100vh;
        padding: 50px 0;

        @media(max-width: 991px) {
            width: 100%;
            padding: 0;
        }

        &.client-main-section {
            position: relative;
            display: flex;
            flex-direction: column;
            background: #fff;
            //opacity: 0;
            //animation: fadeIn .2s ease-in-out forwards .5s;
            @media(max-width: 991px) {
                order: 2;
                animation: none;
                opacity: 1;
            }
        }

        &.order-summary-wrapper {
            position: relative;
            width: 45%;
            background: #fff;

            @media(max-width: 991px) {
                padding: 15px;
                width: 100%;
                border: 1px solid #E5E7EB;
                background-color: #F9FAFB;
            }

            .mobile-toggle-header {
                display: none;
                background: transparent;
                border: 0;
                padding: 0;
                width: 100%;
                font-size: 14px;
                color: #101827;
                @media(max-width: 991px) {
                    display: flex;
                    align-items: center;
                }

                strong {
                    font-size: 18px;
                    margin-left: auto;
                }

                i {
                    font-size: 10px;
                    margin-right: 10px;
                    color: #9CA3AF;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .order-summary {
                @media(max-width: 991px) {
                    display: none;
                }
            }

            &.active {
                .mobile-toggle-header {
                    i.icon-arrows {
                        transform: rotate(180deg);
                    }
                }

                .order-summary {
                    @media(max-width: 991px) {
                        display: block;
                    }
                }
            }

            &:before {
                content: "";
                background: #F9FAFB;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                //animation: slideInBg .5s ease-in-out forwards;
                width: 100%;
                border-left: 1px solid #E5E7EB;
                @media(max-width: 991px) {
                    display: none;
                }
            }

            @keyframes slideInBg {
                0% {
                    width: 0;
                }
                100% {
                    width: 100%;
                }
            }
            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    }

    &.order-finished {
        justify-content: center;

        & > div {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .client-wrapper {
            margin-left: 0;
        }
    }

    .sellfie-blue-alert {
        border-radius: 5px;
        background-color: rgba(95, 165, 250, 0.1);
        color: #3B82F6;
        letter-spacing: 0;
        line-height: 22px;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 10px;
        height: 56px;
        font-size: 14px;

        i {
            margin-right: 5px;
        }

        @media(max-width: 767px) {
            font-size: 13px;
            line-height: 18px;
        }
    }

    .sellfie-green-alert {
        border-radius: 5px;
        background-color: rgba(27, 255, 55, 0.1);
        font-size: 13px;
        letter-spacing: 0;
        line-height: 22px;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 25px;
        color: green;

        i {
            font-size: 10px;
            margin-right: 10px;
        }
    }

    .mobile-title {
        display: none;
        @media(max-width: 767px) {
            display: block;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            position: relative;
            padding: 15px;
            background: #F3F4F6;
            border-radius: 5px;

            &:after {
                font-family: 'selmo';
                content: "\e903";
                position: absolute;
                top: 15px;
                right: 15px;
                transform: rotate(90deg);
                font-size: 10px;
            }

            &.active {
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding-bottom: 0;

                &:after {
                    transform: rotate(270deg);
                }
            }
        }
    }

    .order-summary {
        width: 360px;
        margin-left: 50px;
        position: sticky;
        top: 50px;
        //opacity: 0;
        //animation: fadeIn .2s ease-in-out forwards .5s;

        @media(max-width: 991px) {
            width: 100%;
            position: initial;
            margin-left: 0;
            animation: none;
            opacity: 1;
        }

        &.mobile {
            display: none;
        }

        @media(max-width: 991px) {
            &.mobile {
                background: #F3F4F6;
                margin-bottom: 15px;
                padding: 15px;
                border-radius: 5px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                padding-top: 9px;

                &.active {
                    display: block;
                }
            }

            &.desktop {
                display: none;
            }
        }

        &__title {
            color: #101827;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 23px;
        }

        &__prices {
            padding: 25px 0;
            border-bottom: 1px solid variables.$greyBg;
            border-top: 1px solid variables.$greyBg;
            @media(max-width: 767px) {
                padding: 15px 0;
            }
        }

        .price-row {
            display: flex;
            align-items: center;
            margin-bottom: 4px;

            &:last-of-type {
                margin-bottom: 0;
            }

            .name {
                color: #4B5563;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                flex-grow: 1;
                font-weight: normal;
            }

            .value {
                color: #101827;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 20px;
                white-space: nowrap;
            }
        }

        &__final-price {
            .price-row {
                padding: 25px 0;
                @media(max-width: 767px) {
                    padding-bottom: 0;
                    padding-top: 15px;
                }

                .name {
                    color: #4B5563;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                    @media(max-width: 767px) {
                        font-size: 15px;
                        line-height: 23px;
                    }
                }

                .value {
                    color: #101827;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 25px;
                    text-align: right;
                    @media(max-width: 767px) {
                        font-size: 15px;
                        line-height: 23px;
                    }
                }
            }
        }

        &__description {
            position: relative;
            margin-top: 25px;

            @media(max-width: 767px) {
                margin-top: 0;
            }

            h3 {
                color: #101827;
                font-size: 17px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 23px;
            }

            .more-description {
                color: #6B7280;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 22px;
                position: relative;
                max-height: 200px;
                overflow: hidden;
                min-height: 58px;

                &:before {
                    content: "";
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    position: absolute;
                    background: rgb(255, 255, 255);
                    background: linear-gradient(0deg, rgba(243, 244, 246, 1) 0%, rgba(243, 244, 246, .8) 50%, rgba(255, 255, 255, 0) 100%);
                    z-index: 9;
                }
            }

            &.active {
                .more-description {
                    overflow: visible;
                    max-height: none;

                    &:before {
                        display: none;
                    }
                }
            }

            .more-description-btn {
                background: linear-gradient(0deg, rgba(243, 244, 246, 1) 0%, rgba(243, 244, 246, .8) 50%, rgba(255, 255, 255, 0) 100%);
                padding: 10px 0;
                border: 0;
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 12;

                &:focus {
                    outline: none;
                }
            }
        }

        .selmo-basket {
            border: 1px solid #F3F4F6;
            border-radius: 5px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: center;
            color: #6B7280;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
            padding: 9px;
            margin-top: 16px;

            img {
                width: 71px;
                margin-left: 6px;
            }
        }
    }

    &.client-main {
        @media(max-width: 991px) {
            display: flex;
            flex-direction: column;
        }

        > div {
            @media(max-width: 991px) {
                width: 100%;
                min-height: auto;
            }
        }
    }

    .selmo-bottom-info {
        margin-right: 50px;
        width: 550px;
        margin-left: auto;
        color: #6B7280;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        border-top: 1px solid #F3F4F6;
        padding-top: 15px;
        margin-top: 100px;

        img {
            height: 25px;
            margin-top: 3px;
        }

        @media(max-width: 1200px) {
            margin-left: 0;
            padding: 15px 15px 25px 15px;
            margin-top: 25px;
            width: 100%;
        }
    }

    .client-wrapper {
        margin-right: 50px;
        width: 550px;
        margin-left: auto;
        flex-grow: 1;

        @media(max-width: 1200px) {
            margin-left: 30px;
        }

        @media(max-width: 991px) {
            width: auto;
            margin: 15px;
            padding-left: 0;
        }

        .shipping-chooser-box {
            display: flex;
            align-items: center;
            border-radius: 5px;
            border: 1.5px solid #E5E7EB;
            position: relative;
            padding-left: 41px;
            height: 61px;
            margin-bottom: 15px;

            .text-wrapper {
                .name {
                    color: #101827;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 20px;
                }

                .price {
                    color: #101827;
                    font-size: 13px;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }

            &:before {
                content: "";
                height: 17px;
                width: 17px;
                border-radius: 50%;
                border: 1.5px solid #E5E7EB;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 13px;
                transition: all .2s ease-in-out;
            }

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 19px;
                height: 5px;
                width: 5px;
                border-radius: 50%;
                background: transparent;
                transition: all .2s ease-in-out;
            }

            &:hover {
                border: 1px solid #FCBE24;
                cursor: pointer;

                &:before {
                    border: 1px solid #FCBE24;
                }
            }

            &.active {
                border: 1px solid #FCBE24;

                &:before {
                    border: 1px solid #FCBE24;
                }

                &:after {
                    background: #FCBE24;
                }
            }
        }

        &__title {
            display: flex;
            align-items: center;
            padding-bottom: 22px;
            margin-bottom: 25px;
            border-bottom: 1px solid #F3F4F6;

            .logo-wrapper {
                .logo {
                    margin: 0 auto;
                    height: 45px;
                    width: 45px;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    border-radius: 8px;
                    border: 1px solid variables.$greyBg;
                    overflow: hidden;
                }
            }

            > .lang-select {
                margin-left: auto;
                margin-bottom: 0;
            }

            #custom-dropdown-default.default-size.lang-select {
                margin-left: auto;
                //position: absolute;
                //top: 50px;
                //right: 10px;
                //z-index: 1;
                > .lang-select {
                    position: relative;
                    top: 0;
                    right: 0;
                }

                [class*='-control'] {
                    box-shadow: none;
                    border: 0;
                    min-height: auto;

                    > div:first-of-type {
                        padding: 0;
                    }
                }

                .flag-option {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 1px solid #D1D5DB;
                    margin: auto;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .text-wrapper {
                margin-left: 15px;

                .brand-name {
                    color: variables.$darkColor;
                    font-size: 16px;
                    margin-bottom: 0;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 21px;
                    @media(max-width: 767px) {
                        font-size: 13px;
                    }
                }

                .order-number {
                    color: #6B7280;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: -0.16px;
                    line-height: 18px;
                }
            }

            &.ordered {
                display: flex;
                flex-direction: row;
                padding-bottom: 18px;
                //margin-bottom: 28px;
                //border-bottom: 1px solid #F3F4F6;
                border-bottom: 0;
                margin-bottom: 5px;

                .logo-wrapper {
                    .logo {
                        margin: 0;
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                    }
                }

                .text-wrapper {
                    .brand-name {
                        margin-top: 0;
                    }
                }
            }
        }

        .form-row {
            margin-left: -8px;
            margin-right: -8px;

            > div {
                padding: 0 8px;
            }
        }

        &__breadcrumbs {
            display: flex;
            align-items: center;
            list-style: none;
            margin-top: 15px;
            padding-bottom: 25px;
            border-bottom: 1px solid #f3f4f6;
            padding-left: 0;

            li {
                a, button {
                    color: #697281;
                    background: transparent;
                    border: 0;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    text-decoration: none;
                    line-height: 15px;

                    &.active {
                        font-weight: bold;
                        color: #101827;
                    }

                    &:hover {
                        color: #101827;
                    }

                    &:not([href]) {
                        &:hover {
                            color: #697281;
                        }
                    }
                }

                &:after {
                    content: "\e903";
                    font-family: selmo;
                    display: inline-block;
                    margin: 0 10px;
                    transform: rotate(270deg);
                    font-size: 7px;
                    color: #D1D5DB;
                    position: relative;
                    top: -1px;
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .selmo-form {
            @media(max-width: 767px) {
                margin-bottom: 5px;
            }
        }

        .status-wrapper {
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #F3F4F6;

            @media(max-width: 767px) {
                width: 100%;
                margin-top: 15px;
            }

            .status {
                padding: 10px;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 13px;
                display: flex;
                align-items: center;
                height: 56px;

                @media (max-width: 767px) {
                    font-size: 11px;
                    justify-content: flex-start;
                    padding: 16px 17px;
                }

                strong {
                    display: block;
                    margin-top: 3px;
                    font-size: 14px;
                    @media(max-width: 767px) {
                        font-size: 13px;
                    }
                }

                &:before {
                    content: "";
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    margin-right: 13px;
                }

                &.waiting {
                    background-color: rgba(252, 190, 36, 0.1);
                    color: #101827;

                    &:before {
                        background-color: #FCBE24;
                    }
                }

                &.already-sent {
                    background-color: #EDE9FE;
                    color: #101827;

                    &:before {
                        background-color: #6366F1;
                    }
                }
            }
        }

        .order-status-container {
            .section-title {
                @media(max-width: 767px) {
                    flex-wrap: nowrap;
                }
            }
        }

        .section-title {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            font-weight: bold;

            @media(max-width: 767px) {
                flex-wrap: wrap;
            }

            .icon {
                height: 25px;
                width: 25px;
                border: 1.38px solid #D1D5DB;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                font-weight: 600;
                letter-spacing: -0.14px;
                line-height: 20px;
                margin-right: 10px;
                border-radius: 50%;

                &.no-number {
                    &:before {
                        content: "";
                        height: 11px;
                        width: 11px;
                        border: 1.38px solid #D1D5DB;
                        border-radius: 50%;
                    }
                }
            }

            .text {
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 23px;
                flex-grow: 1;

                @media(max-width: 767px) {
                    line-height: 18px;
                    font-size: 15px;
                }
            }

            .payment-summary {
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 23px;
                text-align: right;

                span {
                    color: #257CF8;
                }

                @media(max-width: 767px) {
                    font-size: 13px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 20px;
                    width: 100%;
                    text-align: left;
                    margin-top: 6px;
                }
            }
        }

        .inpost-results-wrapper {

            .inpost-row {
                border-color: #e5e7eb;

                .inpost-section-title {
                    margin-bottom: 12px;
                }
            }
        }

        .form-group {
            .floated-label {
                top: 14px;

                &.selected {
                    top: 8px;

                    + .form-control {
                        padding-top: 20px;
                    }
                }
            }

            .form-control {
                transition: all .2s ease;
                height: 50px;

                .remove-product {
                    top: 14px;
                }

                .select-custom-option {
                    font-weight: 500;
                    font-size: 14px;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &:focus {
                    border-color: #3c61ea !important;
                    box-shadow: 0 0 0 1px #3c61ea;
                }
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }

            &.checkbox-form {

                .checkbox-checkmark {
                    position: relative;
                    display: block;
                    height: 20px;
                    width: 20px;
                    min-width: 20px;
                    border-radius: 5px;

                    &:after {
                        content: '\e921';
                        font-family: selmo;
                        font-size: 9px;
                        color: variables.$white;
                        position: absolute;
                        top: calc(50% + 0.2px);
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: all .2s ease-in-out;
                        @media(max-width: 1500px) {
                            margin: 0;
                        }
                    }
                }

                .form-control {
                    height: 25px;
                }
            }

            &.error-group {
                &.checkbox-form {
                    .control-label {
                        color: #ef4444;

                        a, button {
                            color: inherit;
                        }
                    }

                    .checkbox-checkmark {
                        border-color: #F87171;
                    }

                    .error-text {
                        display: none;
                    }
                }
            }
        }
    }

    .payment-wrapper {
        margin-top: 35px;

        @media(max-width: 767px) {
            margin-top: 25px;
        }

        h3 {
            color: #101827;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 23px;
        }

        .client-payment-box {
            height: 60px;
            border-radius: 5px;
            background-color: #F3F4F6;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            padding: 15px;

            .text-wrapper {
                flex-grow: 1;
                margin-right: 15px;

                .name {
                    color: #101827;
                    font-size: 13px;
                    letter-spacing: 0;
                    line-height: 13px;

                    @media(max-width: 767px) {
                        font-size: 11px;
                        letter-spacing: 0;
                        line-height: 11px;
                    }
                }

                .value {
                    color: #101827;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 14px;
                    margin-top: 4px;

                    @media(max-width: 767px) {
                        font-size: 13px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 13px;
                    }
                }
            }

            .button-place {
                .btn {
                    height: 32px;
                    font-size: 13px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 20px;
                    width: auto;
                    border-radius: 5px;

                    @media(max-width: 767px) {
                        font-size: 11px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 14px;
                    }
                }
            }
        }
    }

    .hidden-file-input {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        top: -99999px;
    }

    .client-file-wrapper {
        margin: 40px 0;

        .file-placeholder {
            height: 61.5px;
            border: 1.5px dashed #D1D5DB;
            border-radius: 5px;
            background-color: #F9FAFB;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #101827;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 23px;
            margin-bottom: 15px;
            text-decoration: underline;

            .text {
                position: relative;
                @media(max-width: 767px) {
                    font-size: 13px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 20px;
                }

                &:before {
                    content: "";
                    display: block;
                    height: 24px;
                    width: 24px;
                    background: #FCBE24;
                    margin-right: 10px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -27px;
                    //TODO
                    //background: url('../../../assets/images/svg/add-file.svg') no-repeat;
                    background-size: cover;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .btn {
        height: 56px;
        border-radius: 10px;
        background-color: variables.$blue;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23px;
        color: variables.$darkColor;
        text-align: center;
        box-shadow: none;
        outline: none;
        border: 0;
        width: 170px;
        transition: all .2s ease-in-out;

        &:hover {
            color: variables.$darkColor;
        }

        @media(max-width: 767px) {
            width: 100%;
            height: 48px;
            font-size: 14px;
        }
    }

    .button-place {
        display: flex;
        justify-content: flex-end;
    }

    .section-title {
        display: flex;
        align-items: center;

        .icon {

        }

        .text {
            color: #101827;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 23px;
        }
    }

    .form-wrapper {
        margin-top: 30px;
        @media(max-width: 767px) {
            margin-top: 15px;
        }

        form {
            margin-bottom: 25px;
        }

        .button-place {
            margin-top: 30px;
        }

        .bottom-buttons {
            display: flex;
            align-items: center;
            @media(max-width: 1200px) {
                position: static;
                margin-top: 20px;
            }

            a {
                font-size: 12px;
                font-weight: bold;
                color: #9CA3AF;
                margin-right: 35px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .flex-row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;

            &.shipping-row {
                margin: 0 -10px -15px;
            }

            & > div {
                width: 50%;
                padding: 0 10px;

                &.full-width {
                    width: 100%;
                }

                @media(max-width: 767px) {
                    width: 100%;
                }
            }
        }

        .form-shipping-section {
            margin-top: 25px;
        }

        .select2-container--default {
            .select2-selection--single {
                border-radius: 5px;
                box-shadow: none;
                border: 1px solid #d9d9d9;
                transition: none;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
                padding: 6px 15px;
                color: #101827;
                min-height: 48px;
                box-sizing: border-box;
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;

                .select2-selection__rendered {
                    margin: 0;
                    padding: 0;
                    line-height: normal;

                    &:focus-visible {
                        outline: none;
                    }
                }

                .select2-selection__arrow {
                    position: absolute;
                    right: 10px;
                    top: 8px;

                    b {
                        display: none;
                    }

                    &:after {
                        content: "\e903";
                        font-family: selmo;
                        position: absolute;
                        left: 50%;
                        top: calc(50% + 2px);
                        transform: translate(-50%, -50%);
                        color: #9CA3AF;
                        font-size: 8px;
                    }
                }
            }
        }

        .select2-container--open {
            .select2-selection--single .select2-selection__arrow:after {
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }

        .form-group {
            margin-bottom: 24px;

            &.checkbox-form {
                margin-bottom: 10px;
            }


            .form-control {
                //border-radius: 5px;
                //box-shadow: none;
                //border: 1px solid #D1D5DB;
                //transition: none;
                //font-size: 14px;
                //font-weight: 500;
                //letter-spacing: 0;
                //line-height: 18px;
                //padding: 10px;
                //color: variables.$darkColor;
                //box-sizing: border-box;
                //width: 100%;
                //height: auto;

                //@media(max-width: 767px) {
                //	font-size: 13px;
                //	letter-spacing: 0;
                //	line-height: 20px;
                //	padding: 5px 15px;
                //}
                //
                //&.big {
                //	height: 70px;
                //	font-size: 18px;
                //	letter-spacing: 0;
                //	line-height: 28px;
                //}
                //
                //&::placeholder {
                //	color: variables.$lgreyColor;
                //	font-size: 14px;
                //	font-weight: 500;
                //	letter-spacing: 0;
                //	line-height: 18px;
                //}
                //
                //&:focus {
                //	outline: none;
                //	box-shadow: none;
                //}
            }

            textarea.form-control {
                height: auto;
                resize: none;
                padding: 10px 15px;
            }

            &.form-group-addon {
                position: relative;

                .form-control {
                    padding-right: 45px;
                }

                .input-addon {
                    position: absolute;
                    bottom: 20px;
                    right: 15px;
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 15px;
                }
            }

            &.error-group {
                .content {
                    border-color: #F87171;
                    background-color: #FFFAFA;
                }

                .form-control {
                    border: 1px solid #ef4444 !important;
                }

                .error-text {
                    font-size: 12px;
                    color: #ef4444;
                    margin-top: 2px;
                }
            }

            &.checkbox-form {
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;

                .control-label {
                    position: relative;
                    top: .5px;
                    margin-bottom: 0;

                    a, button {
                        font-weight: bold;
                        color: #6B7280;
                        text-decoration: none;
                        position: relative;
                        z-index: 1;
                        border: 0;
                        background: transparent;
                        padding: 0;
                        margin: 0;
                    }
                }

                .checkbox-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 1;

                    &:checked {
                        + .checkbox-checkmark {
                            background: #3C61EA;
                            border-color: #3C61EA;

                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }

                .checkbox-checkmark {
                    position: relative;
                    display: block;
                    height: 20px;
                    width: 20px;
                    min-width: 20px;
                    border: 1px solid #D1D5DB;
                    border-radius: 5px;
                    transition: all .2s ease-in-out;
                    margin-right: 8px;
                    margin-top: 2px;
                    pointer-events: none;

                    &:after {
                        content: "\e921";
                        font-family: selmo;
                        font-size: 9px;
                        color: variables.$white;
                        position: absolute;
                        top: calc(50% + 0.2px);
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        transition: all .2s ease-in-out;
                    }
                }
            }
        }
    }
}

.sellfie-alert {
    position: fixed;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    height: 70px;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    padding: 15px;
    border-left: 5px solid #000;
    z-index: 9999;
    min-width: 400px;

    @media(max-width: 767px) {
        min-width: 0;
        width: 90%;
    }

    .icon {
        margin-right: 15px;
        height: 22px;
        width: 22px;
        border-radius: 50%;

        img {
            height: 22px;
            width: 22px;
        }
    }

    .text-wrapper {
        color: #101827;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 23px;

        @media(max-width: 767px) {
            line-height: 17px;
        }
    }

    &.alert-success {
        border-left-color: #34D299;
    }

    &.alert-fail {
        border-left-color: #F87171;
    }
}

.single-file-box {
    height: 61.5px;
    border: 1.5px dashed #D1D5DB;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;

    &:last-of-type {
        margin-bottom: 0;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 45px;
        border-radius: 5px;
        background-color: #E5E7EB;

        i {
            color: #101827;
            font-size: 16px;
            position: relative;
            left: -2px;
            top: -1px;
        }
    }

    &__title {
        color: #101827;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        margin: 0 15px;
        flex-grow: 1;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        .file-name {
            text-transform: uppercase;
        }

        @media(max-width: 767px) {
            font-size: 12px;
            line-height: 16px;
            margin-left: 10px;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        margin: 0 -5px;

        & > div {
            padding: 0 5px;
        }

        .btn {
            width: 72px;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 34px;
            width: 34px;
            background-color: #FCD34C;
            border-radius: 5px;

            i {
                color: #101827;

                &.icon-arrow-left {
                    &:before {
                        transform: rotate(270deg);
                    }
                }
            }
        }
    }
}

.uploaded-files-wrapper {
    h4 {
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: 600;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}

.extra-message-wrapper {
    margin-top: 35px;
    padding-top: 25px;
    border-top: 1px solid #D1D5DB;

    @media(max-width: 767px) {
        margin-top: 15px;
        padding-top: 15px;
    }
}

.client-alert-wrapper {
    margin: 25px 0;
    padding-bottom: 25px;
    border-bottom: 1px solid #F3F4F6;

    &__title {
        color: #101827;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 23px;
        margin-bottom: 10px;
        @media(max-width: 767px) {
            line-height: 18px;
            font-size: 15px;
        }
    }

    &__box {
        border-radius: 5px;
        background-color: #F3F4F6;
        color: #101827;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 15px;
        padding: 15px;
    }
}

.form-row {
    //display: flex;
    //flex-wrap: wrap;
    //margin-left: -6px;
    //margin-right: -6px;
    //
    //> div {
    //	width: 50%;
    //	padding-left: 6px;
    //	padding-right: 6px;
    //}

    @media(max-width: 991px) {
        .mobile-w-100 {
            width: 100%;
        }
    }

    .form-group {
        margin-bottom: 15px;
    }

    .custom-async-search {
        .select-custom-option {
            &:focus {
                background: transparent;
            }
        }

        &.open {
            .form-control {
                border-color: variables.$blue;
                box-shadow: 0 0 0 1px variables.$blue;
            }
        }
    }
}

.button {
    padding: 0;
    border: 0;
    background: transparent;
    transition: all .2s ease-in-out;

    &.go-back {
        color: variables.$lgreyColor;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        transition: all .2s ease-in-out;
        text-decoration: none;

        i {
            position: relative;
            top: -.1px;
            color: #D1D5DB;
            display: inline-block;
            transform: rotate(90deg);
            margin-right: 5px;
            transition: all .2s ease-in-out;
        }

        &:hover {
            color: #101827;

            i {
                color: #101827;
            }
        }
    }

    &.btn-copy, &.btn-change {
        text-decoration: none;
        margin-left: auto;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        color: variables.$greyColor;
        padding: 11px 20px;
        border: 1px solid variables.$greyBg;
        border-radius: 5px;
        box-shadow: 0 1px 1px 0 rgba(variables.$black, 0.07);
        transition: all .2s ease-in-out;
        width: 105px;

        &:hover {
            background: variables.$greyBg;
            color: variables.$black;
        }

        i {
            margin-left: 5px;
        }
    }

    &.text-only {
        color: #6B7280;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
    }

    &.btn-change {
        width: auto;
    }

    &.btn-proceed {
        height: 50px;
        font-size: 14px;
        border-radius: 8px;
        padding: 12px 25px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        min-width: 185px;
        transition: all .2s ease-in-out;

        &.big-font {
            font-size: 16px;
            font-weight: bold;
        }

        &.not-active {
            background: #D1D5DB;
        }

        &:hover:not(.not-active) {
            background: #284ac7;
        }
    }

    &.loading-button {
        position: relative;
        pointer-events: none;
    }

    .loader-container {
        background: variables.$blue;
        min-height: auto;

        .circle-loader {
            top: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 16px;
            margin: 0;

            .circle {
                width: 16px;
                height: 16px;
                border-width: 2px;
            }
        }
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}

section {
    .section-title {
        margin-bottom: 22px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.2px;
        line-height: 21px;
        color: variables.$darkColor;

        &__description {
            color: #6B7280;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 14px;
            margin-bottom: 12px;
            margin-top: -8px;
        }
    }

    .form-info-box.alert-style {
        margin-top: -2px;
    }

    .data-boxes-wrapper {
        border-radius: 8px;

        .content {
            border: 1px solid variables.$greyBg;
            margin-bottom: 7px;
            position: relative;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 19px;
            border-radius: 5px;
            transition: all .2s ease-in-out;

            .btn-change {
                &:hover {
                    background: #E5E7EB;
                    color: #000;
                }
            }

            &__top-part {
                display: flex;
                align-items: center;
                position: relative;
                padding: 20px;
                @media(max-width: 480px) {
                    padding: 15px;
                }

                &.parcel-content {
                    @media(max-width: 480px) {
                        flex-wrap: wrap;
                    }
                }

                > input {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;

                    &:checked {
                        + .checkmark, + div .checkmark {
                            border-color: transparent;
                            background-color: #3C61EA;

                            &:after {
                                width: 5px;
                                height: 5px;
                            }
                        }
                    }
                }

                figure {
                    margin: 0;

                    img {
                        height: 28px;
                    }
                }

                img {
                    height: 26px;
                }

                .checkmark {
                    position: relative;
                    height: 20px;
                    width: 20px;
                    min-width: 20px;
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-top: 1px;
                    border: 1px solid variables.$greyBg;
                    transition: all .2s ease-in-out;
                    pointer-events: none;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: variables.$white;
                        width: 0;
                        height: 0;
                        border-radius: 50%;
                        transition: all .2s ease-in-out .1s;
                    }
                }

                > i {
                    color: #9CA3AF;
                    font-size: 10px;
                }

                .button-place {
                    @media(max-width: 480px) {
                        margin-top: 10px;
                        button {
                            width: 100%;
                        }
                    }
                }

                .icon-place {
                    position: relative;
                    top: -1px;
                    font-size: 18px;
                    margin-right: 10px;
                    width: 16px;
                    pointer-events: none;
                }

                .text-only {
                    transition: all .2s ease;

                    &:hover {
                        color: variables.$black;
                    }
                }
            }

            .label {
                font-weight: bold;
                color: #101827;
            }

            .data {
                color: variables.$greyColor;

                .popular-text {
                    display: inline-block;
                    color: #3C61EA;
                    font-size: 10px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 12px;
                    text-align: center;
                    border-radius: 5px;
                    background-color: #F0F4FF;
                    padding: 4px 8px;
                    margin-top: 8px;
                    transition: all .2s ease-in-out;

                    i {
                        position: relative;
                        top: 0.5px;
                        margin-right: 4px;
                        font-size: 12px;
                    }

                    &.grey {
                        background-color: #F3F4F6;
                        color: #6B7280;
                    }
                }
            }

            > a {
                text-decoration: none;
                margin-left: auto;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 15px;
                text-align: center;
                color: variables.$greyColor;
                padding: 11px 20px;
                border: 1px solid variables.$greyBg;
                border-radius: 5px;
                box-shadow: 0 1px 1px 0 rgba(variables.$black, 0.07);
                transition: all .2s ease-in-out;

                &:hover {
                    background: variables.$greyBg;
                    color: variables.$black;
                }
            }

            > figure {
                margin: auto 0;
                padding: 0;
                margin-left: auto;
                width: 54px;
                height: 26px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            &.radio-content {
                align-items: flex-start;
            }

            .bottom-part {
                display: none;
            }

            .inpost-results-wrapper {
                margin: 0 20px 20px;

                .inpost-row {
                    align-items: flex-start;
                    border-radius: 5px;
                    background-color: #F9FAFB;
                    padding: 13px 15px;
                    border: 0;
                    margin-bottom: 0;

                    .button {
                        border: 1px solid #DFE1E4;
                        border-radius: 5px;
                        background-color: #FFFFFF;
                        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
                        margin-top: 8px;
                        padding: 6px 10px;
                    }

                    .photo {
                        margin-right: 0;
                    }
                }
            }

            &.active {
                border: 1px solid #3C61EA;
                //border-radius: 5px;
                background-color: #FAFBFF;

                .bottom-part {
                    display: block;
                }

                .data .popular-text {
                    background: #f0f0f0;
                }
            }

            &.last-of-type {
                margin-bottom: 0;
            }

            &:hover:not(.no-hover) {
                //box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
            }

            &.with-left-border {

                &.blue-border {
                    border-left: 4px solid variables.$blue;

                    .content__top-part {
                        .checkmark {
                            //align-self: flex-start;
                            //top: 1px;
                        }
                    }
                }
            }

            &.blue-border.active {
                .data {
                    .popular-text {
                        background: #ecf2ff;
                    }
                }
            }
        }

        &.not-separated {
            border: 1px solid variables.$greyBg;

            .content {
                margin-bottom: 0;
                border-radius: 0;
                border: 0;
                border-bottom: 1px solid variables.$greyBg;

                &:last-of-type {
                    border-bottom: 0;
                }
            }
        }

        &.error-group {
            .content {
                border-color: #F87171;
                background-color: #FFFAFA;
            }
        }
    }
}

.delivery-step-wrapper, .payment-step-wrapper {
    margin-top: 30px;
    @media(max-width: 480px) {
        margin-top: 20px;
    }

    section {
        margin-bottom: 28px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .buttons-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media(max-width: 480px) {
                flex-wrap: wrap;
            }

            .button.go-back {
                @media(max-width: 480px) {
                    order: 2;
                    margin-top: 20px;
                }
            }

            .button.btn-proceed, .safety-payment-buttons {
                @media(max-width: 480px) {
                    width: 100%;
                }
            }

            .safety-payment-buttons {
                .safety-payment {
                    color: variables.$lgreyColor;
                    font-size: 10px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    line-height: 12px;
                    text-align: center;
                    margin-top: 10px;

                    i {
                        font-size: 12px;
                        margin-right: 2px;
                        position: relative;
                        top: 1px;
                    }
                }
            }

            &.payment-buttons-row {
                align-items: flex-start;

                .go-back {
                    margin-top: 12px;
                    @media(max-width: 480px) {
                        margin-top: 20px;
                    }
                }
            }
        }

        .toggle-section-header {
            background: transparent;
            border: 0;
            padding: 0;
            color: #6B7280;
            margin-bottom: 12px;

            .section-title {
                margin-bottom: 0;
                color: #6B7280;
            }

            > i {
                margin-left: 10px;
                font-size: 10px;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &.active {
                cursor: default;

                .section-title {
                    color: #101827;
                }
            }
        }

        &.payment-methods-section {
            border-top: 1px solid #F3F4F6;
            border-bottom: 1px solid #F3F4F6;
            padding: 15px 0;
            margin-bottom: 20px;

            &.open {
                border-top: 0;
                padding-top: 0;

                .section-title {
                    color: #101827;
                    cursor: default;
                }
            }

            &:not(.open) {
                .toggle-section-header {
                    margin-bottom: 0;
                }
            }
        }

        .data-boxes-wrapper {
            .content {
                padding: 0;
            }
        }
    }
}

.popular-box {
    position: relative;
    color: #3C61EA;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 100%;
        left: 0;
        height: 1px;
        background: #3C61EA;
    }

    .text {
        display: inline-block;
        position: relative;
        top: -1px;
        z-index: 1;
        background: #fff;
        padding: 10px;

        i {
            font-size: 12px;
            margin-right: 4px;
        }
    }
}

.bm-payment-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0 -5px;
    padding: 0 25px 15px;

    & > li {
        width: 33.333%;
        padding: 5px;
        margin: 0;

        & > div {
            height: 121px;
            border: 1px solid #E5E7EB;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .2s ease-in-out;

            img {
                display: block;
                margin: 0 auto;
                max-width: 60%;
            }

            .name {
                color: #9CA3AF;
                font-size: 10px;
                font-weight: 500;
                letter-spacing: -0.1px;
                line-height: 12px;
                text-align: center;
            }

            &:hover {
                border-color: #101827;
                cursor: pointer;
            }

            &.active {
                border: 2px solid #101827;

                .name {
                    color: #101827;
                }
            }
        }
    }
}

.modal {
    .modal-dialog {
        &.default-modal {
            max-width: 580px;

            .modal-content {
                border: 0;

                .modal-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 0;
                    padding: 35px 25px 5px 25px;

                    .modal-title {
                        color: #1F2937;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 20px;
                    }

                    > button {
                        color: #6B7280;
                        font-size: 11px;
                        margin-left: auto;
                        @media(max-width: 991px) {
                            font-size: 14px;
                        }
                    }

                    &.with-border {
                        border-bottom: 1px solid variables.$greyBg;
                        padding: 25px;
                    }
                }

                .modal-body {
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-bottom: 20px;
                    word-break: break-word;

                    .form-group {
                        //margin-bottom: 24px;
                        //
                        //label {
                        //	display: block;
                        //	color: variables.$greyColor;
                        //	font-size: 12px;
                        //	font-weight: 500;
                        //	letter-spacing: 0;
                        //	line-height: 15px;
                        //	margin-bottom: 7px;
                        //}

                        .form-control {
                            //border-radius: 5px;
                            //box-shadow: none;
                            //border: 1px solid #D1D5DB;
                            //transition: none;
                            //font-size: 14px;
                            //font-weight: 500;
                            //letter-spacing: 0;
                            //line-height: 18px;
                            //padding: 10px;
                            //color: variables.$darkColor;
                            //box-sizing: border-box;
                            //width: 100%;
                            //height: auto;

                            //@media(max-width: 767px) {
                            //    font-size: 13px;
                            //    letter-spacing: 0;
                            //    line-height: 20px;
                            //    padding: 5px 15px;
                            //}

                            &.big {
                                height: 70px;
                                font-size: 18px;
                                letter-spacing: 0;
                                line-height: 28px;
                            }

                            &::placeholder {
                                color: variables.$lgreyColor;
                                font-size: 14px;
                                font-weight: 500;
                                letter-spacing: 0;
                                line-height: 18px;
                            }

                            //&:focus {
                            //    outline: none;
                            //    box-shadow: none;
                            //}
                        }

                        select.form-control {
                            padding: 0 7px;
                        }
                    }
                }

                .modal-footer {
                    border-radius: 0 0 5px 5px;
                    background-color: #F3F4F6;
                    padding: 20px 25px;
                    border: 0;
                }
            }
        }

        &.terms-modal {
            max-width: 800px;
            word-break: break-all;
            @media(max-width: 480px) {
                margin: 0;
            }

            .modal-content {
                .modal-body {
                    h2 {
                        font-size: 16px;
                    }

                    p, li {
                        font-size: 14px;
                    }
                }
            }
        }

        &.selmo-delete-modal {
            max-width: 450px;
            @media(max-width: 480px) {
                width: 100%;
                margin: 0;
            }

            .modal-content {
                .modal-header {
                    align-items: flex-start;

                    .modal-title {
                        @media(max-width: 480px) {
                            margin-right: 10px;
                        }
                    }

                    .button {
                        @media(max-width: 480px) {
                            position: absolute;
                            top: 34px;
                            right: 25px;
                        }
                    }
                }

                .modal-header {
                    padding-top: 25px;
                }

                .modal-footer {
                    .button {
                        width: 120px;

                        &.border-button {
                            @media(max-width: 480px) {
                                order: 1;
                                margin-top: 0;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }

            .modal-body {
                padding-top: 0;
                color: #4B5563;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 22px;
            }
        }
    }
}

.free-shipping-progress-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 51px;
    border: 1px solid #9EB0F5;
    border-radius: 5px;
    background-color: #EFF5FE;
    @media(max-width: 767px) {
        margin-top: 15px;
        padding: 15px;
        height: auto;
    }

    .free-shipping-progress {
        @media(max-width: 767px) {
            width: 100%;
        }

        &__bar {
            height: 4px;
            width: 334px;
            border-radius: 2px;
            background-color: rgba(95, 165, 250, 0.1);
            position: relative;
            @media(max-width: 480px) {
                width: 100%;
            }

            .progress {
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                border-radius: 2px;
                background-color: #3C61EA;
            }
        }

        &__text {
            color: #101827;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 12px;
            margin-top: 10px;
        }
    }
}

.order-status-container {
    .section-title {
        display: flex;
        align-items: center;
        margin-bottom: 26px !important;

        .square-icon {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            background-color: rgba(#0EB981, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            i {
                color: #0EB981;
                font-size: 12px;
            }
        }

        .number {
            color: #101827;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.17px;
            line-height: 18px;
        }

        .extra-text {
            color: #101827;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 23px;
            @media(max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .order-status-wrapper {
        .order-status-box {
            border: 1px solid #E5E7EB;
            border-radius: 5px;
            background-color: #FFFFFF;
            padding: 20px;
            margin-bottom: 20px;

            .top-part {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                    color: #101827;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: -0.2px;
                    line-height: 21px;
                }

                .button {
                    display: flex;
                    align-items: center;
                    padding: 10px 16px 10px 20px;

                    i {
                        display: inline-block;
                        transform: rotate(-90deg);
                        margin-left: 3px;
                        font-size: 8px;
                    }
                }
            }

            .grey-box {
                margin-top: 16px;
                border: 1px solid #E5E7EB;
                border-radius: 5px;
                background-color: #F9FAFB;
                padding: 16px 20px;
                margin-bottom: 14px;

                .name {
                    color: #101827;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                    margin-bottom: 3px;
                }

                .desc {
                    color: #4B5563;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 20px;
                    display: block;

                    &.with-underline {
                        text-decoration: underline;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    &.big-size {
                        font-size: 14px;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                img {
                    height: 25px;
                    width: 55px;
                    object-fit: contain;
                    margin-left: auto;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.order-summary {
    &__products {
        list-style: none;
        padding: 8px 0;
        margin: 0;

        li {
            display: flex;
            align-items: center;

            figure {
                position: relative;
                margin-right: 12px;
                margin-bottom: 0;

                img {
                    border-radius: 8px;
                    object-fit: cover;
                    width: 50px;
                    height: 50px;
                }

                .count {
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    color: variables.$white;
                    border-radius: 50%;
                    height: 16px;
                    width: 16px;
                    background-color: variables.$greyColor;
                    font-size: 10px;
                    font-weight: 500;
                    letter-spacing: 0;
                    padding-left: 1px;
                    line-height: 12px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        position: relative;
                    }
                }
            }

            &:last-of-type {
                .order-summary__products__content {
                    border-bottom: 0;
                }
            }
        }

        &__content {
            display: flex;
            flex-grow: 1;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
            padding: 20px 0;
            border-bottom: 1px solid variables.$greyBg;

            &__name {
                color: #101827;
                margin-right: 5px;
                line-height: 18px;
            }

            &__size {
                font-weight: 500;
                color: variables.$lgreyColor;
                font-size: 12px;

                span {
                    color: #101827;
                }
            }

            &__price {
                margin-left: auto;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
                text-align: right;
                white-space: nowrap;

                .old-price {
                    text-decoration: line-through;
                    font-size: 12px;

                    + .new-price {
                        color: #e93245;
                    }
                }
            }


        }
    }
}

.paid-products-modal-wrapper {
    .modal-description {
        color: #4B5563;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 22px;
    }

    .button.big-size {
        height: 41px;
    }
}

.show-paid-product-btn {
    border: 1px solid #DFE1E4;
    border-radius: 5px;
    background-color: #FFFFFF !important;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
    color: #6B7280;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
}

.select2-dropdown {
    border-radius: 5px;
    background-color: #FFFFFF !important;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    padding: 0;
    overflow: hidden;
    border: 0;

    .select2-search {
        padding: 0;
        position: relative;

        .select2-search__field {
            border-radius: 3px;
            background-color: #F3F4F6;
            position: relative;
            border: 0;
            box-shadow: none;
            padding-left: 30px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            color: variables.$darkColor;
            height: 32px;

            &::placeholder {
                color: #6B7280;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &:after {
            content: "\e913";
            font-family: selmo;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: #9CA3AF;
            font-size: 14px;
        }
    }

    @mixin custom-scrollbar {
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #dddfe3;
        }
    }

    .select2-results {
        background: #fff;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        margin-top: 4px;
        width: 100%;

        ul {
            padding: 3px;
            list-style: none;
            margin: 0;

            .select2-results__option {
                font-size: 12px;
                padding: 7px 9px;
                margin-bottom: 5px;
                border-radius: 3px;
                transition: all .3s ease-in-out;
                color: #6B7280;
                @include custom-scrollbar;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:hover:not(.select2-results__message) {
                    background: #F9FAFB;
                    color: #101827;
                }

                &--selected {
                    font-weight: bold;
                    color: #101827;
                }
            }
        }
    }

    &.select-dropdown {
        padding: 5px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.16);
        margin-top: 8px;
        min-width: 140px !important;
        border-radius: 5px !important;
    }
}

.select2-container--default {
    .select2-results__option--highlighted {
        background-color: #F3F4F6 !important;
        color: variables.$darkColor !important;
    }

    .select2-results__option--selectable {
        background: transparent;
        color: inherit;
    }
}

.country-select {
    + .select2 {
        width: 100% !important;

        .select2-selection {
            padding: 0 11px !important;

            .select2-selection__rendered {
                margin-top: 13px !important;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.discount-code-row {
    display: flex;
    margin-bottom: 25px;

    .discount-code-field {
        flex-grow: 1;
        margin-right: 10px;

        .form-control {
            height: 44px;
            border: 1px solid #D1D5DB;
            border-radius: 5px;
            background-color: #FFFFFF;
            color: #101827;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
            padding-right: 25px;
            transition: all .2s ease-in-out;

            &::placeholder {
                color: #9AA3B0;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
            }

            &:focus {
                outline: none;
                border-color: #3c61ea;
                box-shadow: 0 0 0 1px #3c61ea;
            }
        }

        .error-text {
            color: #D34545;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            margin-top: 5px;

        }

        .clear-button {
            position: absolute;
            background: transparent;
            border: 0;
            font-size: 8px;
            padding: 5px;
            top: 13px;
            right: 6px;
            color: #9ca0a7;
            transition: all .2s ease;

            &:hover {
                color: #101827;
                box-shadow: none;
                outline: none;
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }

    .discount-code-button {
        border-radius: 5px;
        height: 44px;
        padding: 12px 20px;
        transition: all .2s ease-in-out;

        &.not-active {
            background-color: #C6CBD2 !important;

            .loader-container {
                background: inherit;

                .circle-loader {
                    .circle {
                        border-top-color: #c6cbd2;
                    }
                }
            }

            &:hover {
                background-color: #C6CBD2 !important;
            }
        }
    }

    &.with-border {
        padding-bottom: 20px;
        border-bottom: 1px solid #E5E7EB;
    }
}
