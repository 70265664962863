.sms-receivers-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    border-radius: 9999px;
    height: 10px;

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background-color: transparent;
        border-radius: 9999px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #e5e7eb;
        position: relative;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 4px;
            background-color: #e5e7eb;
        }

        &::before {
            left: 3px;
            top: 3px;
        }

        &::after {
            left: 7px;
            top: 7px;
        }
    }

    &::-moz-range-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background-color: #e5e7eb;
        border-radius: 9999px;
    }

    &::-moz-range-thumb {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #e5e7eb;
        position: relative;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 4px;
            background-color: #000;
        }

        &::before {
            left: 3px;
            top: 3px;
        }

        &::after {
            left: 7px;
            top: 7px;
        }
    }

    &::-moz-range-progress {
        background-color: #5fa5fa;
        height: 10px;
        border-top-left-radius: 9999px;
        border-bottom-left-radius: 9999px;
        cursor: pointer;
    }
}
