//@import "image-gallery";
@use '../../variables';

$border: #e5e7eb;

.tutorial-page-box {
    + .messenger-wrapper {
        @media(min-width: 480px) {
            max-height: 85vh;
        }
    }
}

.messenger-wrapper {
    display: flex;
    background: variables.$white;
    border-radius: 5px;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    height: 100%;
    @media(min-width: 480px) {
        max-height: 93vh;
    }

    * {
        box-sizing: border-box;
    }

    input {
        @media(max-width: 767px) {
            font-size: 16px !important;
        }
    }

    @mixin custom-scrollbar {
        &::-webkit-scrollbar {
            width: 9px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: $border;
        }
    }


    .input-style {
        width: 100%;
        border: 1px solid $border;
        border-radius: 5px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
        color: #6B7280;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
        padding: 8px;
        min-height: 35px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .left-panel {
        min-width: 320px;
        width: 320px;
        border-right: 1px solid $border;
        height: 100%;
        display: flex;
        flex-direction: column;
        @media(max-width: 991px) {
            width: 100%;
            min-width: 100%;
            transition: all .1s ease-in-out;
        }

        .search-box {
            padding: 20px 20px 0 20px;
            border-bottom: 1px solid $border;
            margin-bottom: 10px;
            .top-bar {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                > i {
                    color: variables.$darkColor;
                    margin-right: 5px;
                }

                h1 {
                    margin-top: 0;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 23px;
                    color: variables.$darkColor;
                    margin-bottom: 0;
                    flex-grow: 1;
                }

                > button {
                    border: 0;
                    padding: 0;
                    color: variables.$greyColor;
                    font-size: 16px;
                    width: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 15px;
                    border-radius: 50%;
                    transition: all .2s ease-in-out;
                    background: transparent;
                    outline: none !important;

                    i {
                        transition: all .1s cubic-bezier(.08, .52, .52, 1);
                    }

                    i.icon-cross {
                        font-size: 12px;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &:hover {
                        color: variables.$darkColor;
                    }

                    &:active {
                        i {
                            transform: scale(0.95);
                        }
                    }

                    &.full-screen-button {
                        @media(max-width: 767px) {
                            display: none;
                        }
                    }
                }
            }

            .search-input {
                -webkit-appearance: none;
                @extend .input-style;

                &::placeholder {
                    font-weight: 500;
                    opacity: 0.5;
                    font-size: 12px !important;
                    line-height: 12px;
                    position: relative;
                    top: -1px;
                }

                &::-ms-clear, &::-ms-reveal {
                    display: none;
                }
            }

            .status-filter-box {
                margin-bottom: 5px;
            }
        }

        .chat-list-container {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            @media(max-width: 480px) {
                height: auto;
                flex-grow: 1;
            }

            .new-messages-button {
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
                background: variables.$blue;
                color: white;
                border-radius: 5px;
                padding: 7px 17px 7px 10px;
                white-space: nowrap;
                border: 0;
                z-index: 1;
                font-size: 12px;
                animation: slideButtonDown linear 0.2s forwards;

                i {
                    display: inline-block;
                    transform: rotate(90deg);
                    font-size: 9px;
                    margin-right: 5px;
                    position: relative;
                    bottom: 1px;
                }
            }

            @keyframes slideButtonDown {
                0% {
                    transform: translateY(-20px) translateX(-50%);
                }
                100% {
                    transform: translateY(0) translateX(-50%);
                }
            }
        }

        .chat-list-wrapper {
            flex-grow: 1;
            overflow-y: auto;
            height: 50vh;
            padding: 0 10px;
            @include custom-scrollbar;
            @media(max-width: 480px) {
                //height: auto;
            }


            .single-conversation {
                cursor: pointer;
                display: flex;
                padding: 14px 10px;
                border-radius: 8px;
                margin-bottom: 5px;
                //border-top: 1px solid $border;
                text-decoration: none;
                transition: .2s ease-in-out;

                figure {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin: 0;
                    margin-right: 10px;

                    img:not(.msg-logo) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .content {
                    text-overflow: ellipsis;
                    overflow: hidden;

                    .name {
                        display: flex;
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 15px;
                        color: variables.$darkColor;

                        .date {
                            position: relative;
                            top: 1px;
                            color: variables.$lgreyColor;
                            font-size: 10px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 12px;
                            margin-left: 5px;
                            white-space: nowrap;
                            margin-right: 12px;
                        }
                    }

                    .description {
                        color: variables.$greyColor;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 15px;
                        margin-top: 3px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .status-bar {
                        display: flex;
                        align-items: center;
                        margin-top: 12px;

                        .status {
                            font-size: 10px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 12px;
                            padding: 6px 8px;
                            border-radius: 3px;
                            margin-right: 5px;

                            &.default {
                                color: variables.$greyColor;
                                border: 1px solid $border;
                            }

                            &.blue {
                                color: variables.$blue;
                                background-color: rgba(60, 97, 234, 0.1);
                            }

                            &.grey {
                                background-color: #F3F4F6;
                                color: #4B5563;
                            }

                            &.green {
                                color: #069769;
                                background: #EDF8F4;
                            }
                        }
                    }
                }

                &.active {
                    @media(min-width: 991px) {
                        background-color: #F6F7F9;
                    }

                    .content .status-bar .status.default {
                        background: #fff;
                    }
                }

                &.new {
                    position: relative;

                    .content {
                        .description {
                            font-weight: bold;
                            color: variables.$darkColor;
                        }
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        right: 15px;
                        top: 24px;
                        width: 7px;
                        height: 7px;
                        background: variables.$blue;
                        border-radius: 50%;
                    }
                }

                &:hover {
                    background: #f4f5f7;
                }
            }
        }
    }

    .right-panel {
        flex-grow: 1;
        padding: 30px 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        @media(max-width: 991px) {
            position: absolute;
            left: 100%;
            top: 0;
            bottom: 0;
            padding-bottom: 0;
            background: #fff;
            width: 100%;
            transition: all .1s ease-in-out;
        }


        .user-header {
            border-bottom: 1px solid $border;
            padding-bottom: 25px;
            @media (max-width: 991px) {
                padding-bottom: 10px;
            }

            .top-part {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            .go-to-conversations {
                background: transparent;
                border: 0;
                display: inline-block;
                transform: rotate(90deg);
                margin-right: 10px;
                font-size: 14px;
                padding: 5px;
                margin-left: -5px;
                @media(min-width: 991px) {
                    display: none;
                }
            }

            .profile-box {
                flex-grow: 1;

                .client-number {
                    color: #6B7280;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 12px;
                }

            }

            .top-bar-icons {
                display: flex;
                align-items: center;
                @media (max-width: 991px) {
                    display: none;
                }
            }

            .name {
                flex-grow: 1;
                color: variables.$darkColor;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 23px;
                display: flex;
                align-items: center;
                @media(max-width: 480px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                figure {
                    margin: 0;
                    height: 35px;
                    width: 35px;
                    margin-right: 8px;

                    img:not(.msg-logo) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .small-label {
                    color: variables.$lgreyColor;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 15px;
                }
            }

            .orders-number {
                position: relative;
                border: 1px solid #DFE1E4;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                padding: 0;
                width: 31px;
                height: 31px;
                min-width: 31px;
                background-color: variables.$white;
                box-shadow: 0 1px 1px 0 rgba(variables.$black, 0.07);
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.12px;
                color: #9CA3AF;
                margin-left: 10px;
                white-space: nowrap;
                transition: all .1s ease-in-out;

                @media(max-width: 991px) {
                    border: 0;
                    height: auto;
                    box-shadow: none;
                    color: #1F2937;
                    margin: 0;
                    padding: 6px 8px;
                    min-height: 38px;
                    width: 100%;
                    text-align: left;
                    justify-content: start;
                    i {
                        //border: 1px solid #DFE1E4;
                        font-size: 14px !important;
                        color: #6b7280;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        margin: 0;
                    }

                }

                .count {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    background: #9CA3AF;
                    font-size: 8px;
                    width: 14px;
                    height: 14px;
                    border-radius: 3px;
                    color: white;
                    font-weight: 400;
                }

                span {
                    @media(max-width: 991px) {
                        display: none;
                    }
                }

                strong {
                    margin: 0 5px;
                }

                i.icon-unread {
                    font-size: 11px;
                    position: relative;
                    @media(min-width: 991px) {
                        top: 0.5px;
                    }
                }

                i.icon-read {
                    position: relative;
                }

                i.icon-plus, i.icon-cart {
                    @media(max-width: 991px) {
                        margin-right: 0;
                    }
                }

                i.icon-plus {
                    position: relative;
                    top: 0.5px;
                    font-size: 11px;
                }

                &.blue {
                    @media(min-width: 991px) {
                        border-color: #3C61EA;
                        background: #3C61EA;
                        color: #fff;

                        &:hover {
                            background-color: #1c4ed8;
                        }
                    }
                }
            }
        }

        .messenger-content {
            display: flex;
            flex-direction: column-reverse;
            margin: 0 -20px;
            padding-left: 20px;
            padding-right: 20px;
            overflow-y: auto;
            flex-grow: 1;
            @include custom-scrollbar;
            //max-height: 69vh;
            @media(max-width: 480px) {
                height: auto;
            }

            .infinite-scroll-component {
                overflow: hidden !important;
            }

            .message {
                display: flex;
                align-items: flex-end;
                margin-bottom: 5px;
                max-width: 50%;
                @media(max-width: 991px) {
                    max-width: 75%;
                }

                .avatar {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin: 0;
                    margin-right: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .content {
                    position: relative;
                    display: flex;
                    align-items: flex-end;

                    .status-circle {

                        @keyframes hideCircle {
                            100% {
                                width: 0;
                                height: 0;
                                margin: 0;
                                border: 0;
                                min-width: 0;
                                min-height: 0;
                            }
                        }

                        &.empty {
                            width: 10px;
                            height: 10px;
                            min-width: 10px;
                            min-height: 10px;
                            border: 2px solid variables.$blue;
                            border-radius: 50%;
                            margin-right: 5px;
                        }

                        &.filled {
                            width: 10px;
                            height: 10px;
                            min-width: 10px;
                            min-height: 10px;
                            border: 2px solid variables.$blue;
                            border-radius: 50%;
                            margin-right: 5px;
                            background: variables.$blue;
                            animation: hideCircle 0.2s forwards 0.5s;
                        }
                    }

                    .date {
                        font-size: 10px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 12px;
                        margin-bottom: 5px;
                        position: absolute;
                        left: calc(100% + 5px);
                        top: 50%;
                        transform: translateY(-50%);
                        white-space: nowrap;
                        background: rgba(black, 0.7);
                        color: white;
                        padding: 5px;
                        border-radius: 5px;
                        opacity: 0;
                        visibility: hidden;
                        transition: all .2s ease-in-out;
                    }

                    .text-message-wrapper {
                        .text-message {
                            position: relative;
                            border-radius: 8px;
                            background-color: #F3F4F6;
                            padding: 12px 15px;
                            color: #101827;
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 19px;
                            margin-bottom: 10px;
                            margin-left: 6px;
                            word-break: break-word;
                            white-space: pre-wrap;
                            word-wrap: break-word;

                            &:after {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: -6px;
                                border-left: 10px solid transparent;
                                border-right: 10px solid transparent;
                                border-bottom: 12px solid #F3F4F6;
                                transition: all .2s ease-in-out;
                            }

                            a {
                                color: inherit;
                                text-decoration: underline;
                                @media (max-width: 991px) {
                                    word-break: break-all;
                                }
                            }

                            .button-from-bot {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-decoration: none;
                                border-radius: 5px;
                                background-color: #FFFFFF;
                                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
                                color: #101827;
                                font-size: 12px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 15px;
                                width: 100%;
                                height: 36px;
                                margin-top: 20px;
                                padding: 0 8px;
                            }

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                        }

                        &.pdf-message-wrapper {
                            .pdf-message {
                                display: flex;
                                align-items: center;
                                transition: all .2s ease-in-out;


                                .circle-icon {
                                    margin-right: 10px;
                                    font-size: 16px;
                                }

                                .name {
                                    font-size: 14px;
                                    line-height: 18px;
                                    max-width: 240px;

                                    .size {
                                        opacity: 0.6;
                                        font-size: 12px;
                                        line-height: 14px;
                                    }
                                }

                                &:hover {
                                    background-color: #e8e8ea;

                                    &:after {
                                        border-bottom: 12px solid #e8e8ea;
                                    }
                                }
                            }

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }

                &.right {
                    margin-left: auto;

                    .content {
                        .status-circle {
                            order: 2;
                            margin-right: 0;
                            margin-left: 5px;
                        }

                        .text-message {
                            background: #3B82F6;
                            color: variables.$white;
                            margin-right: 6px;
                            margin-left: 0;

                            &:after {
                                right: -6px;
                                left: auto;
                                border-bottom: 12px solid #3B82F6;
                            }
                        }

                        .date {
                            right: calc(100% + 5px);
                            left: auto;
                        }
                    }

                    &.with-robot-icon {
                        position: relative;

                        .robot-msg {
                            position: absolute;
                            left: -15px;
                            right: auto;
                        }
                    }
                }

                .images-boxes {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -3px;
                    margin-right: -3px;

                    > div {
                        width: 50%;
                        padding: 3px 3px;

                        .file-label {
                            @media(min-width: 991px) {
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .icon-button {
                            width: 45px;
                            height: 45px;
                            border-radius: 8px;
                            background: #f9f9f9;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .uploaded-photo {
                            width: 100%;
                        }
                    }

                    &.three-in-row {
                        > div {
                            width: 33.33%;
                        }
                    }
                }

                .uploaded-photo {
                    margin: 0;
                    width: 140px;

                    img {
                        max-height: 250px;
                        border-radius: 5px;
                        object-fit: contain;
                        max-width: 100%;
                    }

                    &.image {
                        cursor: pointer;
                    }

                    &.audio-box {
                        width: 100%;
                    }
                }

                &:hover {
                    .date {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &.with-robot-icon {
                    position: relative;

                    .robot-msg {
                        position: absolute;
                        right: -15px;
                        top: -15px;
                        background: white;
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;
                        box-shadow: 0 10px 3px 0 rgba(black, 0.1);
                        z-index: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 20px;
                            object-fit: contain;
                        }
                    }
                }

                &.svg-message {
                    .content {
                        .text-message-wrapper {
                            .text-message {
                                background: transparent;
                                padding: 0;

                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                &.last-msg {
                    margin-bottom: 15px;
                }
            }

            .date-separator {
                text-align: center;
                font-size: 12px;
                color: #9CA3AF;
                font-weight: 500;
                padding: 10px 0;
            }

            &.with-error {
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #9CA3AF;
            }
        }
    }

    .send-input-wrapper {
        position: relative;
        display: flex;
        align-items: flex-end;
        padding: 0 0 0 12px;
        border: 1px solid #DFE1E4;
        border-radius: 5px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
        @media(max-width: 991px) {
            border-bottom: 0;
            box-shadow: none;
            border-radius: 0;
            margin-left: -20px;
            margin-right: -20px;
        }

        .textarea-wrapper {
            background: transparent;
            display: flex;
            flex-direction: column;
            width: 100%;
            @media(max-width: 991px) {
                background: #fff;
            }

            textarea {
                background: transparent;
                width: 100%;
                height: 100%;
                padding: 13px 12px;
                border: 0;
                resize: none;
                margin-top: auto;
                max-height: 300px;
                overflow-y: auto;
                padding-right: 115px;
                padding-left: 45px;

                &:focus {
                    outline: none;
                }
            }
        }

        .upload-photo-wrapper {
            position: relative;
            z-index: 1;
            margin-top: auto;
            margin-bottom: 15px;
            cursor: pointer;

            #filePhoto {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                opacity: 0;
                width: 20px;
                z-index: 1;

                &:hover {
                    + .icon-btn {
                        color: #101927;
                    }
                }
            }

            input[type=file],
            input[type=file]::-webkit-file-upload-button {
                cursor: pointer;
            }


            .uploaded-photo {
                position: absolute;
                bottom: 35px;

                img {
                    max-width: 200px;
                    max-height: 200px;
                }

                .remove-photo-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: 0;
                    padding: 0;
                    width: 25px;
                    height: 25px;
                    background: variables.$lgreyColor;
                    color: variables.$white;
                    border-radius: 50%;
                    position: absolute;
                    right: 5px;
                    top: 5px;

                    i {
                        position: relative;
                        left: .5px;
                        top: .5px;
                        font-size: 9px;
                    }
                }
            }

            &.with-photo {
                padding-top: 230px;

                button.icon-button.add-photo {
                    position: relative;
                    top: 4px;
                    @media(max-width: 991px) {
                        top: 4px
                    }
                }

                + textarea {
                    margin: 0;
                    margin-bottom: 3px;
                }
            }

            .error-tooltip {
                background-color: #FEEDF1;
                color: #f75272;
                padding: 10px;
                font-size: 14px;
                border-radius: 5px;
                margin-top: 15px;
                font-weight: 500;
                position: absolute;
                left: -15px;
                bottom: calc(100% + 25px);
                white-space: nowrap;
                display: flex;
                align-items: center;

                &:after {
                    content: "";
                    position: absolute;
                    left: 17px;
                    top: 100%;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 6px solid #FEEDF1;
                }

                button {
                    background: transparent;
                    border: 0;
                    padding: 0;
                    margin: 0;
                    font-size: 10px;
                    color: #f75272;
                    display: flex;
                    align-items: center;
                    position: relative;
                    top: 2px;
                    margin-left: 8px;
                }
            }
        }

        > img {
            //margin-bottom: 1px;
        }

        .icon-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #A1A6BC;
            padding: 0;
            margin: 0;
            font-size: 20px;
            background: transparent;
            border: 0;
            margin-bottom: 1px;
            transition: all .3s ease-in-out;
            @media(max-width: 767px) {
                font-size: 18px;
            }


            &.add-photo {
                color: #a1a6bc;
                position: relative;
                @media(max-width: 991px) {
                    position: relative;
                    top: -1px;
                }
            }

            &:hover {
                color: #101927;
            }
        }

        > textarea, > input {
            position: relative;
            top: -5px;
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            letter-spacing: 0;
            color: variables.$darkColor;
            background-color: transparent;
            border: 0;
            flex-grow: 1;
            resize: none;
            margin: auto -12px 0 -12px;
            padding: 0 12px;

            &.with-scroll {
                top: -6px;
            }

            &::placeholder {
                color: #A1A6BC;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .right-side-buttons {
            position: absolute;
            right: 18px;
            bottom: 8px;
            display: flex;
            align-items: center;

            .emoji-wrapper {
                margin-right: 12px;

                .emoji-dropdown {
                    position: absolute;
                    bottom: 100%;
                    right: 0;
                }
            }

            .send-message {
                width: 32px;
                height: 32px;
                border-radius: 5px;
                background: variables.$blue;
                color: variables.$white;
                font-size: 14px;
                transition: all .1s ease-in-out;

                &:hover {
                    background-color: #1c4ed8;
                }
            }
        }
    }

    .scroll-list-loader, .no-results-text {
        text-align: center;
        font-size: 14px;
        color: #6B7280;
        font-weight: 500;

        img {
            display: block;
            width: 80px;
            margin: 0 auto 0 auto;
        }
    }

    .selmo-alert {
        position: fixed;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 6px 22px 0 rgba(16, 24, 39, 0.08);
        padding: 11px;
        border-left: 5px solid #000;
        z-index: 9999;
        min-width: 400px;
        animation: slideDown ease-in-out 0.3s forwards;

        @media(max-width: 767px) {
            min-width: 0;
            width: 90%;
        }

        .top-part {
            display: flex;
            align-items: center;
        }

        .icon {
            margin-right: 8px;
            height: 16px;
            width: 16px;
            border-radius: 3px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 7px;
        }

        .title {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;
            display: flex;
            margin-top: 1px;
            align-items: center;
        }

        .btn-close {
            margin-left: auto;
            padding-right: 0;
            position: relative;
            top: 1px;
            font-size: 10px;

            &:before {
                font-size: 10px;
            }
        }

        .desc {
            color: #246C57;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;
            margin-left: 25px;
            margin-top: 3px;
        }

        &.alert-success {
            border: 1px solid #0EB981;
            background-color: #F1FAF7;

            .icon-tick {
                position: relative;
                left: 0.2px;
                top: -0.7px;

                &:before {
                    font-weight: bold;
                }
            }

            .icon {
                background: #0EB981;
            }

            .title {
                color: #2A473E;
            }

            .btn-close {
                color: #45B796;
            }

            .desc {
                color: #246C57;
            }
        }

        &.alert-fail {
            border: 1px solid #F87171;
            background-color: #FDF1F1;

            .icon-cross {
                position: relative;
                left: .7px;
                top: -0.7px;
            }

            .icon {
                background: #F87171;
            }

            .title {
                color: #D34545;
            }

            .btn-close {
                color: #F87171;
            }

            .desc {
                color: #F87171;
            }
        }
    }

    @keyframes slideDown {
        0% {
            transform: translateY(-30px) translateX(-50%);
        }
        100% {
            transform: translateY(0) translateX(-50%);
        }
    }

    &.open {
        .left-panel {
            @media(max-width: 991px) {
                transform: translateX(-100%);
            }
        }

        .right-panel {
            @media(max-width: 991px) {
                z-index: 1;
                padding-top: 15px;
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                top: 0;
            }
        }
    }

    .choose-conversation {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        .empty-state {
            width: 400px;
            text-align: center;

            .img-icon {
                width: 104px;
                margin: 0 auto 20px auto;
                text-align: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .title {
                text-align: center;
                color: #1F2937;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 22px;
            }

            .desc {
                color: #6B7280;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 21px;
                text-align: center;
                margin-top: 10px;
                margin-bottom: 32px;
            }
        }
    }


    .add-new-row {
        margin-left: 3px;

        &:focus, &:focus-visible {
            box-shadow: 0 0 0 3px rgba(0, 97, 254, 0.5) !important;
            outline: none !important;
        }
    }

    &.full-screen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
        z-index: 99;
        max-height: none;
    }
}

.tooltip {
    .tooltip-inner {
        font-size: 12px;
    }
}
