.sortable-tree-wrapper {

	.category-tree {
		padding: 0;
		margin: 0;
		list-style: none;
		&.products-tree {
			margin-left: -20px;
			margin-right: -20px;
			.sortable-tree-item.no-border {
				box-shadow: none;
				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}
}

.sortable-tree-item {
	list-style: none;
	margin: 8px 0;
	cursor: grab;
	border: 1px solid #DFE1E4;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
	border-radius: 5px;
	color: #6B7280;
	font-size: 16px;
	background: #fff;


	a {
		color: #101827;
	}

	.sort-button {
		color: #9CA3AF;
		margin-right: 8px;
		padding: 5px;
		transition: all .2s ease-in-out;
		border-radius: 2px;
		&.in-banners {
			margin-top: 12px;
			align-self: flex-start;
		}
	}

	.category-row {
		padding: 14px 12px 18px;
		display: flex;
		align-items: center;
		color: #101827;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: -0.1px;
		line-height: 21px;

		.sub-categories-count {
			background: transparent;
			border: 0;
			margin: 0;
			display: flex;
			align-items: center;
			color: #6B7280;
			font-size: 12px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 15px;
			margin-left: auto;
			padding: 8px 0;
			transition: all .2s ease-in-out;

			&:hover {
				color: #000;
			}

		}

		&:hover {
			.sort-button {
				background-color: #F3F4F6;
			}
		}
	}

	.sub-tree {
		margin-left: 22px;
		padding: 0 12px 20px 12px;
		@media(max-width: 480px) {
			margin-left: 12px;
		}
	}

	.product-row {
		display: flex;
		align-items: center;
		padding: 4px;


		.product-name {
			color: #101827;
			font-size: 0.875rem;
			line-height: 1.125rem;
			font-weight: 600;
		}
	}

	.toggle-icon {
		font-size: 8px;
		color: #9CA3AF;
		margin-left: 5px;
	}

	&.with-products {

	}

	&.new-styles {
		border: 0;
		box-shadow: none;
	}
}

.sub-category-row {
	display: flex;
	align-items: center;
	list-style: none;
	margin-bottom: 5px;

	.sort-button {
		color: #9CA3AF;
		margin-right: 8px;
		padding: 5px;
		transition: all .2s ease-in-out;
		border-radius: 2px;
	}


	.sortable-tree-item {
		flex-grow: 1;
		padding: 7px;
		margin-top: 0;
		font-size: 14px;
		color: #101827;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 18px;
		margin-bottom: 0;
	}
}

.product-row {
	background: #fff;
	padding: 10px;

	.number {
		color: #101827;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 18px;
	}

	.price {
		font-size: 12px;
		font-weight: normal;
	}

	figure {
		width: 40px;
		height: 40px;
		margin: 0;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.sortable-tree-item.no-border {
	border: 0;
	border-bottom: 1px solid #F6F7F8;
	background: #fff;
	padding: 10px 20px;
	margin: 0;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
}
