@use 'styles/vendor/vendor';
@use 'styles/variables';
@use 'styles/aside';
@use 'styles/global';
@use 'styles/activate-button';
@use 'styles/typo';
@use 'styles/common';
@use 'styles/icons';
@use 'styles/table';
@use 'styles/pagination';
@use 'styles/buttons';
@use 'styles/modal';
@use 'styles/forms';
@use 'styles/daterange-picker';
@use 'styles/sortable-tree';
@use 'styles/pages/pages';
@use 'styles/sliders';
@import 'react-time-picker/dist/TimePicker.css';
@import 'react-clock/dist/Clock.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

code {
    font-family:
        source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
    font-family: 'Plus Jakarta Sans', 'Helvetica', sans-serif;
    font-size: 16px;
    background: variables.$mainBg;
}
