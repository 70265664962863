@use '../variables';

.clients-page {
	.status-filters {
		margin-top: 0 !important;

		&:last-of-type {
			display: none !important;
		}
	}

	.avatar-row {
		display: flex;
		align-items: center;

		figure {
			margin-bottom: 0;
			margin-right: 10px;
		}

		img {
			height: 25px;
			width: 25px;
			border-radius: 50%;
		}
	}
}

.orders-page-table {
	@media(max-width: 991px) {
		margin: -20px;
	}

	.dataTables_wrapper {
		position: relative;
		margin-bottom: 35px;

		.dataTables_length {
			position: absolute;
			bottom: 5px;
			left: 0;
			font-family: 'Plus Jakarta Sans';
			font-size: 12px;

			label {
				margin-bottom: 0;
			}

			select {
				border-radius: 5px;
				height: 33px;
				border: 1px solid #DFE1E4;
				font-size: 0.75rem;
				margin: 0 5px;
			}
		}

		.custom-header {
			background: variables.$white;
			box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
			padding: 28px 22px;
			border-radius: 5px 5px 0 0;
			@media(max-width: 991px) {
				padding: 10px 20px;
			}

			.top-part {
				display: flex;
				align-items: center;
				justify-content: space-between;
				@media(max-width: 991px) {
					justify-content: normal;
				}
				@media(max-width: 330px) {
					flex-wrap: wrap;
				}

				h1 {
					font-size: 18px;
					font-weight: bold;
					line-height: 23px;
					color: variables.$darkColor;
					display: flex;
					align-items: center;
					margin-bottom: 0;
					@media(max-width: 991px) {
						margin-bottom: 0;
					}
					@media(max-width: 480px) {
						font-size: 16px;
						line-height: 20px;
					}
				}

				.button.primary {
					padding: 8px 18px;
				}

				.add-order-table {
					@media(max-width: 991px) {
						order: 3;
						border: 1px solid #DFE1E4;
						border-radius: 4.5px;
						box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
						color: #6B7280;
						font-size: 12px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 15px;
						padding: 7px 11px;
						display: inline-flex;
						align-items: center;
						span:before {
							display: none;
						}
					}
					@media(max-width: 330px) {
						width: 100%;
						justify-content: center;
						margin-top: 15px;
					}
				}
			}

			.status-filters {
				display: flex;
				align-items: center;
				margin-top: 20px;
				@media(max-width: 1200px) {
					flex-wrap: wrap;
				}

				.select2 {
					@media(max-width: 991px) {
						width: 100% !important;
						margin: 10px 0;
					}
				}

				.dataTables_filter {
					order: 2;
					margin-left: auto;
					@media(max-width: 991px) {
						width: 100%;
					}

					label {
						position: relative;
						font-size: 0;
						color: transparent;
						margin-bottom: 0;
						@media(max-width: 991px) {
							width: 100%;
						}

						input {
							border-radius: 5px;
							height: 33px;
							width: 120px;
							color: variables.$darkColor;
							font-size: 0.75rem;
							line-height: 1rem;
							padding: 8px 8px 8px 30px;
							background-color: #F3F4F6;
							border: 0;
							@media(max-width: 991px) {
								width: 100%;
							}

							&:focus {
								outline: none;
							}

							&::-webkit-search-cancel-button {
								display: none;
							}

							&::placeholder {
								font-weight: 400;
								color: variables.$lgreyColor;
								font-size: 12px;
								letter-spacing: 0;
								line-height: 15px;
							}
						}

						&:before {
							content: '\e913';
							font-family: selmo;
							position: absolute;
							left: 8px;
							top: 6px;
							font-size: 14px;
							color: #9AA3B0;
						}
					}
				}

				.filter-box {
					position: relative;
					display: flex;
					align-items: center;
					order: 3;
					margin-left: 10px;
					@media(max-width: 991px) {
						justify-content: flex-start;
						margin-left: 0;
					}

					.daterange-input {
						border-radius: 5px 0 0 5px;
						height: 33px;
						border: 1px solid variables.$darkBorder;
						font-size: 0.75rem;
						line-height: 1rem;
						padding: 8px 8px 8px 25px;
						color: #6B7280;
						border-right: 0;
						width: 125px;
						@media(max-width: 991px) {
							flex-grow: 1;
						}

						&:focus {
							outline: none;
						}

					}

					&:before {
						content: '\e905';
						font-family: selmo;
						position: absolute;
						left: 8px;
						top: 50%;
						transform: translateY(-50%);
						color: variables.$darkBorder;
						font-size: 14px;
					}

					&:after {
						content: '\e903';
						font-family: selmo;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 8px;
						width: 35px;
						border: 1px solid variables.$darkBorder;
						height: 33px;
						border-radius: 0 5px 5px 0;
						color: variables.$greyColor;
					}
				}

				.filter-btn {
					font-size: 0.75rem;
					line-height: 1rem;
					color: variables.$greyColor;
					text-decoration: none;
					padding: 9px 14px;
					border-radius: 5px;
					font-weight: bold;
					transition: all .2s ease-in-out;
					@media(max-width: 991px) {
						display: block;
					}

					&.active {
						color: variables.$darkColor;
						background: rgba(variables.$greyColor, 0.1);
					}

					&:hover {
						color: variables.$darkColor;
					}
				}
			}

			@media(max-width: 991px) {
				.status-filters {
					display: none;
				}
				&.active {
					.status-filters {
						display: block;
					}
				}
			}
		}

		.table-responsive {
			background: variables.$white;
			box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.04), 0 1px 3px 0 rgba(0, 0, 0, 0.05);
			border-radius: 0 0 5px 5px;
		}
	}
}

.products-wrapper {
	padding-top: 18px;
	margin-top: 22px;
	border-top: 1px solid #F3F4F6;

	.product-removed {
		.order-summary__products__content__name, .order-summary__products__content__price, .order-summary__products__content__size {
			text-decoration: line-through;
		}

		.removed-by-client {
			padding: 5px 8px;
			margin-top: 3px;
			color: #EF4444;
			font-size: 10px;
			font-weight: bold;
			letter-spacing: 0;
			border-radius: 3px;
			background-color: rgba(239, 68, 68, 0.1);
			line-height: 12px;
		}
	}

	.order-summary {
		width: 360px;
		margin-left: 50px;
		position: sticky;
		top: 50px;
		@media(max-width: 991px) {
			width: 100%;
		}

		&__title {
			color: #101827;
			font-size: 18px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 23px;
		}

		&__products {
			list-style: none;
			padding: 8px 0;
			margin: 0;

			li {
				display: flex;
				align-items: flex-start;

				figure {
					position: relative;
					margin: 0;
					margin-right: 12px;
					width: 36px;
					height: 36px;
					min-width: 36px;
					margin-top: 22px;

					img {
						width: 100%;
						height: 100%;
						border-radius: 5px;
						object-fit: cover;
					}

					.count {
						position: absolute;
						top: -8px;
						right: -8px;
						color: variables.$white;
						border-radius: 50%;
						height: 20px;
						width: 20px;
						background-color: variables.$greyColor;
						font-size: 10px;
						font-weight: bold;
						letter-spacing: 0;
						line-height: 12px;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 2px solid #fff;
						z-index: 1;

						span {
							position: relative;
						}

						&.active {
							background-color: #5fa5fa;
						}
					}
				}

				&:last-of-type {
					.order-summary__products__content {
						border-bottom: 0;
					}
				}
			}

			&__content {
				display: flex;
				flex-grow: 1;
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0;
				line-height: 21px;
				border-bottom: 1px solid #F3F4F6;

				&__name {
					color: #101827;
				}

				&__size {
					font-size: 12px;
					line-height: 16px;
					font-weight: 500;
					color: variables.$lgreyColor;
					margin-top: 2px;

					span {
						color: #9CA3AF;

						strong {
							color: #101827;

						}
					}
				}

				&__price {
					margin-left: auto;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 18px;
					text-align: right;
				}

				.form-group {
					margin-top: 7px;

					.control-label {
						display: none;
					}

					#custom-dropdown-default {
						[class*='-menu'] {
							[class*='-option'] {
								font-weight: normal;
							}
						}

						[class*='-control'] {
							min-height: auto;
							box-shadow: none;
							border: 0;

							.chosen-option {
								display: flex;
								align-items: center;
								font-size: 10px;
								font-weight: bold;
								letter-spacing: 0;
								line-height: 10px;
								border-radius: 3px;
								padding: 6px;

								i {
									position: relative;
									top: -1px;
									font-size: 14px;
									margin-right: 4px;
								}

								> div {
									padding: 0;
								}

								&.red-option {
									color: #BC1010;
									background-color: #FDF0F0;
									i {
										color: #E64747 !important;
									}
								}

								&.green-option {
									color: #075A3F;
									background-color: #F2FAF7;

									i {
										color: #34D299 !important;
									}
								}

								[class*="-singleValue"] {
									overflow: visible;
								}
							}

							[class*='-ValueContainer'] {
								padding: 0;
							}

							[class*='-indicatorContainer'] {
								position: relative;
								top: 1px;
								margin-right: 0;
							}
						}
					}
				}
			}
		}

		&__prices {
			padding: 25px 0;
			border-bottom: 1px solid variables.$greyBg;
			border-top: 0;
			@media(max-width: 991px) {
				padding: 15px 0;
			}
		}

		&__final-price {
			.price-row {
				padding: 25px 0 5px;

				.name {
					color: #101827;
					font-size: 20px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 25px;
					@media(max-width: 767px) {
						color: #4B5563;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 18px;
					}
				}

				.value {
					color: #101827;
					font-size: 20px;
					font-weight: 500;
					letter-spacing: 0;
					line-height: 25px;
					text-align: right;
					@media(max-width: 767px) {
						color: #101827;
						font-size: 20px;
						font-weight: bold;
						letter-spacing: -0.2px;
						line-height: 25px;
					}
				}
			}
		}
	}

	.price-row {
		display: flex;
		align-items: center;
		margin-bottom: 4px;

		&:last-of-type {
			margin-bottom: 0;
		}

		.name {
			color: #101827;
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 18px;
			flex-grow: 1;
			@media(max-width: 767px) {
				color: #4B5563;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 18px;
			}
		}

		.value {
			color: #101827;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 20px;
			white-space: nowrap;
		}
	}
}

body.open-filters {
	.status-filters {
		@media(max-width: 991px) {
			opacity: 1;
			visibility: visible;
		}
	}
}

.storage-page-table {
	.dataTables_wrapper {
		.custom-header {
			.top-part {
				.add-order-table {
					@media (max-width: 991px) {
						color: #fff;
						font-weight: normal;
					}
				}
			}
		}
	}
}
