@use '../../variables';

.live-transmissions-wrapper {
    position: relative;
    @media(max-width: 767px) {
        display: flex;
        flex-direction: column;
    }

    .breadcrumbs {
        position: absolute;
        top: -35px;
        @media(max-width: 991px) {
            position: static;
            padding: 15px;
            order: 1;
        }
    }

    .tutorial-page-box {
        @media(max-width: 991px) {
            order: 2;
        }
    }

    .settings-page {
        @media(max-width: 991px) {
            order: 3;
        }
    }

    .live-details-wrapper {
        > .loader-container {
            top: -45px;
            bottom: 0;
            height: auto;
        }

        .left-col {
            @media(max-width: 991px) {
                order: 2;
                margin-top: 10px;
            }
        }

        .clients-col {
            .infinite-scroll-component__outerdiv {
                @media(max-width: 991px) {
                    width: 100%;
                }

                .infinite-scroll-component {
                    overflow: visible !important;
                }
            }

            .finished-live-box {
                padding-top: 22px;
                padding-bottom: 22px;

                .title {
                    color: #101827;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 18px;
                    margin-bottom: 4px;
                }

                .desc {
                    color: #6B7280;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: -0.1px;
                    line-height: 18px;
                }

                .button.primary {
                    padding: 9px 22px;
                }
            }

            .clients-header {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                width: 100%;
                @media(max-width: 480px) {
                    flex-wrap: wrap;
                }

                > .sort-button {
                    margin-right: 15px;
                    font-size: 14px;
                    @media(max-width: 480px) {
                        margin-left: auto;
                    }

                    &.active {
                        color: variables.$darkColor;
                    }
                }

                .title {
                    margin-bottom: 0;
                    @media(max-width: 480px) {
                        width: auto;
                    }
                }

                .react-dropdown.no-hover {
                    @media(max-width: 480px) {
                        width: 100%;
                        margin-bottom: 12px;
                        order: 3;
                    }

                    .dropdown-toggle {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #6B7280;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: -0.2px;
                        line-height: 15px;
                        z-index: auto;
                        border: 1px solid #DFE1E4;
                        border-radius: 5px;
                        background-color: #FFFFFF;
                        min-width: 120px;
                        height: 33px;
                        padding: 0 12px;

                        i {
                            font-size: 8px;
                            margin-left: 5px;
                        }

                        &:before {
                            display: none;
                        }
                    }
                }

                .form-group.search-form {
                    margin-left: auto;
                    @media(max-width: 480px) {
                        width: 100%;
                        order: 2;
                        .form-control {
                            width: 100%;
                        }
                    }
                }
            }

            .form-info-box {
                margin-bottom: 15px;
                margin-top: -10px;

                i {
                    top: -5px;
                }

                .title {
                    margin-bottom: 8px;
                }

                .desc ul {
                    margin: 0;
                    padding: 0 0 0 17px;

                    li {
                        color: #101827;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 16px;
                        margin-bottom: 6px;
                    }
                }
            }

            .clients-products-wrapper {
                border-radius: 12px;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.07);
                background: #fff;

                .default-shadow-box {
                    border-radius: 0;
                    box-shadow: none;
                    background: transparent;
                }
            }
        }

        .summary-col {
            @media(max-width: 991px) {
                .form-group {
                    width: 100%;
                }
            }
        }

        .summary-box {
            //position: sticky;
            //top: 25px;
            padding-bottom: 26px;
            padding-top: 20px;
            @media(max-width: 991px) {
                position: static;
            }

            .title.small-size {
                font-size: 14px;
                line-height: 20px;
            }

            .top-part {
                display: flex;
                align-items: flex-start;
                @media(max-width: 991px) {
                    width: 100%;
                }

                .title {
                    font-size: 16px;
                    margin-bottom: 0;

                    span:first-of-type {
                        display: flex;
                        align-items: center;

                        &:after {
                            position: relative;
                            top: 1px;
                            content: "";
                            display: inline-block;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background: #000;
                            margin: 0 8px;
                        }
                    }
                }

                .order-export-wrapper {
                    .tooltip-content {
                        bottom: calc(100% + 12px);
                    }
                }

                .show-comments {
                    position: relative;
                    top: -1px;
                    margin-right: 14px;
                }

                .refresh-price {
                    font-size: 13px;
                    line-height: 13px;
                    color: inherit;
                    margin-left: 8px;
                }

                .react-dropdown {
                    > button {
                        font-size: 14px;
                    }
                }

                &.with-border {
                    margin-left: -20px;
                    margin-right: -20px;
                    padding: 0 20px 15px;
                    border-bottom: 1px solid #F3F4F6;
                }
            }

            .form-group {
                margin-top: 20px;
            }

            .sum-part {
                margin: 20px -20px 0;
                padding: 15px 20px 0;
                border-top: 1px solid #F6F7F8;
                flex-grow: 1;

                .price-info {
                    display: flex;
                    justify-content: space-between;

                    .label {
                        color: #4B5563;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 18px;
                    }


                    .price-box {
                        line-height: 18px;

                        .price {
                            display: flex;
                            align-items: center;
                            color: #101827;
                            font-size: 18px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 20px;
                            text-align: right;
                            white-space: nowrap;

                            .refresh-price {
                                font-size: 13px;
                                line-height: 13px;
                                color: inherit;
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }

            .buttons-place {
                width: 100%;

                .button.border-button {
                    height: 40px;
                    width: 100%;
                    margin-bottom: 6px;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    justify-content: flex-start;

                    i {
                        margin-left: auto;
                        font-size: 13px;
                        color: #C0C6CE;

                        &.icon-plus {
                            font-size: 10px;
                        }
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &:first-of-type {
                        margin-top: 15px;
                    }
                }
            }
        }

        .add-product-wrapper {
            margin-bottom: 20px;

            .product-box {
                border-bottom: 1px solid #F6F7F8;
                padding: 0 20px 20px;
                margin: 0 -20px 20px;
                @media(max-width: 991px) {
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                }
            }

            .add-new-row {
                @media(max-width: 991px) {
                    margin-top: 10px;
                }
            }

            .add-several-rows {
                display: flex;
                align-items: center;
                margin-left: auto;

                .form-group {
                    margin-bottom: 0;
                    width: 55px;
                    margin-right: 5px;

                    .form-control {
                        min-height: auto;
                        padding: 7px;
                    }
                }
            }
        }

        .client-group-wrapper {
            width: 100%;

            .client-group {
                border: 1px solid #DFE1E4;
                border-radius: 5px;
                background-color: #FFFFFF;
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
                margin-bottom: 10px;
                @media(max-width: 991px) {
                    box-shadow: none;
                    border-radius: 0;
                    border-width: 1px 0 0;
                    margin-bottom: 0;
                    margin-left: -15px;
                    margin-right: -15px;
                }

                .top-part {
                    padding: 16px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;


                    .name-with-status {
                        display: flex;
                        align-items: center;
                        flex-grow: 1;
                        @media(max-width: 991px) {
                            flex-wrap: wrap;
                        }
                    }

                    .avatar-col {
                        display: flex;
                        align-items: center;
                    }

                    .name {
                        font-size: 14px;
                        font-weight: bold;
                        flex-grow: 1;
                        line-height: 19px;

                        .client-number {
                            color: #6B7280;
                            font-size: 10px;
                            font-weight: 300;
                            line-height: 10px;
                        }

                        @media(max-width: 991px) {
                            width: 100%;
                            font-size: 12px;
                            line-height: 15px;
                        }
                    }

                    .price {
                        color: #101827;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 15px;
                        min-width: 50px;
                        text-align: right;
                        white-space: nowrap;
                    }

                    .statuses-icons {
                        cursor: default;
                        display: flex;
                        align-items: center;
                        @media(max-width: 991px) {
                            margin-top: 5px;
                        }

                        button {
                            background: transparent;
                            border: 0;
                            padding: 0;

                            i {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #979797;
                                font-size: 12px;
                                border: 1px solid transparent;
                                transition: all .2s ease-in-out;
                                height: 24px;
                                width: 24px;
                                border-radius: 50%;
                                background-color: #F3F4F6;
                                margin-right: 5px;

                                &.icon-new {
                                    font-size: 10px;

                                    &.active {
                                        color: #3c61ea;
                                        background: #EAEFFD;
                                    }

                                }

                                &.icon-cart-fill.active {
                                    color: #F59E0C;
                                    background: #FFF5E4;
                                }

                                &.icon-summary.active {
                                    color: #0eb981;
                                    background: #E1F9F2;
                                }
                            }

                            &:hover:not(:disabled) {
                                i {
                                    cursor: pointer;
                                    border: 1px solid #D1D5DB;
                                }
                            }
                        }
                    }

                    .buttons-group {
                        display: flex;
                        align-items: center;

                        .button {
                            background: transparent;
                            border: 0;
                            padding: 0;
                            color: #9CA3AF;
                            font-size: 10px;
                            margin-left: 16px;

                            i {
                                transform: none;
                            }
                        }

                        .react-dropdown {
                            margin-left: 16px;
                        }
                    }
                }

                .products-row-wrapper {
                    padding: 0 16px 16px;
                    display: none;

                    .buttons-row {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .button:not(.add-new-row) {
                            padding: 8px 14px;
                        }
                    }

                    .current-basket-products {
                        .products-list {
                            .products-body > div .count {
                                font-size: 12px;
                            }
                        }
                    }

                    .label {
                        color: #101827;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: -0.12px;
                        line-height: 18px;
                        margin-bottom: 10px;
                    }
                }

                &.active {
                    .products-row-wrapper {
                        display: block;
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .default-shadow-box {
            @media(max-width: 991px) {
                box-shadow: none;
                padding: 18px 15px;
            }
        }
    }
}

@mixin custom-scrollbar {
    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #e7e7e7;
    }
}

.comments-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 350px;
    background: #fff;
    animation: slideInLeft 0.3s forwards;
    left: auto;
    z-index: 999;
    box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.2);
    @media(max-width: 991px) {
        width: 100%;
        box-shadow: none;
        left: 0;
        height: 100%;
    }

    .comments-header {
        position: relative;
        border-bottom: 1px solid #e5e7eb;
        padding: 20px;
        background-color: #f9fafb;
        display: flex;
        justify-content: center;
        min-height: 62px;
        @media(max-width: 480px) {
            padding: 15px 12px;
            min-height: 52px;
        }

        > button {
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            color: #6B7280;
            font-size: 10px;
            background: 0;
            border: 0;
            padding: 10px;
        }

        .right-side-buttons {
            position: absolute;
            display: flex;
            margin-left: auto;
            align-items: center;
            left: auto;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            font-size: 14px;
            transition: all .2s ease;

            > .search-icon-button {
                padding: 5px;
                position: relative;
                top: -1px;
                margin-right: 10px;

                @media(max-width: 991px) {
                    margin-right: 0;
                    color: #6B7280;
                }
            }

            &:hover {
                color: #101827;
            }
        }


        .title {
            color: #101827;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 21px;
        }

        .search-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            padding: 0 20px;

            .form-group {
                width: 100%;

                .form-control {
                    width: 100%;
                    border: 1px solid #e5e7eb;
                    border-radius: 5px;
                    background: #fff;
                    height: 35px;
                }
            }

            .icon-button {
                color: #9CA3AF;
                position: absolute;
                font-size: 8px;
                top: 50%;
                background: transparent;
                border: 0;
                padding: 10px;
                right: 20px;
                transform: translateY(-50%);
            }
        }
    }

    .infinite-scroll-component__outerdiv {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        > div {

        }
    }

    .zoom-buttons {
        display: flex;
        align-items: center;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 72px;
        font-size: 16px;
        border: 1px solid #DFE1E4;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07);
        padding: 3px;
        @media(max-width: 991px) {
            right: 48px;
        }

        button {
            color: #9CA3AF;
            background: transparent;
            border: 0;
            padding: 0;
            width: 24px;
            display: flex;
            height: 24px;
            align-items: center;
            justify-content: center;
            transition: all .2s ease;
            border-radius: 3px;
            margin-right: 3px;

            &.no-bg-hover {
                width: auto;
                height: auto;
                color: #6B7280;
                font-size: 12px;
                line-height: 16px;
                margin-right: 6px;
                margin-left: 6px;
                max-width: 1000px;
                transition: max-width 0.5s ease-in-out, opacity 0.3s ease-in-out;


                &.hidden {
                    opacity: 0;
                    max-width: 0;
                    margin: 0;
                    transition: all 0.25s cubic-bezier(0, 1, 0, 1);
                }
            }

            &.minus {
                font-size: 18px;
            }

            i {
                font-size: 10px;
            }

            span {
                position: relative;
                top: -1px;
            }

            &:last-of-type {
                margin-right: 0;
            }

            &:hover:not(.no-bg-hover) {
                background: #F3F4F6;
            }

            &:hover {
                color: #4B5563;
            }
        }
    }

    .virtualization-list {
        @include custom-scrollbar;

        .message:first-child {
            border-bottom: 1px solid #F6F7F8 !important;
        }
    }

    .message {
        display: flex;
        border-bottom: 1px solid #F6F7F8;
        padding: 12px 20px;
        transition: all .2s ease;
        //animation: fadein .3s ease;
        @media(max-width: 480px) {
            padding: 12px;
        }

        figure {
            position: relative;
            width: 32px;
            height: 32px;
            margin: 0;
            margin-right: 10px;
            min-height: auto;

            .loader-container {
                min-height: auto;

                .circle-loader {
                    top: 50%;
                    width: 100%;
                    height: 100%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    .circle {
                        width: 24px;
                        height: 24px;
                        border-width: 2px;
                    }
                }
            }

            @media(max-width: 480px) {
                width: 28px;
                height: 28px;
            }

            > img:not(.msg-logo) {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }

            .msg-logo {
                z-index: 1;
            }

            .add-customer {
                position: absolute;
                top: -1px;
                left: -1px;
                right: -1px;
                bottom: -1px;
                border-radius: 100%;
                padding: 0;
                margin: 0;
                border: 0;
                background: #eeeeee;
                color: #000;
                opacity: 0;
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 1px 1px 0 rgba(#000, 0.07);
                transition: all .2s ease;

                i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .content {
            .name {
                display: flex;
                color: #101827;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 18px;
                margin-bottom: 2px;
            }

            .info-row {
                display: flex;
                align-items: center;
                border-radius: 10px;
                transition: all .2s ease;
                height: 10px;

                .number {
                    color: #101827;
                    font-size: 10px;
                    letter-spacing: 0;
                    line-height: 12px;

                    strong {
                        margin-left: 2px;
                    }
                }

                .status-bg {
                    font-size: 8px;
                    letter-spacing: 0;
                    line-height: 10px;
                    padding: 2px 4px;
                    min-width: auto;
                }

                > div {
                    white-space: nowrap;
                    display: inline-flex;
                    align-items: center;

                    &:after {
                        content: "";
                        height: 2px;
                        width: 2px;
                        background-color: #9CA3AF;
                        display: block;
                        margin: 0 5px;
                        border-radius: 100%;
                    }

                    &:last-of-type {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            .date {
                color: #6B7280;
                font-size: 10px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 12px;
                white-space: nowrap;
            }


            .text {
                display: inline-block;
                font-weight: 500;
                margin-top: 6px;
                margin-bottom: 6px;
                border-radius: 10px;
                background-color: #F3F4F6;
                color: #101827;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                padding: 10px 12px;
                word-break: break-word;
                transition: all .2s ease;

                @media(max-width: 480px) {
                    font-size: 13px;
                    padding: 8px 10px;
                    line-height: 17px;
                }
            }

            .replied-message-container {
                margin-top: 8px;

                .content {
                    .name {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        font-weight: normal;
                        margin-left: 8px;
                        margin-bottom: 4px;

                        i {
                            font-size: 12px;
                            color: #9CA3AF;
                            margin-right: 5px;
                        }

                        figure {
                            width: 18px;
                            height: 18px;
                            margin: 0 5px;
                            position: relative;
                            top: -2px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .text {
                        background: #E5E7EB;
                        margin-bottom: -5px;
                        margin-top: 0;
                    }
                }

                + .text {
                    margin-top: 0;
                }
            }
        }

        &.selected:not(:hover) {
            background: #EFF6FF;
            border-color: #92C2FB;

            .content {
                .text {
                    background: #D1E5FF;
                }

                .replied-message-container .content .text {
                    background: #d5e3ff;
                }
            }

            + .message {
                border-color: #92C2FB;
            }
        }

        &:first-of-type {
            border-color: transparent !important;
        }

        &:hover {
            background: #F6F7F8;

            .content {
                .text {
                    background: #eeeeee;
                }
            }
        }

        &.ready-to-add {
            &:hover {
                .add-customer {
                    opacity: 1;
                }
            }
        }

        &.responded {
            background: #EDFDF5;
            border-color: #34D299;

            .content {
                .text {
                    background: #04B077;
                    color: #fff;
                }

                .replied-message-container .content .text {
                    background: #067857;
                    color: #fff;
                }
            }

            + .message {
                border-color: #34D299;
            }
        }

        &.loader-parent {
            min-height: auto;

            .loader-container {
                min-height: auto;
                background: rgba(#fff, 0.6);
                z-index: 9;
            }
        }
    }

    .scroll-content {
        position: relative;
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        padding: 15px 0;
        @include custom-scrollbar;
        @media(max-width: 480px) {
            padding: 0;
        }

        @keyframes fadein {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    &.full-screen {
        left: 0;
        width: 100%;

        .scroll-content {
            .message .content {
                max-width: 300px;
            }
        }
    }

    &:not(.full-screen) {
        .scroll-content {
            zoom: 1 !important;
        }
    }
}

html.open-live-comments, html.open-live-comments body {
    @media(max-width: 767px) {
        overflow: hidden;
        height: 100%;
    }
}

.current-basket-products {
    .label {
        color: #101827;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.12px;
        line-height: 18px;
        margin-bottom: 10px;
    }

    .product-box {
        display: flex;
        align-items: center;
        color: #101827;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        margin: 16px 0;

        a {
            color: inherit;
        }

        figure {
            height: 32px;
            width: 32px;
            border-radius: 3px;
            margin-right: 8px;
            margin-bottom: 0;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .product-price {
            margin-left: auto;
        }
    }

    .products-list {
        .products-header, .products-body {
            .name {
                flex-grow: 1;
            }

            .count {
                width: 55px;
                margin-right: 35px;
                text-align: right;

                &.red {
                    color: #EF4444;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .products-header {
            color: #6B7280;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 12px;
            padding-bottom: 8px;
            display: flex;
            align-items: center;
        }

        .products-body {
            color: #101827;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 15px;

            > div {
                display: flex;
                align-items: center;
                padding: 8px 0;
                border-top: 1px solid #F3F4F6;

                .count {
                    font-size: 14px;

                    &:last-of-type {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &.loader-parent {
        min-height: 100px;
    }

    &.bigger-size {

        .products-body {
            font-size: 14px;
        }
    }
}
